// ResetPassword.js
import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Button, Container,
  FormControl, FormLabel, Input, VStack, Text,
  useToast,
  Menu, MenuButton, IconButton, MenuList, MenuOptionGroup, MenuItemOption,
  Spinner
} from '@chakra-ui/react';
import { GrLanguage } from "react-icons/gr";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { token } = useParams();
  const [, setLanguage] = useState(i18next.language || 'zh');
  const { t } = useTranslation();

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18next.changeLanguage(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: t('resetpassword.fail'),
        description: t('resetpassword.fail_notmatch'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`/api/v1/accounts/reset-password/${token}/`, { password });
      toast({
        title: t('resetpassword.success'),
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      navigate('/sign-in');
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      bg="#f5f7fa"
      minHeight="100vh" 
      py={8}>
      <Container maxW="500px">
        <Box 
          borderWidth={1} 
          borderRadius="16px" 
          p={8} 
          position="relative"
          bg="rgba(255, 255, 255, 0.7)"
          backdropFilter="blur(10px)"
          boxShadow="lg"
        >
          <Box position="absolute" top={4} right={8}>
            <Menu>
            <MenuButton as={IconButton} icon={<GrLanguage />} colorScheme="gray" variant="outline" aria-label="Language Switcher" />
              <MenuList>
                <MenuOptionGroup type='radio'>
                  <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                  <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
          <VStack mt={5} spacing={4} align="stretch">
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">{t('resetpassword.title')}</Text>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>{t('resetpassword.newpasswordfield')}</FormLabel>
                  <Input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder={t('resetpassword.newpasswordfield_placeholder')} 
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t('resetpassword.confirmnewpasswordfield')}</FormLabel>
                  <Input 
                    type="password" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                    placeholder={t('resetpassword.confirmpasswordfield_placeholder')} 
                  />
                </FormControl>
                <Button 
                  width="full" 
                  type="submit" 
                  isLoading={isLoading}
                  loadingText={t('resetpassword.resetting')}
                  spinner={<Spinner size="sm" />}
                  isDisabled={isLoading}
                  color="#ffffff" 
                  backgroundColor="#364954"
                  borderRadius="16px"
                  _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                  }}
                >
                  {t('resetpassword.resetbutton')}
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPassword;