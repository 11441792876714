
import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, ListItem, Link, UnorderedList, Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import HomePageLayout from './HomePageLayout';
import { px } from 'framer-motion';

const TermsOfService = () => {
    const { t, ready } = useTranslation("homepage");
    const [showContent, setShowContent] = useState(false);
  
    useEffect(() => {
      if (ready) {
        setShowContent(true);
      }
    }, [ready]);

    const renderContent = () => (
        <VStack spacing={0} maxW="800px" mx="auto" px={4} align="left" textColor="#364954">
            <Heading as="h1" size="2xl" mb={8}>Terms of Service</Heading>
            <Text mb={3}>Latest Update: 2024-08-19</Text>
            <Text mb={3}>
                The following document describes the conditions of use for our website and services.
            </Text>
            <Text mb={3}>
            NexusAI Limited ("NexusAI") is a Hong Kong company whose registered office is located at ROOM 1908-09 LIPPO CENTRE TOWER 1, 89 QUEENSWAY, ADMIRALTY, HONG KONG SAR, CHINA, under the company registration number 76918409, represented by its duly authorized representative.
            </Text>
            <Text mb={6}>
            NexusAI is pleased to provide you with access to "mycareerhelp.ai" ("Website," "MyCareerHelp," or "Service"), and related applications and resources (collectively, the "Services"). Your use of the Services is subject to the binding legal agreement set forth below ("Terms").
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Usage of MyCareerHelp Services
            </Heading>
            <Text mb={3}>
            You may use the "mycareerhelp.ai" platform to access career consulting services, manage your professional development, and utilize credit-based services as per your needs. You understand and acknowledge that the services provided through "mycareerhelp.ai" are based on user input and interaction with AI-driven technologies. While we strive to deliver accurate and reliable information, the outcomes generated by our services, particularly those involving AI, may vary and should be used with discretion.
            </Text>
            <Text mb={6}>
            By registering or using "mycareerhelp.ai," you agree to the terms regarding the potential consumption of resources and the associated costs. You are responsible for monitoring any changes in billing from third-party service providers (e.g., payment processors). You shall bear all consequences related to any abnormal consumption and billing issues that may affect your use of the "mycareerhelp.ai" services.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
            User Responsibilities and Prohibited Activities
            </Heading>
            <Text mb={3}>
            By using our Services, you agree to:
            </Text>
            <UnorderedList spacing="1" mb={3}>
                <ListItem>
                Provide valid information when creating an account.
                </ListItem>
                <ListItem>
                Maintain the security of your account credentials.
                </ListItem>
                <ListItem>
                Use the Services in compliance with all applicable laws and regulations.
                </ListItem>
            </UnorderedList>
            <Text mb={3}>
            You are prohibited from:
            </Text>
            <UnorderedList spacing="1" mb={3}>
                <ListItem>
                Using the Services for any illegal or unauthorized purpose, including but not limited to activities that violate any local, national, or international laws or regulations, or that infringe upon the rights of others.
                </ListItem>
                <ListItem>
                Attempting to gain unauthorized access to any part of the Services.
                </ListItem>
                <ListItem>
                Interfering with or disrupting the integrity or performance of the Services.
                </ListItem>
                <ListItem>
                Uploading or transmitting any malicious code or viruses.
                </ListItem>
                <ListItem>
                Impersonating any person or entity or misrepresenting your affiliation with any person or entity.
                </ListItem>
            </UnorderedList>
            <Text mb={6}>
            We reserve the right to terminate or suspend your access to the Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Beta Software
            </Heading>
            <Text mb={3}>
                We may provide you with beta and experimental features, products, and documentation ("Beta Software") on an early access basis. Beta Software is not generally available and may contain errors, defects, and inaccuracies. We provide Beta Software "as is" without any warranties and may terminate Beta Software at any time without ensuring the preservation of any data associated with it. Our service level agreements do not apply to Beta Software. If Beta Software becomes generally available, you may choose to pay for the software or stop using it. We may use your feedback on Beta Software for further improvements.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Intellectual Property
            </Heading>
            <Text mb={3}>
                The Services, logos, interfaces, and websites are owned by NexusAI Limited or its licensees and are protected by applicable law, with all rights reserved. NexusAI takes the protection of intellectual property rights, including copyrights, seriously. NexusAI will terminate your access to, or use of, all or any part of the Services, without notice, if you infringe or misappropriate the intellectual property rights of others.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
            Account Termination and Suspension
            </Heading>
            <Text mb={3}>
            NexusAI reserves the right to terminate or suspend your account and access to the Services immediately, without prior notice or liability, for any reason, including without limitation if you breach the Terms. Upon termination, your right to use the Services will immediately cease. If you wish to terminate your account, you may simply discontinue using the Services or contact us for account deletion.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
            Indemnification
            </Heading>
            <Text mb={3}>
            You agree to indemnify, defend, and hold harmless NexusAI Limited, its officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the Services using your account.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Limitation of Liability
            </Heading>
            <Text mb={3}>
            The services provided by "mycareerhelp.ai" are on an "as is" and "as available" basis. NexusAI Limited disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. NexusAI Limited shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the services, even if advised of the possibility of such damages. NexusAI’s total liability for any claims arising out of or relating to the Services shall not exceed the amount paid by you to NexusAI Limited in the 12 months preceding the event giving rise to the claim.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
            Dispute Resolution
            </Heading>
            <Text mb={3}>
            Any dispute arising out of or relating to these Terms, including their formation, interpretation, breach, or termination, will be finally settled by binding arbitration in accordance with the Hong Kong International Arbitration Centre Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The seat of arbitration shall be Hong Kong. The decision of the arbitrator will be final and binding on the parties.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Governing Law
            </Heading>
            <Text mb={3}>
            These Terms are governed by the laws of Hong Kong SAR, China. However, if you are a consumer using our Services in another jurisdiction, mandatory consumer protection laws in your jurisdiction may apply. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of Hong Kong SAR, China.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
            Age Restrictions
            </Heading>
            <Text mb={3}>
            The Services are intended for users who are at least 18 years old. By using the Services, you represent and warrant that you are at least 18 years old.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Changes to the Terms
            </Heading>
            <Text mb={3}>
                NexusAI Limited reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the Services after such changes have been posted constitutes your acceptance of the revised Terms.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Contact Information
            </Heading>
            <Text mb={3}>
                If you have any questions or concerns regarding these Terms, please contact us at:
            </Text>
            <Text mb={1}>
                NexusAI Limited
            </Text>
            <Text mb={1}>
                Address: Room 1908-09, Lippo Centre Tower 1, 89 Queensway, Admiralty, Hong Kong SAR, China
            </Text>
            <Text mb={6}>
                Email: contact[a]mycareerhelp.ai
            </Text>

        </VStack>
    );


    const renderPlaceholder = () => (
        <VStack spacing={8} maxW="800px" mx="auto" px={4} align="left">
          <Skeleton height="60px" width="60%" />
    
          {[...Array(6)].map((_, index) => (
            <Box key={index}>
              <Skeleton height="24px" width="40%" mb="2" />
              <Skeleton height="20px" width="100%" />
              <Skeleton height="20px" width="100%" />
              <Skeleton height="20px" width="80%" />
            </Box>
          ))}
    
          <Box>
            <Skeleton height="24px" width="40%" mb="2" />
            <Skeleton height="20px" width="100%" />
            <Skeleton height="20px" width="100%" />
            <UnorderedList>
              {[...Array(3)].map((_, index) => (
                <ListItem key={index}>
                  <Skeleton height="20px" width="90%" mt="2" />
                  <Skeleton height="20px" width="80%" />
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        </VStack>
    );

    return (
        <HomePageLayout>
          <Box py={20} px={6} >
            {showContent ? renderContent() : renderPlaceholder()}
          </Box>
        </HomePageLayout>
      );
};

export default TermsOfService;