// src/utils/orderApi.js
import axiosInstance from './axiosConfig';


export const fetchServiceOrders = async (page = 1, pageSize = 10, serviceType = '') => {
  try {
    const params = {
      page,
      page_size: pageSize,
    };

    // Add service_type to params if it's not empty
    if (serviceType) {
      params.service_type = serviceType;
    }

    const response = await axiosInstance.get('/api/v1/orders/service-orders/', { params });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const fetchServiceOrderDetail = async (orderId) => {
  try {
    const response = await axiosInstance.get(`/api/v1/orders/service-orders/${orderId}/`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const fetchTransactionHistory = async (page = 1, pageSize = 10) => {
  try {
    const response = await axiosInstance.get('/api/v1/orders/transaction-history/', {
      params: {
        page,
        page_size: pageSize,
      },
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const fetchAllTransactionHistory = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/orders/transaction-history/', {
      params: {
        page_size: 100, // Set a large page size to fetch all transactions in one call
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const fetchPurchaseOrderDetail = async (orderId) => {
  try {
    const response = await axiosInstance.get(`/api/v1/orders/purchase-orders/${orderId}/`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const fetchUserProfile = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/accounts/profile/');
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};