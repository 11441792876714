import React from 'react';
import { Box, Text, Button, VStack } from '@chakra-ui/react';
import { Save } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const SummaryBox = ({ summary, description, onSave }) => {
    const { t } = useTranslation();  

    return (
        <Box 
            mt={4}
            bg="#FFFFFF" 
            borderRadius={14}
            borderWidth={1}
            borderColor="#D5BDA7"
        >
            <Box
                bg="#D5BDA7"
                borderTopRadius={14}
                px={4}
                py={2}
                fontWeight="bold"
            >
                <Text>
                {t('prodrawer.generatedsection.title')}
                </Text>
            </Box>
            <VStack 
                align="stretch" 
                spacing={1}
            >
                <Box
                    fontSize="14px"
                    fontWeight="medium"
                    textColor="#364954"
                    p={4}
                >
                    <Text
                        whiteSpace="pre-wrap"
                    >
                        {description}
                    </Text>
                </Box>
            </VStack>

            <Box
                p={4}
            >
                <Button 
                    leftIcon={<Save size="14px" />}
                    variant="solid"
                    size="sm"
                    borderRadius="full"
                    loadingText="Saving..."
                    color="#000000"
                    backgroundColor="#D5BDA7"
                    _hover={{
                        color: "#FFFFFF",
                        backgroundColor: "#82B2CA"
                    }}
                    onClick={onSave}
                >
                    {t('prodrawer.generatedsection.btn-savetoresume')}
                </Button>
            </Box>

        </Box>
    );
};

export default SummaryBox;