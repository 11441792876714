import React, { useState, useEffect, useContext } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Text, Link, 
  Input,
  Button,
  useToast,
  Spinner, Skeleton, SkeletonText,
  Box,
  Flex,
  HStack,
  Spacer
} from '@chakra-ui/react';
import { Info } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import QuestionBox from './ProQuestionBox';
import SummaryBox from './ProSummaryBox';
import ProConfirmationModal from './ProConfirmationModal';
import SaveToResumeConfirmationModal from './SaveToResumeConfirmationModal';
import ProGenerateSummaryConfirmationModal from './ProGenerateSummaryConfirmationModal';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserContext';
import WriteWithAIProResultPreviewModal from './WriteWithAIProResultPreviewModal';

const WriteWithAIProDrawer = ({ isOpen, onClose, resumeId, experiences, fetchExperiences }) => {
    const { t } = useTranslation();  
    const [inProgressSessions, setInProgressSessions] = useState([]);
    const [completedSessions, setCompletedSessions] = useState([]);
    const [summarizedSessions, setSummarizedSessions] = useState([]);
    const [jobTitle, setJobTitle] = useState('');
    const [company, setCompany] = useState('');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [activeSession, setActiveSession] = useState(null);
    const [questionList, setQuestionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isQuestionBoxLoading, setIsQuestionBoxLoading] = useState(false);
    const [targetJobData, setTargetJobData] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [finalSummary, setFinalSummary] = useState(null);
    const [generatedDescription, setGeneratedDescription] = useState(null);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [isGenerateConfirmModalOpen, setIsGenerateConfirmModalOpen] = useState(false);
    const [user, setUser] = useState(null);
    const { updateCreditBalance } = useContext(UserContext);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const toast = useToast();

    useEffect(() => {
        if (isOpen) {
          fetchSessions();
          fetchTargetJob();
        }
    }, [isOpen, resumeId]);

    useEffect(() => {
        if (!isOpen) {
          // Reset state when drawer is closed
          setActiveSession(null);
          setQuestionList([]);
          setJobTitle('');
          setCompany('');
          setTargetJobData('');
          setFinalSummary('');
          setGeneratedDescription('');
          setSelectedQuestion(null);
        }
    }, [isOpen]);

    const fetchSessions = async () => {
        setIsLoading(true);
        try {
          if (experiences.length === 0) {
            setInProgressSessions([]);
            setCompletedSessions([]);
            setSummarizedSessions([]);
            return;
          }
      
          const sessionsPromises = experiences.map(experience => 
            axiosInstance.get(`/api/v1/services/write-with-ai-pro/fetch-in-progress-session/?experience_id=${experience.id}`)
          );
      
          const sessionsResponses = await Promise.all(sessionsPromises);
          const allSessions = sessionsResponses.flatMap(response => response.data);
      
          // Categorize sessions based on status
          const inProgress = allSessions.filter(session => session.status === "in_progress");
          const completed = allSessions.filter(session => session.status === "completed");
          const summarized = allSessions.filter(session => session.status === "summarized");
      
          setInProgressSessions(inProgress);
          setCompletedSessions(completed);
          setSummarizedSessions(summarized);
        } catch (error) {
          console.error('Error fetching sessions:', error);
          toast({
            title: 'Error',
            description: 'Failed to fetch sessions.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        } finally {
          setIsLoading(false);
        }
      };

    const fetchTargetJob = async () => {
        try {
        const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
        if (response.data) {
            const targetJobData = response.data;
            const hasTargetJob = (
            targetJobData.target_job_title &&
            targetJobData.target_job_responsibilities &&
            targetJobData.target_job_requirements
            );

            if (hasTargetJob) {
            setTargetJobData({
                title: targetJobData.target_job_title,
                responsibilities: targetJobData.target_job_responsibilities,
                requirements: targetJobData.target_job_requirements,
                updated_at: targetJobData.target_job_fields_updated_at
            });
            } else {
            toast({
                title: t('copilotdrawer.targetjobcheck.toast-title'),
                description: t('copilotdrawer.targetjobcheck.toast-desc'),
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "top-right",
                });
            onClose();
            setTargetJobData(null);
            }
        }
        } catch (error) {
        console.error('Error fetching target job:', error);
        setTargetJobData(null);
        }
    };

    const handleStartBuilding = () => {
        if (!jobTitle || !company) {
            toast({
                title: t('prodrawer.toast.needjobandcompany-title'),
                description: t('prodrawer.toast.needjobandcompany-desc'),
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            return;
        }
        setIsConfirmModalOpen(true);
    };

    const handleConfirmStart = async () => {
        setIsConfirmModalOpen(false);
        setIsQuestionBoxLoading(true);
        try {
            // Call write-with-ai-pro API
            const writeWithAIResponse = await axiosInstance.post('/api/v1/services/write-with-ai-pro/', {
            resume_title: jobTitle,
            resume_company: company,
            target_job_title: targetJobData?.title || '',
            target_job_responsibilities: targetJobData?.responsibilities || '',
            target_job_requirements: targetJobData?.requirements || '',
            resume_id: resumeId,
            });

            setActiveSession({
            id: writeWithAIResponse.data.copilot_id,
            title: jobTitle,
            company: company,
            created_at: writeWithAIResponse.data.created_at,
            status: writeWithAIResponse.data.status,
            experience: writeWithAIResponse.data.experience_id,
            });

            const new_credit_balance = writeWithAIResponse.data.new_credit_balance;
  
            setUser(prevUser => ({
              ...prevUser,
              credit_balance: new_credit_balance,
            }));
        
            updateCreditBalance(writeWithAIResponse.data.new_credit_balance);

            const sortedQuestions = sortQuestions(writeWithAIResponse.data.question_list || []);
            setQuestionList(sortedQuestions);

            toast({
                title: t('prodrawer.toast.sessioncreated-title'),
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
            });

        } catch (error) {
            console.error('Error starting AI session:', error);

            if (error.response && error.response.data && error.response.data.error === "Insufficient credits") {
                toast({
                  title: t('insufficientcredits.toast-title'),
                  description: t('insufficientcredits.toast-desc'),
                  status: "warning",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              } else {
                toast({
                  title: "Error",
                  description: "Failed to create this session. Please try again.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
                }

        } finally {
            setIsQuestionBoxLoading(false);
        }
    };

    const handleSessionClick = async (session) => {
        setIsQuestionBoxLoading(true);
        setActiveSession(session);
        try {
          // Fetch questions
          const questionsResponse = await axiosInstance.get(`/api/v1/services/write-with-ai-pro/get-questions/?copilot_id=${session.id}`);
          if (Array.isArray(questionsResponse.data)) {
            setQuestionList(sortQuestions(questionsResponse.data));
          } else {
            console.error('Unexpected response structure:', questionsResponse.data);
            setQuestionList([]);
          }
    
          // Fetch final contents
          const finalContentsResponse = await axiosInstance.get(`/api/v1/services/write-with-ai-pro/get-final-contents/?copilot_id=${session.id}`);
          setFinalSummary(finalContentsResponse.data.final_summary);
          setGeneratedDescription(finalContentsResponse.data.generated_description);
        } catch (error) {
          console.error('Error fetching session details:', error);
          toast({
            title: 'Error',
            description: 'Failed to fetch session details.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
          setQuestionList([]);
          setFinalSummary(null);
          setGeneratedDescription(null);
        } finally {
          setIsQuestionBoxLoading(false);
          setSelectedQuestion(null);
        }
    };

    const sortQuestions = (questions) => {
        if (!Array.isArray(questions)) {
        console.error('Questions is not an array:', questions);
        return [];
        }
        const order = ['Q1_RESP', 'Q2_ACHV', 'Q3_TEAM', 'Q4_CHAL', 'Q5_IMPACT', 'Q6_SKILLS'];
        const sortedQuestions = questions.sort((a, b) => order.indexOf(a.question_type) - order.indexOf(b.question_type));
        return sortedQuestions;
    };

    const handleAnswerSubmit = async (questionId, answerText) => {
        try {
          const response = await axiosInstance.post('/api/v1/services/write-with-ai-pro/submit-answer/', {
            question_id: questionId,
            answer_text: answerText,
          });
    
        // Update the question list with the new answer and validation scores
        setQuestionList(prevList =>
            prevList.map(question =>
            question.id === questionId
                ? {
                    ...question,
                    is_answered: true,
                    answers: [{
                    answer_text: answerText,
                    relevance_score: response.data.relevance_score,
                    detail_score: response.data.detail_score,
                    clarity_score: response.data.clarity_score,
                    improvement_suggestions: response.data.improvement_suggestions,
                    version: response.data.version,
                    }],
                }
                : question
            )
        );
    
        toast({
            title: t('prodrawer.toast.answer-submitted-title'),
            description: t('prodrawer.toast.answer-submitted-desc'),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: 'top-right'
        });
        } catch (error) {
          console.error('Error submitting answer:', error);
          toast({
            title: "Error",
            description: "Failed to submit answer. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
    };
    
    const handleGenerationStart = async () => {
        if (!activeSession) {
          toast({
            title: "Error",
            description: "No active session found.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
          return;
        }

        setIsGenerateConfirmModalOpen(true);
    };
    
    const handleConfirmedGeneration = async () => {
        setIsGenerateConfirmModalOpen(false);
        setIsGenerating(true);
        try {
          const response = await axiosInstance.post('/api/v1/services/write-with-ai-pro/generate-final-summary/', {
            copilot_id: activeSession.id,
            resume_title: jobTitle,
            resume_company: company,
            target_job_title: targetJobData?.title || '',
            target_job_responsibilities: targetJobData?.responsibilities || '',
            target_job_requirements: targetJobData?.requirements || '',
          });
      
          setFinalSummary(response.data.final_summary);
          setGeneratedDescription(response.data.generated_description);
          setSelectedQuestion(null);
          toast({
            title: t('prodrawer.toast.summary-generated-title'),
            description: t('prodrawer.toast.summary-generated-desc'),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } catch (error) {
          console.error('Error generating final summary:', error);
          toast({
            title: "Error",
            description: "Failed to generate final summary. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        } finally {
          setIsGenerating(false);
        }
      };

    const handleClose = () => {
        onClose();
        fetchExperiences();
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); // 24-hour format
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${year}/${month}/${day} ${hours}:${minutes}`;
    };

    const handleQuestionClick = (question) => {
        setSelectedQuestion(question);
        setQuestionIndex(questionList.findIndex(q => q.id === question.id));
      };

    const allQuestionsAnswered = questionList.every(q => q.is_answered);

    const StartSkeleton = () => {
        return (
            <VStack align="stretch" width="100%" height="100%">
                <Box p={4} align="center">
                    <Box>
                        <Skeleton height="40px" width="150px" mb={10} borderRadius={12} />
                    </Box>

                    <Box align="left">
                        <SkeletonText mt="4" noOfLines={3} spacing="4" />
                    </Box>

                    {/* Input Fields Skeleton */}
                    <VStack spacing={4} mt={8}>
                        <Skeleton height="40px" width="100%" borderRadius={12} />
                        <Skeleton height="40px" width="100%" borderRadius={12} />
                    </VStack>

                    {/* Start Button Skeleton */}
                    <Skeleton height="50px" width="100%" mt={4} borderRadius={12} />

                </Box>
            </VStack>
        );
    };

    const QuestionBoxSkeleton = () => {
        return (
          <VStack align="stretch" width="100%" height="100%">
            <Box mt={4}>
              <Skeleton height="40px" width="100%" mb={3} borderRadius={12} />
              <SkeletonText px={2} noOfLines={2} spacing="3" />
            </Box>
          </VStack>
        );
    };

    const FinalSummarySkeleton = () => {
        return (
          <VStack align="stretch" width="100%" height="100%">
            <Box mt={4}>
              <Skeleton height="300px" width="100%" mb={3} borderRadius={12} />
            </Box>
          </VStack>
        );
    };

    const handleOpenPreviewModal = () => {
        setIsPreviewModalOpen(true);
      };
    
      const handleClosePreviewModal = () => {
        setIsPreviewModalOpen(false);
      };

    return (
        <Drawer
          isOpen={isOpen}
          onClose={handleClose}
          placement="right"
          size="xl"
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <DrawerOverlay backdropFilter="blur(10px)" />
          <DrawerContent borderLeftRadius={16}>
            <DrawerCloseButton />
            <DrawerHeader>{t('prodrawer.title')}</DrawerHeader>
            <DrawerBody>
              {isLoading ? (
                <StartSkeleton />
              ) : activeSession ? (
                <VStack align="stretch" spacing={0}>
                    <Box 
                        fontSize="14px" 
                        textColor="#364954"
                    >
                        <Flex 
                            direction="row" 
                        >
                            <Text>
                            {t('prodrawer.questionbox.creatingfor')}:
                            </Text>
                            <Text 
                                ml={2}
                                fontWeight="bold" 
                            >
                            {activeSession.title}, {activeSession.company}
                            </Text>
                            <Spacer />
                            <Text
                                fontSize="12px"
                                textColor="#737D82"
                            >
                            {t('prodrawer.questionbox.createdat')}: {formatDate(activeSession.created_at)}
                            </Text>
                        </Flex>
                    </Box>
    
                  ) : (
                    <>
                        <Box>
                        {questionList.map((question, index) => {
                            return isQuestionBoxLoading ? (
                                <QuestionBoxSkeleton key={`skeleton-${question.id}`} />
                            ) : (
                            <QuestionBox
                            key={question.id}
                            index={index}
                            question={question}
                            onClick={() => handleQuestionClick(question)}
                            onAnswerSubmit={handleAnswerSubmit}
                            isReadOnly={finalSummary}
                            title={activeSession.title}
                            company={activeSession.company}
                            />
                            );
                        })}
                        </Box>
                        {isGenerating ?
                        <FinalSummarySkeleton />
                        :     
                            <Box mb={5}>
                            {finalSummary && (
                                <SummaryBox
                                summary={finalSummary}
                                description={generatedDescription}
                                onSave={() => setIsSaveModalOpen(true)}
                                />
                            )}
                            </Box>
                        }
                    </>
                  )
    
                  {!selectedQuestion && !finalSummary && (
                    isQuestionBoxLoading ? 
                    <Spinner size="sm" /> : 
                    <>
                    <Button
                        mt={5}
                        mb={2}
                        onClick={handleGenerationStart}
                        isLoading={isGenerating}
                        isDisabled={!allQuestionsAnswered || isQuestionBoxLoading}
                        color="#ffffff"
                        backgroundColor="#364954"
                        borderRadius="16px"
                        _hover={{
                            color: "#ffffff",
                            backgroundColor: "#82B2CA"
                        }}
                    >
                        {t('prodrawer.questionbox.btn-startgeneration')}
                    </Button>
                    <Box
                        align="center"
                        mb={5}
                    >
                        <Text fontSize="12px" textColor="#737D82" mt="2">
                        {t('prodrawer.questionbox.footnote')}
                        </Text>
                    </Box>
                    </>
                  )}
                </VStack>
              ) : (
                <Flex
                    direction="column"
                    minH="100%"
                >
                    <VStack 
                        align="strench" 
                        spacing={4} 
                    >
                    <Box align="center" fontSize="30px" fontWeight="bold" textColor="#364954">
                        <Text>{t('prodrawer.startpage.title')}</Text>
                    </Box>
                    <Box p="3" borderRadius={16} bg="#f5f7fa" fontSize="14px" textColor="#364954">
                        <Text>
                        {t('prodrawer.startpage.desc')}
                        </Text>
                    </Box>
                    <Box
                        fontSize="14px"
                    >
                        <Text
                            fontWeight="medium"
                            mb={2}
                        >
                           {t('prodrawer.startpage.title-jobtitle')}
                        </Text>
                        <Input
                            placeholder={t('prodrawer.startpage.placeholder-jobtitle')}
                            borderRadius={16}
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                        />
                    </Box>
                    <Box
                        fontSize="14px"    
                    >
                        <HStack
                            mb={2}
                        >
                            <Text
                                fontWeight="medium"
                            >
                                {t('prodrawer.startpage.title-company')}
                            </Text>
                            <Text
                                ml={1}
                                fontWeight="normal"
                                fontSize={14}
                                textColor="#737D82"
                            >
                                {t('prodrawer.startpage.tooltips-company')}
                            </Text>
                        </HStack>
                        <Input
                            placeholder={t('prodrawer.startpage.placeholder-company')}
                            borderRadius={16}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </Box>
                    <Button
                        onClick={handleStartBuilding}
                        color="#ffffff"
                        backgroundColor="#364954"
                        borderRadius="16px"
                        _hover={{
                        color: "#ffffff",
                        backgroundColor: "#82B2CA"
                        }}
                    >
                        {t('prodrawer.startpage.btn-start')}
                    </Button>
                    <Link 
                        align="center"
                        fontSize="14px"
                        textColor="#737D82"
                        onClick={handleOpenPreviewModal}
                    >
                        {t('prodrawer.startpage.btn-preview-service-result')}
                    </Link>
                    </VStack>
                    <HStack 
                        mt="auto"
                        align="top"
                    >
                    {experiences.length > 0 && (
                    <Box
                        width="50%"
                        p={2}
                        bg="#f5f7fa"
                        borderRadius={12}
                        fontSize="12px"
                        textColor="#737D82"
                        overflowY="auto"
                        maxH="100px"
                    >
                        <Text
                            fontWeight="bold"
                            color="#364954"
                        >
                            {t('prodrawer.startpage.unfinishedsession-title')}
                        </Text>
                        {inProgressSessions.length > 0 ? (
                                inProgressSessions
                                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                .map((session) => (
                                    <Box 
                                        key={session.id} 
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent="space-between"
                                            mt="1"
                                        >
                                            <Text
                                                onClick={() => handleSessionClick(session)}
                                                cursor="pointer"
                                            >{session.title}, {session.company}</Text>
                                            <Text>{formatDate(session.created_at)}</Text>
                                        </Flex>
                                    </Box>
                                ))
                            ) : (
                                <Text>{t('prodrawer.startpage.unfinishedsession-nodata')}</Text>
                            )}
                    </Box>
                    )}


                    {experiences.length > 0 && (
                    <Box
                        width="50%"
                        p={2}
                        bg="#f5f7fa"
                        borderRadius={12}
                        fontSize="12px"
                        textColor="#737D82"
                        overflowY="auto"
                        maxH="100px"
                    >
                        <Text
                            fontWeight="bold"
                            color="#364954"
                        >
                            {t('prodrawer.startpage.summarizedsession-title')}
                        </Text>
                        {summarizedSessions.length > 0 ? (
                            summarizedSessions
                            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                            .map((session) => (
                            <Box 
                                key={session.id} 
                            >
                                <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    mt="1"
                                >
                                    <Text
                                        onClick={() => handleSessionClick(session)}
                                        cursor="pointer"
                                    >{session.title}, {session.company}</Text>
                                    <Text>{formatDate(session.created_at)}</Text>
                                </Flex>
                            </Box>
                            ))
                        ) : (
                            <Text>{t('prodrawer.startpage.summarizedsession-nodata')}</Text>
                        )}
                    </Box>
                    )}

                    </HStack>
                    <Box
                        p={2}
                        bg="#FFFFFF"
                        borderRadius={12}
                        fontSize="12px"
                        textColor="#737D82"
                    >
                        <HStack>
                            <Info 
                                size="14px"
                            />
                            <Text>{t('prodrawer.startpage.footnote')}</Text>
                        </HStack>
                    </Box>
                </Flex>
              )}

            {activeSession && (
                <SaveToResumeConfirmationModal
                    isOpen={isSaveModalOpen}
                    onClose={() => setIsSaveModalOpen(false)}
                    generatedDescription={generatedDescription}
                    experienceId={activeSession.experience}
                    onUpdateEntry={(updatedEntry) => {
                        fetchExperiences();
                    }}
                    onDrawerClose={handleClose}
                    jobTitle={activeSession.title}
                    company={activeSession.company}
                />
            )}

            <WriteWithAIProResultPreviewModal
                isOpen={isPreviewModalOpen}
                onClose={handleClosePreviewModal}
            />

            </DrawerBody>
          </DrawerContent>
    
            <ProConfirmationModal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                onConfirm={handleConfirmStart}
                title={jobTitle}
                company={company}
            />

            <ProGenerateSummaryConfirmationModal
                isOpen={isGenerateConfirmModalOpen}
                onClose={() => setIsGenerateConfirmModalOpen(false)}
                onConfirm={handleConfirmedGeneration}
            />
    

        </Drawer>
      );
    };
    
    export default WriteWithAIProDrawer;