import React, { useContext, useState } from 'react';
import { Input, Button, HStack, Text, Tooltip, Grid, GridItem, 
  FormControl, FormLabel, FormErrorMessage, useToast, useDisclosure,
  Textarea, Box, Flex
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { Zap } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import CustomDateDropdown from './CustomDateDropdown';
import axiosInstance from '../../utils/axiosConfig';
import { UserContext } from '../UserContext';
import WriteWithAIBox from '../ResumeWriteWithAISection/WriteWithAIBox';
import ConfirmationModal from '../ResumeWriteWithAISection/ConfirmationModal';

const ExperienceForm = ({ experience, onSubmit, onCancel, resumeId }) => {
  const { t } = useTranslation();  
  const toast = useToast();
  const initialValues = experience || {
    organization_name: '',
    title: '',
    start: '',
    end: '',
    description: '',
    temp_id: null,
  };
  const { fetchUserProfile, updateCreditBalance } = useContext(UserContext);
  const [generatedDescription, setGeneratedDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null);

  const validate = (values) => {
    const errors = {};
    if (!values.organization_name.trim()) errors.organization_name = 'Required';
    if (!values.title.trim()) errors.title = 'Required';
    if (!values.start) errors.start = 'Required';
    if (!values.description.trim()) errors.description = 'Required';
    return errors;
  };

  const checkResumeCompletion = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const resume = response.data;
        return (
          resume.education && resume.education.length > 0 &&
          resume.experiences && resume.experiences.length > 0 &&
          resume.skills && resume.skills.length > 0
        );
      }
      return false;
    } catch (error) {
      console.error('Error checking resume completion:', error);
      return false;
    }
  };

  const fetchTargetJob = async (resumeId) => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const targetJobData = response.data;
        const hasTargetJob = (
          targetJobData.target_job_title &&
          targetJobData.target_job_responsibilities &&
          targetJobData.target_job_requirements
        );

        if (hasTargetJob) {
          return {
            title: targetJobData.target_job_title,
            responsibilities: targetJobData.target_job_responsibilities,
            requirements: targetJobData.target_job_requirements,
            updated_at: targetJobData.target_job_fields_updated_at
          };
        }
      }
      return null;
    } catch (error) {
      console.error('Error fetching target job:', error);
      throw error;
    }
  };

  const handleWriteWithAI = async (values, setFieldValue) => {
    setIsLoading(true);
    try {
        const targetJob = await fetchTargetJob(resumeId);
        if (targetJob) {
            try {
              const response = await axiosInstance.post('/api/v1/services/write-with-ai/', {
                resume_title: values.title,
                target_job_title: targetJob.title,
                target_job_responsibilities: targetJob.responsibilities,
                target_job_requirements: targetJob.requirements,
                temp_id: values.temp_id,
                experience_id: values.id,
              });

              const {new_credit_balance} = response.data;
              setUser(prevUser => ({
                  ...prevUser,
                  credit_balance: new_credit_balance
              }));
              updateCreditBalance(response.data.new_credit_balance);

              setGeneratedDescription(response.data.generated_description);
              if (!values.temp_id) {
                setFieldValue('temp_id', response.data.temp_id);
              }
            } catch (error) {
              console.error('Error generating AI description:', error);

              if (error.response && error.response.data && error.response.data.error === "Insufficient credits") {
                toast({
                  title: t('insufficientcredits.toast-title'),
                  description: t('insufficientcredits.toast-desc'),
                  status: "warning",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              } else {
              toast({
                title: "Error",
                description: "Failed to generate AI description. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: 'top-right',
              });
            }}
          } else {
            toast({
              title: t('copilotdrawer.targetjobcheck.toast-title'),
              description: t('copilotdrawer.targetjobcheck.toast-desc'),
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        } catch (error) {
          console.error('Error checking target job:', error);
          toast({
            title: "Error",
            description: "Failed to check target job. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
      } finally {
        setIsLoading(false);
        onClose();
      }
  };


  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await onSubmit({ ...values, resume: resumeId });
      // Check if all sections are complete
      const isResumeComplete = await checkResumeCompletion();
      
      if (isResumeComplete) {
        // Fetch the user's onboarding tasks
        const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        
        if (onboardingTasksResponse.status === 200) {
          const tasks = onboardingTasksResponse.data;
          const createResumeTask = tasks.find(task => task.task_id === 2);
          
          if (createResumeTask && !createResumeTask.is_completed) {
            // Call the API to complete the task only if it's not already completed
            try {
              const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-create-resume-task/');
              
              if (onboardingResponse.status === 200) {
                await fetchUserProfile();

                toast({
                  title: t('resumepage.onboardingtask.toast-complete'),
                  description: `${t('resumepage.onboardingtask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('resumepage.onboardingtask.toast-complete-des2')}`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            } catch (error) {
              console.error('Error completing resume task:', error);
              // You might want to show an error toast here
            }
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
      toast({
        title: t('wrk.add_fail'),
        description: t('wrk.add_fail_description'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="4">
            <GridItem colSpan={1}>
              <FormControl isInvalid={errors.title && touched.title}>
                <FormLabel>{t('wrk.entry_job_title')}</FormLabel>
                <Field as={Input} name="title" placeholder={t('wrk.entry_job_placeholder')} />
                <FormErrorMessage>{errors.title}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={errors.start && touched.start}>
                <FormLabel>{t('wrk.entry_period_title')}</FormLabel>
                <HStack>
                  <CustomDateDropdown
                    name="start"
                    placeholder={t('wrk.entry_period_start')}
                    onChange={(value) => setFieldValue('start', value)}
                    value={values.start}
                  />
                  <Text>-</Text>
                  <CustomDateDropdown
                    name="end"
                    placeholder={t('wrk.entry_period_end')}
                    onChange={(value) => setFieldValue('end', value)}
                    value={values.end}
                    includePresent={true}
                  />
                </HStack>
                <FormErrorMessage>{errors.start}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={errors.organization_name && touched.organization_name}>
                <Tooltip 
                  hasArrow 
                  borderRadius={12} 
                  bg='#CFD9DF' 
                  color='#364954' 
                  placement='top-start'
                  label={
                    <Box p={1}>
                    {t('wrk.entry_org_tooltip')}
                    </Box>
                  } 
                > 
                    <FormLabel>
                    {t('wrk.entry_org_title')}
                    </FormLabel>
                </Tooltip>
                <Field as={Input} name="organization_name" placeholder={t('wrk.entry_org_placeholder')} />
                <FormErrorMessage>{errors.organization_name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={errors.description && touched.description}>
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  align="center"
                  mb="1"
                >
                  <FormLabel>{t('wrk.entry_jd_title')}</FormLabel>
                  <Button
                      variant="ghost"
                      size="sm"
                      leftIcon={<Zap 
                          color="#364954"
                          size="14px"
                          />}
                      borderRadius="16px"
                      textColor="#364954"
                      onClick={async () => {
                        setIsLoading(true);
                        if (!values.title.trim()) {
                          toast({
                            title: t('wrk.write-with-ai-nojobtitle-toast-title'),
                            description: t('wrk.write-with-ai-nojobtitle-toast-desc'),
                            status: "warning",
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right',
                          });
                        } else {
                          try {
                            const targetJob = await fetchTargetJob(resumeId);
                            if (targetJob !== null) {
                              onOpen();
                            } else {
                              toast({
                                title: t('copilotdrawer.targetjobcheck.toast-title'),
                                description: t('copilotdrawer.targetjobcheck.toast-desc'),
                                status: "warning",
                                duration: 5000,
                                isClosable: true,
                                position: "top-right",
                              });
                            }
                          } catch (error) {
                            console.error('Error fetching target job:', error);
                            toast({
                              title: "Error",
                              description: "Failed to check target job. Please try again.",
                              status: "error",
                              duration: 3000,
                              isClosable: true,
                              position: 'top-right',
                            });
                          }
                        };
                        setIsLoading(false);
                      }}
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      >
                      {t('wrk.write-with-ai-btn')}
                  </Button>
                </Flex>

                {generatedDescription && (
                  <WriteWithAIBox
                    generatedDescription={generatedDescription}
                    onClose={() => setGeneratedDescription('')}
                    onApply={() => {
                      setFieldValue('description', generatedDescription
                                                      .split('\n')
                                                      .filter(line => line.trim() !== '')
                                                      .join('\n')
                      );
                      setGeneratedDescription('');
                    }}
                  />
                )}

                <Field as={Textarea} name="description" placeholder={t('wrk.entry_jd_placeholder')} />
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack mt={4} mb={4} justify="flex-end">
          <Button 
              size="sm"
              onClick={onCancel}
              borderRadius="12px"
              >
            {t('wrk.cancel')}
            </Button>
            <Button 
              size="sm"
              type="submit" 
              color="#ffffff" 
              backgroundColor="#364954"
              borderRadius="12px"
              _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
              }}
              isLoading={isSubmitting}
              >
            {t('wrk.save')}
            </Button>
          </HStack>

          <ConfirmationModal
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={onClose}
            onConfirm={() => handleWriteWithAI(values, setFieldValue)}
          />

        </Form>
      )}
    </Formik>
  );
};

export default ExperienceForm;