import React, { useState, useEffect, useCallback, useContext } from 'react';
import { 
    Box, 
    Heading, 
    Text, 
    Flex, 
    Image,
    VStack, HStack,
    Button, Badge,
    Divider,
    Spacer,
    Skeleton,
    useToast, useDisclosure,
    Menu, MenuButton, MenuList, MenuItem,
    IconButton, Tooltip, Spinner
} from '@chakra-ui/react';
import { WandSparkles, MonitorUp, ChevronDown, Pencil, EyeOff } from 'lucide-react';
import { CheckIcon } from "@chakra-ui/icons";
import { IoIosAddCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import Education from './Education';
import Experience from './Experience';
import Projects from './Projects';
import Skills from './Skills';
import ResumeUploadModal from './ResumeUploadModal';
import { useTranslation } from 'react-i18next';
import SlotFullModal from './SlotFullModal';
import CreateNewVersionsModal from './CreateNewVersionModal';
import ChangeTargetJobModal from './ChangeTargetJobModal';
import { UserContext } from '../UserContext';

const ResumePage = () => {
  const [resume, setResume] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [activeResume, setActiveResume] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();  
  const navigate = useNavigate();
  const [hasResume, setHasResume] = useState(false);
  const [resumeId, setResumeId] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isSlotFullModalOpen, setIsSlotFullModalOpen] = useState(false);
  const [isCreateNewVersionsModalOpen, setIsCreateNewVersionsModalOpen] = useState(false);
  const { updateCreditBalance } = useContext(UserContext);
  const [isVersionSwitching, setIsVersionSwitching] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [resumeContentLoading, setResumeContentLoading] = useState(false);
  const [isChangeTargetJobModalOpen, setIsChangeTargetJobModalOpen] = useState(false);
  const [isProjectVisible, setIsProjectVisible] = useState(false);
  const [isProjectSectionLoading, setIsProjectSectionLoading] = useState(false);


  const checkForResume = useCallback(async () => {
    try {
      const currentResume = activeResume || resume;
      if (currentResume) {
        const hasContent = 
          (currentResume.education && currentResume.education.length > 0) ||
          (currentResume.experiences && currentResume.experiences.length > 0) ||
          (currentResume.skills && currentResume.skills.length > 0);
        
        setHasResume(hasContent);
        return hasContent;
      } else {
        setHasResume(false);
        return false;
      }
    } catch (error) {
      console.error('Error checking for resume:', error);
      toast({
        title: "Error",
        description: "Failed to check for existing resume",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      return false;
    }
  }, [activeResume, resume]);

  const updateResumeState = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      setResume(response.data);
      setActiveResume(response.data);
      checkForResume();
    } catch (error) {
      console.error('Error updating resume state:', error);
    }
  }, [resumeId, checkForResume]);

  const fetchUserAndResumes = useCallback(async () => {
    try {
      setLoading(true);
      const [userResponse, resumesResponse] = await Promise.all([
        axiosInstance.get('/api/v1/accounts/profile/'),
        axiosInstance.get('/api/v1/resumes/resumes/')
      ]);
  
      setUser(userResponse.data); // This now includes resume_slots
  
      if (resumesResponse.data.length > 0) {
        const sortedResumes = resumesResponse.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setResumes(sortedResumes);
        const activeResume = sortedResumes.find(resume => resume.is_active) || sortedResumes[0];
        setActiveResume(activeResume);
        setResume(activeResume);
        setResumeId(activeResume.id);
        setHasResume(true);
        setIsProjectVisible(activeResume.optional_sections?.projects || false);

      } else {
        // Create a new resume if none exist
        const newResume = await axiosInstance.post('/api/v1/resumes/resumes/', {});
        setResumes([newResume.data]);
        setActiveResume(newResume.data);
        setResume(newResume.data);
        setResumeId(newResume.data.id);
        setHasResume(false);
        setIsProjectVisible(newResume.data.optional_sections?.projects || false);

      }
    } catch (err) {
      console.error('Error creating new resume:', error);
      console.error('Error fetching user or resumes:', err.response ? err.response.data : err);
      setError('Failed to fetch user or resumes');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!resume) {
      fetchUserAndResumes();
      checkForResume();
    }
  }, [resume, fetchUserAndResumes]);

  useEffect(() => {
    fetchUserAndResumes();
  }, [fetchUserAndResumes, refreshKey]);

  const handleResumeClick = async (selectedResume) => {
    setIsVersionSwitching(true);
    setResumeContentLoading(true);
    try {
      await axiosInstance.post(`/api/v1/resumes/resumes/${selectedResume.id}/set_active/`);
      setActiveResume(selectedResume);
      setResume(selectedResume);
      setResumeId(selectedResume.id);
      setResumes(prevResumes => prevResumes.map(r => ({...r, is_active: r.id === selectedResume.id})));
      setIsProjectVisible(selectedResume.optional_sections?.projects || false);
      await checkForResume();
    } catch (error) {
      console.error('Error setting resume as active:', error);
      toast({
        title: "Error",
        description: "Failed to set resume as active",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsVersionSwitching(false);
      setResumeContentLoading(false);
    }
  };

  const handleCreateNewVersion = () => {
    setIsCreateNewVersionsModalOpen(true);
  };

  const handleCloseCreateNewVersionsModal = () => {
    setIsCreateNewVersionsModalOpen(false);
  };

  const confirmCreateNewVersion = async () => {
    try {
      const newResume = await axiosInstance.post('/api/v1/resumes/resumes/', {});
      setResumes(prevResumes => [newResume.data, ...prevResumes]);
      await handleResumeClick(newResume.data);
      
      // Close the CreateNewVersionsModal and switch to the new version
      setIsCreateNewVersionsModalOpen(false);

      toast({
        title: t('resumepage.resumelist.createnew.toast-title-success'),
        description: t('resumepage.resumelist.createnew.toast-des-success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
  
    } catch (error) {
      console.error('Error creating new resume version:', error);
      toast({
        title: "Error",
        description: "An error occurred while processing your request. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getMoreSlots = async () => {
    try {
      const response = await axiosInstance.post('/api/v1/resumes/get-more-slot/');
      const { new_credit_balance, new_resume_slots } = response.data;
  
      setUser(prevUser => ({
        ...prevUser,
        credit_balance: new_credit_balance,
        resume_slots: new_resume_slots
      }));
  
      updateCreditBalance(response.data.new_credit_balance);
  
      toast({
        title: t('resumepage.resumelist.unlockslot.toast-success-title'),
        description: t('resumepage.resumelist.unlockslot.toast-success-des'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
  
      // Close the SlotFullModal and refresh the CreateNewVersionsModal
      setIsSlotFullModalOpen(false);
      setIsCreateNewVersionsModalOpen(false);
      setIsCreateNewVersionsModalOpen(true);
    } catch (error) {
      console.error('Error getting more slots:', error);

      if (error.response && error.response.data && error.response.data.error === "Insufficient credits.") {
        toast({
          title: t('insufficientcredits.toast-title'),
          description: t('insufficientcredits.toast-desc'),
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
      toast({
        title: "Error",
        description: "Failed to add new resume slot. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }}
  };

  const toggleProjectVisibility = async () => {
    setIsProjectSectionLoading(true);

    try {
      const response = await axiosInstance.post(`/api/v1/resumes/resumes/${resumeId}/set_optional_section_visibility/`, {
        section_name: 'projects',
        is_visible: !isProjectVisible
      });
      setIsProjectVisible(!isProjectVisible);
      setResume(prevResume => ({...prevResume, optional_sections: response.data.optional_sections}));
      setActiveResume(prevResume => ({...prevResume, optional_sections: response.data.optional_sections}));
      setResumes(prevResumes => prevResumes.map(r => 
        r.id === resumeId ? {...r, optional_sections: response.data.optional_sections} : r
      ));
    } catch (error) {
      console.error('Error toggling project visibility:', error);
      toast({
        title: "Error",
        description: "Failed to toggle project visibility",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsProjectSectionLoading(false);
    }
  };

  const handleOpenChangeTargetJobModal = () => {
    setIsChangeTargetJobModalOpen(true);
  };

  const handleCloseChangeTargetJobModal = () => {
    setIsChangeTargetJobModalOpen(false);
  };

  const handleSaveTargetJob = async (targetJob) => {
    try {
      const response = await axiosInstance.patch(`/api/v1/resumes/resumes/${resumeId}/`, {
        target_job_title: targetJob.title,
        target_job_company: targetJob.company,
        target_job_responsibilities: targetJob.responsibilities,
        target_job_requirements: targetJob.requirements,
      });

      // Update both resume and activeResume states
      setResume(prevResume => ({...prevResume, ...response.data}));
      setActiveResume(prevResume => ({...prevResume, ...response.data}));

      // Update the resume in the resumes array
      setResumes(prevResumes => prevResumes.map(r => 
        r.id === resumeId ? {...r, ...response.data} : r
      ));      

      toast({
        title: t('targetjobmodal.toast.success-title'),
        description: t('targetjobmodal.toast.success-desc'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Error updating target job:', error);
      toast({
        title: "Error",
        description: "Failed to update target job information. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Function to generate resume version title
  const getResumeVersionTitle = (index) => {
    return `${t('resumepage.resumelist.version')} ${resumes.length - index}`;
  };


  const handleResumePolish = async () => {
    setButtonLoading(true);
    try {
      const hasValidResume = await checkForResume();
      if (hasValidResume) {
        navigate(`/gettingstarted/resume-polish/${resumeId}`);
      } else {
        toast({
          title: t('gettingstarted.rp.toast-noresume'),
          description: t('gettingstarted.rp.toast-noresume-des'),
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('Error in resume polish process:', error);
      toast({
        title: "Error",
        description: "An error occurred while processing your request. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setButtonLoading(false);
    }
  };

  const handleUploadSuccess = () => {
    setRefreshKey(prevKey => prevKey + 1);
    toast({
      title: t('resumepage.tools.uploadtoast.title'),
      description: t('resumepage.tools.uploadtoast.des'),
      status: "success",
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const SkeletonSection = () => (
    <VStack spacing={4} align="stretch">
      <Skeleton height="20px" borderRadius="lg" />
      <Skeleton height="20px" borderRadius="lg" />
      <Skeleton height="20px" borderRadius="lg" />
    </VStack>
  );

  const ServiceCardSkeleton = () => (
    <Box
      width="100%"
      bg="#f5f7fa"
      borderRadius="16px"
      p={4}
    >
      <Skeleton height="20px" width="70%" borderRadius="md" mb={4} />
      <Skeleton height="100px" borderRadius="md" mb={4} />
      <Skeleton height="20px" width="50%" borderRadius="md" mb={2} />
      <Skeleton height="40px" borderRadius="md" />
    </Box>
  );

  return (
    <Flex direction="column" minHeight="100%" height="100%">
      <Box height="30px" flexShrink={0} />
      <Flex direction="column" flex="1" overflow="hidden">
        <Heading as="h1" fontSize="2xl" mb={4} textColor="#364954">{t('resumepage.title')}</Heading>
        <Flex flex="1" minHeight="0">
          {/* Main content */}
          <Box
            flex="1"
            bg="#f5f7fa"
            borderTopLeftRadius="16px"
            borderTopRightRadius="16px"
            overflow="auto"
            p={5}
            mr={3}
          >
            {loading || resumeContentLoading ? (
              <VStack spacing={6} align='stretch'>
                <SkeletonSection />
                <SkeletonSection />
                <SkeletonSection />
              </VStack>
            ) : (
              <VStack spacing={3} align='stretch'>
                <Education resumeId={activeResume.id} updateResumeState={updateResumeState} />
                <Experience resumeId={activeResume.id} updateResumeState={updateResumeState} />
                {isProjectVisible && (
                  <Box
                    bg="transparent"
                    borderRadius={12}
                  >
                    <Projects resumeId={activeResume.id} updateResumeState={updateResumeState} />
                    <Box ml="3" p={1}>
                      <HStack
                      >
                      {isProjectSectionLoading ? (
                        <Spinner size="sm" color="gray" />
                      ) : (
                        <EyeOff
                          cursor="pointer"
                          onClick={toggleProjectVisibility}
                          color="gray"
                          size="14px"
                        />
                      )}
                        <Text color="#737D82" fontSize="12px">
                        {isProjectSectionLoading ? t('resumepage.project.section-hiding') : t('resumepage.project.section-hide-desc')}
                        </Text>
                      </HStack>
                    </Box>
                  </Box>
                )}
                <Skills resumeId={activeResume.id} updateResumeState={updateResumeState} />
                {!isProjectVisible &&
                <Button
                    w="100%"
                    h="80px"
                    bg="white"
                    borderRadius="16px"
                    borderWidth={1}
                    borderColor="#f5f7fa"
                    borderStyle="dashed dotted"
                    onClick={toggleProjectVisibility}
                    isDisabled={isProjectVisible || isProjectSectionLoading}
                >
                    <Box align="left">
                      <HStack>
                      {isProjectSectionLoading ? (
                        <Spinner size="sm" color="#737D82" />
                      ) : (
                        <IoIosAddCircle size="16px" color="#737D82" />
                      )}
                        <Text textColor="#737D82" fontSize="14px">
                        {isProjectSectionLoading ? t('resumepage.project.section-adding') : t('resumepage.project.section-add-btn')}
                        </Text>
                        <Tooltip
                            hasArrow 
                            borderRadius={12}
                            bg='#CFD9DF' 
                            color='#364954' 
                            placement='top'
                            label={
                            <Box p={1}>
                                  <Text>
                                  {t('resumepage.project.section-add-beta-desc')}
                                  </Text>
                            </Box>
                          } 
                          >
                          <Badge size="sm" color="#FFF5DF" bg="#D5BDA7" borderRadius="6">{t('layout.beta')}</Badge>
                        </Tooltip>
                      </HStack>
                    </Box>
                </Button>
                }
              </VStack>
            )}
          </Box>

          {/* Service Choose section */}
          <Box
            width="20%"
          >
            <VStack spacing={3}>
            {loading ? (
                <>
                  <ServiceCardSkeleton />
                  <ServiceCardSkeleton />
                  <ServiceCardSkeleton />
                </>
              ) : (
                <>
              <Box
                width="100%"
                bg="#f5f7fa"
                borderRadius="16px"
                ml={3}
                mr={3}
                position="relative"
              >
                <Box p={4} >
                  <HStack width="100%" justify="space-between" mb={3}>
                    <Heading as="h2" fontSize="lg" textColor="#364954">{t('resumepage.resumelist.title')}</Heading>
                    <Button
                      variant='ghost'
                      size="sm"
                      p={1}
                      onClick={handleCreateNewVersion}
                      borderRadius="full"
                    >
                      <IoIosAddCircle size="18px" color="#364954" />
                    </Button>
                  </HStack>
                  <VStack 
                    align="left"
                  >
                  <Flex 
                    align="left"
                    // bg="#FFFFFF"
                    borderRadius={12}
                    direction="column"
                  >

                    <Flex
                      align="center"
                      bg="#FFFFFF"
                      borderRadius={8}
                      mb="2"
                      mt="2"
                    >
                    <Menu 
                      isOpen={isMenuOpen} 
                      onClose={() => !isVersionSwitching && setIsMenuOpen(false)}
                      closeOnSelect={false}
                    >
                      <Tooltip 
                        hasArrow 
                        borderRadius={12}
                        bg='#CFD9DF' 
                        color='#364954' 
                        placement='left'
                        label={
                          <Box p={1}>
                            <Box fontSize="12px" fontWeight="normal" textColor="#364954">
                              <Text>
                                {t('resumepage.resumelist.updatedat')}
                              </Text>
                              <Text>
                                {new Date(resume.updated_at).toLocaleString()}
                              </Text>
                            </Box>
                            <Box fontSize="12px" fontWeight="normal" textColor="#364954" mt="2">
                              <Text>
                                {t('resumepage.resumelist.createdat')}
                              </Text>
                              <Text>
                                {new Date(resume.created_at).toLocaleString()}
                              </Text>
                            </Box>
                        </Box>
                        } 
                      >
                        <Text 
                            flex={1}
                            fontSize="14px"
                            fontWeight="bold"
                            ml="3"
                          >
                        {activeResume ? getResumeVersionTitle(resumes.findIndex(r => r.id === activeResume.id)) : "Select a Resume"}
                        </Text>
                      </Tooltip>
                      <MenuButton 
                        as={IconButton} 
                        icon={<ChevronDown size="16px" />} 
                        variant="ghost"
                        size="sm"
                        borderRadius={8}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        _hover={{
                          background: "transparent"
                        }}
                        _active={{
                          background: "transparent"
                        }}
                      >
                      </MenuButton>
                      <MenuList
                        bg="rgba(255, 255, 255, 1)"
                        borderRadius="16px"
                        boxShadow="lg"
                        zIndex={1000}
                      >  
                      {isVersionSwitching && (
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          right="0"
                          bottom="0"
                          borderRadius={16}
                          bg="rgba(255, 255, 255, 0.8)"
                          backdropFilter="blur(5px)"
                          zIndex={1001}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Spinner />
                        </Box>
                      )}
                        {resumes.map((resume, index) => (
                          <MenuItem
                            key={resume.id}
                            onClick={() => handleResumeClick(resume)}
                            bg={resume.id === activeResume?.id ? "#FFFFFF" : "transparent"}
                            _hover={{ bg: "#f5f7fa"}}
                            isDisabled={isVersionSwitching}
                          >
                            <HStack width="100%" justify="space-between"
                              fontSize="14px"
                              textColor={resume.id === activeResume?.id ? "#364954" : "#737D82"}
                              fontWeight={resume.id === activeResume?.id ? "bold" : "normal"}
                              align="center"
                            >
                              <Box align="left">
                                <Text>
                                  {getResumeVersionTitle(index)}
                                </Text>
                                <Text fontSize="12px" fontWeight="normal">
                                  {new Date(resume.created_at).toLocaleString()}
                                </Text>
                              </Box>
                              <Spacer flex={1} />
                              {resume.id === activeResume?.id && <CheckIcon fontSize="16px" color="#364954" />}
                            </HStack>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    </Flex>

                    <Box
                      align="left"
                      bg="#FFFFFF"
                      borderRadius={8}
                      fontSize="14px"
                      textColor="#737D82"
                      p={3}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                      >
                        <Text>
                          {t('targetjob.section-title')}
                        </Text>

                        <Pencil
                          size="14px"
                          cursor="pointer"
                          onClick={handleOpenChangeTargetJobModal}
                        />

                      </Flex>

                        <Tooltip 
                          label={
                            <Box fontSize="12px" fontWeight="normal" textColor="#364954" p={1}>
                              {resume.target_job_title ? resume.target_job_title
                              : t('targetjob.section-desc')
                              }
                            </Box>
                          } 
                          aria-label="Target Job"
                          hasArrow 
                          borderRadius={12}
                          bg='#CFD9DF' 
                          color='#364954' 
                          placement='left'  
                          >
                        <Box
                          size="sm"
                          maxW="100%"
                          whiteSpace="nowrap"
                          noOfLines={1}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          display="block"
                          mt={1}
                          fontWeight="bold"
                        >
                          {resume.target_job_title ? resume.target_job_title : t('targetjob.section-notset')}
                        </Box>
                        </Tooltip>


                    </Box>

                  </Flex>
                  </VStack>
                    
                </Box>
              </Box>           


              <Box
                width="100%"
                bg="#f5f7fa"
                borderRadius="16px"
                ml={3}
                mr={3}
                position="relative"
              >
                <Box
                  p={4}
                >
                  <Heading as="h2" fontSize="lg" mb={4} textColor="#364954">{t('resumepage.tools.title')}</Heading>

                  <VStack
                    align="left"
                  >
                  <Button
                      variant='ghost'
                      size="sm"
                      p={1}
                      onClick={onOpen}
                  >
                    <HStack width="100%" justify="space-between" >
                      <MonitorUp size="16px" color="#364954" />
                      <Text fontSize="14px" textColor="#364954">{t('resumepage.tools.upload')}</Text>
                      <Spacer flex={1} />
                    </HStack>
                  </Button>

                  </VStack>

                </Box>
              </Box>

              <Box
                width="100%"
                bg="#f5f7fa"
                borderRadius="16px"
                ml={3}
                mr={3}
                position="relative"
              >
                <Box
                  p={4}
                >
                  <Heading as="h2" fontSize="lg" mb={2} textColor="#364954">{t('resumepage.service_card_title')}</Heading>
                  <Text mb={4} fontSize="sm" color="#737D82">
                    {t('resumepage.service_card_intro')}
                  </Text>
                  <Divider mt={3} mb={3} />
                  <Box>
                    <Image />
                    <VStack align="left" mb={4}>
                      <HStack >
                        <WandSparkles size="18px" color="#364954"/>
                        <Text fontSize="16px" fontWeight="bold" textColor="#364954">
                          {t('resumepage.service_resumepolish_title')}
                        </Text>
                        <Badge
                          size="sm"
                          color="#FFFFFF"
                          bg="#84B1CB"
                          borderRadius="6" 
                        >
                          v1
                        </Badge>
                      </HStack>
                      <Text fontSize="sm" color="#737D82">{t('resumepage.service_resumepolish_inro')}</Text>
                    </VStack>
                    <Flex align="center">
                      <Button
                        variant='solid'
                        minW="50%"
                        onClick={handleResumePolish}
                        isDisabled={user.credit_balance < 25 || buttonLoading}
                        isLoading={buttonLoading}
                        backgroundColor="#364954"
                        color="#ffffff"
                        size="sm"
                        borderRadius="12px"
                        _hover={{
                          color: "#ffffff",
                          backgroundColor: "#82B2CA"
                        }}
                      >
                        {t('resumepage.service_start')}
                      </Button>
                      <Spacer />
                      <Text fontSize="sm" mr="0">{t('resumepage.service_resumepolish_cost')}</Text>
                    </Flex>
                  </Box>
                  <Divider mt={3} mb={3} />
                  <Text fontSize="sm" textColor="#737D82">{t('resumepage.service_more_line1')}</Text>
                  <Text fontSize="sm" textColor="#737D82">{t('resumepage.service_more_line2')}</Text>
                </Box>
              </Box>
              </>
              )}
            </VStack>
          </Box>
          </Flex>
        </Flex>

      {/* ResumeUploadModal */}
      {resumeId && (
        <ResumeUploadModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setRefreshKey(prevKey => prevKey + 1);
          }}
          onUploadSuccess={handleUploadSuccess}
          resumeId={activeResume.id}
        />
      )}

      {/* Modals */}
      <SlotFullModal
        isOpen={isSlotFullModalOpen}
        onClose={() => setIsSlotFullModalOpen(false)}
        onGetMoreSlots={getMoreSlots}
      />

      <CreateNewVersionsModal
        isOpen={isCreateNewVersionsModalOpen}
        onClose={handleCloseCreateNewVersionsModal}
        resumes={resumes}
        availableSlots={user ? user.resume_slots - resumes.length : 0}
        onCreateVersion={confirmCreateNewVersion}
        onOpenSlotFullModal={() => setIsSlotFullModalOpen(true)}
        getResumeVersionTitle={getResumeVersionTitle}
      />

      {resume && (
        <ChangeTargetJobModal
          isOpen={isChangeTargetJobModalOpen}
          onClose={handleCloseChangeTargetJobModal}
          resume={resume}
          onSave={handleSaveTargetJob}
        />
      )}

    </Flex>
  );
};

export default ResumePage;