import React from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, HStack, Circle, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import JobAnalysisSection from './JobAnalysisSection';
import ResumeAnalysisSection from './ResumeAnalysisSection';
import ResumeRetouchSection from './ResumeRetouchSection';

const ServiceResultOfResumePolish = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Accordion 
      defaultIndex={[0, 1, 2]} 
      allowMultiple={true}
      borderColor="#ffffff"
    >
      <AccordionItem>
        <AccordionButton 
          _hover={{
            bg: '#f5f7fa', 
            color: '#2D3748',
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}
          _expanded={{ 
            bg: '#f5f7fa', 
            color: '#2D3748',
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}
        >
          <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
            <HStack>
              <Circle size='30px' bg='#364954' color='white'>
                <Text>1</Text>
              </Circle>
              <Text as='b' fontSize={'18px'}>{t('orderdetail.jobanalysis_title')}</Text>
            </HStack>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4} align="left">
          {order.tasks && <JobAnalysisSection taskId={order.tasks.find(task => task.task_name === 'job_analysis')?.id} />}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton 
          _hover={{
            bg: '#f5f7fa', 
            color: '#2D3748',
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}
          _expanded={{ 
            bg: '#f5f7fa', 
            color: '#2D3748',
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}
        >
          <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
            <HStack>
              <Circle size='30px' bg='#364954' color='white'>
                <Text>2</Text>
              </Circle>
              <Text as='b' fontSize={'18px'}>{t('orderdetail.resumeanalysis_title')}</Text>
            </HStack>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          {order.tasks && <ResumeAnalysisSection taskId={order.tasks.find(task => task.task_name === 'resume_analysis')?.id} />}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton 
          _hover={{
            bg: '#f5f7fa', 
            color: '#2D3748',
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}                
          _expanded={{ 
            bg: '#f5f7fa', 
            color: '#2D3748',
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}
        >
          <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
            <HStack>
              <Circle size='30px' bg='#364954' color='white'>
                <Text>3</Text>
              </Circle>
              <Text as='b' fontSize={'18px'}>{t('orderdetail.resumeretouch_title')}</Text>
            </HStack>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          {order.tasks && <ResumeRetouchSection taskId={order.tasks.find(task => task.task_name === 'resume_retouch')?.id} />}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ServiceResultOfResumePolish;