import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  SimpleGrid,
  Box,
  useToast,
  Flex, Button, Spacer, Badge,
  HStack, Alert, AlertIcon
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

const TopupModal = ({ isOpen, onClose }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const toast = useToast();
  const { t } = useTranslation();  
  const [stripePublicKey, setStripePublicKey] = useState(null);

  useEffect(() => {
    fetchProducts();
    fetchStripePublicKey();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('/api/v1/payments/products/');
      const data = await response.json();
      setProducts(data.filter(product => product.is_active));
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchStripePublicKey = async () => {
    try {
      const response = await fetch('/api/v1/payments/get-stripe-public-key/');
      const data = await response.json();
      setStripePublicKey(data.stripePublicKey);
    } catch (error) {
      console.error('Error fetching Stripe Public Key:', error);
    }
  };

  const handleTopup = async (packageId) => {
    if (!selectedProduct) {
      toast({
        title: "Error",
        description: "Please select a package first.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      if (!stripePublicKey) {
        throw new Error('Stripe Public Key not available');
      }
      const stripe = await loadStripe(stripePublicKey);
      const response = await fetch('/api/v1/payments/create-checkout-session/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ package_id: selectedProduct.package_id }),
        credentials: 'include',
      });
      const session = await response.json();

      if (session.error) {
        throw new Error(session.error);
      }

      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent
        borderRadius="16px"
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.1s ease"
      >
        <ModalHeader>{t('credits.topupmodal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Box mb="4">
                <Alert status="warning" borderRadius="12px" fontSize="14px" fontWeight="bold">
                    <AlertIcon />
                    {t('credits.topupmodal.notice')}
                </Alert>
            </Box>
          <Text mb={4}>{t('credits.topupmodal.des')}:</Text>
          <SimpleGrid columns={2} spacing={2} mb="6">
            {products.map((product) => (
              <Box
                key={product.package_id}
                borderRadius="16px"
                p={4}
                cursor="pointer"
                onClick={() => setSelectedProduct(product)}
                transition="all 0.3s ease"
                textColor={selectedProduct?.package_id === product.package_id ? "#364954" : "#364954"}
                bg={selectedProduct?.package_id === product.package_id ? "#CFD9DF" : "transparent"}
                borderWidth={2}
                borderColor={selectedProduct?.package_id === product.package_id ? "#364954" : "#CFD9DF"}
                _hover={{
                  bg: "#CFD9DF",
                  textColor: "#364954"
                }}
              >
                <Flex direction="column" justifyContent="space-between">
                <Text fontWeight="bold" flex={1}>
                  {product.is_promotion ? (
                      <Flex direction="column">
                      <Flex justifyContent="space-between">
                        <HStack>
                          <Text>{product.promotional_credits}</Text>
                          <Text>{t('credits.topupmodal.credits')}</Text>
                        </HStack>
                        <Badge 
                          px={2} 
                          variant="solid" 
                          colorScheme="red" 
                          fontSize="md" 
                          borderRadius="8px" 
                          textAlign="center"
                        >
                          🔥 {t('credits.topupmodal.creditsonsale')}</Badge>
                      </Flex>
                        <Text as="s" fontSize="12px" color="#737D82">{product.credits} {t('credits.topupmodal.credits')}</Text>
                    </Flex>
                  ) : (
                    <Flex direction="column">
                    <Flex>
                      <Text>{product.credits}</Text>
                      <Text ml="1">{t('credits.topupmodal.credits')}</Text>
                    </Flex>
                    <Text as="s" fontSize="12px" color={'transparent'}>{product.credits} {t('credits.topupmodal.credits')}</Text>
                    </Flex>
                  )}
                </Text>
                <Spacer />
                <Box>
                <Flex mt="6" textAlign="left" direction="column">
                  <Text as="kbd">CNY</Text>
                  <Text fontWeight="bold" fontSize="25px" as="kbd">{product.price}</Text>
                </Flex>
                </Box>
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
          <Button 
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="16px"
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
            onClick={handleTopup} 
            isDisabled={!selectedProduct || isLoading} 
            width="100%"
          >
            {selectedProduct ? `${t('credits.topupmodal.paybtn-confirm')} ¥${selectedProduct.price} ` : t('credits.topupmodal.paybtn-selectfirst')}
          </Button>
          <Box p={4}>
          <Text fontSize="12px" color="gray.600">
            1. {t('credits.topupmodal.pay-des1')} 
          </Text>
          <Text fontSize="12px" color="gray.600">
            2. {t('credits.topupmodal.pay-des2')}
          </Text>
          <Text fontSize="12px" color="gray.600">
            3. {t('credits.topupmodal.pay-des3')}
          </Text>
          </Box>
        </ModalBody>
      </ModalContent>
      {isLoading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(0, 0, 0, 0.5)"
          zIndex={9999}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="white" fontSize="xl" fontWeight="bold" >{t('credits.topupmodal.loading')}</Text>
        </Box>
      )}
    </Modal>
  );
};

export default TopupModal;