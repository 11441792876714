import React, { useState } from 'react';
import { Box, Text, IconButton, HStack, VStack, Spinner, Flex } from '@chakra-ui/react';
import { LuPenLine, LuTrash2 } from "react-icons/lu";
import EducationForm from './EducationForm';
import { useTranslation } from 'react-i18next';

const EducationEntry = ({ education, onUpdate, onDelete, resumeId }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // Utility function to truncate display fields
  const truncateName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };

  const maxLength = 40; // Define your desired maximum length
  const inst_name = truncateName(education.institution_name, maxLength);
  const major = truncateName(education.major, maxLength);


  const handleUpdate = async (updatedEducation) => {
    setIsUpdating(true);
    try {
      await onUpdate(updatedEducation);
      setIsEditing(false);
    } finally {
      setIsUpdating(false);
    }
  };

  const degreeMappingWithI18N = {
    bachelor: t('Bachelor'),
    master: t('Master'),
    doctor: t('Doctor'),
    others: t('Others')
  };

  if (isEditing) {
    return (
      <EducationForm
        education={education}
        onSubmit={handleUpdate}
        onCancel={() => setIsEditing(false)}
        resumeId={resumeId}
      />
    );
  }

  return (
    <Box py={1}>
      <HStack align="flex-start" justify="space-between">
        <VStack align="stretch" spacing={1}>
          <Text fontWeight="bold" fontSize="md" textColor="#364954">
            {degreeMappingWithI18N[education.degree]}, {major}, {education.start} - {education.end || t('Present')}
          </Text>
          <Text color="#737D82" fontSize="sm" fontWeight="medium">{inst_name}</Text>
          
          { education.academic_achievements &&
          <Flex 
            direction="column" 
            mt={2}
            textColor="#364954"
            fontSize="14px"
          >
            <Box>
              <Text fontWeight="medium">{t('edu.entry_academic_title')}:</Text>
            </Box>
            <Box>
              <Text 
                whiteSpace="pre-wrap"
                noOfLines="2"
                overflow= 'hidden'
                textOverflow= 'ellipsis'
                color="#364954"
              >
                {education.academic_achievements}
              </Text>
            </Box>
          </Flex>
          }

          { education.related_coursework &&
          <Flex 
            direction="column" 
            mt={2}
            textColor="#364954"
            fontSize="14px"
          >
            <Box>
              <Text fontWeight="medium">{t('edu.entry_coursework_title')}:</Text>
            </Box>
            <Box>
              <Text 
                whiteSpace="pre-wrap"
                noOfLines="1"
                overflow= 'hidden'
                textOverflow= 'ellipsis'
                color="#364954"
              >
                {education.related_coursework}
              </Text>
            </Box>
          </Flex>
          }
          
        </VStack>
        <HStack>
          {isUpdating ? (
            <Spinner size="sm" />
          ) : (
            <>
              <IconButton
                icon={<LuPenLine />}
                aria-label="Edit education"
                onClick={() => setIsEditing(true)}
                variant="ghost"
                size="sm"
              />
              <IconButton
                icon={<LuTrash2 />}
                aria-label="Delete education"
                onClick={onDelete}
                variant="ghost"
                size="sm"
              />
            </>
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default EducationEntry;