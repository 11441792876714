import React, { useState, useMemo } from 'react';
import { 
  VStack, Box, Text, Button, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
  Select
} from '@chakra-ui/react';

const ChatList = ({ chats, selectedChatId, onSelectChat, onNewChat, onDeleteChat, chatRounds }) => {
    const { isOpen: isNewChatOpen, onOpen: onNewChatOpen, onClose: onNewChatClose } = useDisclosure();
    const [selectedCombo, setSelectedCombo] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const sortedChats = useMemo(() => {
        return [...chats].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }, [chats]);

    const handleNewChat = async () => {
        setIsLoading(true);
        await onNewChat(selectedCombo);
        setIsLoading(false);
        onNewChatClose();
    };


  return (
    <VStack spacing={4} align="stretch" width="100%">
      <Button 
        onClick={onNewChatOpen} 
        variant='solid'
        backgroundColor="#364954"
        color="#ffffff"
        size="sm"
        borderRadius="12px"
        _hover={{
          color: "#ffffff",
          backgroundColor: "#82B2CA"
        }}
      >
        New Chat
      </Button>
      {sortedChats.map((chat) => (
        <Box 
          key={chat.id} 
          p={3} 
          borderRadius="16px" 
          cursor="pointer"
          bg={selectedChatId === chat.id ? '#CFD9DF' : 'white'}
          _hover={{ bg: selectedChatId === chat.id ? '#CFD9DF' : 'gray.100' }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box onClick={() => onSelectChat(chat.id)}>
            <Text fontSize="14px" color="#364954">
              {new Date(chat.created_at).toLocaleString()}
            </Text>
            <Text fontSize="12px" color="gray.500">
              Rounds left: {chat.rounds_left}
            </Text>
          </Box>
        </Box>
      ))}

      {/* New Chat Modal */}
      <Modal isOpen={isNewChatOpen} onClose={onNewChatClose}>
        <ModalOverlay 
          backdropFilter="blur(10px)"        
        />
        <ModalContent
          borderRadius={16}
          boxShadow="lg"
          background="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(10px)"
          transition="all 0.1s ease"
          _hover={{
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
          }}        
        >
          <ModalHeader>Select Chat Combo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select placeholder="Select combo" onChange={(e) => setSelectedCombo(e.target.value)}>
              {chatRounds.map((combo) => (
                <option key={combo.id} value={combo.id}>
                  {combo.chat_rounds} rounds for {combo.credits} credits
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button 
                mr={3} 
                borderRadius="16px"
                backgroundColor="#364954"
                color="#ffffff"
                size="md"
                _hover={{
                  color: "#ffffff",
                  backgroundColor: "#82B2CA"
                }}
                onClick={handleNewChat} 
                isLoading={isLoading}
            >
              Create Chat
            </Button>
            <Button 
                borderRadius="16px"
                variant="ghost" 
                onClick={onNewChatClose}
            >
                Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </VStack>
  );
};

export default ChatList;