import React from 'react';
import { Box, Text, VStack, Button } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SuccessPage = () => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const domain = url.origin;
  const { t } = useTranslation();  

  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <VStack spacing={6}>
        <CheckCircleIcon w={20} h={20} color="green.500" />
        <Text fontSize="2xl" fontWeight="bold" color="#364954">
        {t('payment.success.title')}
        </Text>
        <Text color="#737D82">
        {t('payment.success.des')}
        </Text>
        <Button 
          as={Link} 
          to={`${domain}/credits`} 
          variant='solid'
          backgroundColor="#364954"
          color="#ffffff"
          borderRadius="16px"
          _hover={{
            color: "#ffffff",
            backgroundColor: "#82B2CA"
          }}
        >
          {t('payment.back-btn')}
        </Button>
      </VStack>
    </Box>
  );
};

export default SuccessPage;