import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  useToast,
  Flex,
  Text, Textarea, IconButton, FormControl,
  HStack, keyframes,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';
import { EllipsisVertical } from 'lucide-react';
import { Send } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';

const Chatbot = ({ chatId: initialChatId, initialMessages = [], onMessageSent, onChatDeleted }) => {
  const [messages, setMessages] = useState(initialMessages);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(initialChatId);
  const [roundsLeft, setRoundsLeft] = useState(null);
  const [streamedResponse, setStreamedResponse] = useState('');
  const [disabledChats, setDisabledChats] = useState({});
  const chatEndRef = useRef(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    setChatId(initialChatId);
    setMessages(initialMessages);
    fetchChatDetails(initialChatId);
  }, [initialChatId, initialMessages]);

  const fetchChatDetails = async (chatId) => {
    try {
      const response = await axiosInstance.get(`api/v1/chatbot/chats/${chatId}/`);
      setRoundsLeft(response.data.rounds_left);
      setDisabledChats(prev => ({...prev, [chatId]: response.data.rounds_left === 0}));
    } catch (error) {
      console.error('Error fetching chat details:', error);
      toast({
        title: "Error fetching chat details",
        description: "Unable to fetch the details for this chat.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
    if (input.split(/\s+/).length > 200) {
      toast({
        title: "Input too long",
        description: "Please limit your input to 200 words.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }
  
    setMessages(prev => [...prev, { type: 'user', content: input }]);
    setInput('');
    setIsLoading(true);
  
    try {
        const response = await axiosInstance.post('api/v1/chatbot/chat/', {
          user_input: input,
          chat_id: chatId,
        }, {
          responseType: 'text',
        });
    
        console.log('Sending request:', { input, chatId });
        console.log('Received response:', response.data);
    
        let fullResponse = '';
        let metaData = null;
  
    // Split the response by newlines
    const lines = response.data.split('\n');
    for (const line of lines) {
      if (line.startsWith('META:')) {
        try {
          metaData = JSON.parse(line.slice(5));
          setChatId(metaData.chat_id);
          setRoundsLeft(metaData.rounds_left);
          setDisabledChats(prev => ({...prev, [metaData.chat_id]: metaData.rounds_left === 0}));
        } catch (error) {
          console.error('Error parsing metadata:', error);
        }
      } else {
        fullResponse += line + '\n';
      }
    }

    if (fullResponse) {
      setMessages(prev => [...prev, { type: 'bot', content: fullResponse.trim() }]);
    }
  
    if (metaData) {
        setChatId(metaData.chat_id);
        setRoundsLeft(metaData.rounds_left);
        setDisabledChats(prev => ({...prev, [metaData.chat_id]: metaData.rounds_left === 0}));
      }

      // After processing the response and updating the state
      if (onMessageSent) {
        onMessageSent();
      }
    
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: "An error occurred",
        description: error.response?.data?.error || error.message || "Unable to process your request",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
      setStreamedResponse('');
    }
  };

  const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #82B2CA; }
  50% { box-shadow: 0 0 20px #82B2CA; }
  100% { box-shadow: 0 0 5px #82B2CA; }
`;

const handleDeleteChat = async () => {
  setIsDeleting(true);
  try {
    await axiosInstance.delete(`api/v1/chatbot/chats/${chatId}/delete/`);
    toast({
      title: "Chat deleted",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
    onChatDeleted(chatId);
  } catch (error) {
    console.error('Error deleting chat:', error);
    toast({
      title: "Error deleting chat",
      description: error.response?.data?.error || "An unexpected error occurred",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  } finally {
    setIsDeleting(false);
    onClose();
  }
};

  return (
      <Flex 
        direction="column" 
        spacing={4} 
        height="100%"
      >
        <Box 
          mb={3}
          align="right"
        >
          <Menu zIndex={1000}>
              <MenuButton
                as={IconButton}
                icon={<EllipsisVertical size="20px" />}
                variant="ghost"
                aria-label="Options"
                size="sm"
              />
              <MenuList
                bg="rgba(255, 255, 255, 1)"
                backdropFilter="blur(5px)"
                borderRadius="12px"
                boxShadow="lg"
              >
                  <MenuItem 
                    onClick={onOpen} 
                    isLoading={isDeleting}
                    // bg="#ffffff"
                    _hover={{
                      bg: "#f5f7fa",
                    }}
                  >
                    Delete Chat
                  </MenuItem>
              </MenuList>
          </Menu>
        </Box>

        <Box 
          flex={1}
          overflowY="auto" 
        >
          {messages.map((message, index) => (
            <Box 
              key={index} mb={4} 
              textAlign={message.type === 'user' ? 'right' : 'left'}
              ml={message.type === 'user' ? '20' : '0'}
              mr={message.type === 'user' ? '0' : '5'}

            >
              <Text
                bg={message.type === 'user' ? '#CFD9DF' : '#f5f7fa'}
                textColor={message.type === 'user' ? '#364954' : '#364954'}
                fontSize="md"
                p={2}
                borderTopRightRadius={message.type === 'user' ? '0' : '16px'}
                borderTopLeftRadius={message.type === 'user' ? '16px' : '0'}
                borderBottomRightRadius={message.type === 'user' ? '16px' : '16px'}
                borderBottomLeftRadius={message.type === 'user' ? '16px' : '16px'}
                textAlign="left"
                display="inline-block"
                whiteSpace="pre-wrap"
              >
                {message.content}
              </Text>
            </Box>
          ))}
          {streamedResponse && (
            <Box mb={4} textAlign="left">
              <Text 
                bg="gray.100" 
                p={2} 
                display="inline-block"
              >
                {streamedResponse}
              </Text>
            </Box>
          )}
          <div ref={chatEndRef} />
        </Box>

        <Box mt={4}>
          <Text fontSize="14px" mb="2" textColor="#737D82">
            Rounds left: {roundsLeft !== null ? roundsLeft : 'Loading...'}
          </Text>
          <form onSubmit={handleSubmit}>
            <HStack>
              <FormControl>
                <Textarea
                  borderRadius="16px"
                  bg="#ffffff"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Type your message here..."
                  disabled={isLoading || disabledChats[chatId]}
                  border={isLoading ? "2px solid #82B2CA" : "1px solid #E2E8F0"}
                  animation={isLoading ? `${glowAnimation} 2s ease-in-out infinite` : "none"}
                />
              </FormControl>
              <IconButton
                aria-label='Submit'
                isRound={true}
                icon={<Send
                  size="18px"
                  />}
                type="submit"
                isLoading={isLoading}
                disabled={disabledChats[chatId]}
                size="sm"
                variant='solid'
                backgroundColor="#364954"
                color="#ffffff"
                _hover={{
                  color: "#ffffff",
                  backgroundColor: "#82B2CA"
                }}
              />)
            </HStack>
          </form>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay 
          backdropFilter="blur(10px)"

        />
        <ModalContent
          borderRadius={16}
          boxShadow="lg"
          background="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(10px)"
          transition="all 0.1s ease"
          _hover={{
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this chat? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="red" 
              borderRadius="16px"
              mr={3} 
              onClick={handleDeleteChat} 
              isLoading={isDeleting}
            >
              Delete
            </Button>
            <Button 
              borderRadius="16px"
              variant="ghost" 
              onClick={onClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </Flex>
  );
};

export default Chatbot;