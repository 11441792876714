import React from 'react';
import { Box, Flex, Link, Text, VStack, HStack, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('homepage');

  return (
    <Box 
        as="footer" 
        bg="#f5f7fa" 
        py={8}
        mt="auto"
        width="100%"
      >
      <Flex 
        maxW="1200px" 
        mx="auto" 
        px={4} 
        flexDirection={['column', 'row']} 
        justifyContent="space-between" 
        alignItems={['flex-start', 'center']}
        textAlign={['left', 'center']}  /* Adjust text alignment for mobile and desktop */
      >
        <VStack alignItems={['flex-start', 'flex-start']} mb={[4, 0]}>
          <Image src="/myCareerHelp.png" alt="MyCareerHelp Logo" h="40px" mb={2} />
          <HStack fontSize="14px" textColor="#737D82">
            <Text fontSize="14px" color="#737D82">© 2024 {t('footer.company')} All rights reserved.</Text>
          </HStack>
        </VStack>
        <VStack mt={[4, 0]} alignItems={['flex-start', 'flex-end']} fontSize="14px" textColor="#737D82" spacing={2}>
          <Link href="/terms" target="_blank" rel="noopener noreferrer">{t('footer.terms')}</Link>
          <Link href="/privacy" target="_blank" rel="noopener noreferrer">{t('footer.privacy')}</Link>
          <Link href="#">{t('footer.blog')}</Link>
        </VStack>
      </Flex>
    </Box>
  );
};

export default Footer;
