import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import {
  Box,
  Text,
  Button,
  useToast,
  useClipboard,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Icon,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const ReferralProgram = () => {
  const { t } = useTranslation();
  const [referralCode, setReferralCode] = useState('');
  const [usesLeft, setUsesLeft] = useState(0);
  const [expiresAt, setExpiresAt] = useState('');
  const [referredUsers, setReferredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const domain = url.origin; 

  const referralLink = `${domain}/sign-up/${referralCode}`;
  const { hasCopied, onCopy } = useClipboard(referralLink);

  useEffect(() => {
    fetchReferralCode();
  }, []);

  const fetchReferralCode = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/v1/accounts/generate-referral-code/');
      setReferralCode(response.data.referral_code);
      setUsesLeft(response.data.uses_left);
      setExpiresAt(new Date(response.data.expires_at).toLocaleDateString());
      fetchReferredUsers(response.data.referral_code);
    } catch (error) {
      console.error('Error fetching referral code:', error);
      toast({
        title: t('settings.fetchreferral_fail'),
        description: t('settings.fetchreferral_fail_description'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReferredUsers = async (code) => {
    try {
      const response = await axiosInstance.get(`/api/v1/accounts/users-by-referral-code/?code=${code}`);
      setReferredUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching referred users:', error);
      toast({
        title: 'Failed to fetch referred users',
        description: 'An error occurred while fetching referred users.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <Box 
      borderRadius="16px" 
      bg="#ffffff"
      p={4}
      transition="all 0.3s ease"
      _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Text 
        fontSize="16px" 
        fontWeight="bold" 
        mb={4}
        textColor="#364954"
      >
         {t('credits.referral.title')}
      </Text>

      <Box>
        <Box mb={4} p={3} borderRadius="12px" bg="#F7FAFC">
          <Text fontWeight="medium">
          {t('credits.referral.des')}
          </Text>
          <Text mt="2" fontWeight="normal" fontSize="14px" textColor="#737D82">
          {t('credits.referral.des2')}
          </Text>
        </Box>

        <Box 
            mb={1} p={1} 
            borderRadius="16px" 
            border="1px solid" 
            borderColor="gray.200"
            bg="#CFD9DF"
            width="70%"
        >
          <Flex justify="space-between" align="center">
            {isLoading ? (
              <Skeleton height="20px" width="60%" ml={4} />
            ) : (
              <Text 
                  ml={4}
                  fontWeight="medium" 
                  isTruncated>{referralLink}
              </Text>
            )}
            <Button 
                leftIcon={<Icon as={CopyIcon} />} 
                onClick={onCopy} 
                variant='solid'
                backgroundColor="#ffffff"
                color="#364954"
                size="sm"
                borderRadius="12px"
                _hover={{
                    color: "#ffffff",
                    backgroundColor: "#82B2CA"
                }}
                isDisabled={isLoading}
                >
              {hasCopied ? t('credits.referral.copied') : t('credits.referral.copybutton')}
            </Button>
          </Flex>
        </Box>

        {isLoading ? (
          <SkeletonText mt="2" noOfLines={1} spacing="4" ml={5} mb={8} width="50%" />
        ) : (
          <Text 
              fontSize="14px" 
              textColor="#737D82"
              ml={5}
              mb={8}
          >
              {t('credits.referral.usesleft')}: {usesLeft} | {t('credits.referral.autorefresh')}: {expiresAt}
          </Text>
        )}

        <Box>
          <Text 
            fontSize="14px" 
            fontWeight="bold" 
            mb={2}
            textColor="#364954"
          >
            {t('credits.referral.referredusers')}
          </Text>
          {isLoading ? (
            <Skeleton height="100px" mb={4} borderRadius="16px" />
          ) : referredUsers.length === 0 ? (
            <Text fontSize="14px" textColor="#364954" mb={4}>
            {t('credits.referral.noreferreduser')}
            </Text>
          ) : (
            <Table 
            variant="simple"
            size="sm"
            mb="8"
            >
            <Thead>
                <Tr>
                <Th>Email</Th>
                <Th>{t('credits.referral.verificationstatus')}</Th>
                </Tr>
            </Thead>
            <Tbody>
                {referredUsers.map((user, index) => (
                <Tr key={index}>
                    <Td>{user.masked_email}</Td>
                    <Td>{user.email_verified ? t('credits.referral.verified') : t('credits.referral.pending')}</Td>
                </Tr>
                ))}
            </Tbody>
            </Table>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralProgram;