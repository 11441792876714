import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Stack,
  FormErrorMessage,
  Text,
  Box,
  HStack,
  Link,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  Flex,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Circle,
  Center
} from '@chakra-ui/react';
import { ChevronRight } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserContext';
import ResumePolishResultPreviewModal from './ResumePolishResultPreviewModal';

const ResumePolishPage = () => {
  const { t } = useTranslation();  
  const toast = useToast();
  const navigate = useNavigate();
  const { resumeId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [resumeContent, setResumeContent] = useState({
    education: [],
    experiences: [],
    skills: []
  });
  const { updateCreditBalance } = useContext(UserContext);
  const { user, fetchUserProfile } = useContext(UserContext);
  const [isFetching, setIsFetching] = useState(true);
  const [targetJobInfo, setTargetJobInfo] = useState({
    jobTitle: '',
    jobCompany: '',
    jobResponsibilities: '',
    jobRequirements: '',
  });
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);


  const sortEducations = (educations) => {
    return educations.sort((a, b) => {
      const aEnd = a.end === t('Present') ? new Date() : new Date(a.end);
      const bEnd = b.end === t('Present') ? new Date() : new Date(b.end);
      return bEnd - aEnd;
    });
  };

  const sortExperiences = (experiences) => {
    return experiences.sort((a, b) => {
      const aEnd = a.end === t('Present') ? new Date() : new Date(a.end);
      const bEnd = b.end === t('Present') ? new Date() : new Date(b.end);
      return bEnd - aEnd;
    });
  };


  const validationSchema = Yup.object({
    jobTitle: Yup.string().trim().required('Job Title is required'),
    jobCompany: Yup.string().trim(),
    jobResponsibilities: Yup.string().trim().required('Responsibilities are required'),
    jobRequirements: Yup.string().trim().required('Requirements are required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/api/v1/services/resume-polish/', {
        resumeId,
        ...values,
      });
      
      if (response.data && response.data.orderId) {
        updateCreditBalance(response.data.new_credit_balance);
  
        toast({
          title: t('service.order_create_success_title'),
          description: t('service.order_create_success_body'),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
  
        // Fetch the user's onboarding tasks
        const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        
        if (onboardingTasksResponse.status === 200) {
          const tasks = onboardingTasksResponse.data;
          const resumePolishTask = tasks.find(task => task.task_id === 3);
          
          if (resumePolishTask && !resumePolishTask.is_completed) {
            // Call the API to complete the task only if it's not already completed
            try {
              const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-resume-polish-task/');
              
              if (onboardingResponse.status === 200) {
                await fetchUserProfile(); 
  
                toast({
                  title: t('resumepage.rptask.toast-complete'),
                  description: `${t('resumepage.rptask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('resumepage.rptask.toast-complete-des2')}`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            } catch (error) {
              console.error('Error completing resume polish task:', error);
              // You might want to show an error toast here
            }
          }
        }
  
        navigate(`/processing/${response.data.orderId}`);
      } else {
        throw new Error('Order ID not received from server');
      }
  
      return { newCreditBalance: response.data.new_credit_balance };
  
    } catch (error) {
      toast({
        title: t('service.order_create_fail_title'),
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  const fetchResumeContent = async () => {
    setIsFetching(true);
    try {
      const [educationResponse, experienceResponse, skillResponse, resumeResponse] = await Promise.all([
        axiosInstance.get(`/api/v1/resumes/education/?resume=${resumeId}`),
        axiosInstance.get(`/api/v1/resumes/experiences/?resume=${resumeId}`),
        axiosInstance.get(`/api/v1/resumes/skills/?resume=${resumeId}`),
        axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`)
      ]);

      setResumeContent({
        education: sortEducations(educationResponse.data),
        experiences: sortExperiences(experienceResponse.data),
        skills: skillResponse.data
      });

      setTargetJobInfo({
        jobTitle: resumeResponse.data.target_job_title || '',
        jobCompany: resumeResponse.data.target_job_company || '',
        jobResponsibilities: resumeResponse.data.target_job_responsibilities || '',
        jobRequirements: resumeResponse.data.target_job_requirements || '',
      });

    } catch (error) {
      console.error('Error fetching resume content:', error);
      toast({
        title: "Error fetching resume",
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchResumeContent();
    fetchUserProfile();
  }, [resumeId, fetchUserProfile]);

  const degreeMappingWithI18N = {
    bachelor: t('Bachelor'),
    master: t('Master'),
    doctor: t('Doctor'),
    others: t('Others')
  };


  const handleOpenPreviewModal = () => {
    setIsPreviewModalOpen(true);
  };

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
    <Box height="30px" flexShrink={0} />
    <Flex direction="column" flex="1" overflow="hidden">
      <Breadcrumb 
        spacing='8px' 
        separator={<ChevronRight color='#364954' size="16px" />}
        fontSize="2xl" 
        fontWeight="bold"
        mb={4}
      >
        <BreadcrumbItem>
          <BreadcrumbLink 
            as="button"
            onClick={() => navigate('/gettingstarted')}
            textDecoration="none"
            textColor="#737D82"
            fontWeight="bold"
            mt={-1}
          >{t('gettingstarted.title')}</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink 
            href='#' 
            textColor="#364954"
            mt={-1}
          >{t('service.resumepolish_modal_title')}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box
          flex="1"
          bg="#f5f7fa"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="auto"
          p={5}
        >
      <Heading as="h1" fontSize="18px" mb={8}>{t('resumepolish.serviceongoing')}: {t('service.resumepolish_modal_title')}</Heading>

      <Box p={0} mb={8}>
        <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
          <HStack>
          <Circle size='30px' bg='#364954' color='white'>
            <Text>1</Text>
          </Circle>
          <Text as='b' fontSize={'18px'}>{t('resumepolish.sectiontitle-resume')}</Text>
          </HStack>
        </Box>
          <HStack mt="4" mb="1">
            <Text as="b" textColor="#364954">{t('resumepolish.sectiondes-resume')}:</Text>
            <Link onClick={onDrawerOpen}>{t('resumepolish.sectionbtn-resume')}</Link>
          </HStack>
          <Text textColor="#737D82" fontSize="sm">{t('resumepolish.sectionnote-resume')}</Text>
      </Box>

      <Box p={0} mb={4}>
        <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
          <HStack>
          <Circle size='30px' bg='#364954' color='white'>
            <Text>2</Text>
          </Circle>
          <Text as='b' fontSize={'18px'}>{t('resumepolish.sectiontitle-job')}</Text>
          </HStack>
        </Box>

        <Box mt="4" mb="4">
          <Text textColor="#364954" mb="2">{t('service.resumepolish_modal_intro')}</Text>
          <Text textColor="#737D82" fontSize="sm">
          {t('resumepolish.sectiontitle-job-desc')} 
          </Text>
        </Box>

        <Formik
          initialValues={{
            jobTitle: targetJobInfo.jobTitle,
            jobCompany: targetJobInfo.jobCompany,
            jobResponsibilities: targetJobInfo.jobResponsibilities,
            jobRequirements: targetJobInfo.jobRequirements,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack direction={['column', 'row']}>
                <Field name="jobTitle">
                  {({ field, form }) => (
                    <FormControl isRequired mt={4} isInvalid={form.errors.jobTitle && form.touched.jobTitle} isDisabled={isFetching || isLoading}>
                      <FormLabel>{t('service.resumepolish_modal_jobtitle')}</FormLabel>
                      <Input 
                        bg="#ffffff" 
                        {...field} />
                      <FormErrorMessage>{form.errors.jobTitle}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="jobCompany">
                  {({ field, form }) => (
                    <FormControl mt={4} isDisabled={isFetching || isLoading}>
                      <FormLabel>{t('service.resumepolish_modal_company')}</FormLabel>
                      <Input 
                        bg="#ffffff"
                        {...field} />
                    </FormControl>
                  )}
                </Field>
              </Stack>
              <Field name="jobResponsibilities">
                {({ field, form }) => (
                  <FormControl isRequired mt={4} isInvalid={form.errors.jobResponsibilities && form.touched.jobResponsibilities} isDisabled={isFetching || isLoading}>
                    <FormLabel>{t('service.resumepolish_modal_responsibilities')}</FormLabel>
                    <Textarea 
                      bg="#ffffff"                    
                      {...field} />
                    <FormErrorMessage>{form.errors.jobResponsibilities}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="jobRequirements">
                {({ field, form }) => (
                  <FormControl isRequired mt={4} isInvalid={form.errors.jobRequirements && form.touched.jobRequirements} isDisabled={isFetching || isLoading}>
                    <FormLabel>{t('service.resumepolish_modal_requirements')}</FormLabel>
                    <Textarea 
                      bg="#ffffff"                   
                      {...field} />
                    <FormErrorMessage>{form.errors.jobRequirements}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Flex gap="3" mt="10">
                <Button 
                  width="150px"
                  color="#ffffff" 
                  backgroundColor="#364954"
                  borderRadius="16px"
                  type="submit" 
                  _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                  }}
                  isLoading={isLoading} 
                  loadingText={t('service.resumepolish_modal_loading')}
                  isDisabled={user.credit_balance < 25 || isLoading}
                >
                  {t('service.resumepolish_modal_confirm')}
                </Button>
                <Center>
                  <Text
                    textColor="#737D82"
                    fontSize="14px"
                  >
                    {t('resumepolish.pricenote')}
                  <Link
                    ml="2"
                    onClick={handleOpenPreviewModal}
                  >
                    {t('resumepolish.previewresultbtn')}
                  </Link>
                </Text>
                <ResumePolishResultPreviewModal
                  isOpen={isPreviewModalOpen}
                  onClose={handleClosePreviewModal}
                />
                </Center>
              </Flex>
            </Form>
          )}
        </Formik>
          <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
            <DrawerOverlay />
            <DrawerContent
              background="rgba(255, 255, 255, 1)"
              borderLeftRadius={16}
            >
              <DrawerCloseButton />
              <DrawerHeader>{t('resumepolish.previewdrawer.title')}</DrawerHeader>
              <DrawerBody 
                overflowY="auto" 
              >
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text fontWeight="bold" mb={2}>{t('resumepolish.previewdrawer.edu')}:</Text>
                    {resumeContent.education.map(edu => (
                      <Box key={edu.id} mb={2}>
                        <Text fontWeight="medium">{degreeMappingWithI18N[edu.degree]}, {edu.major}, {edu.institution_name}</Text>
                        <Text fontSize="sm">{edu.start} - {edu.end || t('Present')}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" mb={2}>{t('resumepolish.previewdrawer.wrk')}:</Text>
                    {resumeContent.experiences.map(exp => (
                      <Box key={exp.id} mb={2}>
                        <Text fontWeight="medium">{exp.title}, {exp.organization_name}</Text>
                        <Text fontSize="sm" >{exp.start} - {exp.end || t('Present')}</Text>
                        <Text mt={1} fontSize="sm" whiteSpace="pre-wrap">{exp.description}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" mb={2}>{t('resumepolish.previewdrawer.skills')}:</Text>
                    {resumeContent.skills.map(skill => (
                      <Box key={skill.id} mb={2}>
                        <Text fontWeight="medium">{skill.skill_name}</Text>
                        <Text fontSize="sm" >{skill.description}</Text>
                      </Box>
                    ))}
                  </Box>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Box>
    </Flex>
    </Flex>
  );
};

export default ResumePolishPage;