// src/components/HomePage/PricingPage.js
import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, SimpleGrid, VStack, Flex,
    ListItem, OrderedList, UnorderedList,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
    Skeleton, SkeletonText
 } from '@chakra-ui/react';
import HomePageLayout from './HomePageLayout';
import { useTranslation } from 'react-i18next';

const PriceCard = ({ plan, isLoading }) => {
    const { t } = useTranslation("homepage");

    if (isLoading) {
        return (
            <Flex 
                direction="column"
                p={6} 
                bg="linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);" 
                borderRadius="16px" 
                spacing={1} 
                align="stretch"
                justifyContent="space-between"
                textColor="#364954"
            >
                <Skeleton height="20px" width="80%" mb="6" />
                <Skeleton height="24px" width="40%" mb="0" />
                <Skeleton height="36px" width="60%" mb="6" />
                <Skeleton height="16px" width="100%" />
            </Flex>
        );
    }

    return (
        <Flex 
            direction="column"
            p={6} 
            bg="linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);" 
            borderRadius="16px" 
            spacing={1} 
            align="stretch"
            justifyContent="space-between"
            textColor="#364954"
        >
            <Heading as="h3" fontSize="16px" mb="6" textColor="#737D82">{t(`pricingpage.card.${plan.name}`)}</Heading>
            <Text fontSize="16px" fontWeight="bold" mb="0" as="kbd" >{t('pricingpage.card.currency')}</Text>
            <Text fontSize="30px" fontWeight="bold" mb="6" as="kbd" >{plan.price}</Text>
            <Text fontSize="14px" textColor="#737D82">{t('pricingpage.card.forCredits', { credits: plan.credits })}</Text>
        </Flex>
    );
};

const PricingPage = () => {
    const { t, ready } = useTranslation("homepage");
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (ready) {
            setShowContent(true);
        }
    }, [ready]);

    const pricingPlans = [
        { name: "starterPack", price: "20.00", credits: "20" },
        { name: "essentialPack", price: "50.00", credits: "50" },
        { name: "proPack", price: "100.00", credits: "100" },
        { name: "elitePack", price: "200.00", credits: "200" },
    ];

    const faqs = [
        { question: "faq1", answer: "faq1Answer" },
        { question: "faq2", answer: "faq2Answer" },
        { question: "faq3", answer: "faq3Answer" },
    ];

    const renderContent = () => (
        <VStack 
            spacing={8} 
            maxW="800px" 
            mx="auto" 
            px={4} 
            height="100%" 
            justifyContent="space-between"
            align="left"
        >
            <Heading as="h1" size="2xl">{t('pricingpage.title')}</Heading>
            <Box>
                <Text lineHeight={1.5} color="#364954">
                    {t('pricingpage.intro')}
                </Text>
            </Box>
            <Box textColor="#364954">
                <Heading as="h2" fontSize="20px" mb="4">
                    {t('pricingpage.howItWorks.title')}
                </Heading>
                <Text lineHeight={1.5} mb="4">
                    <Text mb="4">
                        {t('pricingpage.howItWorks.intro')}
                    </Text>
                    <OrderedList>
                    <ListItem>
                        <Text as="b">{t('pricingpage.howItWorks.step1.title')}:</Text> {t('pricingpage.howItWorks.step1.description')}
                    </ListItem>
                    <ListItem>
                        <Text as="b">{t('pricingpage.howItWorks.step2.title')}:</Text> {t('pricingpage.howItWorks.step2.description')}
                    </ListItem>
                    <ListItem>
                        <Text as="b">{t('pricingpage.howItWorks.step3.title')}:</Text> {t('pricingpage.howItWorks.step3.description')}
                    </ListItem>
                    </OrderedList>
                </Text>
            </Box>
            <Box textColor="#364954">
                <Heading as="h2" fontSize="20px" mb="4">
                    {t('pricingpage.whyChooseUs.title')}
                </Heading>
                <Text lineHeight={1.5}>
                    <UnorderedList mb="4">
                        <ListItem>
                            <Text as="b">{t('pricingpage.whyChooseUs.reason1.title')}:</Text> {t('pricingpage.whyChooseUs.reason1.description')}
                        </ListItem>
                        <ListItem>
                            <Text as="b">{t('pricingpage.whyChooseUs.reason2.title')}:</Text> {t('pricingpage.whyChooseUs.reason2.description')}
                        </ListItem>
                        <ListItem>
                            <Text as="b">{t('pricingpage.whyChooseUs.reason3.title')}:</Text> {t('pricingpage.whyChooseUs.reason3.description')}
                        </ListItem>
                        <ListItem>
                            <Text as="b">{t('pricingpage.whyChooseUs.reason4.title')}:</Text> {t('pricingpage.whyChooseUs.reason4.description')}
                        </ListItem>
                    </UnorderedList>
                </Text>
            </Box>

            <Heading as="h2" fontSize="20px" textColor="#364954">
                {t('pricingpage.packages.title')}
            </Heading>

            <SimpleGrid columns={[1, null, 2, 4]} spacing={2} width="100%">
                {pricingPlans.map((plan, index) => (
                <PriceCard key={index} plan={plan} isLoading={!showContent} />
                ))}
            </SimpleGrid>
            <Box>
                <Heading as="h2" fontSize="20px" mb={4} textColor="#364954">{t('pricingpage.note.title')}</Heading>
                <Text as="cite">
                    {t('pricingpage.note.content')}
                </Text>
            </Box>

            <Box >
                <Heading as="h2" fontSize="20px" mb={4} textColor="#364954">{t('pricingpage.faqs.title')}</Heading>
                <Box alignItems="center">
                    <Accordion allowMultiple="true">
                        {faqs.map((faq, index) => (
                        <AccordionItem key={index}>
                            <h2>
                            <AccordionButton
                                _hover={{
                                    bg: '#f5f7fa', 
                                    color: '#2D3748',
                                }}
                                _expanded={{ 
                                    bg: '#f5f7fa', 
                                    color: '#2D3748',
                                }}
                            >
                                <Box 
                                    flex="1" 
                                    textAlign="left"
                                    fontWeight="bold"
                                >
                                {t(`pricingpage.faqs.${faq.question}.question`)}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel 
                                p={1} 
                                textAlign="left"
                                fontSize="16px"
                                textColor="#364954"
                            >
                                <Box p={4}>
                                    {t(`pricingpage.faqs.${faq.question}.answer`)}
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        ))}
                    </Accordion>
                    </Box>
                </Box>
        </VStack>
    );

    const renderPlaceholder = () => (
        <VStack 
            spacing={8} 
            maxW="800px" 
            mx="auto" 
            px={4} 
            height="100%" 
            justifyContent="space-between"
            align="left"
        >
            <Skeleton height="60px" width="80%" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
            <Skeleton height="40px" width="60%" />
            <SkeletonText mt="4" noOfLines={6} spacing="4" />
            <Skeleton height="40px" width="60%" />
            <SkeletonText mt="4" noOfLines={6} spacing="4" />
            <Skeleton height="40px" width="60%" />
            <SimpleGrid columns={[1, null, 2, 4]} spacing={2} width="100%">
                {pricingPlans.map((plan, index) => (
                <PriceCard key={index} plan={plan} isLoading={true} />
                ))}
            </SimpleGrid>
            <Skeleton height="40px" width="60%" />
            <SkeletonText mt="4" noOfLines={3} spacing="4" />
            <Skeleton height="40px" width="60%" />
            <SkeletonText mt="4" noOfLines={6} spacing="4" />
        </VStack>
    );

    return (
        <HomePageLayout>
            <Box py={20} px={6} flex={1} align="left">
                {showContent ? renderContent() : renderPlaceholder()}
            </Box>
        </HomePageLayout>
    );
};

export default PricingPage;