// src/components/Credits/TransactionHistory.js
import React, { useState, useEffect } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Text, HStack, 
    useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Badge, Tooltip, Flex
 } from '@chakra-ui/react';
import { ChevronLeft, ChevronRight, CircleCheck, CircleAlert, CircleDashed } from 'lucide-react';
import { fetchTransactionHistory, fetchPurchaseOrderDetail, fetchServiceOrderDetail } from '../../utils/orderApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isServiceOrder, setIsServiceOrder] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { t } = useTranslation();  

    useEffect(() => {
        fetchTransactions();
      }, [currentPage]);

    const fetchTransactions = async () => {
        try {
            const history = await fetchTransactionHistory(currentPage);
            setTransactions(history.results);
            setTotalPages(Math.ceil(history.count / 10)); // Assuming 10 items per page
        } catch (error) {
            console.error('Failed to fetch transactions:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleActionClick = async (order) => {
        if (order.order_type === 'service') {
            const serviceOrder = await fetchServiceOrderDetail(order.id);
            setSelectedOrder(serviceOrder);
            setIsServiceOrder(true);
            onOpen();
        } else {
            const purchaseOrder = await fetchPurchaseOrderDetail(order.id);
            setSelectedOrder(purchaseOrder);
            setIsServiceOrder(false);
            onOpen();
        }
    };

    const handleViewServiceResult = (orderId) => {
        navigate(`/service-orders/${orderId}`);
    };

    const getStatusIcon = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return <CircleCheck size="14px" color="green" />;
            case 'failed':
            case 'canceled':
                return <CircleAlert size="14px" color="red" />;
            case 'pending':
                return <CircleDashed size="14px" color="orange" />;
            default:
                return null;
        }
    };

    const getStatusLabel = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return 'Success';
            case 'failed':
                return 'Failed';
            case 'canceled':
                return 'Canceled';
            case 'pending':
                return 'Pending';
            default:
                return status;
        }
    };

    const getAmountBadge = (transaction) => {
        const amount = transaction.order_type === 'purchase' ? `+ ${transaction.amount}` : `- ${transaction.amount}`;
        const status = transaction.order_status.toLowerCase();

        switch (status) {
            case 'pending':
                return <Badge colorScheme="gray" textColor="#CFD9DF">{amount}</Badge>;
            case 'failed':
            case 'canceled':
                return <Badge colorScheme="whiteAlpha" textDecoration="line-through" textColor="#ffffff">{amount}</Badge>;
            default:
                return (
                    <Badge colorScheme={transaction.order_type === 'purchase' ? "green" : "red"}>
                        {amount}
                    </Badge>
                );
        }
    };


    return (
    <Box 
        borderRadius="16px" 
        bg="#ffffff"
        p={4}
        transition="all 0.3s ease"
        _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
        }}
    >
        <Text fontSize="16px" fontWeight="bold" mb={4} textColor="#364954">
        {t('credits.transaction.title')}
        </Text>

        <Table variant="simple" colorScheme='blackAlpha' size="sm" textColor="#364954">
            <Thead>
                <Tr>
                    <Th width="200px">{t('credits.transaction.th-time')}</Th>
                    <Th>{t('credits.transaction.th-balance')}</Th>
                    <Th>{t('credits.transaction.th-type')}</Th>
                    <Th>{t('credits.transaction.th-status')}</Th>
                    <Th width="350px">{t('credits.transaction.th-note')}</Th>
                    <Th>{t('credits.transaction.th-action')}</Th>
                </Tr>
            </Thead>

            <Tbody>
                    {transactions.map((transaction) => (
                        <Tr key={transaction.id}>
                            <Td fontSize="12px">{new Date(transaction.created_at).toLocaleString()}</Td>
                            <Td>{getAmountBadge(transaction)}</Td>
                            <Td fontSize="12px">{transaction.order_type}</Td>
                            <Td fontSize="12px">
                                <Flex align="center">
                                    {getStatusIcon(transaction.order_status)}
                                    <Text ml={2}>{getStatusLabel(transaction.order_status)}</Text>
                                </Flex>
                            </Td>
                            <Td fontSize="12px">
                                <Tooltip label={transaction.note} aria-label="Note Tooltip">
                                    <span style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '350px'
                                    }}>
                                        {transaction.note}
                                    </span>
                                </Tooltip>
                            </Td>
                            <Td>
                            {(() => {
                                switch (transaction.order_type) {
                                    case 'chat':
                                        return (
                                            <Button 
                                                mt="2" mb="2"
                                                isDisabled={true}
                                                size="xs"
                                                variant="link"
                                            >
                                                {t('credits.transaction.td-action-nodetail')}
                                            </Button>
                                        );
                                    case 'resumeslot':
                                        return (
                                            <Button 
                                                mt="2" mb="2"
                                                isDisabled={true}
                                                size="xs"
                                                variant="link"
                                            >
                                                {t('credits.transaction.td-action-nodetail')}
                                            </Button>
                                        );
                                    default:
                                        return (
                                            <Button 
                                                mt="2" mb="2"
                                                onClick={() => handleActionClick(transaction)}
                                                size="xs"
                                                variant="link"
                                            >
                                                {t('credits.transaction.td-action-view')}
                                            </Button>
                                        );
                                }
                            })()}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
        </Table>

        <HStack justifyContent="right" mt={4}>
            <Text
                fontSize="14px"
                textColor="#737D82"
                mr="6"
            >
                {currentPage} of {totalPages}
            </Text>
            <Button 
                onClick={() => setCurrentPage(prev => prev - 1)} 
                isDisabled={currentPage === 1}
                borderRadius="16px"
                size="sm"
            >
            <ChevronLeft />
            </Button>
            <Button 
                onClick={() => setCurrentPage(prev => prev + 1)} 
                isDisabled={currentPage === totalPages}
                borderRadius="16px"
                size="sm"
            >
            <ChevronRight />
            </Button>
        </HStack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay 
                    backdropFilter="blur(10px)"
                />
                <ModalContent
                    minW="600px"
                    borderRadius={16}
                    boxShadow="lg"
                    background="rgba(255, 255, 255, 0.9)"
                    backdropFilter="blur(10px)"
                    transition="all 0.1s ease"
                    _hover={{
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <ModalHeader>{isServiceOrder ? t('credits.transaction.modal-title-service') : t('credits.transaction.modal-title-purchase')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedOrder && (
                        <>
                        <Flex
                            mb="6"
                            fontSize="16px"
                            textColor="#364954"
                            direction="column"
                            gap="2"
                        >
                            <Flex justifyContent="space-between">
                                <Text textColor="#737D82">{t('credits.transaction.modal.orderid')}:</Text>
                                <Text as="kbd">{selectedOrder.order.id}</Text>
                            </Flex>

                            <Flex justifyContent="space-between">
                                <Text textColor="#737D82">{t('credits.transaction.modal.ordernb')}:</Text>
                                <Text as="kbd">{selectedOrder.order.order_number}</Text>
                            </Flex>

                            {(() => {
                                switch (true) {
                                    case isServiceOrder:
                                        return (
                                            <>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.servicetype')}:</Text>
                                                    <Text as="kbd">{selectedOrder.service_type}</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.note')}:</Text>
                                                    <Text as="kbd" maxW="50%" whiteSpace="pre-wrap" noOfLines={1} textOverflow="ellipsis">{selectedOrder.order.note}</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.serviceresult')}:</Text>
                                                    <Button 
                                                        variant="link" 
                                                        colorScheme="blue" 
                                                        onClick={() => handleViewServiceResult(selectedOrder.order.id)}
                                                        as="kbd"
                                                    >
                                                    {t('credits.transaction.modal.view')}
                                                    </Button>
                                                </Flex>
                                            </>
                                        );
                                    case selectedOrder.order_type === 'resumeslot':
                                        return (
                                            <>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.ordertype')}:</Text>
                                                    <Text as="kbd">Resume Slot</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.note')}:</Text>
                                                    <Text as="kbd" maxW="50%" whiteSpace="pre-wrap" noOfLines={1} textOverflow="ellipsis">{selectedOrder.order.note}</Text>
                                                </Flex>
                                            </>
                                        );
                                    case selectedOrder.order_type === 'chat':
                                        return (
                                            <>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.ordertype')}:</Text>
                                                    <Text as="kbd">Chat</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.rounds')}:</Text>
                                                    <Text as="kbd">{selectedOrder.initial_rounds}</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.note')}:</Text>
                                                    <Text as="kbd" maxW="50%" whiteSpace="pre-wrap" noOfLines={1} textOverflow="ellipsis">{selectedOrder.order.note}</Text>
                                                </Flex>
                                            </>
                                        );
                                    default:
                                        return (
                                            <>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.purchasetype')}:</Text>
                                                    <Text as="kbd">{selectedOrder.purchase_type}</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.paymentid')}:</Text>
                                                    <Text as="kbd">{selectedOrder.payment_id}</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.referralcode')}:</Text>
                                                    <Text as="kbd">{selectedOrder.referral_code}</Text>
                                                </Flex>
                                                <Flex justifyContent="space-between">
                                                    <Text textColor="#737D82">{t('credits.transaction.modal.note')}:</Text>
                                                    <Text as="kbd" maxW="50%" whiteSpace="pre-wrap" noOfLines={1} textOverflow="ellipsis">{selectedOrder.order.note}</Text>
                                                </Flex>
                                            </>
                                        );
                                }
                            })()}
                            <Flex justifyContent="space-between">
                                <Text textColor="#737D82">{t('credits.transaction.modal.createat')}:</Text>
                                <Text as="kbd">{new Date(selectedOrder.created_at).toLocaleString()}</Text>
                            </Flex>
                            <Flex justifyContent="space-between">
                                <Text textColor="#737D82">{t('credits.transaction.modal.updateat')}:</Text>
                                <Text as="kbd">{new Date(selectedOrder.updated_at).toLocaleString()}</Text>
                            </Flex>
                        </Flex>
                        </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default TransactionHistory;