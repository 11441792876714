import React, { useState } from 'react';
import { Box, Text, IconButton, HStack, VStack, Spinner, Tooltip, useToast } from '@chakra-ui/react';
import { LuPenLine, LuTrash2 } from "react-icons/lu";
import { Sparkles, Loader, Delete } from 'lucide-react';
import ExperienceForm from './ExperienceForm';
import { useTranslation } from 'react-i18next';
import CopilotDrawer from '../ResumeCopilotDrawer/CopilotDrawer';
import axiosInstance from '../../utils/axiosConfig';

const ExperienceEntry = ({ experience, onUpdate, onDelete, resumeId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toast = useToast();
  const [targetJob, setTargetJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Utility function to truncate display fields
  const truncateName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };

  const maxLength = 35; // Define your desired maximum length
  const org_name = truncateName(experience.organization_name, maxLength);
  const job_title = truncateName(experience.title, maxLength);

  const handleUpdate = async (updatedExperience) => {
    setIsUpdating(true);
    try {
      await onUpdate(updatedExperience);
      setIsEditing(false);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateEntry = async (updatedExperience) => {
    setIsUpdating(true);
    try {
      await onUpdate(updatedExperience);
    } finally {
      setIsDrawerOpen(false);
      setIsUpdating(false);
    }
  };


  const checkTargetJob = async (resumeId) => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
  
      if (response.data) {
        const targetJobData = response.data;
        const hasTargetJob = (
          targetJobData.target_job_title &&
          targetJobData.target_job_responsibilities &&
          targetJobData.target_job_requirements
        );
  
        if (hasTargetJob) {
          const newTargetJob = {
            title: targetJobData.target_job_title,
            responsibilities: targetJobData.target_job_responsibilities,
            requirements: targetJobData.target_job_requirements,
            updated_at: targetJobData.target_job_fields_updated_at
          };
          setTargetJob(newTargetJob);
        }
  
        return hasTargetJob;
      }
      return false;
    } catch (error) {
      console.error('Error checking target job:', error);
      toast({
        title: "Error checking target job",
        description: "Unable to check target job. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return false;
    }
  };

  const handleCopilotClick = async () => {
    setIsLoading(true);
    const hasTargetJob = await checkTargetJob(resumeId);
    if (!hasTargetJob) {
      setIsLoading(false);
      toast({
        title: t('copilotdrawer.targetjobcheck.toast-title'),
        description: t('copilotdrawer.targetjobcheck.toast-desc'),
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      setIsDrawerOpen(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  if (isEditing) {
    return (
      <ExperienceForm
        experience={experience}
        onSubmit={handleUpdate}
        onCancel={() => setIsEditing(false)}
        resumeId={resumeId}
      />
    );
  }

  return (
    <Box 
      py={1}
      px={ experience.is_visible === false ? "3" : "0" }
      borderWidth={ experience.is_visible === false ? "1" : "0" }
      backgroundImage={ experience.is_visible === false ? "linear-gradient(135deg, #f5f7fa 25%, transparent 25%, transparent 50%, #f5f7fa 50%, #f5f7fa 75%, transparent 75%, transparent)" : null }
      backgroundSize={ experience.is_visible === false ? "20px 20px" : null }
    >
      <HStack align="flex-start" justify="space-between">
        { experience.is_visible === true ? 
        <>
        <VStack align="stretch" spacing={1}>
          <Text fontWeight="bold" fontSize="md" textColor="#364954">
            {job_title}, {experience.start} - {experience.end || t('Present')}
          </Text>
          <Text color="#737D82" fontSize="sm" fontWeight="medium">{org_name}</Text>
          <Text whiteSpace="pre-wrap" fontSize="sm">{experience.description}</Text>
        </VStack>
        </> :
        <>
        <HStack>
          <Tooltip
            hasArrow 
            borderRadius={12} 
            bg='#CFD9DF' 
            color='#364954' 
            placement='top'
            label={
              <Box p={1} fontSize="12px">
              <Text>{t('probtn.wrk.underconstruction-tooltip1')}</Text>
              <Text>{t('probtn.wrk.underconstruction-tooltip2')}</Text>
              </Box>
            }
          >
            <Loader
              size="18px"
              color="#737D82"
            />
          </Tooltip>
          <Text 
            p={1} 
            fontSize="14px"
            fontWeight="medium"
            textColor="#737D82"
          >
            {t('probtn.wrk.underconstruction-desc')}: {job_title}
          </Text>
        </HStack>
        </>
        }
        <HStack>
          {isUpdating ? (
            <Spinner size="sm" />
          ) : (
            <>

            { !isDrawerOpen && (
              <Tooltip 
                hasArrow 
                borderRadius={12} 
                bg='#CFD9DF' 
                color='#364954' 
                placement='top'
                label={
                  <Box p={1}>
                  {t('copilotdrawer.callbtn-tooltip')}
                  </Box>
                } 
              > 
              { experience.is_visible === true ?
              <IconButton
                variant="ghost"
                size="sm"
                icon={<Sparkles
                  size="16px"
                  color="#364954"
                  />}
                onClick={handleCopilotClick}
                aria-label="Call Copilot"
                isLoading={isLoading}
                isDisabled={isLoading || experience.is_visible === false}
              />
              : <></>}
              </Tooltip>
            )}

              { experience.is_visible === true ?
              <IconButton
                icon={<LuPenLine />}
                aria-label="Edit experience"
                onClick={() => setIsEditing(true)}
                isDisabled={experience.is_visible === false}
                variant="ghost"
                size="sm"
              />
              : <></>
              }

              <IconButton
                icon={
                experience.is_visible === true ? <LuTrash2 /> : <Delete size="18px" color="#737D82" />}
                aria-label="Delete experience"
                onClick={onDelete}
                variant="ghost"
                size="sm"
              />
            </>
          )}
        </HStack>
      </HStack>

      {experience && experience.id && 
          <CopilotDrawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            resumeId={resumeId}
            entryId={experience && experience.id ? experience.id : undefined}
            entryType="experience"
            entry={experience}
            entryUpdatedAt={experience && experience.updated_at ? experience.updated_at : undefined}
            targetJob={targetJob}
            onUpdateEntry={handleUpdateEntry}
            isUpdating={isUpdating}
        />}

    </Box>
  );
};

export default ExperienceEntry;