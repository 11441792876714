import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Spinner
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SlotFullModal = ({ isOpen, onClose, onGetMoreSlots }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();  

    const handleGetMoreSlots = async () => {
        setIsLoading(true);
        await onGetMoreSlots();
        setIsLoading(false);
      };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay 
        backdropFilter="blur(10px)"      
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
      >
        <ModalHeader>{t('resumepage.resumelist.unlockslot.modal-title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{t('resumepage.resumelist.unlockslot.modal-des1')}</Text>
          <Text mt={4}>{t('resumepage.resumelist.unlockslot.modal-des2')}</Text>
          <Text fontSize="14px" textColor="#737D82" mt={4}>{t('resumepage.resumelist.unlockslot.modal-des3')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={handleGetMoreSlots}
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="12px"
            isDisabled={isLoading}
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
          >
            {isLoading ? <Spinner size="sm" /> : t('resumepage.resumelist.unlockslot.modal-confirmbtn')}
          </Button>
          <Button 
            variant="ghost" 
            onClick={onClose}
            borderRadius="12px"
            isDisabled={isLoading}
          >
            {t('resumepage.resumelist.unlockslot.modal-cancelbtn')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SlotFullModal;