// components/Orders/ServiceOrderList.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Fade, Skeleton, 
  Button,
  Flex,
  Text,
  HStack, VStack, 
  Badge, 
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { WandSparkles, Blocks, NotebookPen, MonitorPlay, 
  ChevronLeft, ChevronRight, Zap, ListPlus, FilePenLine, Paintbrush } from 'lucide-react';
import { fetchServiceOrders } from '../../utils/orderApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ServiceOrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();


  const fetchOrders = async (pageNum, serviceType = '') => {
    setLoading(true);
    try {
      const data = await fetchServiceOrders(pageNum, 10, serviceType);
      setOrders(data.results);
      setTotalPages(Math.ceil(data.count / 10)); // Assuming 10 items per page
    } catch (error) {
      console.error('Failed to fetch service orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(page, filter);
  }, [page, filter]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const getServiceTypeDisplay = (service_type) => {
    switch (service_type) {
      case 'resume_polish':
        return (
            <HStack ><WandSparkles size="14px"/><Text> {t('gettingstarted.resumePolish.title')}</Text></HStack>
        );
      case 'resume_builder':
        return (
            <HStack ><Blocks size="14px" /><Text> {t('gettingstarted.resumeBuilder.title')}</Text></HStack>
        );
      case 'interview_prep':
        return (
            <HStack ><NotebookPen size="14px" /><Text> {t('gettingstarted.interviewPrep.title')}</Text></HStack>
        );
      case 'mock_interview':
        return (
            <HStack ><MonitorPlay size="14px" /><Text> {t('gettingstarted.mockInterview.title')}</Text></HStack>
        );
      case 'write_with_ai':
        return (
            <HStack ><Zap size="14px" /><Text> {t('gettingstarted.writeWithAi.title')}</Text></HStack>
        );
      case 'content_analysis':
        return (
            <HStack ><ListPlus size="14px" /><Text> {t('gettingstarted.contentAnalysis.title')}</Text></HStack>
        );
      case 'content_generation':
        return (
            <HStack ><Paintbrush size="14px" /><Text> {t('gettingstarted.contentGeneration.title')}</Text></HStack>
        );
      case 'create_with_ai':
        return (
            <HStack ><FilePenLine size="14px" /><Text> {t('gettingstarted.createWithAi.title')}</Text></HStack>
        );
      default:
        return (
          <Text>{service_type}</Text>
        );
    }
  };

  const badgeStyles = {
    pending: { colorScheme: 'blue', text: t('orderlist.pending') },
    completed: { colorScheme: 'green', text: t('orderlist.completed') },
    canceled: { colorScheme: 'red', text: t('orderlist.canceled') },
    failed: { colorScheme: 'gray', text: t('orderlist.failed') },
    // Add more statuses as needed
  };

  const getBadgeStyle = (status) => {
    return badgeStyles[status] || { colorScheme: 'gray', text: 'Unknown' };
  };

  const SkeletonLoading = () => {
    return (
      <VStack spacing={4} align="stretch">
        {[...Array(5)].map((_, index) => (
          <Box key={index} bg="white" borderRadius="16px" p={5}>
            <Skeleton height="20px" width="60%" mb={2} />
            <Skeleton height="16px" width="80%" mb={2} />
            <Skeleton height="16px" width="40%" />
          </Box>
        ))}
      </VStack>
    );
  };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
      <Box height="30px" flexShrink={0} />
      <Flex direction="column" flex="1" overflow="hidden">
        <Heading as="h1" fontSize="2xl" mb={4} textColor="#364954">
          {t('orders.title')}
        </Heading>
        <Box
          flex="1"
          bg="#f5f7fa"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="auto"
          p={5}
        >
          <Box flex="1">
            <Select
              placeholder={t('orderlist.filter.placeholder')}
              onChange={handleFilterChange}
              value={filter}
              mb={4}
              variant="filled"
              bg="white"
              fontSize="14px"
              textColor="#364954"
              borderRadius="14px"
            >
              <option value="resume_polish">{t('orderlist.filter.resumepolish')}</option>
              {/* <option value="resume_builder">Resume Builder</option>
              <option value="interview_prep">Interview Prep</option>
              <option value="mock_interview">Mock Interview</option> */}
              <option value="content_analysis">{t('orderlist.filter.contentanalysis')}</option>
              <option value="content_generation">{t('orderlist.filter.contentgeneration')}</option>
              <option value="write_with_ai">{t('orderlist.filter.writewithai')}</option>
              <option value="create_with_ai">{t('orderlist.filter.createwithai')}</option>
            </Select>
            {loading ? (
              <SkeletonLoading />
            ) : orders.length === 0 ? (
              <Box minHeight="200px" display="flex" alignItems="center" justifyContent="center">
                <VStack spacing={4}>
                  <Text fontSize="16px" textAlign="center" color="#737D82">
                    {t('orders.noOrders')}
                  </Text>
                  <Button
                    onClick={() => navigate("/gettingstarted")}
                    size="sm"
                    color="#ffffff" 
                    backgroundColor="#364954"
                    borderRadius="12px"
                    _hover={{
                      backgroundColor: "#82B2CA",
                      transform: "translateY(-2px)",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
                    }}
                    transition="all 0.2s"
                  >
                    {t('orders.startServices')}
                  </Button>
                </VStack>
              </Box>
            ) : (
              <Fade in={!loading}>
                <TableContainer
                  p={2}
                  borderRadius="14px"
                  bg="white"
                >
                  <Table variant="simple" colorScheme='blackAlpha' size="sm" textColor="#364954">
                    <Thead>
                      <Tr>
                        <Th width="600px">{t('orderlist.th.targetjob')}</Th>
                        <Th>{t('orderlist.th.status')}</Th>
                        <Th>{t('orderlist.th.servicetype')}</Th>
                        <Th>{t('orderlist.th.createdat')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orders.map((order) => (
                        <Tr
                          key={order.order_number}
                          onClick={() => navigate(`/service-orders/${order.order}`)}
                          cursor="pointer"
                          _hover={{ 
                            bg: "#f5f7fa"
                          }}
                        >
                          <Td>
                            <Box>
                              <Text fontSize="12px" textColor="#737D82" mb={2}>
                                {order.order_number}
                              </Text>
                              <Text fontWeight="bold" fontSize="14px" textColor="#364954" mb={1}>
                                {order.job_title}
                              </Text>
                              <Text fontWeight="light" fontSize="12px" textColor="#737D82" mb={1}>
                              {t('orderlist.td.note')}: { order.note ? order.note : '-' }
                              </Text>
                            </Box>
                          </Td>
                          <Td>
                            <Badge 
                              colorScheme={getBadgeStyle(order.order_status).colorScheme}
                              borderRadius="full"
                              variant="subtle"
                            >
                              {getBadgeStyle(order.order_status).text}
                            </Badge>
                          </Td>
                          <Td>
                            <Text fontSize="12px" textColor="#364954">
                              {getServiceTypeDisplay(order.service_type)}
                            </Text>
                          </Td>

                          <Td>
                              <Text fontSize="12px" textColor="#737D82">
                                {new Date(order.created_at).toLocaleString()}
                              </Text>
                          </Td>
                        </Tr>

                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <HStack justifyContent="right" mt={4}>
                  <Text
                      fontSize="12px"
                      textColor="#737D82"
                      mr="6"
                  >
                      {t('orderlist.pagination.pages')}: {page} / {totalPages}
                  </Text>
                  <Button 
                      onClick={() => setPage(prev => prev - 1)} 
                      isDisabled={page === 1}
                      borderRadius="16px"
                      size="sm"
                  >
                  <ChevronLeft />
                  </Button>
                  <Button 
                      onClick={() => setPage(prev => prev + 1)} 
                      isDisabled={page === totalPages}
                      borderRadius="16px"
                      size="sm"
                  >
                  <ChevronRight />
                  </Button>
                </HStack>

              </Fade>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ServiceOrderList;