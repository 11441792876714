import React from 'react';
import { Box, VStack, Heading, Text, useColorModeValue, Fade, Flex, Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ServiceCard = ({ service, onClick }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  const { t } = useTranslation();

  const handleClick = () => {
    if (service.status === 'available') {
      onClick();
    }
  };

  return (
    <Fade in={true}>
    <Box
      bg={service.status === 'available' ? cardBg : '#CFD9DF'}
      borderRadius="16px"
      minH="165px"
      p={4}
      mb={4}
      onClick={handleClick}
      cursor={service.status === 'available' ? "pointer" : "not-allowed"}
      opacity={service.status === 'available' ? 1 : 0.3}
      transition="all 0.3s ease"
      _hover={{ 
        transform: service.status === 'available' ? 'scale(1.02)' : 'none',
        boxShadow: '0 1px 15px rgba(0, 0, 0, 0.1)',
      }}
    >
      <VStack spacing={2} align="stretch">
        <Text fontSize="2xl" mb="2" textColor="#364954">{service.icon}</Text>
        <Flex
          alignItems="center"
        >
          <Heading fontSize="16px" textColor="#364954">{t(service.titleKey)}</Heading>
          <Badge
            size="sm"
            ml="2"
            color="#FFFFFF"
            bg={ service.status === 'available' ? '#84B1CB' : '#737D82' }
            borderRadius="6" 
          >
            {t(service.versionKey)}
          </Badge>
        </Flex>
        <Text fontSize="14px" textColor="#737D82">{t(service.descriptionKey)}</Text>
      </VStack>
    </Box>
    </Fade>
  );
};

export default ServiceCard;