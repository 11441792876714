// UserContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosConfig';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ 
    display_name: '', 
    profile_pic: '', 
    credit_balance: undefined,
    resume_slots: undefined,
    last_seen_version: undefined
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [versionInfo, setVersionInfo] = useState({
    current_version: '',
    update_required: false,
    notification_type: null,
  });


  const fetchUserProfile = useCallback(async () => {
    const logStatus = localStorage.getItem('isLoggedIn');
    if (logStatus === 'true') {
      try {
        const response = await axiosInstance.get('/api/v1/accounts/profile/');
        setUser(response.data);
        setIsAuthenticated(true);
        await fetchVersionInfo();
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }

  }, []);

  const fetchVersionInfo = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/v1/accounts/version/');
      setVersionInfo(response.data);

      if (response.data.update_required) {
        // Perform necessary actions here (e.g., show a modal or red dot)
        console.log('New version available:', response.data);
      }
    } catch (error) {
      console.error('Error fetching version info:', error);
    }
  }, []);

  const updateCreditBalance = useCallback((newBalance) => {
    setUser(prevUser => ({ ...prevUser, credit_balance: newBalance }));
  }, []);

  const updateResumeSlots = useCallback((newSlots) => {
    setUser(prevUser => ({ ...prevUser, resume_slots: newSlots }));
  }, []);

  const updateLastSeenVersion = useCallback(async () => {
    try {
      await axiosInstance.post('/api/v1/accounts/version/');
      setUser(prevUser => ({ ...prevUser, last_seen_version: versionInfo.current_version }));
    } catch (error) {
      console.error('Error updating last seen version:', error);
    }
  }, [versionInfo]);

  useEffect(() => {
    fetchUserProfile();
    fetchVersionInfo();
  }, [fetchUserProfile, fetchVersionInfo]);

  return (
    <UserContext.Provider 
      value={{ 
        user, 
        setUser, 
        fetchUserProfile, 
        updateCreditBalance, 
        updateResumeSlots, 
        isAuthenticated,
        versionInfo,
        updateLastSeenVersion
      }}
    >
      {children}
    </UserContext.Provider>
  );
};