import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, 
    Collapse,
    useDisclosure,
    Textarea, Button,
    Tooltip,
    useToast,
    Spinner,
    HStack
 } from '@chakra-ui/react';
import { GoCheckCircleFill } from "react-icons/go";
import { Circle, Bot, PencilRuler } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import ProHelpMeAnswerModal from './ProHelpMeAnswerModal';
import { useTranslation } from 'react-i18next';


const QuestionBox = ({ question, index, onClick, onAnswerSubmit, isReadOnly, title, company }) => {
    const { t } = useTranslation();  
    const { isOpen, onToggle } = useDisclosure()
    const [answer, setAnswer] = useState(question.answers?.[0]?.answer_text || '');
    const [isEditing, setIsEditing] = useState(!question.is_answered);
    const [isValidating, setIsValidating] = useState(false);
    const [isPromptingQuestions, setIsPromptingQuestions] = useState(false);
    const [isHelpMeAnswerModalOpen, setIsHelpMeAnswerModalOpen] = useState(false);
    const [promptQuestions, setPromptQuestions] = useState([]);
    const relevanceScore = question.answers?.[0]?.relevance_score || 0;
    const detailScore = question.answers?.[0]?.detail_score || 0;
    const clarityScore = question.answers?.[0]?.clarity_score || 0;
    const validationScore = ((relevanceScore * 0.2 + detailScore * 0.4 + clarityScore * 0.4) / 5 * 100).toFixed(1);
    const improvementSuggestions = question.answers?.[0]?.improvement_suggestions || '';
    const toast = useToast();
    const maxWordCount = 999;
    const [wordCount, setWordCount] = useState(0);

    const handleInputChange = (e) => {
        const input = e.target.value;
        if (input.length <= maxWordCount) {
            setAnswer(input); // Update the userInput with the new value
            setWordCount(input.length); // Update the word count
        }
    };

    const FormattedQuestion = ({ template, title, company }) => {
        const parts = template.split(/(\[TITLE\]|\[COMPANY\])/);
        
        return (
          <Text>
            {parts.map((part, index) => {
              if (part === '[TITLE]') {
                return <Text as="span" key={index} fontWeight="bold">{title}</Text>;
              } else if (part === '[COMPANY]') {
                return <Text as="span" key={index} fontWeight="bold">{company}</Text>;
              } else {
                return <React.Fragment key={index}>{part}</React.Fragment>;
              }
            })}
          </Text>
        );
    };

    useEffect(() => {
      // Reset the answer when the question changes
      setAnswer(question.answers?.[0]?.answer_text || '');
      setIsEditing(!question.is_answered);
    }, [question]);

    const handleHelpMeAnswer = async () => {
        setIsPromptingQuestions(true);
        try {
          const response = await axiosInstance.post('/api/v1/services/write-with-ai-pro/generate-prompt-questions/', {
                resume_title: title,
                resume_company: company,
                question_id: question.id,
                question_type: question.question_type
            });
        
            if (response.data && response.data.prompt_questions) {
                setPromptQuestions(response.data.prompt_questions);
                setIsHelpMeAnswerModalOpen(true);
            } else {
                console.error('No prompt questions received');
                setIsHelpMeAnswerModalOpen(true);
            }
            } catch (error) {
                console.error('Error fetching prompt questions:', error);
                setIsHelpMeAnswerModalOpen(true);
                setIsPromptingQuestions(false);
            } finally {
                setIsPromptingQuestions(false);
        }
    };
  
    const handleSubmit = async () => {
        if (answer.trim() === '') {
          toast({
            title: t('prodrawer.questionbox.toast-empty-title'),
            description: t('prodrawer.questionbox.toast-empty-desc'),
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        setIsValidating(true);
        try {
          await onAnswerSubmit(question.id, answer);
          setIsEditing(false);
        } catch (error) {
          console.error("Error submitting answer:", error);
        } finally {
          setIsValidating(false);
        }
    };

    const handleRefine = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setAnswer(question.answers?.[0]?.answer_text || '');
    };


  return (
    <>
    <Box
        mt="5"
        bg={ !isOpen ? "#f5f7fa" : isReadOnly ? "#737D82" : "#364954" }
        textColor={ isOpen ? "#FFFFFF" : "#364954"}
        borderTopRadius={14}
        borderBottomRadius={ isOpen ? '0' : '14'}
        borderWidth={ isOpen ? '1' : '0'}
        cursor="pointer"
        onClick={onToggle}
    >
        <Flex 
            p={3}
            justifyContent="space-between" 
            alignItems="center"
        >
            <HStack
                fontWeight="medium"
                fontSize="14px"
                align="top"
            >
                <Text fontWeight="bold">
                    Q{index + 1}:
                </Text>
                <Text>
                    <FormattedQuestion 
                        template={question.question_template}
                        title={title}
                        company={company}
                    />
                </Text>
            </HStack>
            <Box
                ml="10"
            >
                {question.is_answered ? 
                <GoCheckCircleFill size="16px" /> : 
                <Circle size="16px" />
                }
            </Box>
        </Flex>
    </Box>
    <Collapse 
        in={isOpen} 
        animateOpacity
    >
        <Box
            bg="#FFFFFF"
            p={3}
            borderBottomRadius={14}
            borderTopRadius={0}
            borderBottomWidth={1}
            borderLeftWidth={1}
            borderRightWidth={1}
        >
            {isEditing ?
            <>
            <Box 
                mb={2}
            >
                <Button
                    leftIcon={ 
                        <Bot size="18px" /> 
                    }
                    size="sm"
                    variant="ghost"
                    textColor="#364954"
                    borderRadius={12}
                    isLoading={isPromptingQuestions}
                    isDisabled={isReadOnly || !isEditing || isPromptingQuestions}
                    onClick={handleHelpMeAnswer}
                >
                    {t('prodrawer.questionbox.btn-helper')}
                </Button>
            </Box>
            <Textarea
                value={answer}
                isDisabled={(!isEditing && question.is_answered) || isReadOnly}
                onChange={handleInputChange}
                placeholder={t('prodrawer.questionbox.placeholder-answer')}
                borderRadius={14}
            />
            <Text align="right" mt="2" fontSize="12px" color={wordCount === maxWordCount ? "red.500" : "#737D82"}>
                {wordCount}/{maxWordCount}
            </Text>
            </>
            :
            <Box
                p={1}
                fontSize="14px"
                textColor="#364954"
            >
                <Text
                    whiteSpace="pre-wrap"
                >
                {answer}
                </Text>
            </Box>
            }
            <Flex
                justifyContent="space-between"
                align="center"
                mt={3}
            >
                {isEditing ? (
                    <Box>
                        <Button 
                            onClick={handleSubmit}
                            variant="solid"
                            size="sm"
                            textColor="#364954"
                            borderRadius="full"
                            isLoading={isValidating}
                            isDisabled={isReadOnly}
                        >
                            {question.is_answered ? t('prodrawer.questionbox.btn-updateanswer') : t('prodrawer.questionbox.btn-submitanswer')}
                        </Button>
                        {question.is_answered && (
                            <Button
                                ml={3}
                                onClick={handleCancel}
                                isDisabled={isValidating}
                                variant="ghost"
                                size="sm"
                                textColor="#364954"
                                borderRadius="full"
                            >
                                {t('prodrawer.questionbox.btn-cancel')}
                            </Button>
                        )}
                    </Box>
                ) : (
                    <Button
                        leftIcon={<PencilRuler size="14px" />}
                        onClick={handleRefine}
                        variant="outline"
                        size="sm"
                        textColor="#364954"
                        borderRadius="full"
                        isLoading={isValidating}
                        isDisabled={isReadOnly || isValidating}
                    >
                        {isValidating ? <Spinner /> : t('prodrawer.questionbox.btn-refineanswer') } 
                    </Button>
                )}

                { (question.is_answered && !isEditing) && 
                <Flex
                    align="flex-end"
                    p={2}
                    fontSize="14px"
                    direction="row"
                    textColor="#737D82"
                >
                    <Text>
                        {t('prodrawer.questionbox.answervalidation')}:
                    </Text>
                    <Tooltip
                        hasArrow 
                        borderRadius={12} 
                        bg='#CFD9DF' 
                        color='#364954' 
                        placement='left'
                        label={
                            <>
                            <Box p={1} fontSize="14px">
                                <Text>
                                {t('prodrawer.questionbox.answervalidation-relevance')}: {relevanceScore} / 5 
                                </Text>
                                <Text>
                                {t('prodrawer.questionbox.answervalidation-detail')}: {detailScore} / 5
                                </Text>
                                <Text>
                                {t('prodrawer.questionbox.answervalidation-clarity')}: {clarityScore} / 5
                                </Text>
                            </Box>
                            <Box p={1} fontSize="14px">
                                <Text>
                                {t('prodrawer.questionbox.answervalidation-improvement')}:
                                </Text>
                                <Text>
                                    {improvementSuggestions}
                                </Text>
                            </Box>
                            </>
                        } 
                    >
                        <Text 
                            ml="2"
                            fontWeight="bold"
                            textColor={validationScore > 90 ? "green.500" : validationScore > 50 ? "yellow.500" : "red.300"}
                        >
                            {validationScore > 90 ? t('prodrawer.questionbox.answervalidation-excellent') : validationScore > 50 ? t('prodrawer.questionbox.answervalidation-good') : t('prodrawer.questionbox.answervalidation-needimprove')}
                        </Text>
                    </Tooltip>
                </Flex>
                }

            </Flex>
        </Box>
    </Collapse>

    <ProHelpMeAnswerModal
        isOpen={isHelpMeAnswerModalOpen}
        onClose={() => setIsHelpMeAnswerModalOpen(false)}
        promptQuestions={promptQuestions}
        question={question}
        title={title}
        company={company}
        questionContent={
        <FormattedQuestion 
            template={question.question_template}
            title={title}
            company={company}
        />}
        onConfirm={(generatedAnswer) => {
            setAnswer(generatedAnswer);
            setIsHelpMeAnswerModalOpen(false);
        }}
    />
    </>
  );
};

export default QuestionBox;