import React, { useContext } from 'react';
import { Input, Button, VStack, HStack, Textarea, FormControl, FormLabel, FormErrorMessage, useToast } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosConfig';
import { UserContext } from '../UserContext';

const SkillForm = ({ skill, onSubmit, onCancel, resumeId }) => {
  const { t } = useTranslation();  
  const toast = useToast();
  const initialValues = skill || {
    skill_name: '',
    description: ''
  };
  const { fetchUserProfile } = useContext(UserContext);

  const validate = (values) => {
    const errors = {};
    if (!values.skill_name.trim()) errors.skill_name = 'Required';
    if (!values.description.trim()) errors.description = 'Required';
    return errors;
  };

  const checkResumeCompletion = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const resume = response.data;
        return (
          resume.education && resume.education.length > 0 &&
          resume.experiences && resume.experiences.length > 0 &&
          resume.skills && resume.skills.length > 0
        );
      }
      return false;
    } catch (error) {
      console.error('Error checking resume completion:', error);
      return false;
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await onSubmit({ ...values, resume: resumeId });
      // Check if all sections are complete
      const isResumeComplete = await checkResumeCompletion();
      
      if (isResumeComplete) {
        // Fetch the user's onboarding tasks
        const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        
        if (onboardingTasksResponse.status === 200) {
          const tasks = onboardingTasksResponse.data;
          const createResumeTask = tasks.find(task => task.task_id === 2);
          
          if (createResumeTask && !createResumeTask.is_completed) {
            // Call the API to complete the task only if it's not already completed
            try {
              const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-create-resume-task/');
              
              if (onboardingResponse.status === 200) {
                await fetchUserProfile();

                toast({
                  title: t('resumepage.onboardingtask.toast-complete'),
                  description: `${t('resumepage.onboardingtask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('resumepage.onboardingtask.toast-complete-des2')}`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            } catch (error) {
              console.error('Error completing resume task:', error);
              // You might want to show an error toast here
            }
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
      toast({
        title: t('skill.add_fail'),
        description: t('skill.add_fail_description'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <VStack spacing={4} mt="4">
            <FormControl isInvalid={errors.skill_name && touched.skill_name}>
              <FormLabel>{t('skill.entry_skillname_title')}</FormLabel>
              <Field as={Input} name="skill_name" placeholder={t('skill.entry_skillname_placeholder')} />
              <FormErrorMessage>{errors.skill_name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.description && touched.description}>
              <FormLabel>{t('skill.entry_description_title')}</FormLabel>
              <Field as={Textarea} name="description" placeholder={t('skill.entry_description_placeholder')} />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>
          </VStack>
          <HStack mt={4} mb={4} justify="flex-end">
          <Button 
              size="sm"
              onClick={onCancel}
              borderRadius="12px"
              >
            {t('skill.cancel')}
            </Button>
            <Button 
              size="sm"
              type="submit" 
              color="#ffffff" 
              backgroundColor="#364954"
              borderRadius="12px"
              _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
              }}
              isLoading={isSubmitting}
              >
            {t('skill.save')}
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default SkillForm;