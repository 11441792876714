import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  useToast,
  HStack, Text, Box, 
  Input,
  FormControl,
  FormLabel, FormErrorMessage, 
  Tooltip, Grid, GridItem,
  Spinner
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import axiosInstance from '../../utils/axiosConfig';
import CustomDateDropdown from '../Resume/CustomDateDropdown';
import { useTranslation } from 'react-i18next';

const SaveToResumeConfirmationModal = ({ isOpen, onClose, generatedDescription, experienceId, onUpdateEntry, onDrawerClose, jobTitle, company }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: jobTitle || '',
    organization_name: company || '',
    start: '',
    end: '',
    description: generatedDescription || '',
  });
  const toast = useToast();
  const { t } = useTranslation();  

  useEffect(() => {
    if (isOpen) {
      fetchExperienceData();
    }
  }, [isOpen]);

  const fetchExperienceData = async () => {
    setIsFetching(true);
    try {
        const response = await axiosInstance.get(`/api/v1/resumes/experiences/${experienceId}/`);
        const data = response.data;
        setInitialValues({
            title: data.title || jobTitle,
            organization_name: data.organization_name || company,
            start: data.start || '',
            end: data.end || '',
            descriptionOld: data.description || '',
            description: generatedDescription || '',
        });
    } catch (error) {
        console.error('Error fetching experience data:', error);
    } finally {
        setIsFetching(false);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.organization_name || !values.organization_name.trim()) errors.organization_name = 'Required';
    if (!values.title || !values.title.trim()) errors.title = 'Required';
    if (!values.start) errors.start = 'Required';
    if (!values.description || !values.description.trim()) errors.description = 'Required';
    return errors;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsUpdating(true);
    try {
        // First, update the experience
        const response = await axiosInstance.patch(`/api/v1/resumes/experiences/${experienceId}/`, values);
    
        if (response.status === 200) {
        // Then, call the finish_session action to set is_visible to true
        const finishResponse = await axiosInstance.patch(`/api/v1/resumes/experiences/${experienceId}/finish_session/`, {});
        
        if (finishResponse.status === 200) {
            onUpdateEntry(finishResponse.data);
            toast({
            title: t('wrk.update_success'),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: 'top-right',
            });
            onClose();
            onDrawerClose(); // Close the drawer
        }
    }
    } catch (error) {
      console.error('Error updating entry:', error);
      toast({
        title: "Error",
        description: "Failed to update entry. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsUpdating(false);
      setSubmitting(false);
    }
  };

  return (
    <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        closeOnOverlayClick={false} 
        closeOnEsc={false}
        size="3xl"
    >
      <ModalOverlay 
        backdropFilter="blur(10px)"
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.1s ease"        
      >
        <ModalHeader>{t('prodrawer.updatemodal.title')}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form>
              <ModalBody
              >
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="4">
                  <GridItem colSpan={1}>
                    <FormControl isInvalid={errors.title && touched.title} isDisabled={isFetching || isSubmitting} >
                      <FormLabel>{t('wrk.entry_job_title')}</FormLabel>
                      <Field as={Input} name="title" placeholder={t('wrk.entry_job_placeholder')} />
                      <FormErrorMessage>{errors.title}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={errors.start && touched.start} isDisabled={isFetching || isSubmitting}>
                      <FormLabel>{t('wrk.entry_period_title')}</FormLabel>
                      <HStack>
                        <CustomDateDropdown
                          name="start"
                          placeholder={t('wrk.entry_period_start')}
                          onChange={(value) => setFieldValue('start', value)}
                          value={values.start}
                        />
                        <Text>-</Text>
                        <CustomDateDropdown
                          name="end"
                          placeholder={t('wrk.entry_period_end')}
                          onChange={(value) => setFieldValue('end', value)}
                          value={values.end}
                          includePresent={true}
                        />
                      </HStack>
                      <FormErrorMessage>{errors.start}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={errors.organization_name && touched.organization_name} isDisabled={isFetching || isSubmitting}>
                      <Tooltip 
                        hasArrow 
                        borderRadius={12} 
                        bg='#CFD9DF' 
                        color='#364954' 
                        placement='top-start'
                        label={
                          <Box p={1}>
                            {t('wrk.entry_org_tooltip')}
                          </Box>
                        } 
                      > 
                        <FormLabel>
                          {t('wrk.entry_org_title')}
                        </FormLabel>
                      </Tooltip>
                      <Field as={Input} name="organization_name" placeholder={t('wrk.entry_org_placeholder')} />
                      <FormErrorMessage>{errors.organization_name}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl isInvalid={errors.description && touched.description} isDisabled={isFetching || isSubmitting}>
                      <FormLabel>{t('wrk.entry_jd_title')}</FormLabel>
                      <Box>
                        <Field 
                            as={Textarea} 
                            name="description" 
                            placeholder={t('wrk.entry_jd_placeholder')} 
                        />
                      </Box>

                      {initialValues.descriptionOld && initialValues.descriptionOld !== "-" && <Box
                        p={2}
                        bg="#f5f7fa"
                        borderRadius={12}
                        mt="5"
                        fontSize="14px"
                        textColor="#364954"
                        height="100px"
                        overflowY="auto"                        
                      >
                        {isFetching ? 
                        <Spinner size="sm" /> :
                        <>
                            <Text fontWeight="bold">
                            {t('prodrawer.updatemodal.remind-overwrite')}:
                            </Text>
                            <Text whiteSpace="pre-wrap">{initialValues.descriptionOld}</Text>
                        </>
                        }
                      </Box>}

                      <FormErrorMessage>{errors.description}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
              </ModalBody>
              <ModalFooter>
                <Button 
                    type="submit" 
                    mr={3} 
                    isLoading={isSubmitting}
                    isDisabled={isFetching || isSubmitting}
                    color="#ffffff" 
                    backgroundColor="#364954"
                    borderRadius="16px"
                    _hover={{
                      color:"#ffffff",
                      backgroundColor:"#82B2CA"
                    }}
                >
                    {t('prodrawer.updatemodal.btn-confirm')}
                </Button>
                <Button 
                    variant="ghost" 
                    onClick={onClose}
                    isDisabled={isSubmitting || isFetching}
                    borderRadius="16px"
                >
                    {t('prodrawer.updatemodal.btn-cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default SaveToResumeConfirmationModal;