import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Flex, Spinner
} from '@chakra-ui/react';
import { SquarePlus, LockKeyhole } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const CreateNewVersionsModal = ({
    isOpen, 
    onClose, 
    resumes, 
    availableSlots, 
    onCreateVersion, 
    onOpenSlotFullModal,
    getResumeVersionTitle
  })  => {
    const [loadingSlot, setLoadingSlot] = useState(null);
    const totalResumes = resumes.length;
    const totalSlots = totalResumes + availableSlots;
    const emptySlots = Math.max(0, 5 - totalSlots);
    const { t } = useTranslation();  

    const handleCreateVersion = async (index) => {
        setLoadingSlot(index);
        try {
          await onCreateVersion();
        } finally {
          setLoadingSlot(null);
        }
      };
    

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay 
        backdropFilter="blur(10px)"      
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
      >
        <ModalHeader>{t('resumepage.resumelist.createnewversion.modal-title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text>
              {availableSlots === 0 && emptySlots !== 0
                ? t('resumepage.resumelist.createnewversion.modal-des1')
                : availableSlots === 0 && emptySlots === 0
                ? t('resumepage.resumelist.createnewversion.modal-des2')
                : t('resumepage.resumelist.createnewversion.modal-des3')}
            </Text>
            <VStack spacing={3} mb={6} align="left">
              {resumes.map((resume, index) => (
                <Box 
                    key={resume.id} 
                    w="100%" 
                    h="80px"
                    borderWidth={1}
                    backgroundImage="linear-gradient(135deg, #f5f7fa 25%, transparent 25%, transparent 50%, #f5f7fa 50%, #f5f7fa 75%, transparent 75%, transparent)"
                    backgroundSize="20px 20px"
                    borderRadius="16px"
                >
                    <Flex 
                        direction="column"
                        fontWeight="bold"
                        textColor="#737D82"
                        justifyContent="space-between"
                        ml="3"
                        p={2}
                        height="100%"
                    >
                        <VStack
                            fontSize="12px"
                            fontWeight="normal"
                            justifyContent="space-between"
                            align="left"
                            spacing={0}
                        >
                            <Text fontSize="16px" fontWeight="bold">{getResumeVersionTitle(index)}</Text>
                        </VStack>
                        <HStack
                            fontSize="12px"
                            fontWeight="normal"
                            justifyContent="space-between"
                            align="right"
                            spacing={0}
                        >
                            {/* <Text>{t('resumepage.resumelist.occupied')}</Text> */}
                            <Text>{t('resumepage.resumelist.updatedat')} {new Date(resume.updated_at).toLocaleString()}</Text>
                            <Text>{t('resumepage.resumelist.createdat')} {new Date(resume.created_at).toLocaleString()}</Text>
                        </HStack>
                    </Flex>
                </Box>
              ))}
              {[...Array(availableSlots)].map((_, index) => (
                <Button
                    key={`available-${index}`}
                    w="100%"
                    h="80px"
                    bg="white"
                    borderRadius="16px"
                    borderWidth={1}
                    borderColor="#CFD9DF"
                    borderStyle="dashed dotted"
                    textColor="#737D82"
                    onClick={() => handleCreateVersion(index)}
                    isDisabled={loadingSlot !== null}
                    _hover={{
                        color:"#ffffff",
                        backgroundColor:"#82B2CA"
                      }}
                >
                    <Box align="left">
                        <HStack align="center">
                            {loadingSlot === index ? <Spinner size="sm" /> : <SquarePlus />}
                        <Text>
                            {loadingSlot === index
                            ? t('resumepage.resumelist.createnewversion.modal-creating')
                            : t('resumepage.resumelist.createnewversion.modal-createbtn')}
                        </Text>
                        </HStack>
                    </Box>
                </Button>
              ))}
              {[...Array(emptySlots)].map((_, index) => (
                <Button
                    key={`empty-${index}`}
                    w="100%"
                    h="80px"
                    bg="#f5f7fa"
                    borderWidth={1}
                    borderRadius="16px"
                    onClick={onOpenSlotFullModal}
                    align="center"
                    isDisabled={loadingSlot !== null}
                >
                    <Box>
                        <HStack align="center">
                            <LockKeyhole color="#737D82" />
                            <Text color="#737D82">{t('resumepage.resumelist.createnewversion.modal-unlockbtn')}</Text>
                        </HStack>
                    </Box>
                </Button>
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateNewVersionsModal;