// OurSolutions-CreateWithAI.js
import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Heading, VStack, Image, 
    Skeleton, useBreakpointValue, SkeletonText, Badge
} from '@chakra-ui/react';
import { FilePenLine } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const OurSolutionsCreateWithAI = () => {
  const { t, i18n, ready } = useTranslation("homepage");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  // Using Chakra's responsive values
  const flexDirection = useBreakpointValue({ base: "column-reverse", md: "row" });
  const textAlign = useBreakpointValue({ base: "center", md: "left" });
  const imageFirst = useBreakpointValue({ base: true, md: false });

  const imageUrls = {
    answerGeneration: {
      en: "/homepage/solution-answer-generation.png",
      zh: "/homepage/solution-answer-generation-zh.png",
    },
    promptQuestions: {
      en: "/homepage/solution-prompt-questions.png",
      zh: "/homepage/solution-prompt-questions-zh.png",
    },
    answerValidation: {
      en: "/homepage/solution-answer-validation.png",
      zh: "/homepage/solution-answer-validation-zh.png",
    },
    comprehensiveGeneration: {
        en: "/homepage/solution-comprehensive-generation.png",
        zh: "/homepage/solution-comprehensive-generation-zh.png",
      },
    // Add more image types as needed
  };

  return (
    <Box 
        // bg="#FFFFFF" 
        bg="linear-gradient(to bottom, #f5f7fa 0%, #e7f0fd 100%)"
        pb={10}
    >
      <Box pt={20} pb={5} px={6} align="center">
        <Text mb={2}><FilePenLine size="50px" /></Text>
        <Heading as="h2" size="xl" textAlign="center" mb={10}>
          {showContent ? t('v2.solution.createwithai.title') : <Skeleton height="40px" width="200px" mx="auto" />}
        </Heading>
        <Box maxW="1200px">
          <Skeleton isLoaded={showContent} borderRadius={12}>
            <Text as="h3" fontSize="20px" fontWeight="bold" mb={5}>
            {t('v2.solution.createwithai.subtitle')}
            </Text>
            <Text fontSize="16px" maxW={{ base: "400px", md: "800px" }}>
            {t('v2.solution.createwithai.headingdesc')}
            </Text>
          </Skeleton>
        </Box>
      </Box>

      {/* Answer Generation Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box width={{ base: "100%", md: "40%" }} textAlign={textAlign}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12}>
              <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.createwithai.answergeneration.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.createwithai.answergeneration.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.createwithai.answergeneration.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>

          <Box width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }} ml={{ md: "10" }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.answerGeneration[i18n.language] || imageUrls.answerGeneration.en}
                alt="Answer Generation Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>
        </Flex>
      </Box>

      {/* Prompt Questions Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box order={imageFirst ? 2 : 1} width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.promptQuestions[i18n.language] || imageUrls.promptQuestions.en}
                alt="Prompt Questions Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>

          <Box order={imageFirst ? 1 : 2} width={{ base: "100%", md: "40%" }} textAlign={textAlign} ml={{ md: "10" }}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.createwithai.promptquestions.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.createwithai.promptquestions.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.createwithai.promptquestions.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>
        </Flex>
      </Box>

      {/* Answer Validation Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box width={{ base: "100%", md: "40%" }} textAlign={textAlign}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.createwithai.answervalidation.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.createwithai.answervalidation.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.createwithai.answervalidation.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>

          <Box width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }} ml={{ md: "10" }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.answerValidation[i18n.language] || imageUrls.answerValidation.en}
                alt="Answer Validation Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>
        </Flex>
      </Box>

      {/* Comprehensive Generation Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box order={imageFirst ? 2 : 1} width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.comprehensiveGeneration[i18n.language] || imageUrls.comprehensiveGeneration.en}
                alt="Comprehensive Generation Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>

          <Box order={imageFirst ? 1 : 2} width={{ base: "100%", md: "40%" }} textAlign={textAlign} ml={{ md: "10" }}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.createwithai.comprehensivegeneration.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.createwithai.comprehensivegeneration.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.createwithai.comprehensivegeneration.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default OurSolutionsCreateWithAI;
