import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, Heading, Text, IconButton, Flex, Grid, GridItem, 
  Badge, 
  Skeleton, SkeletonText, HStack, Fade
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { WandSparkles, Blocks, NotebookPen, MonitorPlay, 
  ChevronLeft, ChevronRight, Zap, ListPlus, FilePenLine, Paintbrush } from 'lucide-react';
import { fetchServiceOrderDetail } from '../../utils/orderApi';
import { useTranslation } from 'react-i18next';
import GeneratePDF from './GeneratePDF';
import ServiceResultOfResumePolish from './ServiceResultOfResumePolish';
import ServiceResultOfContentAnalysis from './ServiceResultOfContentAnalysis';
import ServiceResultOfContentGeneration from './ServiceResultOfContentGeneration';
import ServiceResultOfWriteWithAI from './ServiceResultOfWriteWithAI';

const OrderDetail = () => {
  const { t, ready } = useTranslation();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getOrderDetail = async () => {
      try {
        const data = await fetchServiceOrderDetail(orderId);
        setOrder(data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
      setLoading(false);
    };

    getOrderDetail();
  }, [orderId]);

  const isReady = !loading && ready && order;

  const LoadingSkeleton = () => (
    <>
      <Skeleton height="60px" width="200px" mb={4} />
      <Box 
        borderRadius={16}
        mb={3}
        background="rgba(255, 255, 255, 0.6)"
        backdropFilter="blur(10px)"
        transition="all 0.3s ease"
        _hover={{
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box p={5}>
          <Grid templateColumns="repeat(2, 1fr)" gap={8}>
            {[...Array(6)].map((_, index) => (
              <GridItem key={index}>
                <SkeletonText noOfLines={2} spacing="4" />
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );

  const renderServiceResult = () => {
    switch(order.service_type) {
      case 'resume_polish':
        return <ServiceResultOfResumePolish order={order} />;
      case 'content_analysis':
        return <ServiceResultOfContentAnalysis order={order} />;
      case 'content_generation':
        return <ServiceResultOfContentGeneration order={order} />;
      case 'write_with_ai':
        return <ServiceResultOfWriteWithAI order={order} />;
      default:
        return null;
    }
  };

  const getServiceTypeDisplay = (service_type) => {
    switch (service_type) {
      case 'resume_polish':
        return (
            <HStack ><WandSparkles size="14px"/><Text> {t('gettingstarted.resumePolish.title')}</Text></HStack>
        );
      case 'resume_builder':
        return (
            <HStack ><Blocks size="14px" /><Text> {t('gettingstarted.resumeBuilder.title')}</Text></HStack>
        );
      case 'interview_prep':
        return (
            <HStack ><NotebookPen size="14px" /><Text> {t('gettingstarted.interviewPrep.title')}</Text></HStack>
        );
      case 'mock_interview':
        return (
            <HStack ><MonitorPlay size="14px" /><Text> {t('gettingstarted.mockInterview.title')}</Text></HStack>
        );
      case 'write_with_ai':
        return (
            <HStack ><Zap size="14px" /><Text> {t('gettingstarted.writeWithAi.title')}</Text></HStack>
        );
      case 'content_analysis':
        return (
            <HStack ><ListPlus size="14px" /><Text> {t('gettingstarted.contentAnalysis.title')}</Text></HStack>
        );
      case 'content_generation':
        return (
            <HStack ><Paintbrush size="14px" /><Text> {t('gettingstarted.contentGeneration.title')}</Text></HStack>
        );
      case 'create_with_ai':
        return (
            <HStack ><FilePenLine size="14px" /><Text> {t('gettingstarted.createWithAi.title')}</Text></HStack>
        );
      default:
        return (
          <Text>{service_type}</Text>
        );
    }
  };

  const badgeStyles = {
    pending: { colorScheme: 'blue', text: t('orderlist.pending') },
    completed: { colorScheme: 'green', text: t('orderlist.completed') },
    canceled: { colorScheme: 'red', text: t('orderlist.canceled') },
    failed: { colorScheme: 'gray', text: t('orderlist.failed') },
    // Add more statuses as needed
  };

  const getBadgeStyle = (status) => {
    return badgeStyles[status] || { colorScheme: 'gray', text: 'Unknown' };
  };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
      <Box height="30px" flexShrink={0} />
      <Flex direction="column" flex="1" overflow="hidden">
        <Flex align="center" mb={4}>
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={() => navigate('/service-orders')}
            mr={4}
            aria-label="Go back"
          />
          <Heading fontSize="2xl">{t('orderdetail.title')}: {isReady ? order.order.order_number : ''}</Heading>
        </Flex>

        <Box
          flex="1"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="hidden"
          bg="#f5f7fa"
        >
          <Box 
            height="100%"
            overflow="auto"
            p={5}
          >
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <Fade in={!loading}>
              <Flex 
                justifyContent="space-between" 
                mb="6"
              >
                <Box 
                  p={0} 
                  borderRadius="16px"
                  width="50%"
                  mr="6"
                >
                  <Text fontSize="16px" fontWeight="bold" mb={4}>
                    {t('orderdetails.orderinformation')}:
                  </Text>
                  <Box>
                    <Flex justifyContent="space-between">
                      <Text fontSize="14px" textColor="#737D82">{t('orderdetail.status')}:</Text>
                      <Flex align="center">
                        <Badge 
                          colorScheme={getBadgeStyle(order.order.order_status).colorScheme}
                        >
                          {getBadgeStyle(order.order.order_status).text}
                        </Badge>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontSize="14px" textColor="#737D82">{t('orderdetail.servicetype')}:</Text>
                      <Text fontSize="14px" fontWeight="bold">
                        {getServiceTypeDisplay(order.service_type)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontSize="14px" textColor="#737D82">{t('orderdetail.amount')}:</Text>
                      <Text fontSize="14px" fontWeight="bold" color="red.500">- {order.order.amount} {t('orderdetails.orderinfo-credits')}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontSize="14px" textColor="#737D82">{t('orderdetail.createdat')}:</Text>
                      <Text fontSize="14px"fontWeight="bold">{new Date(order.created_at).toLocaleString()}</Text>
                    </Flex>
                  </Box>
                </Box>
                <Box
                  flex={1}
                  p={0} 
                  borderRadius="16px"
                >
                  <Text fontSize="16px" fontWeight="bold" mb={4}>
                    {t('orderdetails.ordertools')}:
                  </Text>
                  <Flex direction="column">
                      { order.service_type === 'resume_polish' ? 
                      <HStack>
                        <GeneratePDF orderId={orderId} service_type={order.service_type} /> 
                      </HStack> : <Box>
                        <Text fontSize="14px" textColor="#737D82">
                        {t('orderdetails.ordertools-notools')}
                        </Text>
                      </Box>
                      }
                  </Flex>
                </Box>
              </Flex>

              <Box
                borderRadius={16}
                mb={6}
                background="rgba(255, 255, 255, 1)"
                p={4} 
              >
                <Text fontSize="16px" fontWeight="bold" mb={4}>
                  {t('orderdetails.serviceresult')}:
                </Text>
                {renderServiceResult()}
              </Box>
            </Fade>
          )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default OrderDetail;