import React, { useState, useCallback } from 'react';
import { Button, useToast, ButtonGroup,
    Popover, PopoverTrigger, 
    PopoverHeader, PopoverContent, PopoverBody, PopoverFooter, PopoverArrow,
    Spacer, Text } from '@chakra-ui/react';
import { Download } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosConfig';

const GeneratePDF = ({ orderId, service_type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const handleGenerateAndDownloadPDF = useCallback(async () => {
    setIsLoading(true);
    setIsOpen(false);
    const startTime = Date.now();

    try {
      // Step 1: Generate the PDF
      const generateResponse = await axiosInstance.get(`/api/v1/orders/generate-pdf/${orderId}/`);
      const pdfUrl = generateResponse.data.pdf_url;

      // Step 2: Serve the PDF
      const serveResponse = await axiosInstance.get(pdfUrl, { responseType: 'json' });

      if (serveResponse.data.pdf_url) {
        // Production: Open the signed URL in a new tab
        window.open(serveResponse.data.pdf_url, '_blank');
      } else {
        // Local development: Make another request to get the PDF content
        const pdfContentResponse = await axiosInstance.get(pdfUrl, { responseType: 'blob' });
        const blob = new Blob([pdfContentResponse.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }

      // Ensure loading state lasts at least 3 seconds
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(3000 - elapsedTime, 0);
      
      await new Promise(resolve => setTimeout(resolve, remainingTime));

      toast({
        title: t('pdf.generationSuccess'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

    } catch (error) {
      console.error('Error generating and downloading PDF:', error);
      toast({
        title: t('pdf.generationError'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [orderId, t, toast]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="12px"
            _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
            }}
            leftIcon={<Download size="16px" />}
            onClick={() => setIsOpen(true)}
            size="sm"
            isDisabled={isLoading || service_type !== 'resume_polish'}
            isLoading={isLoading}
            loadingText={t('pdf.generating')}
        >
            {t('pdf.export')}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderRadius="16px"
        p={1}
        bg="#364954"
      >
        <PopoverArrow
            bg="#364954"
        />
        <PopoverHeader 
            pt={4} 
            fontWeight='bold' 
            border='0'
            textColor="#FFFFFF"
        >
            {t('pdf.popover-title')}
        </PopoverHeader>
        <PopoverBody>
            <Text
                textColor="#FFFFFF"
                fontSize="14px"
            >
            {t('pdf.popover-des')}
            </Text>
        </PopoverBody>
        <PopoverFooter
            border='0'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            pb={4}
        >
            <Spacer flex={1} />
            <ButtonGroup size='sm' flex={3}>
                <Button 
                    colorScheme="green"
                    onClick={handleGenerateAndDownloadPDF}
                    isLoading={isLoading}
                    loadingText={t('pdf.generating')}
                    width="100%"
                >
                    {t('pdf.popover-btn-confirm')}
                </Button>
                <Button 
                    onClick={() => setIsOpen(false)}
                    width="100%"
                >
                    {t('pdf.popover-btn-cancel')}
                </Button>
            </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default GeneratePDF;