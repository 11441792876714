import React, { useState } from 'react';
import { Box, Text, IconButton, HStack, VStack, Spinner } from '@chakra-ui/react';
import { LuPenLine, LuTrash2 } from "react-icons/lu";
import ProjectForm from './ProjectForm';
import { useTranslation } from 'react-i18next';

const ProjectEntry = ({ project, onUpdate, onDelete, resumeId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { t } = useTranslation();

  const handleUpdate = async (updatedProject) => {
    setIsUpdating(true);
    try {
      await onUpdate(updatedProject);
      setIsEditing(false);
    } finally {
      setIsUpdating(false);
    }
  };

  if (isEditing) {
    return (
      <ProjectForm
        project={project}
        onSubmit={handleUpdate}
        onCancel={() => setIsEditing(false)}
        resumeId={resumeId}
      />
    );
  }

  return (
    <Box py={1}>
      <HStack align="flex-start" justify="space-between">
        <VStack align="stretch" spacing={1}>
          <Text fontWeight="bold" fontSize="md" textColor="#364954">
            {project.role}, {project.start} - {project.end || t('Present')}
          </Text>
          <Text color="gray.600" fontSize="sm">{project.name}</Text>
          <Text whiteSpace="pre-wrap" fontSize="sm">{project.description}</Text>
        </VStack>
        <HStack>
          {isUpdating ? (
            <Spinner size="sm" />
          ) : (
            <>
              <IconButton
                icon={<LuPenLine />}
                aria-label="Edit project"
                onClick={() => setIsEditing(true)}
                variant="ghost"
                size="sm"
              />
              <IconButton
                icon={<LuTrash2 />}
                aria-label="Delete project"
                onClick={onDelete}
                variant="ghost"
                size="sm"
              />
            </>
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default ProjectEntry;