import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Text, 
    Spinner,
    VStack, HStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import { diffChars, diffWords } from 'diff';

const ServiceResultOfContentGeneration = ({ order }) => {
    const [generationResult, setGenerationResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchGenerationResult = async () => {
            try {
                const response = await axiosInstance.get(`/api/v1/services/copilot/generation/by-order/${order.order.id}/`);
                setGenerationResult(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch generation result');
                setLoading(false);
            }
        };

        if (order && order.order.id) {
            fetchGenerationResult();
        }
    }, [order]);

    const containsChinese = (str) => /[\u4e00-\u9fa5]/.test(str);

    const getHighlightedText = (before, after) => {
        if (!before || !after) {
            return before || after || '';
        }

        const hasChinese = containsChinese(before) || containsChinese(after);
        const diff = hasChinese ? diffChars(before, after) : diffWords(before, after);

        return diff.map((part, index) => {
            let style = {};
            if (part.added) {
                style = { backgroundColor: '#C7E4D4', color: '#364954', fontWeight: 'bold' };
            } else if (part.removed) {
                style = { backgroundColor: '#D5BDA7', color: '#FFF5DF', textDecoration: 'line-through' };
            }

            return (
                <span key={index} style={style}>
                    {part.value}
                </span>
            );
        });
    };

    const parseContent = (content) => {
        if (!content) return null;
        try {
            return JSON.parse(content.replace(/'/g, '"'));
        } catch (error) {
            console.error('Failed to parse content:', error);
            return content; // Return the original content if parsing fails
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Spinner />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{t('cgorder.error')}</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    if (!generationResult) {
        return (
            <Alert status="info">
                <AlertIcon />
                <AlertTitle mr={2}>{t('cgorder.nodata')}</AlertTitle>
            </Alert>
        );
    }

    // Add this check for version being null
    if (generationResult && generationResult.version === null) {
        return (
            <Alert status="info" borderRadius={16}>
                <AlertIcon />
                <AlertTitle mr={2}>{t('cgorder.resultnull-title')}</AlertTitle>
                <AlertDescription>
                {t('cgorder.resultnull-desc')}
                </AlertDescription>
            </Alert>
        );
    }

    const parsedOriginalContent = parseContent(generationResult.original_content);
    const parsedGeneratedContent = parseContent(generationResult.generated_content);

    const before = typeof parsedOriginalContent === 'string' 
        ? parsedOriginalContent 
        : JSON.stringify(parsedOriginalContent);

    const after = parsedGeneratedContent && typeof parsedGeneratedContent.retouched_job_description === 'string' 
        ? parsedGeneratedContent.retouched_job_description 
        : JSON.stringify(parsedGeneratedContent?.retouched_job_description || '');

    return (
        <Box>
            <VStack spacing={4} align="stretch">
                <HStack fontSize="14px" textColor="#364954" align="top">
                    <Box width="50%">
                        <Text fontWeight="bold" mb="2">{t('cgorder.before')}:</Text>
                        <Box p={2} bg="#f5f7fa" borderRadius="12px">
                            <Text whiteSpace="pre-wrap">{getHighlightedText(before, after)}</Text>
                        </Box>
                    </Box>
                    
                    <Box width="50%">
                        <Text fontWeight="bold" mb="2">{t('cgorder.after')}:</Text>
                        <Box p={2} bg="green.50" borderRadius="12px">
                            <Text whiteSpace="pre-wrap" fontWeight="medium">
                                {parsedGeneratedContent?.retouched_job_description || t('cgorder.nodata')}
                            </Text>
                        </Box>
                    </Box>
                </HStack>
                
                <Box textColor="#364954" fontSize="14px">
                    <Text fontWeight="bold" mb="2">{t('cgorder.explanation')}:</Text>
                    <Text>{parsedGeneratedContent?.explanation || t('cgorder.nodata')}</Text>
                </Box>
            </VStack>
        </Box>
    );
};

export default ServiceResultOfContentGeneration;