import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  VStack, HStack, 
  Box,
  Spinner,
  List,
  ListItem,
  Alert,
  AlertIcon, Circle, useToast
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserContext';

const ResumeUploadModal = ({ isOpen, onClose, onUploadSuccess, resumeId }) => {
  const [file, setFile] = useState(null);
  const [extractedContent, setExtractedContent] = useState(null);
  const [parsedContent, setParsedContent] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();  
  const toast = useToast();
  const { fetchUserProfile } = useContext(UserContext);


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf' && selectedFile.size <= 5 * 1024 * 1024) {
      setFile(selectedFile);
      setError(null);
    } else {
      setFile(null);
      setError('Please select a PDF file under 5MB.');
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const uploadResponse = await axiosInstance.post('/api/v1/resumes/upload_parse/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setExtractedContent(uploadResponse.data.extractedContent);

      // Now call the parse API
      const parseResponse = await axiosInstance.post('/api/v1/resumes/parse/', {
        extractedContent: uploadResponse.data.extractedContent
      });

      setParsedContent(parseResponse.data.parsedContent);
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during upload or parsing.');
    } finally {
      setIsLoading(false);
    }
  };

  const checkResumeCompletion = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const resume = response.data;
        return (
          resume.education && resume.education.length > 0 &&
          resume.experiences && resume.experiences.length > 0 &&
          resume.skills && resume.skills.length > 0
        );
      }
      return false;
    } catch (error) {
      console.error('Error checking resume completion:', error);
      return false;
    }
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post('/api/v1/resumes/confirm/', 
        { parsedContent }, 
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      // Check if all sections are complete
      const isResumeComplete = await checkResumeCompletion();

      if (isResumeComplete) {
        // Fetch the user's onboarding tasks
        const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        
        if (onboardingTasksResponse.status === 200) {
          const tasks = onboardingTasksResponse.data;
          const createResumeTask = tasks.find(task => task.task_id === 2);
          
          if (createResumeTask && !createResumeTask.is_completed) {
            // Call the API to complete the task only if it's not already completed
            try {
              const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-create-resume-task/');
              
              if (onboardingResponse.status === 200) {
                await fetchUserProfile();

                toast({
                  title: t('resumepage.onboardingtask.toast-complete'),
                  description: `${t('resumepage.onboardingtask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('resumepage.onboardingtask.toast-complete-des2')}`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            } catch (error) {
              console.error('Error completing resume task:', error);
              // You might want to show an error toast here
            }
          }
        }
      }

      onUploadSuccess();
      onClose();
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while saving the resume.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFile(null);
    setExtractedContent(null);
    setParsedContent(null);
    setError(null);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} size="3xl" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay 
        backdropFilter="blur(10px)"
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.1s ease"     
      >
        <ModalHeader>{t('resumepage.tools.uploadmodal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Box mb="4">
                <Alert status="warning" borderRadius="12px" fontSize="14px" fontWeight="bold">
                    <AlertIcon />
                    {t('resumepage.tools.uploadmodal.betaalert')}
                </Alert>
            </Box>
            <Box>
                <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
                    <HStack>
                    <Circle size='30px' bg='#364954' color='white'>
                        <Text>1</Text>
                    </Circle>
                    <Text as='b' fontSize={'18px'}>{t('resumepage.tools.uploadmodal.step1title')}</Text>
                    </HStack>
                </Box>
                <Box p={2}>
                    <VStack align="left">
                        <HStack>
                        <Input 
                            type="file" 
                            accept=".pdf" 
                            onChange={handleFileChange} 
                            mt={2} 
                            borderRadius="12px"
                            alignContent="center"
                        />
                        {error && <Text color="red.500" mt={2}>{error}</Text>}
                        <Button 
                            onClick={handleUpload} 
                            isDisabled={!file || isLoading || parsedContent} 
                            mt={2}
                            variant='solid'
                            minW="18%"
                            backgroundColor="#f5f7fa"
                            color="#364954"
                            size="md"
                            borderRadius="12px"
                            _hover={{
                              color: "#ffffff",
                              backgroundColor: "#82B2CA"
                            }}
                        >
                            {isLoading ? <Spinner size="sm" /> : t('resumepage.tools.uploadmodal.uploadbtn')}
                        </Button>
                        </HStack>
                        <Text fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.uploaddes')}</Text>
                    </VStack>
                </Box>
            </Box>
            <Box mt="4">
                <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
                    <HStack>
                    <Circle size='30px' bg='#364954' color='white'>
                        <Text>2</Text>
                    </Circle>
                    <Text as='b' fontSize={'18px'}>{t('resumepage.tools.uploadmodal.step2title')}</Text>
                    </HStack>
                </Box>

              {isLoading ? (
                <Box
                    mt="4"
                    p={2}
                    borderRadius="12px"
                    bg="#f5f7fa"                
                >
                <VStack mt={2}>
                  <Spinner />
                  <Text fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.loadingdes1')}</Text>
                  <Text fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.loadingdes2')}</Text>
                </VStack>
                </Box>
              ) : parsedContent ? (
                <Box
                    mt="4"
                    p={2}
                    borderRadius="12px"
                    bg="#f5f7fa"                 
                >
                <VStack align="stretch" spacing={4} p={2}>
                  <Alert status="warning" borderRadius="12px" fontSize="14px" fontWeight="bold">
                    <AlertIcon />
                    {t('resumepage.tools.uploadmodal.step2alert')}
                  </Alert>
                  <Box>
                    <Text fontWeight="bold">{t('resumepage.tools.uploadmodal.edutitle')}</Text>
                    {parsedContent.education && parsedContent.education.length > 0 ? (
                      parsedContent.education.map((edu, index) => (
                        <Box key={index} mt={2}>
                          <Text fontWeight="medium">{edu.degree}, {edu.major}</Text>
                          <Text fontSize="sm">{edu.institution_name}, {edu.start} - {edu.end}</Text>
                        </Box>
                      ))
                    ) : (
                        <Text mt="2" fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.nodata')}</Text>
                    )}
                  </Box>
                  <Box>
                    <Text fontWeight="bold">{t('resumepage.tools.uploadmodal.wrktitle')}</Text>
                    {parsedContent.experiences && parsedContent.experiences.length > 0 ? (
                      parsedContent.experiences.map((exp, index) => (
                        <Box key={index} mt={2}>
                          <Text fontWeight="medium">{exp.title}, {exp.organization_name}, {exp.start} - {exp.end}</Text>
                          <Text fontSize="sm" whiteSpace="pre-wrap">{exp.description}</Text>
                        </Box>
                      ))
                    ) : (
                        <Text mt="2" fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.nodata')}</Text>
                    )}
                  </Box>
                  <Box>
                    <Text fontWeight="bold">{t('resumepage.tools.uploadmodal.skillstitle')}</Text>
                    {parsedContent.skills && parsedContent.skills.length > 0 ? (
                      <List>
                        {parsedContent.skills.map((skillObj, index) => (
                          <ListItem key={index} mt={2}>
                            <Text fontWeight="medium">{skillObj.skill_name}</Text>
                            <Text fontSize="sm" whiteSpace="pre-wrap">{skillObj.description}</Text>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                        <Text mt="2" fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.nodata')}</Text>
                    )}
                  </Box>
                </VStack>
                </Box>
              ) : (
                <Box 
                    mt="4"
                    p={2}
                    borderRadius="12px"
                    bg="#f5f7fa"
                >
                    <Text ml="2" fontSize="14px" color="#737D82">{t('resumepage.tools.uploadmodal.nodatades')}</Text>
                </Box>
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {parsedContent && !isLoading && (
            <>
              <Button
                mr={3} 
                onClick={handleConfirm}
                color="#ffffff" 
                backgroundColor="#364954"
                borderRadius="12px"
                _hover={{
                  color:"#ffffff",
                  backgroundColor:"#82B2CA"
                }}
              >
                {t('resumepage.tools.uploadmodal.btnconfirm')}
              </Button>
              <Button variant="ghost" onClick={handleCancel}>{t('resumepage.tools.uploadmodal.btncancel')}</Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ResumeUploadModal;