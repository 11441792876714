// src/components/Orders/ResumeRetouchSection.js
import React, { useState, useEffect } from 'react';
import { Box, Text, Spinner, Flex, VStack, Divider, Heading, 
  Alert, AlertIcon, AlertTitle, AlertDescription, HStack, 
  Switch
} from '@chakra-ui/react';
import { ArrowRight } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import { diffWords, diffChars } from 'diff';
import RerunButton from './RerunButton';

const ResumeRetouchSection = ({ taskId }) => {
  const { t } = useTranslation();
  const [resumeRetouch, setResumeRetouch] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState({});

  // Simple function to detect if a string contains Chinese characters
  const containsChinese = (str) => /[\u4e00-\u9fa5]/.test(str);

  const getHighlightedText = (before, after) => {
    // Detect if either string contains Chinese
    const hasChinese = containsChinese(before) || containsChinese(after);

    // Use diffChars for Chinese, diffWords for other languages
    const diff = hasChinese ? diffChars(before, after) : diffWords(before, after);

    return diff.map((part, index) => {
      let style = {};
      if (part.added) {
        style = { backgroundColor: '#C7E4D4', color: '#364954', fontWeight: 'bold' };
      } else if (part.removed) {
        style = { backgroundColor: '#D5BDA7', color: '#FFF5DF', textDecoration: 'line-through' };
      }

      return (
        <span key={index} style={style}>
          {part.value}
        </span>
      );
    });
  };

  useEffect(() => {
    const fetchResumeRetouch = async () => {
      if (!taskId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get(`/api/v1/orders/resume-retouch/${taskId}/`);
        setResumeRetouch(response.data);
      } catch (err) {
        console.error('Error fetching resume retouch:', err);
        setError('Failed to load resume retouch');
      } finally {
        setIsLoading(false);
      }
    };

    fetchResumeRetouch();
  }, [taskId]);

  const handleToggle = (id) => {
    setShow(prevState => ({
      ...prevState,
      [id]: !prevState[id]  // Toggle the specific item by its id
    }));
  };

  if (isLoading)
    return <Spinner />;

  if (error)
    return (
      <Alert status="error" variant="left-accent" borderRadius="md" mb={4}>
        <AlertIcon />
        <AlertTitle mr={2}>{t('orderdetail.error_title')}</AlertTitle>
        <HStack spacing={2}>
          <AlertDescription>{t('orderdetail.error_content')}</AlertDescription>
          <RerunButton taskId={taskId} onRerunComplete={() => window.location.reload()} />
        </HStack>
      </Alert>
    );

  if (!resumeRetouch)
    return null;

  return (
    <Flex align="left">
      <VStack align="left" width="100%">
        {resumeRetouch.details.map(detail => (
          <VStack key={detail.id} align="left">
            <Flex mt="4" align="center">
              <Box 
                width="50%"
                height="100%"
                p={3}
                borderRadius="14px"
                bg="gray.50"
              >
                <Flex 
                  direction="row" 
                  justifyContent="space-between"
                  mb="4"
                  align="center"
                >
                  <Heading size="16px" color="#2D3748">{t('orderdetail.resumeanretouch_before')}</Heading>
                  <HStack>
                    <Text fontSize="12px" textColor="#364954">
                      { show[detail.id] ? t('orderdetail.resumeanretouch_btn_showcompare') : t('orderdetail.resumeanretouch_btn_hidecompare') }
                    </Text>
                  <Switch 
                      size="sm" 
                      colorScheme="gray" 
                      isChecked={!show[detail.id] || false}  // Get the show state for this item
                      onChange={() => handleToggle(detail.id)}  // Toggle this item
                    />
                  </HStack>
                </Flex>
                <Text 
                  as="cite" 
                  whiteSpace="pre-wrap"
                >
                  { show[detail.id] ? detail.before : getHighlightedText(detail.before, detail.after)}
                </Text>
              </Box>
              <Box 
                align="center"
                mx="2"
              >
                <ArrowRight 
                  color="#364954"
                />
              </Box>
              <Box 
                width="50%"
                height="100%"
                p={3}
                borderRadius="14px"
                bg="gray.50"
              >
                <Heading size="16px" color="#2D3748" mb="4">{t('orderdetail.resumeanretouch_after')}</Heading>
                <Text as="cite" whiteSpace="pre-wrap">{detail.after}</Text>
              </Box>
            </Flex>
            <Heading size="16px" mt={4} mb={2} color="#2D3748">{t('orderdetail.resumeanretouch_explanation')}</Heading>
            <Text whiteSpace="pre-wrap">{detail.explanation}</Text>
            <Divider mt={6} mb={4} />
          </VStack>
        ))}
      </VStack>
    </Flex>
  );
};

export default ResumeRetouchSection;
