import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Text, 
    Spinner,
    VStack
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

const ServiceResultOfWriteWithAI = ({ order }) => {
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchResult = async () => {
        try {
            const response = await axiosInstance.get(`/api/v1/services/writewithai/by-order/${order.order.id}/`);
            setResult(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch analysis result');
            setLoading(false);
        }
        };

        if (order && order.order.id) {
        fetchResult();
        }
    }, [order]);

    if (loading) {
        return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Spinner />
        </Box>
        );
    }

    if (error) {
        return (
        <Box>
            <Text color="error">{error}</Text>
        </Box>
        );
    }

    if (!result) {
        return (
        <Box>
            <Text>{t('wwaorder.nodata')}</Text>
        </Box>
        );
    }

    return (
        <Box>
            <VStack spacing={6} align="stretch" textColor="#364954">
                
                <Box fontSize="14px" >
                    <Text fontWeight="bold" mb="2">{t('wwaorder.jobtitle')}:</Text>
                    <Box p={2} bg="#f5f7fa" borderRadius="12px">
                        <Text whiteSpace="pre-wrap">{result[0].title}</Text>
                    </Box>
                </Box>
                
                <Box fontSize="14px" >
                    <Text fontWeight="bold" mb="2">{t('wwaorder.generated-examples')}:</Text>
                    <Box p={2} bg="#f5f7fa" borderRadius="12px">
                        <Text whiteSpace="pre-wrap">{result[0].generated_description}</Text>
                    </Box>
                </Box>
            
                
            </VStack>
        </Box>
    );
};

export default ServiceResultOfWriteWithAI;