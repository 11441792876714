// CustomDateDropdown.js
import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, VStack, HStack } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const CustomDateDropdown = ({ name, placeholder, onChange, value, includePresent = false }) => {
  const { t } = useTranslation();  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const dropdownRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({length: 51}, (_, i) => currentYear - i);
  const months = [
    t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'),
    t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSelectedYear(null);
        setSelectedMonth(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    if (selectedMonth) {
      onChange(`${year}-${String(months.indexOf(selectedMonth) + 1).padStart(2, '0')}`);
      setIsOpen(false);
      setSelectedYear(null);
      setSelectedMonth(null);
    }
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    if (selectedYear) {
      onChange(`${selectedYear}-${String(months.indexOf(month) + 1).padStart(2, '0')}`);
      setIsOpen(false);
      setSelectedYear(null);
      setSelectedMonth(null);
    }
  };

  const handlePresentSelect = () => {
    onChange(t('Present'));
    setIsOpen(false);
    setSelectedYear(null);
    setSelectedMonth(null);
  };

  return (
    <Box 
        position="relative" 
        ref={dropdownRef}
    >
      <Button 
        onClick={() => setIsOpen(!isOpen)} 
        rightIcon={<ChevronDownIcon />}
        variant="outline"
        fontWeight="normal"
      >
        {value || placeholder}
      </Button>
      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          zIndex={1}
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          width="200px"
          boxShadow="sm"
        >
          {includePresent && (
            <Button variant="ghost" w="100%" justifyContent="center" onClick={handlePresentSelect}>
              {t('Present')}
            </Button>
          )}
          <HStack spacing={0} align="stretch">
            <VStack
              width="50%"
              maxHeight="200px"
              overflowY="auto"
              borderRight="1px solid"
              borderColor="gray.200"
              spacing={0}
              align="stretch"
              p={1}
            >
              {years.map(year => (
                <Button
                  key={year}
                  variant="ghost"
                  justifyContent="center"
                  onClick={() => handleYearSelect(year)}
                  bg={selectedYear === year ? "#CFD9DF" : "transparent"}
                  p={3}
                  textColor="#364954"
                  fontWeight="normal"
                >
                  {year}
                </Button>
              ))}
            </VStack>
            <VStack
              width="50%"
              maxHeight="200px"
              overflowY="auto"
              spacing={0}
              align="stretch"
              p={1}
            >
              {months.map(month => (
                <Button
                  key={month}
                  variant="ghost"
                  justifyContent="center"
                  onClick={() => handleMonthSelect(month)}
                  bg={selectedMonth === month ? "#CFD9DF" : "transparent"}
                  p={3}
                  textColor="#364954"
                  fontWeight="normal"
                >
                  {month}
                </Button>
              ))}
            </VStack>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default CustomDateDropdown;