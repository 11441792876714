import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import {
  Box, Container, Center, Button, 
  VStack, Text, Skeleton,
  Menu, MenuButton, IconButton, MenuList, MenuOptionGroup, MenuItemOption
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { GrLanguage } from "react-icons/gr";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const VerifyEmail = React.memo(() => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [, setEmail] = useState('');
  const requestMadeRef = useRef(false);
  const [, setLanguage] = useState(i18next.language || 'zh');
  const { t, i18n, ready } = useTranslation();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18next.changeLanguage(value);
  };

  const verifyEmail = useCallback(async () => {
    if (requestMadeRef.current) return;
    requestMadeRef.current = true;

    try {
      const response = await axiosInstance.get(`/api/v1/accounts/verify-email/${token}/`);
      setVerificationStatus('success');
      setEmail(response.data.email || '');
    } catch (error) {
      setVerificationStatus('failed');
    }
  }, [token]);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  const renderContent = () => {
    switch (verificationStatus) {
      case 'success':
        return (
          <>
            <Center flexDirection="column" mb={6}>
              <Box bg="green.100" borderRadius="full" p={3} mb={4}>
                <CheckIcon boxSize={6} color="green.500" />
              </Box>
              <Text fontSize="2xl" fontWeight="bold">{t('emailverify.success_title')}</Text>
            </Center>
            <Text color="gray.600" textAlign="center" mb={4}>
              {t('emailverify.success_message_line1')}<br />
              {t('emailverify.success_message_line2')}
            </Text>
            <Button 
              width="full" 
              color="#ffffff" 
              backgroundColor="#364954"
              borderRadius="16px"
              _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
              }}
              onClick={() => navigate('/sign-in')}
            >
              {t('emailverify.login_button')}
            </Button>
          </>
        );
      case 'failed':
        return (
          <>
            <Center flexDirection="column" mb={6}>
              <Box bg="red.100" borderRadius="full" p={3} mb={4}>
                <CloseIcon boxSize={6} color="red.500" />
              </Box>
              <Text fontSize="2xl" fontWeight="bold">{t('emailverify.fail_title')}</Text>
            </Center>
            <Text color="gray.600" textAlign="center" mb={4}>
              {t('emailverify.fail_message_line1')}<br />
              {t('emailverify.fail_message_line2')}
            </Text>
            <Button 
              width="full" 
              color="#ffffff" 
              backgroundColor="#364954"
              borderRadius="16px"
              _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
              }}
              onClick={() => navigate('/')}
            >
              {t('emailverify.backtohome_button')}
            </Button>
          </>
        );
      default:
        return <Text>{t('emailverify.loading')}</Text>;
    }
  };

  const renderPlaceholder = () => (
    <>
      <Center flexDirection="column" mb={6}>
        <Skeleton height="48px" width="48px" borderRadius="full" mb={4} />
        <Skeleton height="36px" width="200px" />
      </Center>
      <Skeleton height="20px" width="100%" mb={2} />
      <Skeleton height="20px" width="80%" mb={4} />
      <Skeleton height="40px" width="100%" />
    </>
  );

  return (
    <Box 
      bg="#f5f7fa"
      minHeight="100vh" 
      py={8}>
      <Container maxW="500px">
        <Box 
          borderWidth={1} 
          borderRadius="16px" 
          p={8} 
          position="relative"
          bg="rgba(255, 255, 255, 0.7)"
          backdropFilter="blur(10px)"
          boxShadow="lg"
        >
          <Box position="absolute" top={4} right={8}>
            <Menu>
              <MenuButton as={IconButton} icon={<GrLanguage />} colorScheme="gray" variant="outline" aria-label="Language Switcher" />
              <MenuList>
                <MenuOptionGroup type='radio' defaultValue={i18n.language}>
                  <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                  <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
          <VStack mt="5" spacing={6} align="stretch">
            {showContent ? renderContent() : renderPlaceholder()}
          </VStack>
        </Box>
      </Container>
    </Box>
  );
});

export default VerifyEmail;