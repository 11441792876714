import axiosInstance from '../utils/axiosConfig';
import { logout } from '../utils/auth';

export const handleSignOut = async (navigate, toast) => {
  try {
    // Call Django's logout view
    await axiosInstance.post('/api/v1/accounts/sign-out/');  // Adjust this URL to match your Django logout URL
    
    // Call the logout function from auth.js
    logout();

    toast({
      title: 'Signed Out',
      description: 'You have been successfully signed out.',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  } catch (error) {
    console.error('Error during sign out:', error);
    toast({
      title: 'Sign Out Error',
      description: 'There was an error signing out. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  } finally {
    // Always navigate to home, even if there's an error
    navigate('/');
  }
};