import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text, Image, 
  VStack, HStack, 
  Box,
  Circle
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const imageUrls = {
    answerGeneration: {
      en: "/homepage/solution-answer-generation.png",
      zh: "/homepage/solution-answer-generation-zh.png",
    },
    promptQuestions: {
      en: "/homepage/solution-prompt-questions.png",
      zh: "/homepage/solution-prompt-questions-zh.png",
    },
    answerValidation: {
      en: "/homepage/solution-answer-validation.png",
      zh: "/homepage/solution-answer-validation-zh.png",
    },
    comprehensiveGeneration: {
        en: "/homepage/solution-comprehensive-generation.png",
        zh: "/homepage/solution-comprehensive-generation-zh.png",
      },
  };

const WriteWithAIProResultPreviewModal = ({ isOpen, onClose }) => {

  const { t, i18n } = useTranslation();  

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal 
        isOpen={isOpen} 
        onClose={handleCancel} 
        size="2xl" 
        closeOnOverlayClick={true} 
        closeOnEsc={true}
    >
      <ModalOverlay 
        backdropFilter="blur(10px)"
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.1s ease"     
      >
        <ModalHeader>{t('resultpreview.modaltitle')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch" mb={5}>
            <Box mb="4">
                <Text
                    fontSize="16px"
                    textColor="#364954"
                >
                    {t('resultpreview.writewithai.desc')}
                </Text>
            </Box>
            <Box>
                <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
                    <HStack>
                    <Circle size='30px' bg='#364954' color='white'>
                        <Text>1</Text>
                    </Circle>
                    <Text as='b' fontSize={'18px'}>{t('resultpreview.writewithai.start-title')}</Text>
                    </HStack>
                </Box>
                <Box
                    fontSize="14px"
                    textColor="#364954"
                    my="3"
                >
                    <Text>
                    {t('resultpreview.writewithai.start-desc')}
                    </Text>
                </Box>
            </Box>
            <Box mt="4">
                <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
                    <HStack>
                    <Circle size='30px' bg='#364954' color='white'>
                        <Text>2</Text>
                    </Circle>
                    <Text as='b' fontSize={'18px'}>{t('resultpreview.writewithai.helper-title')}</Text>
                    </HStack>
                </Box>
                <Box
                    fontSize="14px"
                    textColor="#364954"
                    my="3"
                >
                    <Text>
                    {t('resultpreview.writewithai.helper-desc1')}
                    </Text>
                </Box>
                <Box
                    align="center"
                >
                    <Image
                    src={imageUrls.promptQuestions[i18n.language] || imageUrls.promptQuestions.en}
                    alt="Job Analysis Graphic"
                    objectFit="contain"
                    width="80%"
                    />
                </Box>
                <Box
                    fontSize="14px"
                    textColor="#364954"
                    my="3"
                >
                    <Text>
                    {t('resultpreview.writewithai.helper-desc2')}
                    </Text>
                </Box>
                <Box
                    align="center"
                >
                    <Image
                    src={imageUrls.answerGeneration[i18n.language] || imageUrls.answerGeneration.en}
                    alt="Job Analysis Graphic"
                    objectFit="contain"
                    width="80%"
                    />
                </Box>
            </Box>
            <Box mt="4">
                <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
                    <HStack>
                    <Circle size='30px' bg='#364954' color='white'>
                        <Text>3</Text>
                    </Circle>
                    <Text as='b' fontSize={'18px'}>{t('resultpreview.writewithai.answervalidation-title')}</Text>
                    </HStack>
                </Box>
                <Box
                    fontSize="14px"
                    textColor="#364954"
                    my="3"
                >
                    <Text>
                    {t('resultpreview.writewithai.answervalidation-desc')}
                    </Text>
                </Box>
                <Box
                    align="center"
                >
                    <Image
                    src={imageUrls.answerValidation[i18n.language] || imageUrls.answerValidation.en}
                    alt="Job Analysis Graphic"
                    objectFit="contain"
                    width="80%"
                    />
                </Box>
            </Box>
            <Box mt="4">
                <Box as='span' flex='1' textAlign='left' mt="2" mb="3">
                    <HStack>
                    <Circle size='30px' bg='#364954' color='white'>
                        <Text>4</Text>
                    </Circle>
                    <Text as='b' fontSize={'18px'}>{t('resultpreview.writewithai.comprehensivegeneration-title')}</Text>
                    </HStack>
                </Box>
                <Box
                    fontSize="14px"
                    textColor="#364954"
                    my="3"
                >
                    <Text>
                    {t('resultpreview.writewithai.comprehensivegeneration-desc')}
                    </Text>
                </Box>
                <Box
                    align="center"
                >
                    <Image
                    src={imageUrls.comprehensiveGeneration[i18n.language] || imageUrls.comprehensiveGeneration.en}
                    alt="Job Analysis Graphic"
                    objectFit="contain"
                    width="80%"
                    />
                </Box>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WriteWithAIProResultPreviewModal;