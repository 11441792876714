import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text, Box,
  Flex
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ProConfirmationModal = ({ isOpen, onClose, onConfirm, title, company }) => {
    const { t } = useTranslation();  


    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
            size="xl"
        >
            <ModalOverlay 
                backdropFilter="blur(10px)"          
            />
            <ModalContent
                borderRadius={16}
                boxShadow="lg"
                background="rgba(255, 255, 255, 1)"
                transition="all 0.1s ease" 
            >
            <ModalHeader>{t('prodrawer.startconfirm-modal.title')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box
                    textColor="#364954"
                >
                    <Text
                        fontWeight="bold"
                    >
                    {t('prodrawer.startconfirm-modal.desc1')}
                    </Text>
                    <Flex 
                        mt={2}
                        p={3}
                        bg="#f5f7fa"
                        borderRadius={12}
                        direction="column"
                        fontSize="14px"
                    >
                        <Text as="kbd">
                        {t('prodrawer.startpage.title-jobtitle')}: {title}
                        </Text>
                        <Text as="kbd">
                        {t('prodrawer.startpage.title-company')}: {company}
                        </Text>
                    </Flex>
                </Box>
                <Text mt={4}>
                {t('prodrawer.startconfirm-modal.desc2')}
                </Text>
                <Box>
                    <Text fontSize="14px" textColor="#737D82" mt={4}>
                    {t('prodrawer.startconfirm-modal.desc-notice')}
                    </Text>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button 
                    onClick={onConfirm}
                    mr={3} 
                    color="#ffffff" 
                    backgroundColor="#364954"
                    borderRadius="16px"
                    _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                    }}
                >
                    {t('prodrawer.startconfirm-modal.btn-confirm')}
                </Button>

                <Button 
                    variant="ghost" 
                    onClick={onClose}
                    borderRadius="16px"
                >
                    {t('prodrawer.startconfirm-modal.btn-cancel')}
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      );
    };

export default ProConfirmationModal;