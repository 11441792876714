import React from 'react';
import { Box, Spinner, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const LoadingPage = () => {
  const { t } = useTranslation();  

  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <VStack spacing={4}>
        <Spinner size="xl" color="#364954" />
        <Text fontSize="xl" fontWeight="bold" color="#364954">
        {t('payment.loading')}
        </Text>
      </VStack>
    </Box>
  );
};

export default LoadingPage;