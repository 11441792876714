import React, { useContext } from 'react';
import { Input, Button, HStack, Text, Tooltip, Grid, GridItem, FormControl, FormLabel, FormErrorMessage, useToast, Textarea, Box } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomDateDropdown from './CustomDateDropdown'
import axiosInstance from '../../utils/axiosConfig';
import { UserContext } from '../UserContext';

const ProjectForm = ({ project, onSubmit, onCancel, resumeId }) => {
  const { t } = useTranslation();  
  const toast = useToast();
  const initialValues = project || {
    name: '',
    role: '',
    start: '',
    end: '',
    description: ''
  };
  const { fetchUserProfile } = useContext(UserContext);

  const validate = (values) => {
    const errors = {};
    if (!values.name.trim()) errors.name = 'Required';
    if (!values.role.trim()) errors.role = 'Required';
    if (!values.start) errors.start = 'Required';
    if (!values.description.trim()) errors.description = 'Required';
    return errors;
  };

  const checkResumeCompletion = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const resume = response.data;
        return (
          resume.education && resume.education.length > 0 &&
          resume.experiences && resume.experiences.length > 0 &&
          resume.skills && resume.skills.length > 0
        );
      }
      return false;
    } catch (error) {
      console.error('Error checking resume completion:', error);
      return false;
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await onSubmit({ ...values, resume: resumeId });
      // Check if all sections are complete
      const isResumeComplete = await checkResumeCompletion();
      
      if (isResumeComplete) {
        // Fetch the user's onboarding tasks
        const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        
        if (onboardingTasksResponse.status === 200) {
          const tasks = onboardingTasksResponse.data;
          const createResumeTask = tasks.find(task => task.task_id === 2);
          
          if (createResumeTask && !createResumeTask.is_completed) {
            // Call the API to complete the task only if it's not already completed
            try {
              const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-create-resume-task/');
              
              if (onboardingResponse.status === 200) {
                await fetchUserProfile();

                toast({
                  title: t('resumepage.onboardingtask.toast-complete'),
                  description: `${t('resumepage.onboardingtask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('resumepage.onboardingtask.toast-complete-des2')}`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            } catch (error) {
              console.error('Error completing resume task:', error);
              // You might want to show an error toast here
            }
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
      toast({
        title: t('prj.add_fail'),
        description: t('prj.add_fail_description'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="4">
            <GridItem colSpan={1}>
              <FormControl isInvalid={errors.role && touched.role}>
                <FormLabel>{t('prj.entry_job_title')}</FormLabel>
                <Field as={Input} name="role" placeholder={t('prj.entry_job_placeholder')} />
                <FormErrorMessage>{errors.role}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={errors.start && touched.start}>
                <FormLabel>{t('prj.entry_period_title')}</FormLabel>
                <HStack>
                  <CustomDateDropdown
                    name="start"
                    placeholder={t('prj.entry_period_start')}
                    onChange={(value) => setFieldValue('start', value)}
                    value={values.start}
                  />
                  <Text>-</Text>
                  <CustomDateDropdown
                    name="end"
                    placeholder={t('prj.entry_period_end')}
                    onChange={(value) => setFieldValue('end', value)}
                    value={values.end}
                    includePresent={true}
                  />
                </HStack>
                <FormErrorMessage>{errors.start}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={errors.name && touched.name}>
                <Tooltip 
                  hasArrow 
                  borderRadius={12} 
                  bg='#CFD9DF' 
                  color='#364954' 
                  placement='top-start'
                  label={
                    <Box p={1}>
                    {t('prj.entry_org_tooltip')}
                    </Box>
                  } 
                > 
                    <FormLabel>
                    {t('prj.entry_org_title')}
                    </FormLabel>
                </Tooltip>
                <Field as={Input} name="name" placeholder={t('prj.entry_org_placeholder')} />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={errors.description && touched.description}>
                <FormLabel>{t('prj.entry_jd_title')}</FormLabel>
                <Field as={Textarea} name="description" placeholder={t('prj.entry_jd_placeholder')} />
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack mt={4} mb={4} justify="flex-end">
          <Button 
              size="sm"
              onClick={onCancel}
              borderRadius="12px"
              >
            {t('prj.cancel')}
            </Button>
            <Button 
              size="sm"
              type="submit" 
              color="#ffffff" 
              backgroundColor="#364954"
              borderRadius="12px"
              _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
              }}
              isLoading={isSubmitting}
              >
            {t('prj.save')}
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectForm;