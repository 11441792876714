import axiosInstance from './axiosConfig';

export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post('api/v1/accounts/sign-in', { email, password });
    if (response.data.access && response.data.refresh) {
      // localStorage.setItem('access_token', response.data.access);
      // localStorage.setItem('refresh_token', response.data.refresh);
      return true;
    }
    return false;
  } catch (error) {
    console.error('Login failed:', error);
    return false;
  }
};

export const isAuthenticated = () => {
  return localStorage.getItem('isLoggedIn') === 'true';
};

export const logout = () => {
  localStorage.removeItem('isLoggedIn');
};