import React, { useState, useEffect, useCallback } from 'react';
import { Box, VStack, Text, Button, Flex, Spinner, HStack, Collapse } from '@chakra-ui/react';
import { Check, X, ArrowRight } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const MotionBox = motion(Box);

const ProcessingPage = () => {
  const { t } = useTranslation();  
  const [tasks, setTasks] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);

  const taskOrder = ['Job Analysis', 'Resume Analysis', 'Resume Retouch'];

  const fetchProgress = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/orders/${orderId}/progress/`);
      const { tasks: newTasks, tasks_completed, total_tasks, is_complete } = response.data;
      
      const processedTasks = taskOrder.map(taskName => {
        const relatedTasks = newTasks.filter(task => task.task_name === taskName);
        const mostRecentTask = relatedTasks.reduce((prev, current) => 
          (new Date(current.updated_at) > new Date(prev.updated_at)) ? current : prev
        );
        return mostRecentTask || { task_name: taskName, status: 'PENDING' };
      });

      setTasks(processedTasks);
      setProgress((tasks_completed / total_tasks) * 100);
      setIsComplete(is_complete);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching progress:', error);
    }
  }, [orderId]);

  const allTasksCompleted = tasks.length > 0 && tasks.every(task => task.status === 'COMPLETED');
  const allTasksFailed = tasks.length > 0 && tasks.every(task => ['COMPLETED', 'FAILED'].includes(task.status)) && !allTasksCompleted;

  useEffect(() => {
    let intervalId;

    const runFetchProgress = async () => {
      await fetchProgress();
      if (allTasksCompleted || isComplete || allTasksFailed) {
        clearInterval(intervalId);
      }
    };

    runFetchProgress(); // Run immediately
    intervalId = setInterval(runFetchProgress, 5000);

    return () => clearInterval(intervalId);
  }, [fetchProgress, allTasksCompleted, isComplete, allTasksFailed]);

  const getTaskIcon = (status) => {
    if (status === 'COMPLETED') {
      return <Box as={Check} color="#364954" mr={2} />;
    } else if (status === 'FAILED' && allTasksFailed) {
      return <Box as={X} color="red.500" mr={2} />;
    } else {
      return <Spinner size="sm" mr={2} />;
    }
  };


  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
    <Box height="20px" flexShrink={0} />
    <Flex direction="column" flex="1" overflow="hidden">
    <Box
      flex="1"
      bg="#f5f7fa"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      overflow="auto"
      p={5}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box 
        mt="20"
        p={5} 
        width="60%"
        bg="rgba(255, 255, 255, 0"
        borderRadius="12px"
      >
        <VStack spacing={8} align="stretch" mx="auto">

          <Box>
              <Text 
                fontSize="3xl" 
                fontWeight="bold" 
                textAlign="center" 
                textColor="#364954"
                mb="1"
              >
                { allTasksCompleted ? (t('processing.done')) : (t('processing.title'))
                }
              </Text>
              <Text
                fontSize="14px" 
                fontWeight="normal" 
                textAlign="center" 
                textColor="#737D82"
              >
                { allTasksCompleted ? (t('processing.done-des')) : (t('processing.des'))
                }
              </Text>
          </Box>

          <Box w="100%" position="relative" height="4px" bg="gray.100" borderRadius="full" overflow="hidden">
            <MotionBox
              initial={{ x: "-100%" }}
              animate={{ x: "250%" }}
              transition={{ 
                repeat: Infinity, 
                duration: 1, 
                ease: "linear" 
              }}
              height="100%"
              width="50%"
              bg="rgba(54, 73, 84, 0.2)"
              borderRadius="full"
              position="absolute"
              top="0"
              left="0"
            />
            <MotionBox
              initial={{ width: "0%" }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              height="100%"
              bg="#364954"
              borderRadius="full"
              position="absolute"
              top="0"
              left="0"
            />
          </Box>
          <AnimatePresence>
            {isLoading ? (
              <Flex justify="center">
                <Spinner size="xl" color="#737D82" />
              </Flex>
            ) : (
              <VStack align="stretch">
                {tasks.map((task) => (
                  <motion.div
                    key={task.task_name}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box align="left" mb={2}>
                      <HStack key={task.task_name} align="center" mb={2}>
                        {getTaskIcon(task.status)}
                        <Text color={task.status !== 'PENDING' ? "#364954" : "gray.500"} fontWeight={task.status !== 'PENDING' ? "bold" : "normal"}>
                          {task.task_name}
                        </Text>
                      </HStack>
                    </Box>
                  </motion.div>
                ))}
              </VStack>
            )}
          </AnimatePresence>

          <Button 
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="16px"
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
            w="100%"
            onClick={() => navigate(`/service-orders/${orderId}`)} 
            isDisabled={isLoading || !(allTasksCompleted || allTasksFailed)}
          >
            <HStack>
              { (allTasksCompleted || allTasksFailed) ? (
                <>
                <Text>{t('processing.continuetoresult')} </Text>
                <Text><ArrowRight size="20px" /></Text>
                </>
              ) : (
                <>
                <Text>{t('processing.btn-processing')}</Text>
                </>
              )}

            </HStack>
          </Button>

          <Box
            my="10"
            bg="#ffffff"
            borderRadius="16px"
            borderWidth={1}
            borderColor="gray.50"
            p={6}
            textColor="#737D82"
            fontSize="14px"
            lineHeight={1.5}
          >
            <Text as="b">{t('processing.howitworks.title')}</Text>
            <Text mt="2" mb="2" >
            {t('processing.howitworks.p1')}
            </Text>
            <Collapse in={isExpanded}>
            <Text mb="2">
            {t('processing.howitworks.p2')}
            </Text>
            <Text>
            {t('processing.howitworks.p3')}
            </Text>
            </Collapse>
            <Button
              mt="2"
              variant="link"
              onClick={toggleExpand}
              fontSize="14px"
              fontWeight="normal"
              textColor="#82B2CA"
            >
              {isExpanded ? t('processing.howitworks.btn-less') : t('processing.howitworks.btn-expand')}
            </Button>
          </Box>

        </VStack>
      </Box>
    </Box>
    </Flex>
    </Flex>
  );
};

export default ProcessingPage;