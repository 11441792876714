import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Button,
    SimpleGrid,
    Icon,
    useBreakpointValue,
    Center,
    Skeleton, Badge
  } from "@chakra-ui/react";
import { WandSparkles, Blocks, NotebookPen, MonitorPlay, ArrowRight } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import parse from 'html-react-parser';
import { color, easeIn, motion } from "framer-motion";
  
  const HeroNew = () => {
    const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
    const textSize = useBreakpointValue({ base: "md", md: "lg" });
    const MotionHeading = motion(Heading);
    const MotionText = motion(Text);
    const MotionButton = motion(Button);

    const { t, ready } = useTranslation('homepage');
    const [showContent, setShowContent] = useState(false);

    const highlightStyle = {
        color: '#2580B3',
        fontWeight: 'bold',
        // Add any other styles you want
    };

    const parseWithStyles = (text) => {
    return parse(text, {
        replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === 'highlighted') {
            return <span style={highlightStyle}>{domNode.children[0].data}</span>;
        }
        return domNode;
        },
    });
    };
  
    useEffect(() => {
      if (ready) {
        setShowContent(true);
      }
    }, [ready]);
  
    return (
    <Box 
        align="center"
        py={20}
        px={{ base: 5, lg: 10 }}
        // bg="#f5f7fa"
        bg="linear-gradient(to bottom, #f5f7fa 0%, #e7f0fd 100%)"
    >
        <Center
            py={20}
            px={{ base: 4, lg: 8 }} 
            borderRadius={20}
            minH="600px"
            width="100%"
            bg="#FFFFFF"
            // bg="linear-gradient(to top right, #dfe9f3 0%, white 100%);"
            backdropBlur="10px"
        >
        <Flex 
            direction={{ base: "column", lg: "row" }} 
            align="center"
            height="100%"
            width="1200px"
        >
            <Box 
                flex="1" 
                textAlign={{ base: "center", lg: "left" }} 
                mb={{ base: 8, lg: 0 }}
            >
                {showContent ? 
                <>
                <Trans i18nKey="hero.title">
                <MotionHeading 
                    as="h1" 
                    size="2xl" 
                    fontWeight="bold" 
                    textColor="#364954" 
                    mb={6} 
                    zIndex={9999}
                    initial={{ y: 50, opacity: 0 }}  // Start position (off-screen below)
                    animate={{ y: 0, opacity: 1 }}   // End position (on-screen)
                    transition={{ duration: 0.5, easeIn }}    // Animation duration
                >
                {parseWithStyles(t('hero.title'))}
                </MotionHeading>
                </Trans>
                </> : <Skeleton height="60px" borderRadius={12} mb={6} width="60%" />
                }

                {showContent ? 
                <MotionText 
                    fontSize={textSize} 
                    fontWeight="medium" 
                    textColor="#364954" 
                    mb={6}
                    initial={{ y: 50, opacity: 0 }}  // Subtitle starts below
                    animate={{ y: 0, opacity: 1 }}   // Moves up to final position
                    transition={{ duration: 0.4, easeIn, delay: 0.2 }}  // Starts after heading
                >
                {t('hero.subtitle')}
                </MotionText>  : <Skeleton height="30px" borderRadius={12} mb={6} width="80%" />
                }

                {showContent ? 
                <MotionButton
                    rightIcon={<ArrowRight />}
                    size={buttonSize}
                    variant="solid"
                    as={RouterLink} 
                    to="/sign-up" 
                    borderRadius="full"
                    color="#ffffff" 
                    // bg="linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);"
                    // bg="linear-gradient(to top right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%);"
                    bg="linear-gradient(to right, #ec77ab 0%, #7873f5 100%);"
                    _hover={{
                        color:"#ffffff",
                        bg:"linear-gradient(to bottom right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                    }}
                    initial={{ y: 25, opacity: 0 }}  // Button starts below
                    animate={{ y: 0, opacity: 1 }}   // Moves up to final position
                    transition={{ duration: 0.2, easeIn, delay: 0.4 }}  // Starts after subtitle
                >
                {t('hero.cta')}
                </MotionButton>  : <Skeleton height="50px" borderRadius={12} mb={6} width="30%" />
                }
            </Box>
    
            <Box

            >
                <SimpleGrid 
                    columns={{ base: 2, md: 2 }} 
                    spacing={3} 
                >

                    {showContent ? 
                    <Box
                        height={{ base: '150px', md: '200px' }}
                        width={{ base: '150px', md: '220px' }}
                        bg="#f5f7fa"
                        // bg="linear-gradient(to top right, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);"
                        // bg="linear-gradient(to top right, #accbee -40%, #e7f0fd 100%);"
                        // bg="linear-gradient(to bottom left, #fff1eb 20%, #ace0f9 130%);"
                        borderRadius="16px"
                        // borderWidth={2}
                        p={{ base: '4', md: '6' }}
                        position="relative"
                        overflow="hidden"
                        _hover={{
                            "& > .hover-content": { opacity: 1 },
                            "& > .normal-content": { opacity: 0 },
                            "& > .hover-background": { transform: "scale(5)" }
                        }}
                        >
                        <Flex
                            className="normal-content"
                            direction="column"
                            height="100%"
                            justifyContent="space-between"
                            transition="opacity 0.3s"
                            textColor="#364954"
                        >
                            <Box align="end" flex={1}>
                                <Icon as={WandSparkles} w={8} h={8} />
                            </Box>
                            <Box align="start" fontSize={{ base: '22px', md: '26px' }}>
                                <Text fontWeight="bold">{t('heronew.resumepolish-title')}</Text>
                            </Box>
                        </Flex>
                        
                        <Flex
                            className="hover-content"
                            direction="column"
                            height="100%"
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            justifyContent="space-between"
                            p={{ base: '4', md: '6' }}
                            color="white"
                            opacity={0}
                            transition="opacity 0.5s"
                            zIndex={2}
                        >
                            <Flex
                            direction="row"
                            justifyContent="space-between"
                            >
                                <Box align="start" fontSize={{ base: '18px', md: '26px' }}>
                                    <Text fontWeight="bold">{t('heronew.resumepolish-title')}</Text>
                                </Box>
                                <Box align="end" flex={1}>
                                    <Icon as={WandSparkles} w={8} h={8} />
                                </Box>
                            </Flex>

                            <Box
                                textAlign="left"
                                mt="auto"
                                fontSize={{ base: '12px', md: '14px' }}
                                fontWeight="medium"
                            >
                                <Text>{t('heronew.resumepolish-card-desc')}</Text>
                            </Box>

                        </Flex>
                        
                        <Box
                            className="hover-background"
                            position="absolute"
                            bottom="-100px"
                            left="-100px"
                            width="100px"
                            height="100px"
                            borderRadius="50%"
                            bg="linear-gradient(to top, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                            transform="scale(0)"
                            transition="transform 0.4s ease-out"
                            zIndex={1}
                        />
                    </Box>
                    
                    : <Skeleton height={{ base: '150px', md: '200px' }} borderRadius={12} />
                    }
        
                    {showContent ? 
                    <Box
                        height={{ base: '150px', md: '200px' }}
                        width={{ base: '150px', md: '220px' }}
                        bg="#f5f7fa"
                        // bg="linear-gradient(to top right, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);"
                        // bg="linear-gradient(to top right, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);"
                        borderRadius="16px"
                        // borderWidth={2}
                        p={{ base: '4', md: '6' }}
                        position="relative"
                        overflow="hidden"
                        _hover={{
                            "& > .hover-content": { opacity: 1 },
                            "& > .normal-content": { opacity: 0 },
                            "& > .hover-background": { transform: "scale(5)" }
                        }}
                        >
                        <Flex
                            className="normal-content"
                            direction="column"
                            height="100%"
                            justifyContent="space-between"
                            transition="opacity 0.3s"
                            textColor="#364954"
                        >
                            <Box align="end" flex={1}>
                                <Icon as={Blocks} w={8} h={8} />
                            </Box>
                            <Box align="start" fontSize={{ base: '22px', md: '26px' }}>
                                <Text fontWeight="bold">{t('heronew.resumebuilder-title')}</Text>
                            </Box>
                        </Flex>
                        
                        <Flex
                            className="hover-content"
                            direction="column"
                            height="100%"
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            justifyContent="space-between"
                            p={{ base: '4', md: '6' }}
                            color="white"
                            opacity={0}
                            transition="opacity 0.5s"
                            zIndex={2}
                        >
                            <Flex
                            direction="row"
                            justifyContent="space-between"
                            >
                                <Box align="start" fontSize={{ base: '18px', md: '26px' }}>
                                    <Text fontWeight="bold">{t('heronew.resumebuilder-title')}</Text>
                                </Box>
                                <Box align="end" flex={1}>
                                    <Icon as={Blocks} w={8} h={8} />
                                </Box>
                            </Flex>

                            <Box
                                textAlign="left"
                                mt="auto"
                                fontSize={{ base: '12px', md: '14px' }}
                                fontWeight="medium"
                            >
                                <Text>{t('heronew.resumebuilder-card-desc')}</Text>
                            </Box>

                        </Flex>
                        
                        <Box
                            className="hover-background"
                            position="absolute"
                            bottom="-100px"
                            left="-100px"
                            width="100px"
                            height="100px"
                            borderRadius="50%"
                            bg="linear-gradient(to top, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                            transform="scale(0)"
                            transition="transform 0.4s ease-out"
                            zIndex={1}
                        />
                    </Box>
                    
                    : <Skeleton height={{ base: '150px', md: '200px' }} borderRadius={12} />
                    }

                    {showContent ? 
                    <Box
                        height={{ base: '150px', md: '200px' }}
                        width={{ base: '150px', md: '220px' }}
                        bg="#f5f7fa"
                        // bg="linear-gradient(to top right, #accbee -40%, #e7f0fd 100%);"
                        borderRadius="16px"
                        // borderWidth={2}
                        p={{ base: '4', md: '6' }}
                        position="relative"
                        overflow="hidden"
                        _hover={{
                            "& > .hover-content": { opacity: 1 },
                            "& > .normal-content": { opacity: 0 },
                            "& > .hover-background": { transform: "scale(5)" }
                        }}
                        >
                        <Flex
                            className="normal-content"
                            direction="column"
                            height="100%"
                            justifyContent="space-between"
                            transition="opacity 0.3s"
                            textColor="#364954"
                        >
                            <Box align="end" flex={1}>
                                <Icon as={NotebookPen} w={8} h={8} />
                            </Box>
                            <Box align="start" fontSize={{ base: '22px', md: '26px' }}>
                                <Text fontWeight="bold">{t('heronew.interviewprep-title')}</Text>
                            </Box>
                        </Flex>
                        
                        <Flex
                            className="hover-content"
                            direction="column"
                            height="100%"
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            justifyContent="space-between"
                            p={{ base: '4', md: '6' }}
                            color="white"
                            opacity={0}
                            transition="opacity 0.5s"
                            zIndex={2}
                        >
                            <Flex
                            direction="row"
                            justifyContent="space-between"
                            >
                                <Box align="start" fontSize={{ base: '18px', md: '26px' }}>
                                    <Text fontWeight="bold">{t('heronew.interviewprep-title')}</Text>
                                </Box>
                                <Box align="end" flex={1}>
                                    <Icon as={NotebookPen} w={8} h={8} />
                                </Box>
                            </Flex>

                            <Box
                                textAlign="left"
                                mt="auto"
                                fontSize={{ base: '12px', md: '14px' }}
                                fontWeight="medium"
                            >
                                <Text>{t('heronew.interviewprep-card-desc')}</Text>
                            </Box>

                        </Flex>
                        
                        <Box
                            className="hover-background"
                            position="absolute"
                            bottom="-100px"
                            left="-100px"
                            width="100px"
                            height="100px"
                            borderRadius="50%"
                            bg="linear-gradient(to top, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                            transform="scale(0)"
                            transition="transform 0.4s ease-out"
                            zIndex={1}
                        />
                    </Box> 
                    
                    : <Skeleton height={{ base: '150px', md: '200px' }} borderRadius={12} />
                    }

                    {showContent ? 
                    <Box
                        height={{ base: '150px', md: '200px' }}
                        width={{ base: '150px', md: '220px' }}
                        bg="#f5f7fa"
                        // bg="linear-gradient(to bottom left, #fff1eb 20%, #ace0f9 130%);"
                        borderRadius="16px"
                        // borderWidth={2}
                        p={{ base: '4', md: '6' }}
                        position="relative"
                        overflow="hidden"
                        _hover={{
                            "& > .hover-content": { opacity: 1 },
                            "& > .normal-content": { opacity: 0 },
                            "& > .hover-background": { transform: "scale(5)" }
                        }}
                        >
                        <Flex
                            className="normal-content"
                            direction="column"
                            height="100%"
                            justifyContent="space-between"
                            transition="opacity 0.3s"
                            textColor="#364954"
                        >
                            <Box align="end" flex={1}>
                                <Icon as={MonitorPlay} w={8} h={8} />
                            </Box>
                            <Box align="start" fontSize={{ base: '22px', md: '26px' }}>
                                <Text fontWeight="bold">{t('heronew.mockinterview-title')}</Text>
                            </Box>
                        </Flex>
                        
                        <Flex
                            className="hover-content"
                            direction="column"
                            height="100%"
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            justifyContent="space-between"
                            p={{ base: '4', md: '6' }}
                            color="white"
                            opacity={0}
                            transition="opacity 0.5s"
                            zIndex={2}
                        >
                            <Flex
                            direction="row"
                            justifyContent="space-between"
                            >
                                <Box align="start" fontSize={{ base: '18px', md: '26px' }}>
                                    <Text fontWeight="bold">{t('heronew.mockinterview-title')}</Text>
                                </Box>
                                <Box align="end" flex={1}>
                                    <Icon as={MonitorPlay} w={8} h={8} />
                                </Box>
                            </Flex>

                            <Box
                                textAlign="left"
                                mt="auto"
                                fontSize={{ base: '12px', md: '14px' }}
                                fontWeight="medium"
                            >
                                <Text>{t('heronew.mockinterview-card-desc')}</Text>
                            </Box>

                        </Flex>
                        
                        <Box
                            className="hover-background"
                            position="absolute"
                            bottom="-100px"
                            left="-100px"
                            width="100px"
                            height="100px"
                            borderRadius="50%"
                            bg="linear-gradient(to top, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                            transform="scale(0)"
                            transition="transform 0.4s ease-out"
                            zIndex={1}
                        />
                    </Box> 
                    
                    : <Skeleton height={{ base: '150px', md: '200px' }} borderRadius={12} />
                    }

                </SimpleGrid>
            </Box>


        </Flex>
        </Center>
      </Box>
    );
  };
  
  export default HeroNew;
  