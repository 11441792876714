// src/components/Orders/RerunButton.js
import React, { useState, useEffect } from 'react';
import { Button, Box, Text, Spinner, Flex, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

const RerunButton = ({ taskId, onRerunComplete }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('idle');
  const [pollInterval, setPollInterval] = useState(null);

  const checkTaskStatus = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/orders/check-task-status/${taskId}/`);
      console.log('Task status:', response.data.status); // For debugging
      return response.data.status;
    } catch (error) {
      console.error('Error checking task status:', error);
      return 'ERROR';
    }
  };

  const stopPolling = () => {
    if (pollInterval) {
      clearInterval(pollInterval);
      setPollInterval(null);
    }
  };

  const handleRerun = async () => {
    setStatus('loading');
    try {
      await axiosInstance.post(`/api/v1/orders/rerun-task/${taskId}/`);

      const interval = setInterval(async () => {
        const taskStatus = await checkTaskStatus();
        if (taskStatus === 'COMPLETED') {
          setStatus('success');
          stopPolling();
        } else if (taskStatus === 'FAILED' || taskStatus === 'ERROR') {
          setStatus('error');
          stopPolling();
        }
        // If status is still PROCESSING_STARTED or any other status, continue polling
      }, 5000); // Check every 5 seconds

      setPollInterval(interval);

    } catch (error) {
      console.error('Error rerunning task:', error);
      setStatus('error');
    }
  };

  const handleViewResult = () => {
    setIsModalOpen(false);
    onRerunComplete();
  };

  // Clean up interval on component unmount
  useEffect(() => {
    return () => stopPolling();
  }, []);

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} colorScheme="blue" variant='link'>{t('orderdetail.retry')}</Button>

      <Modal isOpen={isModalOpen} onClose={() => {
        if (status !== 'loading') {
          setIsModalOpen(false);
          stopPolling();
        }
      }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('orderdetail.retry_modaltitle')}</ModalHeader>
          <ModalBody>
            {status === 'idle' && (
              <Box>
                <Text>Please Note:</Text>
                <Text>This process will take approximately 30-60 seconds. During this time, do not close the window. Thank you for your patience!</Text>
              </Box>
            )}
            {status === 'loading' && (
              <Flex direction="column" align="center">
                <Spinner size="xl" />
                <Text mt={4}>Rerunning task... Please wait patiently and do not close this window.</Text>
              </Flex>
            )}
            {status === 'success' && (
              <Box>
                <HStack><Box as={CheckIcon} color="blue.500" mr={2} /><Text>Task retry completed.</Text></HStack>
              </Box>
            )}
            {status === 'error' && (
              <Box>
                <HStack><Box as={CloseIcon} color="red.500" mr={2} /><Text>Task retry failed.</Text></HStack>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            {status === 'idle' && (
              <>
                <Button 
                  color="#ffffff" 
                  backgroundColor="#364954" 
                  mr={3} 
                  onClick={handleRerun}
                  >
                {t('orderdetail.retry_modal_confirm')}
                </Button>
                <Button variant="ghost" onClick={() => setIsModalOpen(false)}>{t('orderdetail.retry_modal_cancel')}</Button>
              </>
            )}
            {(status === 'success' || status === 'error') && (
              <Button 
                color="#ffffff" 
                backgroundColor="#364954" 
                onClick={handleViewResult}
                >
                {t('orderdetail.retry_modal_viewresult')}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RerunButton;
