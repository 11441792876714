import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from './UserContext';
import {
  Center, Container, Box, Flex, VStack, Button,
  Image, Icon, Text,
  Menu, MenuButton, MenuList, MenuItem, MenuItemOption, MenuOptionGroup, MenuGroup, MenuDivider, IconButton,
  Divider, Link, Skeleton, 
  useColorModeValue, useToast, Spinner, Badge, useBreakpointValue,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { House, FileText, SquareLibrary, Settings, HeartHandshake, PackagePlus, MonitorCheck, Rocket } from 'lucide-react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handleSignOut } from './SignOut';
import axiosInstance from '../utils/axiosConfig';

const SkeletonMenuItem = () => (
  <Skeleton borderRadius={12}>
    <Flex align="center" p={3}>
      <Center w="24px">
        <Icon boxSize={5} />
      </Center>
      <Box ml={2} width="100px" height="20px" />
    </Flex>
  </Skeleton>
);

const SidebarMenuItem = ({ icon, children, to }) => {
  const location = useLocation();
  const isSelected = location.pathname.startsWith(to);
  const bg = useColorModeValue('rgba(247, 250, 252, 0)', 'rgba(247, 250, 252, 0)');
  const hoverBg = useColorModeValue('rgba(245, 247, 250, 1)', 'rgba(247, 250, 252, 1)');
  const selectedBg = useColorModeValue('rgba(245, 247, 250, 1)', 'rgba(247, 250, 252, 1)');
  const textColor = useColorModeValue('#2D3748', '#2D3748');

  return (
    <Link as={RouterLink} to={to} style={{ textDecoration: 'none' }} w="100%">
      <Flex
        align="center"
        p={2}
        borderRadius={16}
        bg={isSelected ? selectedBg : bg}
        _hover={{ bg: hoverBg, transform: 'translateX(3px)' }}
        transition="all 0.3s ease"
      >
        <Center w="24px" color={textColor}>
          {icon}
        </Center>
        <Text ml={2} color={textColor} fontWeight={isSelected ? 'bold' : 'normal'}>
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

// Utility function to truncate display name
const truncateDisplayName = (name, maxLength) => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + '...';
  }
  return name;
};

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [isTranslationReady, setIsTranslationReady] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [isLanguageSwitching, setIsLanguageSwitching] = useState(false);
  const { user, versionInfo, updateLastSeenVersion } = useContext(UserContext);
  const [language, setLanguage] = useState(i18n.language || 'en');
  const navigate = useNavigate();
  const toast = useToast();
  const [versionModalVisible, setVersionModalVisible] = useState(false);

  // Check if the screen size is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    axiosInstance.get('/api/v1/accounts/get-csrf-token/');
  }, []);

  useEffect(() => {
    const initializeApp = async () => {
      // Wait for translations to be ready
      await i18n.loadNamespaces(['translation']);
      setIsTranslationReady(true);

      // Add a slight delay before showing content
      setTimeout(() => setIsContentLoading(false), 300);
    };

    initializeApp();
  }, [i18n]);


  useEffect(() => {
    const syncLanguageWithServer = async () => {
      try {
        // Get the current browser language from i18n
        const browserLanguage = i18n.language;

        // Update the backend with the browser language
        const response = await axiosInstance.put('/api/v1/accounts/update-language/', {
          language_preference: browserLanguage,
        });
        
        if (response.status === 200) {
          console.log('Language preference synced with server:', response.data.language_preference);
        }
      } catch (error) {
        console.error('Error syncing language preference:', error);
      }
    };

    syncLanguageWithServer();
  }, []);

  useEffect(() => {
    if (versionInfo.update_required && versionInfo.notification_type === 'Pop') {
      setVersionModalVisible(true);
    }
  }, [versionInfo]);

  // Handle version click (for "Red Dot" notification type)
  const handleVersionClick = async () => {
    await updateLastSeenVersion();
  };

  // Handle closing the modal (for "Pop" notification type)
  const handleCloseModal = async () => {
    setVersionModalVisible(false);
    await updateLastSeenVersion();
  };

  const handleLanguageChange = async (value) => {
    if (isLanguageSwitching) return;

    setIsLanguageSwitching(true);
    try {
      const response = await axiosInstance.put('/api/v1/accounts/update-language/', {
        language_preference: value,
      });
      
      if (response.status === 200) {
        setLanguage(value);
        i18n.changeLanguage(value);
        setIsLanguageSwitching(false);
        console.log('Language preference updated:', response.data.language_preference);
      }
    } catch (error) {
      console.error('Error updating language preference:', error);
      toast({
        title: "Error updating language",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLanguageSwitching(false);
    }
  };

  if (!isTranslationReady) {
    return <FullPageLoader />; // Create a full-page loading component
  }

  if (isMobile) {
    return (
      <Box 
        position="fixed" 
        top="0" 
        left="0" 
        width="100%" 
        height="100%"
        bg="#CFD9DF" 
        color="white" 
        textAlign="center" 
        p="4" 
        zIndex="9999"
        backdropFilter="blur(20px)"
      >
        <Box
            mt={5}
            bg="#FFFFFF"
            borderRadius="16px"
            p={5}
            textColor="#364954"
        >
          <Flex
            direction="row"
            align="center"
          >
          <MonitorCheck
            size="100px"
          />
          <VStack textAlign="left" ml="6">
            <Text
                mt={3}
                fontWeight="bold"
            >
              To use your personal career copilot, please visit our desktop (PC) site.
            </Text>
            <Text
                mb={3}
                fontWeight="bold"
            >
                为了给您提供更好的浏览和使用体验，请您使用电脑打开本网站。
            </Text>
          </VStack>
          </Flex>
        </Box>
        <Box align="center" mt={isMobile ? 8 : 10} > 
            <Image src="/myCareerHelp.png" alt={t('layout.site_logo')} mx="auto" width={isMobile ? "150px" : "200px"} />
            <Text fontSize="14px" color="#737D82">Empowering Careers, One Step at a Time.</Text>
        </Box>
      </Box>
    );
  }

  const maxLength = 15; // Define your desired maximum length for the display name
  const displayName = truncateDisplayName(user.display_name, maxLength);

  return (
    <Flex minH="100vh">
      {/* Sidebar */}
      <Box
        w="280px"
        bg="rgba(255, 255, 255, 0.3)"
        height="100vh"
        p={5}
        borderRight="1px solid rgba(255, 255, 255, 0.3)"
        position="fixed"
        overflowY="auto"
        backdropFilter="blur(10px)"
      >
        <VStack spacing={4} align="stretch" height="100%">
          {/* User Profile */}
          <Skeleton isLoaded={!isContentLoading} borderRadius={16}>
          <Flex
            align="center"
            bg="rgba(245, 247, 250, 1)"
            borderRadius={16}
            p={3}
            mb={4}
            zIndex={2}
          >
            <Image
              borderRadius={12}
              boxSize="40px"
              src={user.profile_pic}
              alt={displayName}
            />
            <Box ml={2} flex={1}>
              <Text fontWeight="bold" color="#2D3748">{displayName}</Text>
            </Box>
            <Menu 
              zIndex={1000}
              onClose={() => !isLanguageSwitching}
              closeOnSelect={false}
            >
              <MenuButton
                as={IconButton}
                icon={<ChevronDownIcon />}
                variant="ghost"
                aria-label="Options"
                size="sm"
              />
              <MenuList
                bg="rgba(255, 255, 255, 1)"
                borderRadius="16px"
                boxShadow="lg"
                zIndex={1000}
              >
                {isLanguageSwitching && (
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    borderRadius={16}
                    bg="rgba(255, 255, 255, 0.8)"
                    backdropFilter="blur(5px)"
                    zIndex={1001}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner />
                  </Box>
                )}
                <MenuOptionGroup defaultValue={language} title={t('layout.languages')} type='radio' onChange={handleLanguageChange}>
                  <MenuItemOption value='zh' bg="rgba(255, 255, 255, 0)" _hover={{ bg: "#f5f7fa"}}>{t('layout.chinese')}</MenuItemOption>
                  <MenuItemOption value='en' bg="rgba(255, 255, 255, 0)" _hover={{ bg: "#f5f7fa"}}>{t('layout.english')}</MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuGroup title={t('layout.system')}>
                  <MenuItem onClick={() => handleSignOut(navigate, toast)} bg="rgba(255, 255, 255, 0)" _hover={{ bg: "#f5f7fa"}}>{t('layout.sign_out')}</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Flex>
          </Skeleton>

          {/* Menu Items */}
          <VStack spacing={2} align="stretch" flex={1} zIndex={1}>
            {isContentLoading ? (
              <>
                <SkeletonMenuItem borderRadius={16} />
                <SkeletonMenuItem borderRadius={16} />
                <SkeletonMenuItem borderRadius={16} />
                <SkeletonMenuItem borderRadius={16} />
                <SkeletonMenuItem borderRadius={16} />
              </>
            ) : (
              <>
                <SidebarMenuItem icon={<Icon as={House} boxSize={5} />} to="/gettingstarted">{t('layout.gettingstarted')}</SidebarMenuItem>
                <SidebarMenuItem icon={<Icon as={FileText} boxSize={5} />} to="/resumes">{t('layout.resume')}</SidebarMenuItem>
                <SidebarMenuItem icon={<Icon as={SquareLibrary} boxSize={5} />} to="/service-orders">{t('layout.orders')}</SidebarMenuItem>
                <SidebarMenuItem icon={<Icon as={Settings} boxSize={5} />} to="/settings">{t('layout.settings')}</SidebarMenuItem>
                <SidebarMenuItem icon={<Icon as={HeartHandshake} boxSize={5} />} to="/feedback">
                  <Flex alignItems="center" justifyContent="center">
                  {t('layout.help_support')} <Badge ml={2} size="sm" color="#FFF5DF" bg="#D5BDA7" borderRadius="6" >{t('layout.beta')}</Badge>
                  </Flex>
                </SidebarMenuItem>
              </>
            )}
            <Divider my="3" borderColor="#CFD9DF" />
            <Skeleton isLoaded={!isContentLoading} borderRadius={12} >
              <SidebarMenuItem icon={<Icon as={PackagePlus} boxSize={5} />} to="/credits">
                <Text>{t('resumepage.service_creditbalance_title')}: {user.credit_balance !== undefined ? user.credit_balance : 'Loading...'} </Text>
              </SidebarMenuItem>
            </Skeleton>
          </VStack>

          {/* Footer */}
          <Box mt="auto">
            <Skeleton isLoaded={!isContentLoading} borderRadius={12}>
              <a href='/'>
              <Image src="/myCareerHelp.png" 
                alt={t('layout.site_logo')} 
                mb={2} 
                align="left" 
                width="180px" 
                borderRadius={5}
              />
              </a>
            </Skeleton>
            <Flex mt={5} justify="space-between" fontSize="xs" color="#737D82">
              <Skeleton isLoaded={!isContentLoading} borderRadius={12}>
                <Link
                  onClick={handleVersionClick} 
                  position="relative"
                  href="https://mycareerhelp-ai.gitbook.io/mycareerhelp.ai/"
                  target="_blank"
                >
                v{versionInfo.current_version || '1.0.0'}
                {versionInfo.update_required && versionInfo.notification_type === 'Red Dot' && (
                <span
                    style={{
                      position: 'absolute',
                      top: '1px',
                      right: '-5px',
                      height: '4px',
                      width: '4px',
                      backgroundColor: 'red',
                      borderRadius: '50%',
                    }}
                  />
                )}
                </Link>
              </Skeleton>
              <Skeleton isLoaded={!isContentLoading} borderRadius={12}><Link href="/terms" target="_blank" rel="noopener noreferrer">{t('layout.terms_of_use')}</Link></Skeleton>
              <Skeleton isLoaded={!isContentLoading} borderRadius={12}><Link href="/privacy" target="_blank" rel="noopener noreferrer">{t('layout.privacy_policy')}</Link></Skeleton>
            </Flex>
          </Box>
        </VStack>
      </Box>

      {/* Modal for "Pop" notification type */}
      {versionModalVisible && (
        <Modal 
          isOpen={versionModalVisible} 
          onClose={handleCloseModal} 
          isCentered
          closeOnOverlayClick={false} 
          closeOnEsc={false}
          size="sm"
        >
          <ModalOverlay 
          backdropFilter="blur(5px)" 
          />
          <ModalContent
          borderRadius={16}
          >
            <ModalHeader
              textAlign="center"
            >New Update Available! (v{versionInfo.current_version})</ModalHeader>
            <ModalBody>
              <Box
                textAlign="center"
                fontSize="14px"
                textColor="#364954"
              >
                <Center mb="5">
                <Rocket
                  size="150px"
                />
                </Center>
                <Text>Click 'Update' to apply the latest features.</Text>
                <Text my="1">You can check the detailed changelogs at <Link fontWeight="bold" textColor="#84B1CB" href="https://mycareerhelp-ai.gitbook.io/mycareerhelp.ai/" target="_blank" rel="noopener noreferrer">here</Link>.</Text>
              </Box>
              <Box
                align="center"
                mt={5}
                mb={5}
              >
                <Button 
                  onClick={handleCloseModal}
                  color="#ffffff" 
                  backgroundColor="#364954"
                  borderRadius="16px"
                  width="100%"
                  _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                  }}
                >
                  Update
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* Main Content */}
      <Box flex={1} height="100vh"
      >
        <Box 
          direction="column"
          p={0} 
          ml="280px"
          mr="16px"
          height="100%"
        >
        <Container 
          maxW="1920px" 
          p={0} 
          flex={1}
          display="flex"
          flexDirection="column"
          height="100%"
        >
          {children}
        </Container>
        </Box>
      </Box>
    </Flex>
  );
};

export default Layout;

// Create a full-page loading component
const FullPageLoader = () => (
  <Flex height="100vh" width="100vw" alignItems="center" justifyContent="center">
    <Spinner size="xl" />
  </Flex>
);
