import React, { useState, useEffect } from 'react';
import { 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, 
  ModalFooter, Button, Input, useToast 
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

const UpdateDisplayNameModal = ({ isOpen, onClose, onDisplayNameUpdated }) => {
  const [displayName, setDisplayName] = useState('');
  const [originalDisplayName, setOriginalDisplayName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const toast = useToast();
  const { t, } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      const fetchUserProfile = async () => {
        setIsFetching(true);
        try {
          const response = await axiosInstance.get('/api/v1/accounts/profile/');
          setDisplayName(response.data.display_name);
          setOriginalDisplayName(response.data.display_name);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          toast({
            title: "Error",
            description: "Failed to fetch user profile",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        } finally {
          setIsFetching(false);
        }
      };

      fetchUserProfile();
    }
  }, [isOpen, toast]);

  const handleUpdateDisplayName = async () => {
    setIsLoading(true);
    try {
      // Update display name
      const response = await axiosInstance.put('/api/v1/accounts/update-display-name/', { display_name: displayName });
      
      toast({
        title: t('gettingstarted.updatedisplayname.toast-success'),
        description: t('gettingstarted.updatedisplayname.toast-successdes'),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      // Check and complete onboarding task
      const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-display-name-task/');
      
      if (onboardingResponse.status === 200) {
        toast({
          title: t('gettingstarted.updatedisplayname.toast-complete'),
          description: `${t('gettingstarted.updatedisplayname.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('gettingstarted.updatedisplayname.toast-complete-des2')}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }

      onDisplayNameUpdated(displayName); // Notify parent component
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error updating display name:', error);
      toast({
        title: "Error",
        description: "Failed to update display name",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isNameChanged = displayName !== originalDisplayName;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay 
        backdropFilter="blur(10px)"
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
        _hover={{
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <ModalHeader>{t('gettingstarted.updatedisplayname.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input 
            placeholder={t('gettingstarted.updatedisplayname.modalplaceholder')}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            isDisabled={isFetching}
          />
        </ModalBody>
        <ModalFooter>
          <Button 
            mr={3} 
            type="submit" 
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="12px"
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
            onClick={handleUpdateDisplayName} 
            isLoading={isLoading}
            isDisabled={isFetching || !isNameChanged}
          >
            {t('gettingstarted.updatedisplayname.save')}
          </Button>
          <Button 
            variant="ghost"
            onClick={onClose}
            borderRadius="12px"
          >{t('gettingstarted.updatedisplayname.cancel')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateDisplayNameModal;