import React, { useState, useEffect } from 'react';
import {
  Box, Heading,
  Text, Button,
  VStack, Divider, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Tooltip,
  HStack, Skeleton, SkeletonText, Spinner, useDisclosure
} from '@chakra-ui/react';
import { LuPlusCircle, LuHelpCircle } from "react-icons/lu";
import { FilePenLine } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import ExperienceEntry from './ExperienceEntry';
import ExperienceForm from './ExperienceForm';
import { useTranslation } from 'react-i18next';
import WriteWithAIProDrawer from '../ResumeWriteWithAISection/WriteWithAIProDrawer';

const Experience = ({ resumeId, updateResumeState }) => {
  const { t } = useTranslation();  
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTargetJobFetching, setIsTargetJobFetching] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sortExperiences = (experiences) => {
    return experiences.sort((a, b) => {
      // First, check if either of the entries have is_visible as false
      if (!a.is_visible && b.is_visible) {
        return 1; // a should come after b
      }
      if (a.is_visible && !b.is_visible) {
        return -1; // a should come before b
      }
      // If both are visible or both are not visible, sort by end date
      const aEnd = a.end === t('Present') ? new Date() : new Date(a.end);
      const bEnd = b.end === t('Present') ? new Date() : new Date(b.end);
      return bEnd - aEnd;
    });
  };

  useEffect(() => {
    fetchExperiences();
  }, [resumeId]);

  const fetchExperiences = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/experiences/?resume=${resumeId}`);
      setExperiences(sortExperiences(response.data));
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch experiences');
      setLoading(false);
    }
  };

  const fetchTargetJob = async (resumeId) => {
    setIsTargetJobFetching(true);
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const targetJobData = response.data;
        const hasTargetJob = (
          targetJobData.target_job_title &&
          targetJobData.target_job_responsibilities &&
          targetJobData.target_job_requirements
        );

        if (hasTargetJob) {
          return {
            title: targetJobData.target_job_title,
            responsibilities: targetJobData.target_job_responsibilities,
            requirements: targetJobData.target_job_requirements,
            updated_at: targetJobData.target_job_fields_updated_at
          };
        }
      }
      return null;
    } catch (error) {
      console.error('Error fetching target job:', error);
      throw error;
    } finally {
      setIsTargetJobFetching(false);
    }
  };

  const handleAdd = async (newExperience) => {
    try {
      const response = await axiosInstance.post('/api/v1/resumes/experiences/', {
        ...newExperience,
        resume: resumeId
      });
      setExperiences(sortExperiences([...experiences, response.data]));
      setIsAdding(false);
      toast({
        title: t('wrk.add_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to add experience');
      toast({
        title: t('wrk.add_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleUpdate = async (updatedExperience) => {
    try {
      const response = await axiosInstance.put(`/api/v1/resumes/experiences/${updatedExperience.id}/`, {
        ...updatedExperience,
        resume: resumeId
      });
      setExperiences(sortExperiences(experiences.map(exp => exp.id === updatedExperience.id ? response.data : exp)));
      toast({
        title: t('wrk.update_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to update experience');
      toast({
        title: t('wrk.update_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/api/v1/resumes/experiences/${id}/`);
      setExperiences(experiences.filter(exp => exp.id !== id));
      setIsDeleteModalOpen(false);
      toast({
        title: t('wrk.delete_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to delete experience');
      toast({
        title: t('wrk.delete_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const ExperienceSkeleton = () => (
    <Box>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='3' />
      <SkeletonText mt='4' noOfLines={2} spacing='4' />
      <Skeleton height='20px' mt='4' />
    </Box>
  );

  if (loading) return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
    >
      <Box
        p="4"
      >
        <HStack align="flex-start" justify="space-between">
          <Skeleton height="24px" width="150px" />
          <Skeleton height="24px" width="24px" />
        </HStack>
        <Divider my={4} />
        <VStack spacing={4} align="stretch">
          <ExperienceSkeleton />
          <ExperienceSkeleton />
          <ExperienceSkeleton />
        </VStack>
      </Box>
    </Box>
  );

  if (error) return <Box>{error}</Box>;

  return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
      transition="all 0.3s ease"
      _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        p={4}
        pb={2}
      >
        <HStack align="flex-start" justify="space-between">
          <Heading as="h2" fontSize="lg" mb={2} textColor="#364954">{t('wrk.section_title')}</Heading>
          <Tooltip 
            hasArrow 
            borderRadius={12} 
            bg='#CFD9DF' 
            color='#364954' 
            placement='top'
            label={
              <Box p={1}>
              {t('wrk.section_intro')}
              </Box>
            } 
          >
            <Text><LuHelpCircle /></Text>
          </Tooltip>
        </HStack>
        {experiences.length > 0 && <Divider my={1} />}
        <VStack spacing={0} align="stretch">
        {experiences.length === 0 ? (
          <Text
            fontSize="sm"
            textColor="#737D82"
            mt="2"
            mb="1"
          >
          {t('wrk.norecord')}</Text>
        ) : (
        experiences.map((experience, index) => (
            <React.Fragment key={experience.id}>
              <ExperienceEntry
                experience={experience}
                onUpdate={handleUpdate}
                onDelete={() => {
                  setExperienceToDelete(experience);
                  setIsDeleteModalOpen(true);
                }}
                resumeId={resumeId}
              />
              {index < experiences.length - 1 && <Divider my={1} />}
            </React.Fragment>
          ))
        )}
        </VStack>
      </Box>
      {experiences.length > 0}
        {isAdding ? (
          <Box px={4}>
          <ExperienceForm 
            onSubmit={handleAdd} 
            onCancel={() => setIsAdding(false)} 
            resumeId={resumeId}
          />
          </Box>
        ) : (
          <Box 
            borderBottomRadius="12px"
            px="4"
            py="2"
          >
            <Button 
              leftIcon={<LuPlusCircle />} 
              onClick={() => setIsAdding(true)} 
              variant="ghost" 
              size="sm"
              borderRadius="12px"
              textColor="#364954"
            >
              {t('wrk.add')}
            </Button>
            <Button 
              ml="4"
              leftIcon={<FilePenLine size="14px" />} 
              variant="ghost" 
              size="sm"
              borderRadius="12px"
              textColor="#364954"
              isLoading={isTargetJobFetching}
              onClick={async () => {
                  try {
                    const targetJob = await fetchTargetJob(resumeId);
                    if (targetJob !== null) {
                      onOpen();
                    } else {
                      toast({
                        title: t('probtn.wrk.targetjobcheck-fail-toast-title'),
                        description: t('probtn.wrk.targetjobcheck-fail-toast-desc'),
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                      });
                    }
                  } catch (error) {
                    console.error('Error fetching target job:', error);
                    toast({
                      title: "Error",
                      description: "Failed to check target job. Please try again.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                      position: 'top-right',
                    });
                  } finally {
                    setIsTargetJobFetching(false);
                  }
                }}
                >
              {t('probtn.wrk.createwithai')}
            </Button>
          </Box>
        )}

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay
          backdropFilter="blur(10px)"
          />
        <ModalContent
          borderRadius={12}
          boxShadow="lg"
          background="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(10px)"
          transition="all 0.1s ease"
          _hover={{
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
          }}
          >
          <ModalHeader>{t('wrk.delete_modaltitle')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t('wrk.delete_modalbody')}</Text>
            <Text mt="2" fontSize="14px" textColor="#737D82">{t('wrk.delete_notice')}</Text>
          </ModalBody>
          <ModalFooter>
            <Button 
              variant="ghost"
              mr={3} 
              borderRadius="12px"
              onClick={() => setIsDeleteModalOpen(false)}
              isDisabled={isDeleting}
            >
              {t('wrk.cancel')}
            </Button>
            <Button 
              colorScheme="red" 
              borderRadius="12px"
              onClick={() => handleDelete(experienceToDelete.id)}
              isLoading={isDeleting}
              loadingText={t('wrk.deleting')}
            >
              {isDeleting ? <Spinner size="sm" /> : t('wrk.delete')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <WriteWithAIProDrawer 
        isOpen={isOpen} 
        onClose={onClose} 
        resumeId={resumeId} 
        experiences={experiences}
        fetchExperiences={fetchExperiences}
      />
    </Box>
  );
};

export default Experience;