import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Text, 
    Spinner,
    VStack, HStack, Progress,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

const ServiceResultOfContentAnalysis = ({ order }) => {
    const [analysisResult, setAnalysisResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchAnalysisResult = async () => {
        try {
            const response = await axiosInstance.get(`/api/v1/services/copilot/analysis/by-order/${order.order.id}/`);
            setAnalysisResult(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch analysis result');
            setLoading(false);
        }
        };

        if (order && order.order.id) {
        fetchAnalysisResult();
        }
    }, [order]);

    const parseContent = (content) => {
        if (!content) return null;
        try {
            return JSON.parse(content.replace(/'/g, '"'));
        } catch (error) {
            console.error('Failed to parse content:', error);
            return content; // Return the original content if parsing fails
        }
    };

    if (loading) {
        return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Spinner />
        </Box>
        );
    }

    if (error) {
        return (
        <Box>
            <Text color="error">{error}</Text>
        </Box>
        );
    }

    if (!analysisResult) {
        return (
        <Box>
            <Text>{t('caorder.nodata')}</Text>
        </Box>
        );
    }

    // Check if analysis_result is null
    if (analysisResult.analysis_result === null) {
        return (
            <Alert status="info" borderRadius={16}>
                <AlertIcon />
                <AlertTitle mr={2}>{t('caorder.resultnull-title')}</AlertTitle>
                <AlertDescription>
                {t('caorder.resultnull-desc')}
                </AlertDescription>
            </Alert>
        );
    }

    // Parse the generated_content string into an object
    const parsedOriginalContent = parseContent(analysisResult.original_content);
    const parsedAnalysisResult = analysisResult.analysis_result;

    return (
        <Box>
            <VStack spacing={6} align="stretch" textColor="#364954">
                
                <Box>
                    <Text fontWeight="bold" mb={2} fontSize="14px">{t('caorder.originalcontent')}:</Text>
                    <Box fontSize="14px" p={2} bg="#f5f7fa" borderRadius="12px">
                        <Text whiteSpace="pre-wrap">{parsedOriginalContent}</Text>
                    </Box>
                </Box>
                
                <Box>
                    <Text fontWeight="bold" fontSize="14px">{t('caorder.overallrating')}:</Text>
                    <HStack>
                        <Text>{parsedAnalysisResult.overall_rating * 10} / 100</Text>
                    </HStack>
                </Box>
                
                <Box>
                    <Text fontWeight="bold" fontSize="14px">{t('caorder.aspects')}:</Text>
                    {parsedAnalysisResult.rating_aspects.map((aspect, index) => (
                        <Box key={index} mt={4} mb={8} textColor="#364954">
                            <Text fontWeight="semibold">{aspect.aspect_name}</Text>
                            <Progress 
                                width="100%"
                                value={parseFloat(aspect.rating) * 50} 
                                my={2}
                                height={2}
                                borderRadius="full"
                                colorScheme={
                                    aspect.rating < 0.5
                                        ? "red"
                                        : aspect.rating < 0.8
                                        ? "orange"
                                        : aspect.rating < 1.2
                                        ? "yellow"
                                        : "green"
                                }
                            />
                            <Box
                                fontSize="14px"
                                p={1}
                            >
                                <Text 
                                >
                                    {aspect.explanation}
                                </Text>
                            </Box>
                            <Box 
                                fontSize="14px"
                                fontWeight="medium"
                                p={1}
                            >
                                <Text as="cite">{t('copilotdrawer.evaluation.improvement-title')}: </Text> 
                                <Text as="cite">{aspect.improvement}</Text>
                            </Box>
                        </Box>
                    ))}
                </Box>
                
            </VStack>
        </Box>
    );
};

export default ServiceResultOfContentAnalysis;