import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, VStack, Spacer, HStack, useDisclosure, Skeleton } from '@chakra-ui/react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { fetchUserProfile, fetchAllTransactionHistory } from '../../utils/orderApi';
import { subDays, format } from 'date-fns';
import TopupModal from './TopupModal';
import { useTranslation } from 'react-i18next';

const CurrentBalance = () => {
  const [balance, setBalance] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const profile = await fetchUserProfile();
        setBalance(profile.credit_balance);

        const transactions = await fetchAllTransactionHistory();

        // Initialize data structure for the last 15 days
        const last15Days = Array.from({ length: 15 }, (_, i) => {
          const date = subDays(new Date(), i);
          return {
            date: format(date, 'yyyy-MM-dd'),
            [t('credits.topup')]: 0,
            [t('credits.consume')]: 0,
          };
        }).reverse();

        // Aggregate transactions by date and type
        transactions.forEach(order => {
          const date = format(new Date(order.created_at), 'yyyy-MM-dd');
          const transaction = last15Days.find(item => item.date === date);
          if (transaction) {
            if (order.order_type === 'purchase' && order.order_status === 'completed') {
              transaction[t('credits.topup')] += order.amount;
            } else if (order.order_type === 'service' || order.order_type === 'chat' || order.order_type === 'resumeslot') {
              transaction[t('credits.consume')] += order.amount;
            }
          }
        });

        setChartData(last15Days);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Custom Tooltip component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box 
          p={2} 
          bg="white" 
          borderRadius="12px"
          boxShadow="0 1px 20px rgba(0,0,0,0.15)"
        >
          <Text 
            fontWeight="bold"
            fontSize="14px"
          >{label}</Text>

          {payload.map((entry, index) => (
            <Text 
                key={`item-${index}`} 
                color={entry.color}
                fontSize="12px"
            >
              {entry.name}: {entry.value}
            </Text>
          ))}
        </Box>
      );
    }
  
    return null;
  };

  return (
    <Box 
      borderRadius="16px" 
      bg="#ffffff"
      p={4}
      transition="all 0.3s ease"
      _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Text 
        fontSize="16px" 
        fontWeight="bold" 
        mb={4}
        textColor="#364954"
      >
        {t('credits.currentbalance.title')}
      </Text>

      <Flex>
        <VStack align="start" flex={1}>
          <Spacer />
          <HStack alignItems="flex-end">
            {isLoading ? (
              <Skeleton height="40px" width="100px" borderRadius="16px" />
            ) : (
              <>
                <Text fontSize="40px" fontWeight="bold" textColor="#364954">{balance}</Text>
                <Text fontSize="16px" ml="1" mb="2" alignSelf="flex-end" textColor="#737D82">{t('credits.currentbalance.credits')}</Text>
              </>
            )}
          </HStack>
          {isLoading ? (
            <Skeleton height="32px" width="50%" borderRadius="12px" />
          ) : (
            <Button 
              onClick={onOpen}
              variant='solid'
              minW="50%"
              backgroundColor="#364954"
              color="#ffffff"
              size="sm"
              borderRadius="12px"
              _hover={{
                color: "#ffffff",
                backgroundColor: "#82B2CA"
              }}
            >
              {t('credits.currentbalance.topupbutton')}
            </Button>
          )}
        </VStack>

        <Box flex={3} height="150px">
          {isLoading ? (
            <Skeleton height="100%" borderRadius="16px" />
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData} fontSize="14px">
                <CartesianGrid strokeDasharray="2" vertical={false} />
                <XAxis dataKey="date" fontSize="12px" />
                <YAxis fontSize="12px" />
                <Tooltip content={<CustomTooltip />}/>
                <Bar dataKey={t('credits.topup')} fill="#364954" />
                <Bar dataKey={t('credits.consume')} fill="#82B2CA" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Flex>

      <TopupModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default CurrentBalance;