// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import AppRoutes from './AppRoutes';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';
import { UserProvider } from './components/UserContext';
import axiosInstance from './utils/axiosConfig';
import WeChatNotice from './components/WeChatNotice'; 

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    axiosInstance.get('/api/v1/accounts/get-csrf-token/');
    i18n.loadNamespaces('homepage');
  }, [i18n]);
  
  return (
    <ChakraProvider>
      <I18nextProvider i18n={i18n}>
        <Router>
          <UserProvider>
            <div className="App">
              <WeChatNotice /> 
              <AppRoutes />
            </div>
          </UserProvider>
        </Router>
      </I18nextProvider>
    </ChakraProvider>
  );
}

export default App;
