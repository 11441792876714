// src/components/ResumeCopilotDrawer/EvaluationResult.js
import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  Button,
  Text,
  Box,
  Flex,
  useDisclosure,
  Center,
  Skeleton, SkeletonText,
  useToast,
  Tooltip
} from '@chakra-ui/react';
import { Paintbrush, CopyCheck, FileClock } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from './ConfirmationModal';
import EditContentModal from './EditContentModal';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserContext';


const RetouchResult = ({ session, entryId, entryType, entryUpdatedAt, data, latestEvaluationResult, onUpdateEntry, isEntryUpdating }) => {
    const [generationResults, setGenerationResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const { t } = useTranslation();  
    const toast = useToast();
    const [user, setUser] = useState(null);
    const { updateCreditBalance } = useContext(UserContext);
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const [selectedContent, setSelectedContent] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
  
    useEffect(() => {
      if (session) {
        fetchGenerationResults();
      }
    }, [session]);
  
    const fetchGenerationResults = async () => {
        setIsFetching(true);
        try {
          const response = await axiosInstance.get(`/api/v1/services/copilot/${session.id}/message/`, {
            params: { message_type: 'generation' }
          });
          setGenerationResults(response.data.map(message => ({
            ...message.related_content,
            timestamp: message.timestamp,
            id: message.id
          })));
        } catch (error) {
          console.error('Error fetching generation results:', error);
        } finally {
          setIsFetching(false);
        }
      };
  
    const handleCreateGeneration = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.post(`/api/v1/services/copilot/${session.id}/generate/`, {
          entryId,
          entryType,
          resume_title: data.resume_title,
          resume_description: data.resume_description,
          target_job_title: data.target_job_title,
          target_job_responsibilities: data.target_job_responsibilities,
          target_job_requirements: data.target_job_requirements,
          analysis_result: latestEvaluationResult,
        });

        const {new_credit_balance} = response.data;
        setUser(prevUser => ({
            ...prevUser,
            credit_balance: new_credit_balance
        }));
        updateCreditBalance(response.data.new_credit_balance);
        // Fetch updated results after creating a new analysis
        await fetchGenerationResults();
        toast({
            title: t('copilotdrawer.generation.createsuccess.toast-title'),
            description: t('copilotdrawer.generation.createsuccess.toast-desc'),
            status: "success",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
        })

      } catch (error) {
        console.error('Error creating generation:', error);

        if (error.response && error.response.data && error.response.data.error === "Insufficient credits") {
            toast({
              title: t('insufficientcredits.toast-title'),
              description: t('insufficientcredits.toast-desc'),
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          } else {
            toast({
              title: "Error",
              description: "Failed to create generation. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
            }
      } finally {
        setIsLoading(false);
        onConfirmClose();
      }
    };

    const handleEditClick = (content) => {
        setSelectedContent(content);
        onEditOpen();
      };

    const handleEditConfirm = async (editedContent) => {
        setIsUpdating(true);
    try {
        const response = await axiosInstance.patch(`/api/v1/resumes/${entryType}s/${entryId}/`, {
        description: editedContent
        });
        
        if (response.status === 200) {
            setIsUpdating(true);
            onUpdateEntry(response.data);
            setIsUpdating(false);
        }
    } catch (error) {
        console.error('Error updating entry:', error);
        toast({
        title: "Error",
        description: "Failed to update entry. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        });
    } finally {
        setIsUpdating(false);
        onEditClose();
    }
    };

    const isResultExpiredByTargetJob = (result) => {
        if (!result || !result.timestamp || !data || !data.target_job_fields_updated_at) {
            return false;
        }

        const resultTimestamp = new Date(result.timestamp);
        const targetJobUpdatedAt = new Date(data.target_job_fields_updated_at);

        return resultTimestamp < targetJobUpdatedAt;
    };

    const isResultExpiredByEntry = (result) => {
        if (!result || !result.timestamp || !entryUpdatedAt ) {
            return false;
        }

        const resultTimestamp = new Date(result.timestamp);
        const entryUpdated = new Date(entryUpdatedAt);

        return resultTimestamp < entryUpdated;
    };


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const RetouchResultSkeleton = () => (
        <Box borderWidth="1px" borderRadius="16px" p={4} mb={4}>
            <Skeleton height="20px" width="200px" mb={3} />
            <Skeleton height="30px" width="300px" mb={6} />
            {[1, 2, 3].map((_, index) => (
            <Box key={index} mb={8}>
                <Skeleton height="20px" width="150px" mb={2} />
                <Skeleton height="8px" mb={2} />
                <SkeletonText mt="4" noOfLines={3} spacing="4" />
                <SkeletonText mt="4" noOfLines={2} spacing="4" />
            </Box>
            ))}
        </Box>
    );
  
    const renderRetouchResult = (result) => {
        if (!result || !result.explanation) {
          return null;
        }
      
        return (
            <Box key={result.id} 
                borderWidth="1px" 
                borderRadius="16px" 
                p={4} 
                mb={2}
                mt={0}
            >
            <Flex
                direction="row"
                alignItems="center"
                mb={3}
            >
                <Text fontSize="14px" color="gray.500">
                {t('copilotdrawer.generation.analyzedon')}: {formatDate(result.timestamp)}
                </Text>

                { (isResultExpiredByTargetJob(result) || isResultExpiredByEntry(result)) &&
                <Box ml="3">
                    <Tooltip 
                        hasArrow 
                        borderRadius={12}
                        bg='#CFD9DF' 
                        color='#364954' 
                        placement='top'
                        label={
                            <Box p={1} fontSize="14px" fontWeight="medium" textColor="#364954">
                                <Text fontWeight="bold">{t('copilotdrawer.generation.expiration-title')}</Text>
                                <Text>{t('copilotdrawer.generation.expiration-notice')}</Text>
                            </Box>
                        } 
                    >  
                        <FileClock
                            size="14px"
                            color="#737D82"
                        />
                    </Tooltip>
                </Box>
                }

            </Flex>
                <Box mb={8} textColor="#364954">
                    <Flex
                        direction="row"
                        align="center"
                        justifyContent="space-between"
                        mb={2}                    
                    >
                        <Text fontWeight="bold">{t('copilotdrawer.generation.after')}:</Text>
                        <Button
                            variant="ghost"
                            size="sm"
                            leftIcon={<CopyCheck 
                                color="#364954"
                                size="14px"
                                />}
                            onClick={() => handleEditClick(result.retouched_job_description)}
                            borderRadius="16px"
                            textColor="#364954"
                            isLoading={ isUpdating || isEntryUpdating }
                            isDisabled={ isLoading || isUpdating || isEntryUpdating }
                            >
                            {t('copilotdrawer.generation.btn-use-it')}
                        </Button>
                    </Flex>
                    <Box
                        fontSize="14px"
                        mb="4"
                        p={3}
                        bg="#f5f7fa"
                        borderRadius="8px"
                        whiteSpace="pre-wrap"
                    >
                        <Text>{result.retouched_job_description}</Text>
                    </Box>

                    <Text fontWeight="bold" mb={2}>{t('copilotdrawer.generation.explanation')}:</Text>
                    <Text fontSize="14px" mb={4}>{result.explanation}</Text>

                </Box>
            </Box>
        );
      };
  
    return (
      <VStack spacing={4} align="stretch">
        {isFetching ? (
        <Box textAlign="center">
            <>
            <RetouchResultSkeleton />
            <RetouchResultSkeleton />
            </>
        </Box>
        ) : generationResults.length > 0 ? (
            <>
            <Button 
                leftIcon={<Paintbrush 
                    color="#364954"
                />}
                onClick={onConfirmOpen} 
                isLoading={ isLoading }
                isDisabled={ isLoading || isUpdating || isEntryUpdating }
                borderRadius="16px"
                textColor="#364954"
                mt={4}
            >
                {t('copilotdrawer.generation.create-new-btn')}
            </Button>
            {generationResults.map(result => renderRetouchResult(result))}
            </>
        ) : (
        <Center 
            height="200px"
            bg="#f5f7fa"
            borderRadius={16}
            mt={4}
        >
            <VStack>
            <Button 
                leftIcon={<Paintbrush 
                    color="#364954"
                />}
                onClick={onConfirmOpen} 
                isLoading={ isLoading }
                isDisabled={ isLoading || isUpdating || isEntryUpdating }
                borderRadius="16px"
                textColor="#364954"
            >
                {t('copilotdrawer.generation.create-btn')}
            </Button>
            <Text
                fontSize="14px"
                textColor="#737D82"
            >
                {t('copilotdrawer.generation.nodata')}
            </Text>
            </VStack>
        </Center>
        )}
        <ConfirmationModal
            isOpen={isConfirmOpen}
            isLoading={isLoading}
            onClose={onConfirmClose}
            onConfirm={handleCreateGeneration}
            title={t('copilotdrawer.generation.confirmmodal-title')}
            message={t('copilotdrawer.generation.confirmmodal-desc')}
        />
        <EditContentModal
            isOpen={isEditOpen}
            isUpdating={isUpdating}
            onClose={onEditClose}
            onConfirm={handleEditConfirm}
            originalTitle={data.resume_title}
            originalContent={data.resume_description}
            content={selectedContent}
        />
      </VStack>
    );
  };
  
  export default RetouchResult;