import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ProGenerateSummaryConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();  

    return (
        <Modal 
          isOpen={isOpen} 
          onClose={onClose}
          size="lg"
        >
            <ModalOverlay 
                backdropFilter="blur(10px)"
            />
            <ModalContent
                borderRadius={16}
                boxShadow="lg"
                background="rgba(255, 255, 255, 1)"
                transition="all 0.1s ease"       
            >
                <ModalHeader>{t('prodrawer.finalconfirm-modal.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                    {t('prodrawer.finalconfirm-modal.desc')}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button 
                      variant="ghost" 
                      mr={3} 
                      borderRadius="16px"
                      onClick={onClose}
                    >
                      {t('prodrawer.finalconfirm-modal.btn-cancel')}
                    </Button>
                    <Button 
                      onClick={onConfirm}
                      color="#ffffff" 
                      backgroundColor="#364954"
                      borderRadius="16px"
                      _hover={{
                      color:"#ffffff",
                      backgroundColor:"#82B2CA"
                      }}
                    >
                      {t('prodrawer.finalconfirm-modal.btn-confirm')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ProGenerateSummaryConfirmationModal;