// src/components/ResumeCopilotDrawer/ConfirmationModal.js
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button, Box, Text
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, isLoading, onClose, onConfirm, title, message }) => {
    const { t } = useTranslation();  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        backdropFilter="blur(10px)"
     />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.1s ease"
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            {message}
            <Box>
                <Text fontSize="14px" textColor="#737D82" mt={4}>
                {t('copilotdrawer.evaluation.confirmmodal-notice')}
                </Text>
            </Box>
        </ModalBody>
        <ModalFooter>
            <Button 
                mr={3} 
                onClick={onConfirm}
                isLoading={isLoading}
                isDisabled={isLoading}
                color="#ffffff" 
                backgroundColor="#364954"
                borderRadius="16px"
                _hover={{
                  color:"#ffffff",
                  backgroundColor:"#82B2CA"
                }}
            >
                {t('copilotdrawer.evaluation.confirmmodal-btn-confirm')}
            </Button>
            <Button 
                borderRadius="16px"
                variant="ghost" 
                onClick={onClose}
                isDisabled={isLoading}
            >
                {t('copilotdrawer.evaluation.confirmmodal-btn-cancel')}
            </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;