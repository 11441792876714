// SkeletonLoading.js
import React from 'react';
import { Box, Skeleton, SkeletonText, Grid } from '@chakra-ui/react';

const SkeletonLoading = () => {
  return (
    <Box>
      <Skeleton height="40px" width="300px" mb="4" />
      <Skeleton height="60px" mb="4" />
      <SkeletonText mt="4" noOfLines={3} spacing="4" />
      <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={3} mt="4">
        {[...Array(4)].map((_, index) => (
          <Skeleton key={index} height="150px" borderRadius="16px" />
        ))}
      </Grid>
    </Box>
  );
};

export default SkeletonLoading;