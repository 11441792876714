// src/components/HomePage/HomePageLayout.js
import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const HomePageLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Box 
        as="main" 
        pt="60px" 
        flex={1}
        minHeight="calc(100vh - 60px - 140px)" /* Add top padding to account for fixed header */
      >
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default HomePageLayout;