import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button,
    Flex, Text,
    Heading, Skeleton, Fade, VStack
} from '@chakra-ui/react';
import { SquarePen } from 'lucide-react';
import TicketList from './TicketList';
import CreateTicketModal from './CreateTicketModal';
import { useTranslation } from 'react-i18next';

const FeedbackPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const { t } = useTranslation();  

  const handleTicketCreated = useCallback(() => {
    // Increment the refreshKey to trigger re-renders in child components
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
      // Simulate data loading
      const timer = setTimeout(() => {
          setLoading(false);
      }, 500);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
  }, []);

  const SkeletonLoading = () => {
    return (
        <VStack spacing={4} align="stretch">
            <Box bg="white" borderRadius="16px" p={5}>
                <Skeleton height="24px" width="40%" mb={2} />
                <Skeleton height="32px" width="60%" mb={4} />
                <Skeleton height="20px" width="80%" />
            </Box>
            <Box bg="white" borderRadius="16px" p={5}>
                <Skeleton height="24px" width="50%" mb={2} />
                <Skeleton height="16px" width="70%" mb={2} />
                <Skeleton height="16px" width="60%" />
            </Box>
        </VStack>
    );
};

  return (
    <Flex direction="column" minHeight="100%" height="100%">
    <Box height="30px" flexShrink={0} />
    <Flex direction="column" flex="1">
        <Heading as="h1" fontSize="2xl" mb={4} textColor="#364954">{t('feedback.title')}</Heading>

        <Box
            flex="1"
            bg="#f5f7fa"
            borderTopLeftRadius="16px"
            borderTopRightRadius="16px"
            overflow="auto"
            p={5}
        >
            {loading ? (
                <SkeletonLoading />
                ) : (
                <Fade in={!loading}>

            <Box
                borderRadius="16px" 
                bg="#ffffff"
                p={4}
                transition="all 0.3s ease"
                _hover={{
                    boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
                }}
                mb="4"
            >
                <Text fontSize="16px" fontWeight="bold" textColor="#364954" mb="4">
                {t('feedback.myfeedback.title')}
                </Text>

                <Box>
                    <Button 
                        onClick={() => setIsModalOpen(true)}
                        mb="4"
                        variant='solid'
                        backgroundColor="#364954"
                        color="#ffffff"
                        size="sm"
                        borderRadius="12px"
                        _hover={{
                          color: "#ffffff",
                          backgroundColor: "#82B2CA"
                        }}
                    >
                        <SquarePen size="16px" color="#FFFFFF" />
                        <Text fontSize="14px" textColor="#FFFFFF" ml="2">{t('feedback.myfeedback.btn-write')}</Text>
                    </Button>
                </Box>
                <Box>
                    <TicketList key={`ticketlist-${refreshKey}`} />
                </Box>

            </Box>

            <Box
                borderRadius="16px" 
                bg="#ffffff"
                p={4}
                transition="all 0.3s ease"
                _hover={{
                    boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Text fontSize="16px" fontWeight="bold" mb={4} textColor="#364954">
                {t('feedback.faq.title')}
                </Text>

                <Box>
                    <Text fontSize="14px" color="#737D82">
                    {t('helppage.articles.novalue')}
                    </Text>
                </Box>
                
            </Box>

            </Fade>

                )}
        </Box>

    </Flex>
        <CreateTicketModal 
            isOpen={isModalOpen} 
            onClose={() => setIsModalOpen(false)}
            onTicketCreated={handleTicketCreated}
        />
    </Flex>
    
  );
};

export default FeedbackPage;