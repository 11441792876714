// src/components/ResumeCopilotDrawer/EvaluationResult.js
import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  Button,
  Text,
  Box,
  Flex,
  Progress,
  useDisclosure,
  Tooltip,
  Badge,
  Center,
  Skeleton, SkeletonText,
  useToast
} from '@chakra-ui/react';
import { ListPlus, FileClock } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from './ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserContext';


const EvaluationResult = ({ session, entryId, entryType, entryUpdatedAt, data, updateLatestEvaluationResult }) => {
    const [analysisResults, setAnalysisResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();  
    const toast = useToast();
    const [user, setUser] = useState(null);
    const { updateCreditBalance } = useContext(UserContext);
  
    useEffect(() => {
      if (session) {
        fetchAnalysisResults();
      }
    }, [session]);
  
    const fetchAnalysisResults = async () => {
        setIsFetching(true);
        try {
          const response = await axiosInstance.get(`/api/v1/services/copilot/${session.id}/message/`, {
            params: { message_type: 'analysis' }
          });
          const results = response.data.map(message => ({
            ...message.related_content,
            timestamp: message.timestamp
          })).filter(Boolean);
          setAnalysisResults(results);
          if (results.length > 0) {
            updateLatestEvaluationResult(results[0].rating_aspects);
          }
        } catch (error) {
          console.error('Error fetching analysis results:', error);
        } finally {
          setIsFetching(false);
        }
    };
  
    const handleCreateEvaluation = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.post(`/api/v1/services/copilot/${session.id}/analyze/`, {
          entryId,
          entryType,
          resume_title: data.resume_title,
          resume_description: data.resume_description,
          target_job_title: data.target_job_title,
          target_job_responsibilities: data.target_job_responsibilities,
          target_job_requirements: data.target_job_requirements,
        });

        const {new_credit_balance} = response.data;
        setUser(prevUser => ({
            ...prevUser,
            credit_balance: new_credit_balance
        }));
        updateCreditBalance(response.data.new_credit_balance);
        // Fetch updated results after creating a new analysis
        await fetchAnalysisResults();
        toast({
            title: t('copilotdrawer.evaluation.createsuccess.toast-title'),
            description: t('copilotdrawer.evaluation.createsuccess.toast-desc'),
            status: "success",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
        })

      } catch (error) {
        console.error('Error creating evaluation:', error);

        if (error.response && error.response.data && error.response.data.error === "Insufficient credits") {
            toast({
              title: t('insufficientcredits.toast-title'),
              description: t('insufficientcredits.toast-desc'),
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          } else {
            toast({
              title: "Error",
              description: "Failed to create evaluation. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
            }
        } finally {
            setIsLoading(false);
            onClose();
      }
    };

    const isResultExpiredByTargetJob = (result) => {
        if (!result || !result.timestamp || !data || !data.target_job_fields_updated_at) {
            return false;
        }

        const resultTimestamp = new Date(result.timestamp);
        const targetJobUpdatedAt = new Date(data.target_job_fields_updated_at);

        return resultTimestamp < targetJobUpdatedAt;
    };

    const isResultExpiredByEntry = (result) => {
        if (!result || !result.timestamp || !entryUpdatedAt ) {
            return false;
        }

        const resultTimestamp = new Date(result.timestamp);
        const entryUpdated = new Date(entryUpdatedAt);

        return resultTimestamp < entryUpdated;
    };


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const AnalysisResultSkeleton = () => (
        <>
        <Skeleton height="40px" borderRadius="16px" mb={4} mt={4} />
        <Box borderWidth="1px" borderRadius="16px" p={4} mb={4} mt={4}>
            <Skeleton height="20px" width="200px" mb={3} />
            <Skeleton height="30px" width="300px" mb={6} />
            {[1, 2, 3].map((_, index) => (
            <Box key={index} mb={8}>
                <Skeleton height="20px" width="150px" mb={2} />
                <Skeleton height="8px" mb={2} />
                <SkeletonText mt="4" noOfLines={3} spacing="4" />
                <SkeletonText mt="4" noOfLines={2} spacing="4" />
            </Box>
            ))}
        </Box>
        </>
    );
  
    const renderAnalysisResult = (result) => {
        if (!result || !result.overall_rating) {
            return null;
        }
      
        return (
        <Box key={result.id} 
            borderWidth="1px" 
            borderRadius="16px" 
            p={4} 
            mb={2}
            mt={0}
        >
            <Flex
                direction="row"
                alignItems="center"
                mb={3}
            >
                <Text fontSize="14px" color="gray.500">
                    {t('copilotdrawer.evaluation.analyzedon')}: {formatDate(result.timestamp)}
                    
                </Text>

                { (isResultExpiredByTargetJob(result) || isResultExpiredByEntry(result)) &&
                <Box ml="3">
                    <Tooltip 
                        hasArrow 
                        borderRadius={12}
                        bg='#CFD9DF' 
                        color='#364954' 
                        placement='top'
                        label={
                            <Box p={1} fontSize="14px" fontWeight="medium" textColor="#364954">
                                <Text fontWeight="bold">{t('copilotdrawer.evaluation.expiration-title')}</Text>
                                <Text>{t('copilotdrawer.evaluation.expiration-notice')}</Text>
                            </Box>
                        } 
                    >  
                        <FileClock
                            size="14px"
                            color="#737D82"
                        />
                    </Tooltip>
                </Box>
                }

            </Flex>
            <Flex mb={6} alignItems="center">
                <Text
                    fontSize="25px"
                    fontWeight="bold"
                    textColor="#364954"
                >
                    {t('copilotdrawer.evaluation.overallrating-title')}: 
                </Text>
                <Badge
                    ml="2"
                    fontSize="25px"
                    borderRadius={8}
                    bg={
                        result.overall_rating < 3
                            ? "red.500"
                            : result.overall_rating < 5
                            ? "orange.500"
                            : result.overall_rating < 8
                            ? "yellow.500"
                            : "green.500"
                    }
                    color="white"
                >
                    {parseFloat(result.overall_rating * 10).toFixed(1)}
                </Badge>
                <Text
                    fontSize="25px"
                    fontWeight="bold"
                    textColor="#364954"
                    ml={2}
                >
                    / 100
                </Text>
            </Flex>

            {result.rating_aspects.map((aspect, index) => (
                <Box key={index} mb={8} textColor="#364954">
                    <Text fontWeight="bold">{aspect.aspect_name}</Text>
                    <Progress 
                        width="100%"
                        value={parseFloat(aspect.rating) * 50} 
                        my={2}
                        height={2}
                        borderRadius="full"
                        colorScheme={
                            aspect.rating < 0.5
                                ? "red"
                                : aspect.rating < 0.8
                                ? "orange"
                                : aspect.rating < 1.2
                                ? "yellow"
                                : "green"
                        }
                    />
                    <Box
                        fontSize="14px"
                        mb="2"
                        p={1}
                    >
                        <Text 
                        >
                            {aspect.explanation}
                        </Text>
                    </Box>
                    <Box 
                        fontSize="14px"
                        fontWeight="medium"
                        p={1}
                    >
                        <Text as="cite">{t('copilotdrawer.evaluation.improvement-title')}: </Text> 
                        <Text as="cite">{aspect.improvement}</Text>
                    </Box>
                </Box>
            ))}
        </Box>
      );
    };
  
    return (
      <VStack spacing={4} align="stretch">
        {isFetching ? (
        <Box textAlign="center">
            <>
                <AnalysisResultSkeleton />
                <AnalysisResultSkeleton />
            </>
        </Box>
        ) : analysisResults.length > 0 ? (
            <>
            <Button 
                leftIcon={<ListPlus 
                    color="#364954"
                />}
                onClick={onOpen} 
                isLoading={isLoading}
                borderRadius="16px"
                textColor="#364954"
                mt="4"
            >
            {t('copilotdrawer.evaluation.create-new-btn')}
            </Button>
            {analysisResults.map(renderAnalysisResult)}
            </>
        ) : (
            <Center 
                height="200px"
                bg="#f5f7fa"
                borderRadius={16}
                mt="4"
            >
                <VStack>
                    <Button 
                        leftIcon={<ListPlus 
                            color="#364954"
                        />}
                        onClick={onOpen} 
                        isLoading={isLoading}
                        borderRadius="16px"
                        textColor="#364954"
                    >
                        {t('copilotdrawer.evaluation.create-btn')}
                    </Button>
                    <Text
                        fontSize="14px"
                        textColor="#737D82"
                    >
                        {t('copilotdrawer.evaluation.nodata')}
                    </Text>
                </VStack>
            </Center>
        )}
        <ConfirmationModal
          isOpen={isOpen}
          isLoading={isLoading}
          onClose={onClose}
          onConfirm={handleCreateEvaluation}
          title={t('copilotdrawer.evaluation.confirmmodal-title')}
          message={t('copilotdrawer.evaluation.confirmmodal-desc')}
        />
      </VStack>
    );
  };
  
  export default EvaluationResult;