import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Box,
  Text,
  FormErrorMessage, Flex
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ChangeTargetJobModal = ({ isOpen, onClose, resume, onSave }) => {
  const { t } = useTranslation();
  const MAX_CHARS = 999;

  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    responsibilities: Yup.string().required('Required'),
    requirements: Yup.string().required('Required'),
  });


  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await onSave(values);
      onClose();
    } catch (error) {
      console.error("Error saving target job:", error);
      // Optionally, you can show an error message to the user here
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
      >
        <ModalHeader>{t('targetjobmodal.title')}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            title: resume?.target_job_title || '',
            company: resume?.target_job_company || '',
            responsibilities: resume?.target_job_responsibilities || '',
            requirements: resume?.target_job_requirements || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <ModalBody>
                <VStack spacing={4}>
                  <Box fontSize="14px" textColor="#364954" bg="#f5f7fa" borderRadius="12px" p={3}>
                    <Text>{t('targetjobmodal.desc')}</Text>
                  </Box>
                  <Field name="title">
                    {({ field, form }) => (
                      <FormControl isRequired isDisabled={isSubmitting} isInvalid={form.errors.title && form.touched.title}>
                        <FormLabel fontSize="14px" textColor="#364954">{t('targetjobmodal.jobtitle')}</FormLabel>
                        <Input fontSize="14px" {...field} />
                        <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="company">
                    {({ field }) => (
                      <FormControl isDisabled={isSubmitting} >
                        <FormLabel fontSize="14px" textColor="#364954">{t('targetjobmodal.jobcompany')}</FormLabel>
                        <Input fontSize="14px" {...field} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="responsibilities">
                    {({ field, form }) => (
                      <FormControl isRequired isDisabled={isSubmitting}  isInvalid={form.errors.responsibilities && form.touched.responsibilities}>
                        <FormLabel fontSize="14px" textColor="#364954">{t('targetjobmodal.jobres')}</FormLabel>
                        <Textarea fontSize="14px" {...field} maxLength={MAX_CHARS} />
                        <Flex
                            justifyContent="space-between"
                        >
                            <FormErrorMessage>{form.errors.responsibilities}</FormErrorMessage>
                            <Text fontSize="12px" textAlign="right" mt={1} color="#737D82">
                            {field.value.length}/{MAX_CHARS}
                            </Text>
                        </Flex>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="requirements">
                    {({ field, form }) => (
                      <FormControl isRequired isDisabled={isSubmitting}  isInvalid={form.errors.requirements && form.touched.requirements}>
                        <FormLabel fontSize="14px" textColor="#364954">{t('targetjobmodal.jobreq')}</FormLabel>
                        <Textarea fontSize="14px" {...field} maxLength={MAX_CHARS} />
                        <Flex
                            justifyContent="space-between"
                        >
                            <FormErrorMessage>{form.errors.requirements}</FormErrorMessage>
                            <Text fontSize="12px" textAlign="right" mt={1} color="#737D82">
                            {field.value.length}/{MAX_CHARS}
                            </Text>
                        </Flex>
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  mr="3"
                  type="submit"
                  color="#ffffff"
                  backgroundColor="#364954"
                  borderRadius="16px"
                  _hover={{
                    color: "#ffffff",
                    backgroundColor: "#82B2CA"
                  }}
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  {t('targetjobmodal.btn-save')}
                </Button>
                <Button
                  variant="ghost"
                  borderRadius="16px"
                  onClick={onClose}
                >
                  {t('targetjobmodal.btn-cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ChangeTargetJobModal;