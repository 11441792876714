import React from 'react';
import { Box, Text, Button, VStack, Flex, Tooltip } from '@chakra-ui/react';
import { NotebookPen } from 'lucide-react';
import { useTranslation } from 'react-i18next';


const WriteWithAIBox = ({ generatedDescription, onApply }) => {
    const { t } = useTranslation();  
  
    return (
        <Box 
            borderRadius="16px" 
            bg="#f5f7fa"
            px={4} 
            py={2}
            mb={4}
        >
            <VStack align="stretch">
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    align="center"
                >
                    <Text fontWeight="bold" textColor="#364954" fontSize="14px">{t('writewithai.box.title')}:</Text>
                    <Tooltip 
                        hasArrow 
                        borderRadius={12} 
                        bg='#CFD9DF' 
                        color='#364954' 
                        placement='top'
                        label={
                        <Box p={1}>
                        {t('writewithai.box.use-it-tooltip')}
                        </Box>
                        } 
                    > 
                    <Button 
                        onClick={onApply} 
                        variant="ghost"
                        size="sm"
                        leftIcon={<NotebookPen 
                            color="#364954"
                            size="14px"
                            />}
                        borderRadius="16px"
                        textColor="#364954"
                    >
                        {t('writewithai.box.btn-use-it')}
                    </Button>
                    </Tooltip>
                </Flex>
            <Text
                fontSize="14px"
                textColor="#364954"
                whiteSpace="pre-wrap"
            >
                {generatedDescription
                    .split('\n')
                    .filter(line => line.trim() !== '')
                    .join('\n')
                }
            </Text>
            </VStack>
        </Box>
    );
  };

export default WriteWithAIBox;