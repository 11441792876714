import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, VStack, Grid, useDisclosure, useToast, Fade, Spacer, Flex } from '@chakra-ui/react';
import { WandSparkles, Blocks, NotebookPen, MonitorPlay } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import UpdateDisplayNameModal from './UpdateDisplayNameModal';
import ServiceCard from './ServiceCard';
import OnboardingTasks from './OnboardingTasks';
import { UserContext } from '../UserContext';
import SkeletonLoading from './SkeletonLoading';
import { useTranslation } from 'react-i18next';
  
  const GettingStartedPage = () => {
    const [onboardingTasks, setOnboardingTasks] = useState([]);
    const [resume, setResume] = useState(null);
    const [hasResume, setHasResume] = useState(false);
    const [resumeId, setResumeId] = useState(null);
    const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onClose: onUpdateModalClose } = useDisclosure();
    const [currentDisplayName, setCurrentDisplayName] = useState('');
    const toast = useToast();
    const { fetchUserProfile } = useContext(UserContext);
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const { t, } = useTranslation();

    const services = [
      {
        id: "resumePolish",
        titleKey: "gettingstarted.resumePolish.title",
        descriptionKey: "gettingstarted.resumePolish.description",
        versionKey: "v1",
        icon: <WandSparkles />,
        status: "available"
      },
      {
        id: "resumeBuilder",
        titleKey: "gettingstarted.resumeBuilder.title",
        descriptionKey: "gettingstarted.resumeBuilder.description",
        versionKey: "gettingstarted.commingsoon",
        icon: <Blocks />,
        status: "coming_soon"
      },
      {
        id: "interviewPrep",
        titleKey: "gettingstarted.interviewPrep.title",
        descriptionKey: "gettingstarted.interviewPrep.description",
        versionKey: "gettingstarted.commingsoon",
        icon: <NotebookPen />,
        status: "coming_soon"
      },
      {
        id: "mockInterview",
        titleKey: "gettingstarted.mockInterview.title",
        descriptionKey: "gettingstarted.mockInterview.description",
        versionKey: "gettingstarted.commingsoon",
        icon: <MonitorPlay />,
        status: "coming_soon"
      }
    ];

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
          await Promise.all([
            fetchOnboardingTasks(),
            fetchUserProfile(),
            checkForResume()
          ]);
          setIsLoading(false);
        };
    
        fetchData();
      }, []);
  
    const fetchOnboardingTasks = async () => {
      try {
        const response = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        setOnboardingTasks(response.data);
      } catch (error) {
        console.error('Error fetching onboarding tasks:', error);
        toast({
          title: "Error",
          description: "Failed to fetch onboarding tasks",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    const checkForResume = async () => {
      try {
        const response = await axiosInstance.get('/api/v1/resumes/resumes/');
        if (response.data && response.data.length > 0) {
          const sortedResumes = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          const activeResume = sortedResumes.find(resume => resume.is_active) || sortedResumes[0];
          setResumeId(activeResume.id);
          
          const hasContent = 
            (activeResume.education && activeResume.education.length > 0) ||
            (activeResume.experiences && activeResume.experiences.length > 0) ||
            (activeResume.skills && activeResume.skills.length > 0);
          
          setHasResume(hasContent);
          setResume(activeResume);
        } else {
          setHasResume(false);
          setResume(null);
          setResumeId(null);
        }
      } catch (error) {
        console.error('Error checking for resume:', error);
        toast({
          title: "Error",
          description: "Failed to check for existing resume",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    };
  
    const handleStartClick = (service) => {
        if (service.id === "resumePolish") {
          if (hasResume) {
            navigate(`/gettingstarted/resume-polish/${resumeId}`);
          } else {
            toast({
              title: t('gettingstarted.rp.toast-noresume'),
              description: t('gettingstarted.rp.toast-noresume-des'),
              status: "warning",
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
            navigate('/resumes');
          }
        }
      };

    // Define constants for task IDs
    const TASK_IDS = {
        UPDATE_DISPLAY_NAME: 1,
        CREATE_RESUME: 2,
        TRY_RESUME_POLISH: 3,
        LEAVE_FEEDBACK: 4,
        // Add other task IDs as needed
    };
  
    const handleTaskClick = (task) => {
        switch (task.task_id) {
          case TASK_IDS.UPDATE_DISPLAY_NAME:
            onUpdateModalOpen();
            break;
          case TASK_IDS.CREATE_RESUME:
            navigate('/resumes');
            break;
          case TASK_IDS.TRY_RESUME_POLISH:
            if (hasResume) {
              navigate(`/gettingstarted/resume-polish/${resumeId}`);
            } else {
              toast({
                title: t('gettingstarted.rp.toast-noresume'),
                description: t('gettingstarted.rp.toast-noresume-des'),
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
              navigate('/resumes');
            }
            break;
            case TASK_IDS.LEAVE_FEEDBACK:
              navigate('/feedback');
              break;
          // Handle other tasks as needed
          default:
            console.log(`No action defined for task ID ${task.task_id}`);
        }
      };
  
    const handleDisplayNameUpdated = async (newDisplayName) => {
      setCurrentDisplayName(newDisplayName);
      await fetchUserProfile(); // Refresh user profile after updating display name
      fetchOnboardingTasks(); // Refresh tasks after updating display name
    };

    const incompleteOnboardingTasks = onboardingTasks.filter(task => !task.is_completed);
    
    return (
    <Flex direction="column" minHeight="100%" height="100%">
    <Box height="30px" flexShrink={0} />
    <Flex direction="column" flex="1" overflow="hidden">
        <Heading as="h1" fontSize="2xl" mb={4} textColor="#364954">{t('gettingstarted.title')}</Heading>
        <Box
          flex="1"
          bg="#f5f7fa"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="auto"
          p={5}
        >
            <Box flex="1">
                {isLoading ? (
                    <SkeletonLoading />
                ) : (
                    <Fade in={!isLoading}>
                        <VStack align="stretch" spacing={4} height="100%">
                            <Heading 
                                fontSize="4xl" 
                                mt="6" mb="6" 
                                textColor="#364954" 
                                align="left"
                            >
                                {t('gettingstarted.welcome')} 👋 
                            </Heading>
                            <Box
                                maxW="60%"
                                borderTopRightRadius="16px"
                                borderBottomRightRadius="16px"
                                borderBottomLeftRadius="16px"
                                p="4"
                                mb="2"
                                background="rgba(255, 255, 255, 1)"
                                transition="all 0.3s ease"
                                _hover={{
                                    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Text fontSize="16px" textColor="#364954">
                                    Hi <Text as="b">{user.display_name ? user.display_name : "there"}</Text>, {t('gettingstarted.greetings')}
                                </Text>
                            </Box>

                            {incompleteOnboardingTasks.length > 0 && (
                                <OnboardingTasks
                                    tasks={onboardingTasks}
                                    onTaskClick={handleTaskClick}
                                    isLoading={isLoading} />
                            )}

                            <Heading fontSize="16px" mt="0" mb="0">{t('gettingstarted.choose')}:</Heading>
                            <Grid templateColumns="repeat(auto-fill, minmax(220px, 1fr))" gap={3}>
                                {services.map((service) => (
                                    <ServiceCard
                                        key={service.id}
                                        service={service}
                                        onClick={() => handleStartClick(service)} />
                                ))}
                            </Grid>
                            <Spacer />
                            {/* <Heading fontSize="16px" mt="2" mb="0">or, ask me anything:</Heading> */}
                        </VStack>
                    </Fade>
                )}

                <UpdateDisplayNameModal
                    isOpen={isUpdateModalOpen}
                    onClose={onUpdateModalClose}
                    onDisplayNameUpdated={handleDisplayNameUpdated}
                    currentDisplayName={currentDisplayName}
                />
            </Box>
        </Box>
    </Flex>
    </Flex>
    );
};
  
  export default GettingStartedPage;