import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Button, Flex, Spinner, HStack
 } from '@chakra-ui/react';
import { Check, X, ArrowRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const MotionBox = motion(Box);

const ProcessingDemo = () => {
  const { t } = useTranslation();  
  const [tasks, setTasks] = useState([
    { task_name: 'Job Analysis', status: 'PENDING', updated_at: new Date().toISOString() },
    { task_name: 'Resume Analysis', status: 'PENDING', updated_at: new Date().toISOString() },
    { task_name: 'Resume Retouch', status: 'PENDING', updated_at: new Date().toISOString() },
  ]);
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const taskOrder = ['Job Analysis', 'Resume Analysis', 'Resume Retouch'];

  const updateTasks = () => {
    const newTasks = tasks.map(task => {
      if (task.status === 'PENDING') {
        return { ...task, status: 'PROCESSING_STARTED', updated_at: new Date().toISOString() };
      } else if (task.status === 'PROCESSING_STARTED') {
        return { ...task, status: 'COMPLETED', updated_at: new Date().toISOString() };
      }
      return task;
    });

    const processedTasks = taskOrder.map(taskName => {
      const relatedTasks = newTasks.filter(task => task.task_name === taskName);
      const mostRecentTask = relatedTasks.reduce((prev, current) => 
        (new Date(current.updated_at) > new Date(prev.updated_at)) ? current : prev
      );
      return mostRecentTask || { task_name: taskName, status: 'PENDING' };
    });

    setTasks(processedTasks);
    const completedTasks = processedTasks.filter(task => task.status === 'COMPLETED').length;
    setProgress((completedTasks / processedTasks.length) * 100);
    setIsComplete(completedTasks === processedTasks.length);
  };

  useEffect(() => {
    // Simulate initial loading
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    // Simulate task updates
    const interval = setInterval(() => {
      updateTasks();
    }, 3000);

    return () => clearInterval(interval);
  }, [tasks]);

  const getTaskIcon = (status) => {
    switch (status) {
      case 'COMPLETED':
        return <Box as={Check} color="#364954" mr={2} />;
      case 'FAILED':
        return <Box as={X} color="red.500" mr={2} />;
      case 'PROCESSING_STARTED':
      case 'AI_PROCESSING':
      case 'STAGE_I_COMPLETE':
      case 'STAGE_II_COMPLETE':
        return <Spinner size="sm" mr={2} />;
      default:
        return <Box w={4} h={4} borderRadius="full" borderWidth={1} borderColor="gray.300" mr={2} />;
    }
  };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
    <Box height="20px" flexShrink={0} />
    <Flex direction="column" flex="1" overflow="hidden">
    <Box
          flex="1"
          bg="#f5f7fa"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="auto"
          p={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
      <Box 
        p={5} 
        width="60%"
        bg="rgba(255, 255, 255, 0"
        borderRadius="12px"
      >
        <VStack spacing={8} align="stretch" mx="auto">

          <Box>
            <Text 
              fontSize="3xl" 
              fontWeight="bold" 
              textAlign="center" 
              textColor="#364954"
              mb="1"
            >
              { isComplete ? ("Resume Polish Done!") : (t('processing.title')) 
              }
            </Text>
            <Text
              fontSize="14px" 
              fontWeight="normal" 
              textAlign="center" 
              textColor="#737D82"
            >
              { isComplete ? ("Click the button below to view results") : ("This may take few minutes") 
              }
            </Text>
          </Box>
          <Box w="100%" position="relative" height="4px" bg="gray.100" borderRadius="full" overflow="hidden">
            <MotionBox
              initial={{ x: "-100%" }}
              animate={{ x: "250%" }}
              transition={{ 
                repeat: Infinity, 
                duration: 1, 
                ease: "linear" 
              }}
              height="100%"
              width="50%"
              bg="rgba(54, 73, 84, 0.2)"
              borderRadius="full"
              position="absolute"
              top="0"
              left="0"
            />
            <MotionBox
              initial={{ width: "0%" }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              height="100%"
              bg="#364954"
              borderRadius="full"
              position="absolute"
              top="0"
              left="0"
            />
          </Box>
          <AnimatePresence>
            {isLoading ? (
              <Flex justify="center">
                <Spinner size="xl" color="#737D82" />
              </Flex>
            ) : (
              <VStack align="stretch">
                {tasks.map((task) => (
                  <motion.div
                    key={task.task_name}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box align="left" mb={2}>
                      <HStack key={task.task_name} align="center" mb={2}>
                        {getTaskIcon(task.status)}
                        <Text color={task.status !== 'PENDING' ? "#364954" : "gray.500"} fontWeight={task.status !== 'PENDING' ? "bold" : "normal"}>
                          {task.task_name}
                        </Text>
                      </HStack>
                    </Box>
                  </motion.div>
                ))}
              </VStack>
            )}
          </AnimatePresence>

          <Button 
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="16px"
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
            w="100%"
            onClick={() => alert('Demo: Navigate to results')} 
            isDisabled={isLoading || !isComplete}
          >
            <HStack>
              { isComplete ? (
                <>
                <Text>{t('processing.continuetoresult')} </Text>
                <Text><ArrowRight size="20px" /></Text>
                </>
              ) : (
                <>
                <Text>Processing...</Text>
                </>
              )}

            </HStack>
          </Button>

          <Box
            my="10"
            bg="#ffffff"
            borderRadius="16px"
            borderWidth={1}
            borderColor="gray.50"
            p={6}
            textColor="#737D82"
            fontSize="14px"
            lineHeight={1.5}
          >
            <Text as="b">How It Works?</Text>
            <Text mt="2" mb="2" >
              Unlike traditional resume services, our AI-powered Resume Polish goes beyond simple retouching. We help you understand your desired job better, analyze your resume from an HR or interviewer's perspective, and optimize each line to present you as the ideal candidate.
            </Text>
            <Text mb="2">
              Our AI analyzes your resume using algorithms trained on thousands of successful resumes and hiring patterns across various industries. It identifies key elements that hiring managers and ATS (Applicant Tracking Systems) look for, dramatically increasing your chances of passing initial resume screenings by ensuring your resume speaks directly to the needs of your target role.
            </Text>
            <Text>
              By using our resume polish service, you'll gain invaluable insights into how recruiters perceive your resume, allowing you to understand and address potential weaknesses before submitting your application. Additionally, you'll gain a deeper understanding of effective resume writing techniques, enabling you to continually improve your resume for future opportunities.
            </Text>
          </Box>

        </VStack>
      </Box>
    </Box>
    </Flex>
    </Flex>
  );
};

export default ProcessingDemo;
