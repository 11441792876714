import React, { useState, useEffect } from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Skeleton, useBreakpointValue } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

const faqs = [
  {
    question: "faq.question1",
    answer: "faq.answer1"
  },
  {
    question: "faq.question2",
    answer: "faq.answer2"
  },
  {
    question: "faq.question3",
    answer: "faq.answer3"
  },
  {
    question: "faq.question4",
    answer: "faq.answer4"
  },
  // ... (add more FAQs as needed)
];

const FAQ = () => {
  const { t, ready } = useTranslation('homepage');
  const [showContent, setShowContent] = useState(false);

  // Determine if the screen size is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const renderContent = () => (
    <>
      <Heading as="h2" size="xl" textAlign="center" mb={10}>
        {t('faq.title')}
      </Heading>
      <Box width={isMobile ? "100%" : "720px"} alignItems="center" mx="auto">
        <Accordion allowMultiple>
          {faqs.map((faq, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton
                  _hover={{
                    bg: '#f5f7fa',
                    color: '#2D3748',
                  }}
                  _expanded={{
                    bg: '#f5f7fa',
                    color: '#2D3748',
                  }}
                >
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    {t(faq.question)}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={1} textAlign="left" fontSize="16px" textColor="#364954">
                <Box p={4}>
                  {faq.question === 'faq.question3' ? (
                    <Trans i18nKey="faq.answer3">
                      <p>{{ part1: t('faq.answer3.part1') }}</p>
                      <br />
                      <ul style={{ paddingLeft: '30px' }}>
                        <li>{{ item1: t('faq.answer3.list.item1') }}</li>
                        <li>{{ item2: t('faq.answer3.list.item2') }}</li>
                        <li>{{ item3: t('faq.answer3.list.item3') }}</li>
                      </ul>
                      <br />
                      <p>{{ part2: t('faq.answer3.part2') }}</p>
                    </Trans>
                  ) : (
                    t(faq.answer)
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </>
  );

  const renderPlaceholder = () => (
    <>
      <Skeleton height="40px" width="200px" mb={10} />
      <Box width="60%" alignItems="center" mx="auto">
        {faqs.map((_, index) => (
          <Skeleton key={index} height="60px" mb={2} />
        ))}
      </Box>
    </>
  );

  return (
    <Box 
      py={20} 
      mx="auto" 
      // bg="gray.50" 
      bg="linear-gradient(to top, #f5f7fa 0%, #e7f0fd 100%)"
      align="center" 
      px={6}
    >
      {showContent ? renderContent() : renderPlaceholder()}
    </Box>
  );
};

export default FAQ;
