import React from 'react';
import { Box, Button, HStack, Icon, Text, Skeleton, Fade } from '@chakra-ui/react';
import { CircleCheck, Circle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const OnboardingTasksSkeleton = () => (
  <Box p={4} mb={4} borderRadius="16px" background="rgba(255, 255, 255, 1)">
    <Skeleton height="24px" width="80%" mb={3} />
    {[...Array(3)].map((_, index) => (
      <Skeleton key={index} height="40px" mb={2} />
    ))}
  </Box>
);

const OnboardingTasks = ({ tasks, onTaskClick, isLoading }) => {
  const { t, } = useTranslation();

  if (isLoading) {
    return <OnboardingTasksSkeleton />;
  }

  return (
    <Fade in={!isLoading}>
      <Box 
        p={4} 
        mb={4}
        borderTopRightRadius="16px"
        borderBottomRightRadius="16px"
        borderBottomLeftRadius="16px"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.3s ease"
        _hover={{
          boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Text 
          fontSize="16px" 
          mb={3} 
          textColor="#364954"
          fontWeight="bold"
        >
          {t('gettingstarted.onboradingtaskgreeting')}:
        </Text>
        {tasks.map((task) => (
          <Button
            key={task.id}
            onClick={() => !task.is_completed && onTaskClick(task)}
            variant="ghost"
            borderRadius="12px"
            width="100%"
            justifyContent="flex-start"
            _hover={{ bg: "gray.100" }}
            _focus={{ boxShadow: "none" }}
            isDisabled={task.is_completed}
          >
            <HStack width="100%" justify="space-between" mb={0}>
              <HStack>
                <Icon as={task.is_completed ? CircleCheck : Circle} />
                <Text fontWeight="normal" fontSize="14px" as={task.is_completed ? "del" : undefined}>
                  {t(`gettingstarted.onboardingtasks.${task.task_id}.title`)}
                </Text>
                <Text fontSize="14px" color={task.is_completed ? "#737D82" : "blue.500"} as={task.is_completed ? "del" : undefined}>
                  + {task.task_credit_award} {t('gettingstarted.credits')}
                </Text>
              </HStack>
              <Text fontSize="14px" textColor={task.is_completed ? "#364954" : "#737D82"} as={task.is_completed ? "del" : undefined}>
                {task.is_completed ? t('gettingstarted.completed') : t('gettingstarted.notcompleted')}
              </Text>
            </HStack>
          </Button>
        ))}
      </Box>
    </Fade>
  );
};

export default OnboardingTasks;