// src/components/ResumeCopilotDrawer/EditContentModal.js
import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea, Text, Flex, Box
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { diffChars, diffWords } from 'diff';

const EditContentModal = ({ isOpen, isUpdating, onClose, onConfirm, originalTitle, originalContent, content }) => {
  const [editedContent, setEditedContent] = useState(content);
  const { t } = useTranslation();

// Simple function to detect if a string contains Chinese characters
const containsChinese = (str) => /[\u4e00-\u9fa5]/.test(str);

const getHighlightedText = (before, after) => {
  // Detect if either string contains Chinese
  const hasChinese = containsChinese(before) || containsChinese(after);

  // Use diffChars for Chinese, diffWords for other languages
  const diff = hasChinese ? diffChars(before, after) : diffWords(before, after);

  return diff.map((part, index) => {
    let style = {};
    if (part.added) {
      style = { backgroundColor: '#C7E4D4', color: '#364954', fontWeight: 'bold' };
    } else if (part.removed) {
      style = { backgroundColor: '#D5BDA7', color: '#FFF5DF', textDecoration: 'line-through' };
    }

    return (
      <span key={index} style={style}>
        {part.value}
      </span>
    );
  });
};

  // Update editedContent when the content prop changes
  useEffect(() => {
    setEditedContent(content);
  }, [content]);

  const handleConfirm = () => {
    onConfirm(editedContent);
  };

  return (
    <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        closeOnOverlayClick={false} 
        closeOnEsc={false}
        size="4xl">
      <ModalOverlay 
        backdropFilter="blur(10px)"
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        transition="all 0.1s ease"
      >
        <ModalHeader>{t('copilotdrawer.editmodal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text
                mb="3"
                fontSize="16px"
                textColor="#364954"
            >
            {t('copilotdrawer.editmodal.desc')}
            </Text>
            <Flex
                direction="row"
                gap={4}
            >
                <Box
                    width="50%"
                    fontSize="14px"
                    textColor="#364954"
                    bg="#f5f7fa"
                    p={3}
                    borderRadius={16}
                >
                    <Text mb="2" fontWeight="bold">
                        {originalTitle}
                    </Text>
                    <Text whiteSpace="pre-wrap" >
                        {getHighlightedText(originalContent, editedContent)}
                    </Text>
                </Box>
                <Box
                    width="50%"
                    bg="green.50"
                    fontSize="14px"
                    p={3}
                    borderRadius={16}
                >
                    <Text mb="2" fontWeight="bold">
                        {originalTitle}
                    </Text>
                    <Textarea
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        rows={10}
                        textColor="#364954"
                        fontSize="14px"
                        whiteSpace="pre-wrap"
                        p={2}
                        bg="rgba(255, 255, 255, 0.9)"
                        isDisabled={isUpdating}
                    />
                </Box>
            </Flex>
        </ModalBody>
        <ModalFooter>
          <Button 
            onClick={handleConfirm}
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="16px"
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
            isLoading={isUpdating}
            isDisabled={isUpdating}
            mr={3} 
          >
            {t('copilotdrawer.editmodal.confirm')}
          </Button>
          <Button 
            variant="ghost" 
            onClick={onClose}
            borderRadius="16px"
            isDisabled={isUpdating}
          >
            {t('copilotdrawer.editmodal.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditContentModal;