// src/components/Orders/JobAnalysisSection.js
import React, { useState, useEffect } from 'react';
import { Text, Spinner, Flex, VStack, HStack, Tag, UnorderedList, ListItem, 
  Alert, AlertIcon, AlertTitle, AlertDescription, Divider, Box
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import RerunButton from './RerunButton';

const JobAnalysisSection = ({ taskId }) => {
  const { t } = useTranslation();
  const [jobAnalysis, setJobAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Fetching job analysis for taskId:', taskId);
    const fetchJobAnalysis = async () => {
      if (!taskId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get(`/api/v1/orders/job-analysis/${taskId}/`);
        console.log('Job analysis response:', response.data);
        setJobAnalysis(response.data);
      } catch (err) {
        console.error('Error fetching job analysis:', err);
        setError('Failed to load job analysis');
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobAnalysis();
  }, [taskId]);

  if (isLoading)
    return <Spinner />;

  if (error)
    return (
      <Alert status="error" variant="left-accent" borderRadius="md" mb={4}>
        <AlertIcon />
        <AlertTitle mr={2}>{t('orderdetail.error_title')}</AlertTitle>
        <HStack spacing={2}>
          <AlertDescription>{t('orderdetail.error_content')}</AlertDescription>
          <RerunButton taskId={taskId} onRerunComplete={() => window.location.reload()} />
        </HStack>
      </Alert>
    );

  if (!jobAnalysis)
    return null;

  return (
    <Flex align="stretch">
      <VStack spacing={4} align="left">
        <Text mt={2} color="#2D3748">{jobAnalysis.summary}</Text>
        <Divider />
        {jobAnalysis.details.map(detail => (
          <>
          <HStack key={detail.id} mt={1} align="left">
            <VStack align="left" width="30%">
              <Text fontSize="14px" color="#737D82">{t('orderdetail.jobanalysis_responsibilities')}:</Text>
              <Text fontWeight="bold" fontSize="16px" color="#364954">{detail.description}</Text>
              <VStack align="left" mt={3}>
                <Text fontSize="14px" color="#737D82">{t('orderdetail.jobanalysis_keywords')}:</Text>
                <Box>
                  {detail.keywords.map((keyword, index) => (
                    <Tag
                      size={'md'}
                      key={keyword}
                      colorScheme='blue'
                      borderRadius="full"
                      px={3}
                      mr={2}
                      mb={2}
                    >
                      {keyword}</Tag>
                  ))}
                </Box>
              </VStack>
            </VStack>
            <VStack align="left" width="70%" ml={3}>
              <Text color="#2D3748" fontSize="16px">{detail.explanation}</Text>
              <VStack align="start" mt={2}>
                <Text mt={2} as="b" color="#2D3748">{t('orderdetail.jobanalysis_examples')}:</Text>
                {detail.examples.map((example, index) => (
                  <UnorderedList key={index}>
                    <ListItem key={example} mt={-1} fontSize="16px" color="#2D3748"> {example}</ListItem>
                  </UnorderedList>
                ))}
              </VStack>
            </VStack>
          </HStack>
          <Divider mt="3" />
          </>
        ))}
      </VStack>
    </Flex>
  );
};

export default JobAnalysisSection;
