import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import AboutPage from './components/HomePage/AboutPage';
import PricingPage from './components/HomePage/PricingPage';
import HomePage from './components/HomePage/HomePage';
import SignUp from './components/SignUp';
import SignUpSuccessful from './components/SignUpSuccessful';
import SignIn from './components/SignIn';
import VerifyEmail from './components/VerifyEmail';
import PrivateRoute from './components/PrivateRoute';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import GettingStarted from './components/GettingStarted/GettingStarted';
import ResumePolishPage from './components/Resume/ResumePolishPage';
import ResumePage from './components/Resume/ResumePage';
import ServiceOrderList from './components/Orders/ServiceOrderList';
import OrderDetail from './components/Orders/ServiceOrderDetail';
import ProcessingPage from './components/Orders/ProcessingPage';
import ProcessingPageDemo from './components/Orders/ProcessingPageDemo';
import Settings from './components/Settings';
import Layout from './components/Layout';
import Credits from './components/Credits/Credits';
import SuccessPage from './components/Credits/SuccessPage';
import FailPage from './components/Credits/FailPage';
import LoadingPage from './components/Credits/LoadingPage';
import ChatPage from './components/Chats/ChatPage';
import FeedbackPage from './components/Feedback/FeedbackPage';
import TicketDetails from './components/Feedback/TicketDetails';
import TermsOfService from './components/HomePage/TermsOfService';
import PrivacyPolicy from './components/HomePage/PrivacyPolicy';

const ProtectedLayout = () => (
  <Layout>
    <Outlet />
  </Layout>
);

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/about" element={<AboutPage />} />
    <Route path="/pricing" element={<PricingPage />} />
    <Route path="/sign-up" element={<SignUp />} />
    <Route path="/sign-up/:referralCode" element={<SignUp />} />
    <Route path="/signup-successful" element={<SignUpSuccessful />} />
    <Route path="/sign-in" element={<SignIn />} />
    <Route path="/verify-email/:token" element={<VerifyEmail />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/reset-password/:token" element={<ResetPassword />} />
    <Route path="/terms" element={<TermsOfService />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    
    {/* Protected Routes */}
    <Route element={<PrivateRoute />}>
      <Route element={<ProtectedLayout />}>
        <Route path="/gettingstarted" element={<GettingStarted />} />
        <Route path="/gettingstarted/resume-polish/:resumeId" element={<ResumePolishPage />} />
        <Route path="/resumes" element={<ResumePage />} />
        <Route path="/service-orders" element={<ServiceOrderList />} />
        <Route path="/service-orders/:orderId" element={<OrderDetail />} />
        <Route path="/processing/:orderId" element={<ProcessingPage />} />
        <Route path="/processing-demo" element={<ProcessingPageDemo />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/payment/success" element={<SuccessPage />} />
        <Route path="/payment/fail" element={<FailPage />} />
        <Route path="/payment/loading" element={<LoadingPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/feedback" element={<FeedbackPage />} />
        <Route path="/feedback/:id" element={<TicketDetails />} />
        {/* Add other protected routes here */}
      </Route>
    </Route>
  </Routes>
);

export default AppRoutes;