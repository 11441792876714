// Education.js
import React, { useState, useEffect } from 'react';
import {
  Box, Heading,
  Text, Button,
  VStack, Divider, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Tooltip,
  HStack, Skeleton, SkeletonText, Spinner
} from '@chakra-ui/react';
import { LuPlusCircle, LuHelpCircle } from "react-icons/lu";
import axiosInstance from '../../utils/axiosConfig';
import EducationEntry from './EducationEntry';
import EducationForm from './EducationForm';
import { useTranslation } from 'react-i18next';

const Education = ({ resumeId, updateResumeState }) => {
  const { t } = useTranslation();  
  const [educations, setEducations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [educationToDelete, setEducationToDelete] = useState(null);
  const toast = useToast();

  const sortEducations = (educations) => {
    return educations.sort((a, b) => {
      const aEnd = a.end === t('Present') ? new Date() : new Date(a.end);
      const bEnd = b.end === t('Present') ? new Date() : new Date(b.end);
      return bEnd - aEnd;
    });
  };

  useEffect(() => {
    fetchEducations();
  }, [resumeId]);

  const fetchEducations = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/education/?resume=${resumeId}`);
      setEducations(sortEducations(response.data));
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch educations');
      setLoading(false);
    }
  };

  const handleAdd = async (newEducation) => {
    try {
      const response = await axiosInstance.post('/api/v1/resumes/education/', {
        ...newEducation,
        resume: resumeId
      });
      setEducations(sortEducations([...educations, response.data]));
      setIsAdding(false);
      toast({
        title: t('edu.add_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to add education');
      toast({
        title: t('edu.add_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleUpdate = async (updatedEducation) => {
    try {
      const response = await axiosInstance.put(`/api/v1/resumes/education/${updatedEducation.id}/`, {
        ...updatedEducation,
        resume: resumeId
      });
      setEducations(sortEducations(educations.map(edu => edu.id === updatedEducation.id ? response.data : edu)));
      toast({
        title: t('edu.update_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to update education');
      toast({
        title: t('edu.update_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/api/v1/resumes/education/${id}/`);
      setEducations(educations.filter(edu => edu.id !== id));
      setIsDeleteModalOpen(false);
      toast({
        title: t('edu.delete_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to delete education');
      toast({
        title: t('edu.delete_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const EducationSkeleton = () => (
    <Box>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='3' />
      <SkeletonText mt='4' noOfLines={2} spacing='4' />
      <Skeleton height='20px' mt='4' />
    </Box>
  );

  if (loading) return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
    >
      <Box
        p={4}
      >
        <HStack align="flex-start" justify="space-between">
          <Skeleton height="24px" width="150px" />
          <Skeleton height="24px" width="24px" />
        </HStack>
        <Divider my={4} />
        <VStack spacing={4} align="stretch">
          <EducationSkeleton />
        </VStack>
      </Box>
    </Box>
  );
  if (error) return <Box>{error}</Box>;

  return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
      transition="all 0.3s ease"
      _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
    <Box
      p={4}
      pb={2}

    >
      <HStack align="flex-start" justify="space-between">
        <Heading as="h2" fontSize="lg" mb={2} textColor="#364954">{t('edu.section_title')}</Heading>
        <Tooltip 
          hasArrow 
          borderRadius={12} 
          bg='#CFD9DF' 
          color='#364954' 
          placement='top'
          label={
            <Box p={1}>
            {t('edu.section_intro')}
            </Box>
          } 
        >
          <Text><LuHelpCircle /></Text>
        </Tooltip>
      </HStack>
      {educations.length > 0 && <Divider my={1} />}
      <VStack spacing={0} align="stretch">
      {educations.length === 0 ? (
          <Text
            fontSize="sm"
            textColor="#737D82"
            mt="2"
            mb="1"
          >
          {t('edu.norecord')}</Text>
        ) : (
      educations.map((education, index) => (
          <React.Fragment key={education.id}>
            <EducationEntry
              education={education}
              onUpdate={handleUpdate}
              onDelete={() => {
                setEducationToDelete(education);
                setIsDeleteModalOpen(true);
              }}
              resumeId={resumeId}
            />
            {index < educations.length - 1 && <Divider my={1} />}
          </React.Fragment>
        ))
      )}
      </VStack>
    </Box>
    {educations.length > 0}
        {isAdding ? (
          <Box px={4}>
          <EducationForm 
            onSubmit={handleAdd} 
            onCancel={() => setIsAdding(false)} 
            resumeId={resumeId}
          />
          </Box>
        ) : (
          <Box 
            borderBottomRadius="12px"
            px="4"
            py="2"
          >
            <Button 
              leftIcon={<LuPlusCircle />} 
              onClick={() => setIsAdding(true)} 
              variant="ghost" 
              size="sm"
              borderRadius="12px"
              textColor="#364954"
            >
              {t('edu.add')}
            </Button>
          </Box>
        )}

    <Modal 
      isOpen={isDeleteModalOpen} 
      onClose={() => setIsDeleteModalOpen(false)}
      >
      <ModalOverlay
        backdropFilter="blur(10px)"
        />
      <ModalContent
        borderRadius={12}
        boxShadow="lg"
        background="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
        _hover={{
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
        }}
        >
        <ModalHeader>{t('edu.delete_modaltitle')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {t('edu.delete_modalbody')}
        </ModalBody>
        <ModalFooter>
          <Button 
            variant="ghost" 
            mr={3} 
            borderRadius="12px"
            onClick={() => setIsDeleteModalOpen(false)}
            isDisabled={isDeleting}
          >
            {t('edu.cancel')}
          </Button>
          <Button 
            colorScheme="red" 
            borderRadius="12px"
            onClick={() => handleDelete(educationToDelete.id)}
            isLoading={isDeleting}
            loadingText={t('edu.deleting')}
          >
            {isDeleting ? <Spinner size="sm" /> : t('edu.delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>
  );
};

export default Education;