import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, ListItem, Link, UnorderedList, Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import HomePageLayout from './HomePageLayout';
import { px } from 'framer-motion';

const PrivacyPolicy = () => {
    const { t, ready } = useTranslation("homepage");
    const [showContent, setShowContent] = useState(false);
  
    useEffect(() => {
      if (ready) {
        setShowContent(true);
      }
    }, [ready]);

    const renderContent = () => (
        <VStack spacing={0} maxW="800px" mx="auto" px={4} align="left" textColor="#364954">
            <Heading as="h1" size="2xl" mb={8}>Privacy Policy</Heading>
            <Text mb={3}>Latest Update: 2024-08-19</Text>
            <Text mb={3}>
                Welcome to mycareerhelp.ai. NexusAI Limited ("NexusAI," "we," "us," "our") operates the mycareerhelp.ai service. This Privacy Policy explains how we collect, use, and protect your personal information when you use our services. By using mycareerhelp.ai, you agree to the practices described in this Privacy Policy.
            </Text>
            <Text mb={6}>
                Please carefully read this Policy before you use or submit any information through or in connection with the Services. If you do not agree with this Policy, please do not access or use our Services or interact with any other aspect of our business. Unless otherwise required by laws in your residence, by using our Services, you accept our privacy practices described in this Policy.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Information We Collect
            </Heading>

            <Heading as="h3" fontSize="16px" mb={3} mt={3}>
                Information You Provide to Us:
            </Heading>
            <Text mb={3}>
                We will collect and store personal information that you provide to us directly through our website when using our products, as well as through other means (such as through user support requests, interacting through social media, participating in surveys or promotions, applying for a job, and interacting on our website and at events). The information we collect includes, such as:
            </Text>
            <UnorderedList spacing="3" mb={6}>
                <ListItem>
                Account Information: When you register for an account, we collect your email address, passwords, and other details you may provide, such as a display name.
                </ListItem>
                <ListItem>
                Content You Provide: As part of the service, we collect and store the content you post, send, receive, and share through our product. This includes any data you enter in any "free text" box on our product, as well as files and links you upload to the service.
                </ListItem>
                <ListItem>
                Support Information: If you contact us for support, we collect information necessary to resolve your issue, such as contact details and any relevant documentation.
                </ListItem>
                <ListItem>
                Payment Information: We use third-party providers like Stripe to process payments. While we collect payment information to facilitate transactions, we do not store credit card details on our servers.
                </ListItem>
            </UnorderedList>

            <Heading as="h3" fontSize="16px" mb={3} mt={3}>
                Information We Collect Automatically:
            </Heading>
            <Text mb={3}>
                When you use our services (including browsing our website and taking certain actions within the service), we may collect information about you.
            </Text>
            <UnorderedList spacing="3" mb={6}>
                <ListItem>
                Usage Data: We track your interactions with our services, such as the features you use, the content you access, and the links you click.
                </ListItem>
                <ListItem>
                Device Information: We collect information about the devices you use to access our services, including IP addresses, browser type, and operating system details.
                </ListItem>
                <ListItem>
                Cookies and Tracking: We and our third-party partners, use cookies and similar technologies to track your activity on our site and improve your experience.
                </ListItem>
            </UnorderedList>

            <Heading as="h3" fontSize="16px" mb={3} mt={3}>
                Information from Other Sources:
            </Heading>
            <Text mb={3}>
                We may receive information about you from third-party services, such as social media platforms or analytics providers, and combine it with data we already hold. We are not responsible for the data policies and procedures or content of any third party. We recommend that you review the privacy policies of each website you visit.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                How We Use Information
            </Heading>
            <Text mb={3}>
                We use the information we collect to:
            </Text>
            <UnorderedList spacing="3" mb={3}>
                <ListItem>
                Provide, maintain, and improve our services.
                </ListItem>
                <ListItem>
                Personalize your experience and offer tailored content.
                </ListItem>
                <ListItem>
                Respond to your inquiries and provide customer support.
                </ListItem>
                <ListItem>
                Process transactions and manage your account.
                </ListItem>
                <ListItem>
                Communicate with you about updates, promotions, and other news related to our services.
                </ListItem>
                <ListItem>
                Comply with legal obligations and protect the security of our services.
                </ListItem>
            </UnorderedList>
            <Text mb={6}>
                We may also use such information in any other way we may describe when you provide the information or for any other purpose with your consent.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                How We Share Information
            </Heading>
            <UnorderedList spacing="3" mb={6}>
                <ListItem>
                With Your Consent: We may share your information with third parties when you give us explicit consent.
                </ListItem>
                <ListItem>
                With Service Providers: We share data with third-party providers who help us operate and improve our services, such as hosting providers, payment processors, and analytics services.
                </ListItem>
                <ListItem>
                With Potential Buyers and Advisors: If there is a company sale, merger, reorganization, dissolution, similar event, or measures taken in anticipation of such an event (such as due diligence in a transaction), your personal information may (in accordance with applicable law) be shared with our advisors and any potential buyer's advisors and be transferred to the new owner of the business.
                </ListItem>
                <ListItem>
                For Legal Reasons: We may share information to comply with legal obligations, enforce our terms, or protect the rights and safety of NexusAI, our users, or others.
                </ListItem>
            </UnorderedList>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Data Storage and Security
            </Heading>
            <UnorderedList spacing="3" mb={6}>
                <ListItem>
                Storage Locations: Your data may be stored and processed in various countries/regions where NexusAI or its partners operate, including the United States, Singapore, China, and the European Economic Area (including the United Kingdom). Our choice of processing location is to ensure efficient operations, improve performance, and create redundancy to protect data in the event of disruptions or other issues. We take measures to ensure that the data we collect in accordance with this Privacy Policy is processed in compliance with this Privacy Policy and applicable laws, regardless of where the data is located.
                </ListItem>
                <ListItem>
                International Data Transfers: NexusAI operates globally and may transfer your personal information to different countries for processing and storage. Specifically, our servers are located in the United States and Singapore. By using our services, you acknowledge and consent to the transfer of your information to these countries. For users in the European Economic Area (EEA), United Kingdom, or Switzerland: Please note that the United States and Singapore may not have data protection laws that are deemed as comprehensive as those in your country. However, we strive to provide an adequate level of protection for your personal information. When transferring data from the EEA, UK, or Switzerland to countries not deemed to provide an adequate level of data protection, we use appropriate safeguards such as Standard Contractual Clauses approved by the European Commission or applicable supervisory authorities.
                </ListItem>
                <ListItem>
                Security Measures: We take reasonable measures to protect your data from unauthorized access, loss, or misuse. However, no system is completely secure, and we cannot guarantee the absolute security of your information.
                </ListItem>
                <ListItem>
                Retention: We retain personal information for as long as necessary for the purposes for which the personal information is processed and for longer periods as necessary for us to comply with applicable laws. For example, we retain your account information for as long as your account is active or as needed to provide you with Services you have requested or authorized, including maintaining and improving the performance of the Services and protecting system security.
                </ListItem>
            </UnorderedList>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Your Rights
            </Heading>
            <Text mb={3}>
            You have the right to:
            </Text>
            <UnorderedList spacing="3" mb={6}>
                <ListItem>
                Access the personal data we hold about you.
                </ListItem>
                <ListItem>
                Request corrections to inaccurate or incomplete data.
                </ListItem>
                <ListItem>
                Request the deletion of your data under certain circumstances.
                </ListItem>
                <ListItem>
                Object to or restrict the processing of your data.
                </ListItem>
                <ListItem>
                Withdraw your consent to data processing at any time.
                </ListItem>
                <ListItem>
                Opt-out of promotional communications.
                </ListItem>
            </UnorderedList>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Children’s Privacy
            </Heading>
            <Text mb={6}>
                Our services are not intended for individuals under the age of 18, and we do not knowingly collect personal data from minors. If we discover that a minor has provided us with personal information, we will take steps to delete such data.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Changes to This Policy
            </Heading>
            <Text mb={6}>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant updates. Your continued use of our services after such changes constitutes your acceptance of the new policy.
            </Text>

            <Heading as="h2" size="md" mb={4} mt={8}>
                Contact Us
            </Heading>
            <Text mb={3}>
                You information is controlled by NexusAI Limited. If you have any questions about this Privacy Policy or our data practices, please contact us at:
            </Text>
            <Text mb={1}>
                NexusAI Limited
            </Text>
            <Text mb={1}>
                Address: Room 1908-09, Lippo Centre Tower 1, 89 Queensway, Admiralty, Hong Kong SAR, China
            </Text>
            <Text mb={6}>
                Email: contact[a]mycareerhelp.ai
            </Text>


        </VStack>
    );


    const renderPlaceholder = () => (
        <VStack spacing={8} maxW="800px" mx="auto" px={4} align="left">
          <Skeleton height="60px" width="60%" />
    
          {[...Array(6)].map((_, index) => (
            <Box key={index}>
              <Skeleton height="24px" width="40%" mb="2" />
              <Skeleton height="20px" width="100%" />
              <Skeleton height="20px" width="100%" />
              <Skeleton height="20px" width="80%" />
            </Box>
          ))}
    
          <Box>
            <Skeleton height="24px" width="40%" mb="2" />
            <Skeleton height="20px" width="100%" />
            <Skeleton height="20px" width="100%" />
            <UnorderedList>
              {[...Array(3)].map((_, index) => (
                <ListItem key={index}>
                  <Skeleton height="20px" width="90%" mt="2" />
                  <Skeleton height="20px" width="80%" />
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        </VStack>
    );

    return (
        <HomePageLayout>
          <Box py={20} px={6} >
            {showContent ? renderContent() : renderPlaceholder()}
          </Box>
        </HomePageLayout>
      );
};

export default PrivacyPolicy;