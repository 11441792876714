// src/components/Credits/Credits.js
import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Flex, Skeleton, Fade } from '@chakra-ui/react';
import CurrentBalance from './CurrentBalance';
import TransactionHistory from './TransactionHistory';
import ReferralProgram from './ReferralProgram';
import { useTranslation } from 'react-i18next';

const Credits = () => {
    const { t } = useTranslation();  
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate data loading
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        // Cleanup timer on unmount
        return () => clearTimeout(timer);
    }, []);

    const SkeletonLoading = () => {
        return (
            <VStack spacing={4} align="stretch">
                <Box bg="white" borderRadius="16px" p={5}>
                    <Skeleton height="24px" width="40%" mb={2} />
                    <Skeleton height="32px" width="60%" mb={4} />
                    <Skeleton height="20px" width="80%" />
                </Box>
                <Box bg="white" borderRadius="16px" p={5}>
                    <Skeleton height="24px" width="50%" mb={2} />
                    <Skeleton height="16px" width="70%" mb={2} />
                    <Skeleton height="16px" width="60%" />
                </Box>
                <Box bg="white" borderRadius="16px" p={5}>
                    <Skeleton height="24px" width="60%" mb={2} />
                    <Skeleton height="16px" width="100%" mb={2} />
                    <Skeleton height="16px" width="100%" mb={2} />
                    <Skeleton height="16px" width="100%" />
                </Box>
            </VStack>
        );
    };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
    <Box height="30px" flexShrink={0} />
        <Flex direction="column" flex="1" overflow="hidden">
            <Heading 
                as="h1" 
                fontSize="2xl" 
                mb={4}
                textColor="#364954"
            >
            {t('credits.title')}
            </Heading>
            <Box
                flex="1"
                bg="#f5f7fa"
                borderTopLeftRadius="16px"
                borderTopRightRadius="16px"
                overflow="auto"
                p={5}
            >

                    <Box flex="1">
                        {loading ? (
                            <SkeletonLoading />
                        ) : (
                            <Fade in={!loading}>
                                <VStack spacing={4} align="stretch">
                                    <CurrentBalance />
                                    <ReferralProgram />
                                    <TransactionHistory />
                                </VStack>
                            </Fade>
                        )}
                    </Box>

            </Box>
        </Flex>
    </Flex>
  );
};

export default Credits;