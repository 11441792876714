import React, { useContext } from 'react';
import { Input, Select, Button, HStack, Text, Tooltip, Grid, GridItem, FormControl, FormLabel, FormErrorMessage, useToast, Box, Textarea } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosConfig';
import CustomDateDropdown from './CustomDateDropdown'
import { UserContext } from '../UserContext';

const EducationForm = ({ education, onSubmit, onCancel, resumeId }) => {
  const { t } = useTranslation();  
  const toast = useToast();
  const initialValues = education || {
    institution_name: '',
    degree: '',
    major: '',
    start: '',
    end: '',
    academic_achievements: '',
    related_coursework: ''
  };
  const { fetchUserProfile } = useContext(UserContext);


  const validate = (values) => {
    const errors = {};
    if (!values.institution_name.trim()) errors.institution_name = 'Required';
    if (!values.degree) errors.degree = 'Required';
    if (!values.major.trim()) errors.major = 'Required';
    if (!values.start) errors.start = 'Required';
    return errors;
  };

  const checkResumeCompletion = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/resumes/${resumeId}/`);
      if (response.data) {
        const resume = response.data;
        return (
          resume.education && resume.education.length > 0 &&
          resume.experiences && resume.experiences.length > 0 &&
          resume.skills && resume.skills.length > 0
        );
      }
      return false;
    } catch (error) {
      console.error('Error checking resume completion:', error);
      return false;
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await onSubmit({ ...values, resume: resumeId });
      
      // Check if all sections are complete
      const isResumeComplete = await checkResumeCompletion();
      
      if (isResumeComplete) {
        // Fetch the user's onboarding tasks
        const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');
        
        if (onboardingTasksResponse.status === 200) {
          const tasks = onboardingTasksResponse.data;
          const createResumeTask = tasks.find(task => task.task_id === 2);
          
          if (createResumeTask && !createResumeTask.is_completed) {
            // Call the API to complete the task only if it's not already completed
            try {
              const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-create-resume-task/');
              
              if (onboardingResponse.status === 200) {
                await fetchUserProfile();

                toast({
                  title: t('resumepage.onboardingtask.toast-complete'),
                  description: `${t('resumepage.onboardingtask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('resumepage.onboardingtask.toast-complete-des2')}`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            } catch (error) {
              console.error('Error completing resume task:', error);
              // You might want to show an error toast here
            }
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
      toast({
        title: t('edu.add_fail'),
        description: t('edu.add_fail_description'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="4">
            <GridItem>
              <FormControl isInvalid={errors.degree && touched.degree}>
                <FormLabel>{t('edu.entry_degree_title')}</FormLabel>
                <Field as={Select} name="degree" placeholder={t('edu.entry_degree_placeholder')}>
                  <option value="bachelor">{t('edu.entry_degree_bachelor')}</option>
                  <option value="master">{t('edu.entry_degree_master')}</option>
                  <option value="doctor">{t('edu.entry_degree_doctor')}</option>
                  <option value="others">{t('edu.entry_degree_others')}</option>
                </Field>
                <FormErrorMessage>{errors.degree}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={errors.major && touched.major}>
                <FormLabel>{t('edu.entry_major_title')}</FormLabel>
                <Field as={Input} name="major" placeholder={t('edu.entry_major_placeholder')} />
                <FormErrorMessage>{errors.major}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isInvalid={errors.institution_name && touched.institution_name}>
              <Tooltip 
                hasArrow 
                borderRadius={12} 
                bg='#CFD9DF' 
                color='#364954' 
                placement='top-start'
                label={
                  <Box p={1}>
                  {t('edu.entry_institution_tooltip')}
                  </Box>
                } 
              >  
                  <FormLabel>
                  {t('edu.entry_institution_title')}
                  </FormLabel>
              </Tooltip>
                <Field as={Input} name="institution_name" placeholder={t('edu.entry_institution_placeholder')} />
                <FormErrorMessage>{errors.institution_name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={errors.start && touched.start}>
                <FormLabel>{t('edu.entry_period_title')}</FormLabel>
                <HStack>
                  <CustomDateDropdown
                    name="start"
                    placeholder={t('edu.entry_period_start')}
                    onChange={(value) => setFieldValue('start', value)}
                    value={values.start}
                  />
                  <Text>-</Text>
                  <CustomDateDropdown
                    name="end"
                    placeholder={t('edu.entry_period_end')}
                    onChange={(value) => setFieldValue('end', value)}
                    value={values.end}
                    includePresent={true}
                  />
                </HStack>
                <FormErrorMessage>{errors.start}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>{t('edu.entry_academic_title')}</FormLabel>
                <Field as={Textarea} name="academic_achievements" placeholder={t('edu.entry_academic_placeholder')} />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>{t('edu.entry_coursework_title')}</FormLabel>
                <Field as={Input} name="related_coursework" placeholder={t('edu.entry_coursework_placeholder')} />
              </FormControl>
            </GridItem>            
          </Grid>
          <HStack mt={4} mb={4} justify="flex-end">
            <Button 
              size="sm"
              onClick={onCancel}
              borderRadius="12px"
              >
            {t('edu.cancel')}
            </Button>
            <Button 
              size="sm"
              type="submit" 
              color="#ffffff" 
              backgroundColor="#364954"
              borderRadius="12px"
              _hover={{
                color:"#ffffff",
                backgroundColor:"#82B2CA"
              }}
              isLoading={isSubmitting}
              >
            {t('edu.save')}
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default EducationForm;