import React, { useState } from 'react';
import { Box, Text, IconButton, HStack, VStack, Spinner } from '@chakra-ui/react';
import { LuPenLine, LuTrash2 } from "react-icons/lu";
import SkillForm from './SkillForm';

const SkillEntry = ({ skill, onUpdate, onDelete, resumeId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // Utility function to truncate display fields
  const truncateName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };

  const maxLength = 35; // Define your desired maximum length
  const skill_name = truncateName(skill.skill_name, maxLength);
  

  const handleUpdate = async (updatedSkill) => {
    setIsUpdating(true);
    try {
      await onUpdate(updatedSkill);
      setIsEditing(false);
    } finally {
      setIsUpdating(false);
    }
  };

  if (isEditing) {
    return (
      <SkillForm
        skill={skill}
        onSubmit={handleUpdate}
        onCancel={() => setIsEditing(false)}
        resumeId={resumeId}
      />
    );
  }

  return (
    <Box py={1}>
      <HStack align="flex-start" justify="space-between">
        <VStack align="stretch" spacing={1}>
          <Text fontWeight="bold" fontSize="md" textColor="#364954">{skill_name}</Text>
          <Text fontSize="sm" textColor="#364954">{skill.description}</Text>
        </VStack>
        <HStack>
          {isUpdating ? (
            <Spinner size="sm" />
          ) : (
            <>
              <IconButton
                icon={<LuPenLine />}
                aria-label="Edit skill"
                onClick={() => setIsEditing(true)}
                variant="ghost"
                size="sm"
              />
              <IconButton
                icon={<LuTrash2 />}
                aria-label="Delete skill"
                onClick={onDelete}
                variant="ghost"
                size="sm"
              />
            </>
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default SkillEntry;