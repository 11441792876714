import React, { useState, useEffect } from 'react';
import {
  Box, Heading,
  Text, Button,
  VStack, Divider, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Tooltip,
  HStack, Skeleton, SkeletonText, Spinner
} from '@chakra-ui/react';
import { LuPlusCircle, LuHelpCircle } from "react-icons/lu";
import axiosInstance from '../../utils/axiosConfig';
import SkillEntry from './SkillEntry';
import SkillForm from './SkillForm';
import { useTranslation } from 'react-i18next';

const Skills = ({ resumeId, updateResumeState }) => {
  const { t } = useTranslation();  
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchSkills();
  }, [resumeId]);

  const fetchSkills = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/skills/?resume=${resumeId}`);
      setSkills(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch skills');
      setLoading(false);
    }
  };

  const handleAdd = async (newSkill) => {
    try {
      const response = await axiosInstance.post('/api/v1/resumes/skills/', {
        ...newSkill,
        resume: resumeId
      });
      setSkills([...skills, response.data]);
      setIsAdding(false);
      toast({
        title: t('skill.add_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to add skill');
      toast({
        title: t('skill.add_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleUpdate = async (updatedSkill) => {
    try {
      const response = await axiosInstance.put(`/api/v1/resumes/skills/${updatedSkill.id}/`, {
        ...updatedSkill,
        resume: resumeId
      });
      setSkills(skills.map(skill => skill.id === updatedSkill.id ? response.data : skill));
      toast({
        title: t('skill.update_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to update skill');
      toast({
        title: t('skill.update_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/api/v1/resumes/skills/${id}/`);
      setSkills(skills.filter(skill => skill.id !== id));
      setIsDeleteModalOpen(false);
      toast({
        title: t('skill.delete_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to delete skill');
      toast({
        title: t('skill.delete_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const SkillSkeleton = () => (
    <Box>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='3' />
      <SkeletonText mt='4' noOfLines={2} spacing='4' />
      <Skeleton height='20px' mt='4' />
    </Box>
  );

  if (loading) return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
    >
      <Box
        p="4"
      >
        <HStack align="flex-start" justify="space-between">
          <Skeleton height="24px" width="150px" />
          <Skeleton height="24px" width="24px" />
        </HStack>
        <Divider my={4} />
        <VStack spacing={4} align="stretch">
          <SkillSkeleton />
          <SkillSkeleton />
        </VStack>
      </Box>
    </Box>
  );
  if (error) return <Box>{error}</Box>;

  return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
      transition="all 0.3s ease"
      _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
    <Box
      p={4}
      pb={2}
    >
        <HStack align="flex-start" justify="space-between">
          <Heading as="h2" fontSize="lg" mb={1} textColor="#364954">{t('skill.section_title')}</Heading>
          <Tooltip 
            hasArrow 
            borderRadius={12} 
            bg='#CFD9DF' 
            color='#364954' 
            placement='top'
            label={
              <Box p={1}>
              {t('skill.section_intro')}
              </Box>
            } 
          >
            <Text><LuHelpCircle /></Text>
          </Tooltip>
        </HStack>
      {skills.length > 0 && <Divider my={1} />}
      <VStack spacing={0} align="stretch">
        {skills.length === 0 ? (
          <Text
            fontSize="sm"
            textColor="#737D82"
            mt="2"
            mb="1"
          >
          {t('skill.norecord')}</Text>
        ) : (
        skills.map((skill, index) => (
          <React.Fragment key={skill.id}>
            <SkillEntry
              skill={skill}
              onUpdate={handleUpdate}
              onDelete={() => {
                setSkillToDelete(skill);
                setIsDeleteModalOpen(true);
              }}
              resumeId={resumeId}
            />
            {index < skills.length - 1 && <Divider my={1} />}
          </React.Fragment>
        ))
      )}
      </VStack>
    </Box>
    {skills.length > 0}
        {isAdding ? (
          <Box px={4}>
          <SkillForm 
            onSubmit={handleAdd} 
            onCancel={() => setIsAdding(false)} 
            resumeId={resumeId}
          />
          </Box>
        ) : (
          <Box 
            borderBottomRadius="12px"
            px="4"
            py="2"
          >
            <Button 
              leftIcon={<LuPlusCircle />} 
              onClick={() => setIsAdding(true)} 
              variant="ghost" 
              size="sm"
              borderRadius="12px"
              textColor="#364954"
            >
              {t('skill.add')}
            </Button>
          </Box>
        )}

    <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay
            backdropFilter="blur(10px)"
        />
        <ModalContent
          borderRadius={12}
          boxShadow="lg"
          background="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(10px)"
          transition="all 0.1s ease"
          _hover={{
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
        <ModalHeader>{t('skill.delete_modaltitle')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {t('skill.delete_modalbody')}
        </ModalBody>
        <ModalFooter>
          <Button 
            variant="ghost" 
            mr={3} 
            borderRadius="12px"
            onClick={() => setIsDeleteModalOpen(false)}
            isDisabled={isDeleting}
          >
            {t('skill.cancel')}
          </Button>
          <Button 
            colorScheme="red" 
            borderRadius="12px"
            onClick={() => handleDelete(skillToDelete.id)}
            isLoading={isDeleting}
            loadingText={t('skill.deleting')}
          >
            {isDeleting ? <Spinner size="sm" /> : t('skill.delete')}
          </Button>
      </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>
  );
};

export default Skills;