import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, ListItem, Link, UnorderedList, Skeleton } from '@chakra-ui/react';
import HomePageLayout from './HomePageLayout';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
  const { t, ready } = useTranslation("homepage");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const renderContent = () => (
    <VStack spacing={8} maxW="800px" mx="auto" px={4} align="left" textColor="#364954">
      <Heading as="h1" size="2xl">{t('aboutus.title')}</Heading>

      <Box>
        <Heading as="h2" fontSize="18px" mb="2">{t('aboutus.mission.title')}</Heading>
        <Text lineHeight={1.5}>{t('aboutus.mission.description')}</Text>
      </Box>

      <Box>
        <Heading as="h2" fontSize="18px" mb="2">{t('aboutus.whoWeAre.title')}</Heading>
        <Text lineHeight={1.5}>
          {t('aboutus.whoWeAre.description')}
        </Text>
      </Box>

      <Box>
        <Heading as="h2" fontSize="18px" mb="2">{t('aboutus.philosophy.title')}</Heading>
        <Text lineHeight={1.5}>
          {t('aboutus.philosophy.intro')}
          <UnorderedList>
            <ListItem>
              <Text as="b">{t('aboutus.philosophy.transparency.title')}</Text>
              {t('aboutus.philosophy.transparency.description')}
            </ListItem>
            <ListItem>
              <Text as="b">{t('aboutus.philosophy.userCentric.title')}</Text>
              {t('aboutus.philosophy.userCentric.description')}
            </ListItem>
            <ListItem>
              <Text as="b">{t('aboutus.philosophy.improvement.title')}</Text>
              {t('aboutus.philosophy.improvement.description')}
            </ListItem>
          </UnorderedList>
        </Text>
      </Box>

      <Box>
        <Heading as="h2" fontSize="18px" mb="2">{t('aboutus.future.title')}</Heading>
        <Text lineHeight={1.5}>{t('aboutus.future.description')}</Text>
      </Box>

      <Box>
        <Heading as="h2" fontSize="18px" mb="2">{t('aboutus.legal.title')}</Heading>
        <Text lineHeight={1.5}>{t('aboutus.legal.description')}</Text>
      </Box>

      <Box>
        <Heading as="h2" fontSize="18px" mb="2">{t('aboutus.contact.title')}</Heading>
        <Text lineHeight={1.5}>{t('aboutus.contact.description')}</Text>
      </Box>
    </VStack>
  );

  const renderPlaceholder = () => (
    <VStack spacing={8} maxW="800px" mx="auto" px={4} align="left">
      <Skeleton height="60px" width="60%" />

      {[...Array(6)].map((_, index) => (
        <Box key={index}>
          <Skeleton height="24px" width="40%" mb="2" />
          <Skeleton height="20px" width="100%" />
          <Skeleton height="20px" width="100%" />
          <Skeleton height="20px" width="80%" />
        </Box>
      ))}

      <Box>
        <Skeleton height="24px" width="40%" mb="2" />
        <Skeleton height="20px" width="100%" />
        <Skeleton height="20px" width="100%" />
        <UnorderedList>
          {[...Array(3)].map((_, index) => (
            <ListItem key={index}>
              <Skeleton height="20px" width="90%" mt="2" />
              <Skeleton height="20px" width="80%" />
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </VStack>
  );

  return (
    <HomePageLayout>
      <Box py={20} px={6}>
        {showContent ? renderContent() : renderPlaceholder()}
      </Box>
    </HomePageLayout>
  );
};

export default AboutPage;