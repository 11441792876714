import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  Text,
  VStack,
  useToast,
  Flex, Box,
  IconButton, UnorderedList, ListItem, Divider,
  Spinner
} from '@chakra-ui/react';
import { ArrowBigRightDash } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

const ProHelpMeAnswerModal = ({ isOpen, onClose, questionContent, question, title, company, onConfirm, promptQuestions }) => {
    const { t } = useTranslation();  
    const [userInput, setUserInput] = useState('');
    const [hasReachedLimit, setHasReachedLimit] = useState('');
    const [generatedAnswer, setGeneratedAnswer] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [remainingAttempts, setRemainingAttempts] = useState(3);
    const toast = useToast();
    const maxWordCount = 499;
    const [wordCount, setWordCount] = useState(0);

    useEffect(() => {
        if (!isOpen) {
        // Reset state when modal is closed
        setUserInput('');
        setGeneratedAnswer('');
        }
    }, [isOpen]);

    const fetchHelperState = async () => {
        try {
        const response = await axiosInstance.get('/api/v1/services/write-with-ai-pro/generate-helper-result/', {
            params: { question_id: question.id }
        });
        setRemainingAttempts(response.data.remaining_attempts);
        setHasReachedLimit(response.data.has_reached_limit);
        } catch (error) {
        console.error('Failed to fetch question state:', error);
        toast({
            title: "Error",
            description: "Failed to fetch question state. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
        });
        }
    };

    useEffect(() => {
        if (isOpen) {
        fetchHelperState();
        }
    }, [isOpen, question.id]);

    const handleInputChange = (e) => {
        const input = e.target.value;
        if (input.length <= maxWordCount) {
            setUserInput(input); // Update the userInput with the new value
            setWordCount(input.length); // Update the word count
        }
    };

    const handleGenerateAnswer = async () => {
        if (hasReachedLimit) {
            toast({
              title: t('prodrawer.helpermodal.toast-limite-title'),
              description: t('prodrawer.helpermodal.toast-limite-desc'),
              status: "warning",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
        
        setIsGenerating(true);
        try {
            const response = await axiosInstance.post('/api/v1/services/write-with-ai-pro/generate-helper-result/', {
                question_id: question.id,
                user_input: userInput,
                title: title,
                company: company,
            });

            setGeneratedAnswer(response.data.helper_result);
            setRemainingAttempts(response.data.remaining_attempts);
            setHasReachedLimit(response.data.has_reached_limit);
            setIsGenerating(false);

        } catch (error) {
            if (error.response && error.response.status === 403) {
                setRemainingAttempts(0);
                setHasReachedLimit(true);
                toast({
                  title: t('prodrawer.helpermodal.toast-limite-title'),
                  description: t('prodrawer.helpermodal.toast-limite-desc'),
                  status: "warning",
                  duration: 3000,
                  isClosable: true,
                });
            } else {
                toast({
                  title: "Error",
                  description: "Failed to generate answer. Please try again.",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
            }
        } finally {
            setIsGenerating(false);
        }
    };

    const handleConfirm = () => {
        onConfirm(generatedAnswer);
        onClose();
    };

    return (
    <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        closeOnOverlayClick={false} 
        closeOnEsc={false}
        size="3xl"
    >
        <ModalOverlay 
            backdropFilter="blur(10px)"            
        />
        <ModalContent
            borderRadius={16}
            boxShadow="lg"
            background="rgba(255, 255, 255, 1)"
            transition="all 0.1s ease"         
        >
            <ModalHeader>{t('prodrawer.helpermodal.title')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <VStack align="stretch">
                <Text fontSize="14px">
                {t('prodrawer.helpermodal.desc')}
                </Text>
                <Box
                    mt={2}
                    p={3}
                    fontSize="14px"
                    fontWeight="bold"
                    textColor="#364954"
                    borderRadius={12}
                    bg="#f5f7fa"
                >
                    <Text >{t('prodrawer.helpermodal.question')}:</Text>
                    <Text fontSize="16px">{questionContent}</Text>

                    {promptQuestions && <Box
                        mt={2}
                        fontSize="14px"
                        textColor="#364954"
                    >
                        <Divider />
                        <Text fontWeight="medium" mt={2}>{t('prodrawer.helpermodal.promptquestions')}:</Text>
                        <UnorderedList spacing={1} mt="1">
                            {promptQuestions.map((question, index) => (
                            <ListItem key={index}>
                                <Text fontWeight="normal">{question}</Text>
                            </ListItem>
                            ))}
                        </UnorderedList>
                    </Box>
                    }
                </Box>

                <Flex
                    direction="row"
                    mt={2}
                >
                    <Box
                        width="35%">
                    <Textarea
                        borderRadius={12}
                        value={userInput}
                        onChange={handleInputChange}
                        placeholder={t('prodrawer.helpermodal.placeholder-input')}
                        minHeight="150px"
                        fontSize="14px"
                        textColor="#364954"
                        isDisabled={isGenerating || remainingAttempts <= 0}
                    />
                    <Flex justifyContent="space-between" mt={2}>
                        <Text 
                            fontSize="12px" 
                            textColor="#737D82"
                        >
                            {remainingAttempts} {t('prodrawer.helpermodal.attemptsleft')}
                        </Text>
                        <Text fontSize="12px" color={wordCount === maxWordCount ? "red.500" : "#737D82"}>
                            {wordCount}/{maxWordCount}
                        </Text>
                    </Flex>
                    </Box>
                    <Box
                        px={2}
                        minHeight="150px"
                        alignContent="top"
                        mt={6}
                    >
                        <IconButton
                            icon={ isGenerating ? <Spinner /> : <ArrowBigRightDash /> }
                            onClick={handleGenerateAnswer} 
                            borderRadius="full"
                            isLoading={isGenerating}
                            isDisabled={!userInput || remainingAttempts <= 0 || isGenerating}
                            _hover={{
                                color:"#ffffff",
                                backgroundColor:"#82B2CA"
                            }}
                            color="#ffffff" 
                            backgroundColor="#364954"
                        />
                    </Box>
                    <Box
                        width="65%"
                        position="relative"
                    >
                        <Box
                            p={2}
                            borderRadius={12}
                            minHeight="150px"
                            fontSize="14px"
                            fontWeight="medium"
                            textColor="#364954"
                            bg="#f5f7fa"
                            overflowY="auto"
                        >
                            {isGenerating ? 
                            <Spinner size="sm" /> :
                            generatedAnswer ?
                            generatedAnswer : t('prodrawer.helpermodal.placeholder-generatedcontent')
                            }
                        </Box>
                        <Flex justifyContent="flex-end" mt={2}>
                            <Text
                                fontSize="12px" 
                                textColor="#737D82"
                            >
                                {t('prodrawer.helpermodal.noticeforeditlater')}
                            </Text>
                        </Flex>
                    </Box>
                </Flex>

            </VStack>
            </ModalBody>
            <ModalFooter>
            <Button 
                variant="ghost" 
                borderRadius="16px"
                mr={3} 
                onClick={onClose}
                isDisabled={isGenerating}
            >
                {t('prodrawer.helpermodal.btn-cancel')}
            </Button>
            <Button 
                color="#ffffff" 
                backgroundColor="#364954"
                borderRadius="16px"
                _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                }}
                onClick={handleConfirm} 
                isDisabled={!generatedAnswer || isGenerating}
            >
                {t('prodrawer.helpermodal.btn-confirm')}
            </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
  );
};

export default ProHelpMeAnswerModal;
