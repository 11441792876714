import React, { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, Icon, List, ListItem, ListIcon, Skeleton, SkeletonText } from '@chakra-ui/react';
import { ShieldCheck, Rocket, CircleCheck } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const CommitmentCard = ({ commitment, isLoading }) => {
    const { t } = useTranslation("homepage");

  if (isLoading) {
    return (
      <VStack p={0} bg="gray.50" borderRadius="16px" borderWidth={1}>
        <Box bg="white" borderTopRadius="16px" p="6">
          <Skeleton height="80px" width="200px" mb="2" />
          <SkeletonText mt="4" noOfLines={1} spacing="4" />
          <SkeletonText mt="4" noOfLines={3} spacing="4" />
        </Box>
        <Box borderBottomRadius="16px" p="6">
          <SkeletonText mt="4" noOfLines={2} spacing="4" />
          <SkeletonText mt="4" noOfLines={2} spacing="4" />
          <SkeletonText mt="4" noOfLines={2} spacing="4" />
        </Box>
      </VStack>
    );
  }

  return (
    <VStack p={0} bg="gray.50" borderRadius="16px" borderWidth={1}>
      <Box bg="white" borderTopRadius="16px" p="6" width="100%" minH="200px">
        <Icon as={commitment.icon} boxSize={10} color="#364954" mb="2" />
        <Heading as="h3" fontSize="20px" mb="4" color="#364954">{t(commitment.title)}</Heading>
        <Text textAlign="left" textColor="#364954" fontSize="16px" mb="2">
          {t(commitment.description)}
        </Text>
      </Box>
      <Box borderBottomRadius="16px" p="6" align="top">
        <List spacing={6} textAlign="left" textColor="#364954" width="100%">
          {commitment.bullets.map((bullet, index) => (
            <ListItem key={index}>
              <ListIcon as={CircleCheck} color="#364954" />
              <strong>{t(bullet.title)}</strong> {t(bullet.content)}
            </ListItem>
          ))}
        </List>
      </Box>
    </VStack>
  );
};

const OurCommitment = () => {
  const { t, ready } = useTranslation("homepage");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const commitments = [
    { 
      title: "commitment.privacyFirst.title", 
      icon: ShieldCheck, 
      description: "commitment.privacyFirst.description",
      bullets: [
        { 
          title: "commitment.privacyFirst.bullets.noDataSharing.title",
          content: "commitment.privacyFirst.bullets.noDataSharing.content",
        },
        { 
          title: "commitment.privacyFirst.bullets.anonymityOption.title",
          content: "commitment.privacyFirst.bullets.anonymityOption.content",
        },
        { 
          title: "commitment.privacyFirst.bullets.easyDataRemoval.title",
          content: "commitment.privacyFirst.bullets.easyDataRemoval.content",
        },
        { 
          title: "commitment.privacyFirst.bullets.minimalRegistration.title",
          content: "commitment.privacyFirst.bullets.minimalRegistration.content",
        },
      ] 
    },
    { 
      title: "commitment.continuousImprovement.title", 
      icon: Rocket, 
      description: "commitment.continuousImprovement.description",
      bullets: [
        { 
          title: "commitment.continuousImprovement.bullets.aiEnhancement.title",
          content: "commitment.continuousImprovement.bullets.aiEnhancement.content",
        },
        { 
          title: "commitment.continuousImprovement.bullets.newFeatures.title",
          content: "commitment.continuousImprovement.bullets.newFeatures.content",
        },
        { 
          title: "commitment.continuousImprovement.bullets.userDrivenDevelopment.title",
          content: "commitment.continuousImprovement.bullets.userDrivenDevelopment.content",
        },
      ] 
    },
  ];

  return (
    <Box 
      py={20} 
      px={6} 
      // bg="#FFFFFF"
      bg="linear-gradient(to bottom, #f5f7fa 0%, #e7f0fd 100%)"
    >
      <Heading as="h2" size="xl" textAlign="center" mb={10}>
        {showContent ? t('commitment.title') : <Skeleton height="40px" width="300px" mx="auto" />}
      </Heading>
      <SimpleGrid columns={[1, null, 2, 2]} spacing={10} maxW="1200px" mx="auto">
        {commitments.map((commitment, index) => (
          <CommitmentCard key={index} commitment={commitment} isLoading={!showContent} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default OurCommitment;