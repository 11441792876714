import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, 
    Box, Button, useToast, Text, 
    HStack, Badge, Skeleton, SkeletonText, Center
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';


const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const { t } = useTranslation();  

  const fetchTickets = async (currentPage) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/v1/feedback/tickets/?page=${currentPage}`);
      setTickets(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 5)); // Assuming 5 items per page
    } catch (error) {
      console.error('Error fetching tickets:', error);
      toast({
        title: "Error fetching tickets",
        description: "Unable to load tickets. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        });
    } finally {
    setIsLoading(false);
    }
};

  useEffect(() => {
    fetchTickets(currentPage);
  }, [currentPage]);

  const categoryName = {
    'BUG': { text: t('feedback.table.cat-bug'), colorScheme: "red" },
    'FEATURE': { text: t('feedback.table.cat-feature'), colorScheme: "green" },
    'UX': { text: t('feedback.table.cat-ux'), colorScheme: "blue" },
    'OTHER': { text: t('feedback.table.cat-other'), colorScheme: "gray" },

  }

  const getCategoryName = (category) => {
    return categoryName[category] || t('feedback.table.cat-other');
  }

  if (tickets.length === 0) {
    return (
      <Box align="center"
        borderRadius={16}
      >
        <Center height="150px">
        <Text fontSize="14px" color="#737D82">{t('feedback.table.nodata')}</Text>
        </Center>
      </Box>
    );
  }

  return (
    <Box>
      <Table variant="simple" colorScheme='blackAlpha' size="sm" textColor="#364954">
        <Thead>
          <Tr>
            <Th width="800px">{t('feedback.table.th-des')}</Th>
            <Th>{t('feedback.table.th-createat')}</Th>
            <Th>{t('feedback.table.th-actions')}</Th>
          </Tr>
        </Thead>
        <Tbody>
            {isLoading ? (
                // Loading skeleton
                [...Array(5)].map((_, index) => (
                <Tr key={index}>
                    <Td>
                    <SkeletonText noOfLines={2} spacing="4" />
                    </Td>
                    <Td>
                    <Skeleton height="20px" width="100px" />
                    </Td>
                    <Td>
                    <Skeleton height="20px" width="50px" />
                    </Td>
                </Tr>
                ))
            ) : (
            // Actual data
            tickets
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((ticket) => (
            <Tr key={ticket.id}>
              <Td>
                <HStack>
                <Text whiteSpace="pre-wrap" noOfLines={2} fontSize="14px">
                    {ticket.description}
                </Text>
                <Badge ml="2" colorScheme={getCategoryName(ticket.category).colorScheme} borderRadius="6">
                    {getCategoryName(ticket.category).text}
                </Badge>
                </HStack>
              </Td>
              <Td>{new Date(ticket.created_at).toLocaleDateString()}</Td>
              <Td>
                <Button 
                    as={Link} 
                    to={`/feedback/${ticket.id}`} 
                    size="sm"
                    mt="2" mb="2"
                    variant="link"
                >
                  {t('feedback.table.tb-view')}
                </Button>
              </Td>
            </Tr>
            ))
        )}
        </Tbody>
      </Table>
      <HStack justifyContent="right" mt={4}>
            <Text
                fontSize="14px"
                textColor="#737D82"
                mr="6"
            >
                {currentPage} of {totalPages}
            </Text>
            <Button 
                onClick={() => setCurrentPage(prev => prev - 1)} 
                isDisabled={currentPage === 1}
                borderRadius="16px"
                size="sm"
            >
            <ChevronLeft />
            </Button>
            <Button 
                onClick={() => setCurrentPage(prev => prev + 1)} 
                isDisabled={currentPage === totalPages}
                borderRadius="16px"
                size="sm"
            >
            <ChevronRight />
            </Button>
        </HStack>
    </Box>
  );
};

export default TicketList;