import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text, Box
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, isLoading, onClose, onConfirm }) => {
    const { t } = useTranslation();  

    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
        >
        <ModalOverlay 
            backdropFilter="blur(10px)"
        />
        <ModalContent
            borderRadius={16}
            boxShadow="lg"
            background="rgba(255, 255, 255, 1)"
            transition="all 0.1s ease"      
        >
            <ModalHeader>{t('writewithai.confirmmodal.title')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box>
                    <Text>
                        {t('writewithai.confirmmodal.desc')}
                    </Text>
                </Box>
                <Box>
                    <Text fontSize="14px" textColor="#737D82" mt={4}>
                        {t('writewithai.confirmmodal.notice')}
                    </Text>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button 
                    mr={3} 
                    onClick={onConfirm}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    color="#ffffff" 
                    backgroundColor="#364954"
                    borderRadius="16px"
                    _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                    }}
                >
                    {t('writewithai.confirmmodal.btn-confirm')}
                </Button>
                <Button 
                    variant="ghost" 
                    borderRadius="16px"
                    isDisabled={isLoading}
                    onClick={onClose}
                >
                    {t('writewithai.confirmmodal.btn-cancel')}
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;