import React, { useState, useEffect, useContext } from 'react';
import { Box, Flex, VStack, Heading, useToast } from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import ChatList from './ChatList';
import Chatbot from './Chatbot';
import { UserContext } from '../UserContext';


const ChatPage = () => {
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedChatMessages, setSelectedChatMessages] = useState([]);
  const [chatRounds, setChatRounds] = useState([]);
  const toast = useToast();
  const { updateCreditBalance } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await fetchChats();
      await fetchChatRounds();
      setIsLoading(false);
    };
    loadData();
  }, []);

  const fetchChats = async () => {
    try {
      const response = await axiosInstance.get('api/v1/chatbot/chats/');
      setChats(response.data);
    } catch (error) {
      console.error('Error fetching chats:', error);
      toast({
        title: "Error fetching chats",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchChatRounds = async () => {
    try {
      const response = await axiosInstance.get('api/v1/chatbot/chat-rounds/');
      setChatRounds(response.data);
    } catch (error) {
      console.error('Error fetching chat rounds:', error);
      toast({
        title: "Error fetching chat options",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelectChat = async (chatId) => {
    setSelectedChatId(chatId);
    try {
      const response = await axiosInstance.get(`api/v1/chatbot/chats/${chatId}/`);
      const formattedMessages = response.data.rounds.map(round => [
        { type: 'user', content: round.user_input },
        { type: 'bot', content: round.robot_b_output }
      ]).flat();
      setSelectedChatMessages(formattedMessages);
    } catch (error) {
      console.error('Error fetching chat details:', error);
      toast({
        title: "Error fetching chat details",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewChat = async (comboId) => {
    try {
      const response = await axiosInstance.post('api/v1/chatbot/chats/', { chat_rounds_id: comboId });
      setChats([...chats, response.data.chat]);
      setSelectedChatId(response.data.chat.id);
      setSelectedChatMessages([]);
      updateCreditBalance(response.data.new_credit_balance);

      toast({
        title: "New chat created",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return { newCreditBalance: response.data.new_credit_balance };

    } catch (error) {
      console.error('Error creating new chat:', error);
      toast({
        title: "Error creating new chat",
        description: error.response?.data?.error || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChatDeleted = (deletedChatId) => {
    setChats(chats.filter(chat => chat.id !== deletedChatId));
    if (selectedChatId === deletedChatId) {
      setSelectedChatId(null);
      setSelectedChatMessages([]);
    }
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Flex direction="column" minHeight="100%" height="100%">
      <Box height="30px" flexShrink={0} />
      <Flex direction="column" flex="1" overflow="hidden">
        <Heading as="h1" fontSize="2xl" mb={4} textColor="#364954">My Chats </Heading>
        <Flex 
          flex="1" 
          minHeight="0"
          bg="#f5f7fa"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
        >
          {/* Chat List */}
          <Box
            bg="#f5f7fa"
            borderTopLeftRadius="16px"
            borderTopRightRadius="16px"
            overflow="auto"
            p={4}
            mr={1}
            width="250px"
          >
            <VStack>
              <ChatList 
                chats={chats} 
                selectedChatId={selectedChatId}
                onSelectChat={handleSelectChat} 
                onNewChat={handleNewChat}
                chatRounds={chatRounds}
              />
            </VStack>
          </Box>

          {/* Chat Box Window */}
          <Box
                flex="1"
                p={4}
                bg="#f5f7fa"
                borderTopLeftRadius="16px"
                borderTopRightRadius="16px"
                overflow="auto"
            >
                {selectedChatId ? (
                <Chatbot 
                    chatId={selectedChatId} 
                    initialMessages={selectedChatMessages}
                    onMessageSent={fetchChats}
                    onChatDeleted={handleChatDeleted}
                    height="100%"
                />
                ) : (
                <VStack justify="center" height="100%">
                    <Box>Select a chat or start a new one</Box>
                </VStack>
                )}
            </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatPage;