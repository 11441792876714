// src/components/ResumeCopilotDrawer/CopilotDrawer.js
import React, { useState, useEffect } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel, Box, Text,
  Skeleton, SkeletonText,
  Collapse,
  Button, Flex, Fade
} from '@chakra-ui/react';
import EvaluationResult from './EvaluationResult';
import RetouchResult from './ContentRetouch';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

const CopilotDrawer = ({ isOpen, onClose, resumeId, entryId, entryType, entry, entryUpdatedAt, targetJob, onUpdateEntry, isUpdating }) => {
  const [session, setSession] = useState(null);
  const { t } = useTranslation();  
  const [isLoading, setIsLoading] = useState(false);
  const [latestEvaluationResult, setLatestEvaluationResult] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isOpen) {
      checkOrCreateSession();
    }
  }, [isOpen]);

  const updateLatestEvaluationResult = (result) => {
    setLatestEvaluationResult(result);
  };

  const checkOrCreateSession = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/v1/services/copilot/check-sessions/', {
        params: { resume_id: resumeId, entry_id: entryId, entry_type: entryType }
      });

      if (response.data.length > 0) {
        setSession(response.data[0]);
      } else {
        const sessionData = {
          resume: resumeId,
          [entryType]: entryId
        };
        const newSession = await axiosInstance.post('/api/v1/services/copilot/start/', sessionData);
        setSession(newSession.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking or creating session:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const DrawerContentSkeleton = () => (
    <>
      <Box mb={4}>
        <Skeleton height="20px" width="200px" borderRadius="14px" mb={2} />
        <Skeleton height="100px" width="100%" borderRadius="14px" />
      </Box>
      <Skeleton height="30px" width="100%" borderRadius="14px" mb={4} />
      <Box borderTopWidth={1} borderColor="#f5f7fa" pt={4}>
        <Skeleton height="40px" borderRadius="14px" width="200px" mb={4} />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
        <Skeleton height="100px" borderRadius="14px" width="100%" mt={4} />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>
    </>
  );

  const handleToggle = () => setShow(!show)

  return (
    <Drawer 
        size="xl"
        placement="right" 
        isOpen={isOpen} 
        onClose={onClose} 
        closeOnOverlayClick={false} 
        closeOnEsc={false}
    >
      <DrawerOverlay 
        backdropFilter="blur(10px)"      
      />
      <DrawerContent
        borderLeftRadius={16}
      >
        <DrawerCloseButton />
        <DrawerHeader>{t('copilotdrawer.drawer.title')}</DrawerHeader>
        <DrawerBody>
            {isLoading || isUpdating ? (
                <DrawerContentSkeleton />
            ) : (
            <>
            <Fade in={true}>
            <Box mb={4}>
                <Text mb={2} fontSize="14px" fontWeight="bold" textColor="#364954">
                {t('copilotdrawer.drawer.youareviewing')}:
                </Text>
                <Box 
                    p={2}
                    borderRadius="14px"
                    bg="#f5f7fa"
                    fontSize="14px" 
                    textColor="#364954"
                >
                    <Flex justifyContent="space-between">
                        <Text as="b">
                            {entry.title}, {entry.organization_name}, {entry.start} - {entry.end || t('Present')}
                        </Text>
                        <Button
                            variant="link"
                            size="sm"
                            onClick={handleToggle}
                            mr="2"
                        >
                            { show ? t('copilotdrawer.drawer.collapse') : t('copilotdrawer.drawer.expand')}
                        </Button>
                    </Flex>
                    <Collapse startingHeight={20} in={show}>
                    <Text
                        whiteSpace="pre-wrap"
                    >
                        {entry.description}
                    </Text>
                    </Collapse>
                </Box>
            </Box>
          <Tabs isFitted variant='unstyled' mb={4}>
            <TabList>
              <Tab
                borderTopRadius={16}
                height="35px"
                fontSize="16px"
                mx={1}
                _hover={{
                    color: 'white',
                    bg: '#82B2CA',
                    fontWeight: 'bold'
                }}
                _selected={{ 
                    color: 'white',
                    bg: '#364954',
                    fontWeight: 'bold'
                    }}
              >{t('copilotdrawer.drawer.tab-evaluation')}</Tab>
              <Tab
                borderTopRadius={16}
                height="35px"
                fontSize="16px"
                mx={1}
                _hover={{
                    color: 'white',
                    bg: '#82B2CA',
                    fontWeight: 'bold'
                }}
                _selected={{ 
                    color: 'white',
                    bg: '#364954',
                    fontWeight: 'bold'
                    }}
              >{t('copilotdrawer.drawer.tab-retouch')}</Tab>
              <Tab
                isDisabled
                borderTopRadius={16}
                height="35px"
                fontSize="16px"
                mx={1}
                _hover={{
                    color: 'white',
                    bg: '#82B2CA',
                    fontWeight: 'bold'
                }}
                _selected={{ 
                    color: 'white',
                    bg: '#364954',
                    fontWeight: 'bold'
                    }}
              >{t('copilotdrawer.drawer.tab-interviewprep')}</Tab>
            </TabList>
            <TabPanels borderTopWidth={1} borderColor="#f5f7fa">
              <TabPanel
                p={0}
                bg="#FFFFFF"
              >
                {isLoading ? (
                    <DrawerContentSkeleton />
                ) : targetJob ? (
                <EvaluationResult 
                    session={session} 
                    entryId={entryId} 
                    entryType={entryType}
                    entryUpdatedAt={entryUpdatedAt}
                    data={{
                        resume_title: entry.title || entry.role,
                        resume_description: entry.description,
                        target_job_title: targetJob.title,
                        target_job_responsibilities: targetJob.responsibilities,
                        target_job_requirements: targetJob.requirements,
                        target_job_fields_updated_at: targetJob.updated_at,
                    }}
                    updateLatestEvaluationResult={updateLatestEvaluationResult}
                />
                ) : (
                    <Text>{t('copilotdrawer.drawer.tab-somethingwrong')}</Text>
                )}
              </TabPanel>
              <TabPanel
                p={0}
                bg="#FFFFFF"
              >
                {isLoading ? (
                    <DrawerContentSkeleton />
                ) : targetJob ? (
                <RetouchResult 
                    session={session} 
                    entryId={entryId} 
                    entryType={entryType}
                    entryUpdatedAt={entryUpdatedAt}
                    data={{
                        resume_title: entry.title || entry.role,
                        resume_description: entry.description,
                        target_job_title: targetJob.title,
                        target_job_responsibilities: targetJob.responsibilities,
                        target_job_requirements: targetJob.requirements,
                        target_job_fields_updated_at: targetJob.updated_at,
                    }}
                    latestEvaluationResult={latestEvaluationResult}
                    onUpdateEntry={onUpdateEntry}
                    isEntryUpdating={isUpdating}
                />
                ) : (
                    <Text>{t('copilotdrawer.drawer.tab-somethingwrong')}</Text>
                )}
              </TabPanel>
              <TabPanel>
                {/* Interview Tips tab content */}
              </TabPanel>
            </TabPanels>
          </Tabs>
            <Box 
                bg="#f5f7fa"
                mb="20px"
                borderRadius={16}
                alignContent="left"
                p={4}
            >
                <Text
                    fontSize="12px"
                    textColor="#737D82"
                    fontWeight="medium"
                >
                    {t('copilotdrawer.evaluation.footernotice-title')}:
                </Text>
                <Text
                    fontSize="12px"
                    textColor="#737D82"
                >
                    {t('copilotdrawer.evaluation.footernotice-content')}
                </Text>
            </Box>
            </Fade>

          </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CopilotDrawer;