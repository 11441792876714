// utils/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: true,  // This is important for sending cookies
});

axiosInstance.interceptors.request.use(function (config) {
  const csrfToken = getCookie('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

axiosInstance.interceptors.response.use(
  function (response) {
    // If the response is successful, just return it
    return response;
  },
  function (error) {
    // Check if the response is 401 (Unauthorized) or 403 (Forbidden)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Remove 'isLoggedIn' value from localStorage
      localStorage.removeItem('isLoggedIn');
      
      // Redirect to the sign-in page if the session has expired
      window.location.href = '/sign-in';  // Update the URL as per your sign-in route
    }
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         const refreshToken = localStorage.getItem('refresh_token');
//         const response = await axios.post('/api/v1/accounts/refresh-tokens/', { refresh: refreshToken });
        
//         if (response.status === 200) {
//           localStorage.setItem('access_token', response.data.access);
//           axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + response.data.access;
//           return axiosInstance(originalRequest);
//         }
//       } catch (refreshError) {
//         localStorage.removeItem('access_token');
//         localStorage.removeItem('refresh_token');
//         localStorage.removeItem('isLoggedIn');
//       }
//     }

//     return Promise.reject(error);
//   }
// );
  
export default axiosInstance;