// src/components/HomePage/CallToAction.js
import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Button, VStack, Flex, Skeleton } from '@chakra-ui/react';
import { ArrowRight } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CallToAction = () => {
  const { t, ready } = useTranslation('homepage');
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const renderContent = () => (
    <VStack spacing={6} textAlign="center" maxW="800px" mx="auto" textColor="#FFFFFF">
      <Heading as="h2" size="xl">{t('calltoaction.title')}</Heading>
      <Text fontSize="lg">{t('calltoaction.subtitle')}</Text>
      <Button 
        rightIcon={<ArrowRight />}
        as={RouterLink} 
        to="/sign-up" 
        size="lg"
        borderRadius="full"
        color="#364954" 
        backgroundColor="#FFFFFF"
        // bg="linear-gradient(to right, #ec77ab 0%, #7873f5 100%);"
        _hover={{
          color:"#ffffff",
          bg:"#82B2CA"
        }}
      >
        {t('hero.cta')}
      </Button>
    </VStack>
  );

  const renderPlaceholder = () => (
    <VStack spacing={6} textAlign="center" maxW="800px" mx="auto">
      <Skeleton height="40px" width="70%" />
      <Skeleton height="24px" width="90%" />
      <Skeleton height="48px" width="200px" />
    </VStack>
  );

  return (
    <Box 
      // bg="#ffffff" 
      bg="linear-gradient(to bottom, #f5f7fa 0%, #f5f7fa 100%)"
      py={20}
    >
      <Flex 
        alignItems="center"
        justifyContent="center"
        mx={10}
        bg="#364954"
        // bg="linear-gradient(to right, #868f96 0%, #596164 100%);"
        borderRadius={16}

      >
        <Box
          px={3}
          py={20}
        >
        {showContent ? renderContent() : renderPlaceholder()}
        </Box>
      </Flex>
    </Box>
  );
};

export default CallToAction;