import React, { useState } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Button,
  FormControl, FormLabel,
  Textarea,
  useToast,
  RadioGroup, Radio, VStack, Checkbox,
  Alert, AlertIcon, Box, Text,
  Spinner
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import { fetchUserProfile } from '../../utils/orderApi';

const CreateTicketModal = ({ isOpen, onClose, onTicketCreated }) => {
  const { t } = useTranslation();  
  const [formData, setFormData] = useState({
    category: '',
    description: '',
    email_notify: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [charCount, setCharCount] = useState(0);
  const MAX_CHARS = 999;

  const handleChange = (e) => {
    if (isSubmitting) return; // Prevent changes during submission
    const { name, value, type, checked } = e.target;
    if (name === 'description') {
      if (value.length <= MAX_CHARS) {
        setFormData(prev => ({ ...prev, [name]: value }));
        setCharCount(value.length);
      }
    } else {
      setFormData(prev => ({ 
        ...prev, 
        [name]: type === 'checkbox' ? checked : value 
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axiosInstance.post('/api/v1/feedback/tickets/', formData);
      onClose();
      if (onTicketCreated) onTicketCreated();
      setFormData({ category: '', description: '', email_notify: false }); // Reset form
      toast({
        title: t('feedback.toast.create-success'),
        description: t('feedback.toast.create-success-des'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });

      // Fetch user's onboarding tasks
      const onboardingTasksResponse = await axiosInstance.get('/api/v1/accounts/onboarding-tasks/');

      if (onboardingTasksResponse.status === 200) {
        const tasks = onboardingTasksResponse.data;
        const leaveFeedbackTask = tasks.find(task => task.task_id === 4);

        if (leaveFeedbackTask && !leaveFeedbackTask.is_completed) {
            // call the API to complete the task only if it's not already completed
            try {
                const onboardingResponse = await axiosInstance.post('/api/v1/accounts/check-complete-leave-feedback-task/');

                if (onboardingResponse.status === 200) {
                    await fetchUserProfile();

                    toast({
                        title: t('feedback.onboardingtask.toast-complete'),
                        description: `${t('feedback.onboardingtask.toast-complete-des1')} ${onboardingResponse.data.credits_awarded} ${t('feedback.onboardingtask.toast-complete-des2')}`,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right',
                    });
                }
            } catch (error) {
                console.error('Error completing leave feedback task:', error);
            }
        }
      }

    } catch (error) {
      console.error('Error creating ticket:', error);
      toast({
        title: t('feedback.toast.create-error'),
        description: error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
        });
    } finally {
    setIsSubmitting(false);
    }
};

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay 
        backdropFilter="blur(10px)"
      />
      <ModalContent
        borderRadius={16}
        boxShadow="lg"
        background="rgba(255, 255, 255, 1)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
        _hover={{
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
        }}      
      >
        <ModalHeader>{t('feedback.modal.title')}</ModalHeader>
        <ModalCloseButton isDisabled={isSubmitting} />
        <ModalBody>
          <FormControl isRequired isDisabled={isSubmitting}>
            <FormLabel>{t('feedback.modal.question-category')}</FormLabel>
            <RadioGroup 
                name="category" 
                onChange={(value) => setFormData(prev => ({ ...prev, category: value }))} 
                value={formData.category}
            >
              <VStack direction="column" align="left">
                <Radio colorScheme="gray" value="BUG">{t('feedback.modal.cat-bug')}</Radio>
                <Radio colorScheme="gray" value="FEATURE">{t('feedback.modal.cat-feature')}</Radio>
                <Radio colorScheme="gray" value="UX">{t('feedback.modal.cat-ux')}</Radio>
                <Radio colorScheme="gray" value="OTHER">{t('feedback.modal.cat-other')}</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl mt={4} isRequired isDisabled={isSubmitting}>
            <FormLabel>{t('feedback.modal.question-description')}</FormLabel>
            <Textarea 
                name="description" 
                onChange={handleChange} 
                value={formData.description} 
                fontSize="14px"
                placeholder= {t('feedback.modal.question-placeholder')}
                maxLength={MAX_CHARS}
            />
            <Text fontSize="12px" textAlign="right" mt={1} color="#737D82">
                {charCount}/{MAX_CHARS} 
            </Text>
          </FormControl>
          {/* <FormControl mt={4}>
            <FormLabel>{t('feedback.modal.question-email-opt')}</FormLabel>
            <Checkbox
              name="email_notify"
              onChange={handleChange}
              isChecked={formData.email_notify}
              colorScheme="gray" 
            >
              {t('feedback.modal.question-email-des')}
            </Checkbox>
          </FormControl> */}
        <Box mt="5">
            <Alert status="info" borderRadius="14px" fontSize="14px" fontWeight="medium">
                <AlertIcon />
                <Text >{t('feedback.modal.alert')}</Text>
            </Alert>
        </Box>
        </ModalBody>
        <ModalFooter>
            <Button 
                mr={3} 
                onClick={handleSubmit}
                isDisabled={!formData.category || !formData.description || isSubmitting}
                color="#ffffff" 
                backgroundColor="#364954"
                borderRadius="14px"
                _hover={{
                  color:"#ffffff",
                  backgroundColor:"#82B2CA"
                }}
            >
            {isSubmitting ? <Spinner size="sm" /> : t('feedback.modal.btn-submit')}
            </Button>
            <Button 
                variant="ghost" 
                onClick={onClose}
                borderRadius="14px"    
                isDisabled={isSubmitting}
            >
                {t('feedback.modal.btn-cancel')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};  

export default CreateTicketModal;