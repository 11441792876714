import React, { useState, useEffect } from 'react';
import {
  Box, Heading,
  Text, Button,
  VStack, Divider, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Tooltip,
  HStack, Skeleton, SkeletonText, Spinner, Badge
} from '@chakra-ui/react';
import { LuPlusCircle, LuHelpCircle } from "react-icons/lu";
import axiosInstance from '../../utils/axiosConfig';
import ProjectEntry from './ProjectEntry';
import ProjectForm from './ProjectForm';
import { useTranslation } from 'react-i18next';

const Projects = ({ resumeId, updateResumeState }) => {
  const { t } = useTranslation();  
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const toast = useToast();

  const sortProjects = (projects) => {
    return projects.sort((a, b) => {
      const aEnd = a.end === t('Present') ? new Date() : new Date(a.end);
      const bEnd = b.end === t('Present') ? new Date() : new Date(b.end);
      return bEnd - aEnd;
    });
  };

  useEffect(() => {
    fetchProjects();
  }, [resumeId]);

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/resumes/projects/?resume=${resumeId}`);
      setProjects(sortProjects(response.data));
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch projects');
      setLoading(false);
    }
  };

  const handleAdd = async (newProject) => {
    try {
      const response = await axiosInstance.post('/api/v1/resumes/projects/', {
        ...newProject,
        resume: resumeId
      });
      setProjects(sortProjects([...projects, response.data]));
      setIsAdding(false);
      toast({
        title: t('prj.add_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to add project');
      toast({
        title: t('prj.add_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleUpdate = async (updatedProject) => {
    try {
      const response = await axiosInstance.put(`/api/v1/resumes/projects/${updatedProject.id}/`, {
        ...updatedProject,
        resume: resumeId
      });
      setProjects(sortProjects(projects.map(prj => prj.id === updatedProject.id ? response.data : prj)));
      toast({
        title: t('prj.update_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to update project');
      toast({
        title: t('prj.update_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(`/api/v1/resumes/projects/${id}/`);
      setProjects(projects.filter(prj => prj.id !== id));
      setIsDeleteModalOpen(false);
      toast({
        title: t('prj.delete_success'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      updateResumeState();
    } catch (err) {
      setError('Failed to delete project');
      toast({
        title: t('prj.delete_fail'),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const ProjectSkeleton = () => (
    <Box>
      <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='3' />
      <SkeletonText mt='4' noOfLines={2} spacing='4' />
      <Skeleton height='20px' mt='4' />
    </Box>
  );

  if (loading) return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
    >
      <Box
        p="4"
      >
        <HStack align="flex-start" justify="space-between">
          <Skeleton height="24px" width="150px" />
          <Skeleton height="24px" width="24px" />
        </HStack>
        <Divider my={4} />
        <VStack spacing={4} align="stretch">
          <ProjectSkeleton />
          <ProjectSkeleton />
          <ProjectSkeleton />
        </VStack>
      </Box>
    </Box>
  );

  if (error) return <Box>{error}</Box>;

  return (
    <Box
      borderRadius={12}
      background="rgba(255, 255, 255, 1)"
      transition="all 0.3s ease"
      _hover={{
        boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        p={4}
        pb={2}
      >
        <HStack align="flex-start" justify="space-between">
          <Heading as="h2" fontSize="lg" mb={2} textColor="#364954">
            {t('prj.section_title')}
            <Tooltip
                hasArrow 
                borderRadius={12}
                bg='#CFD9DF' 
                color='#364954' 
                placement='top'
                label={
                <Box p={1}>
                        <Text>
                        {t('resumepage.project.section-add-beta-desc')}
                        </Text>
                </Box>
                } 
            >
            <Badge ml={2} size="sm" color="#FFF5DF" bg="#D5BDA7" borderRadius="6">
            {t('layout.beta')}
            </Badge>
            </Tooltip>
          </Heading>
          <Tooltip 
            hasArrow 
            borderRadius={12} 
            bg='#CFD9DF' 
            color='#364954' 
            placement='top'
            label={
              <Box p={1}>
              {t('prj.section_intro')}
              </Box>
            } 
          >
            <Text><LuHelpCircle /></Text>
          </Tooltip>
        </HStack>
        {projects.length > 0 && <Divider my={1} />}
        <VStack spacing={0} align="stretch">
        {projects.length === 0 ? (
          <Text
            fontSize="sm"
            textColor="#737D82"
            mt="2"
            mb="1"
          >
          {t('prj.norecord')}</Text>
        ) : (
        projects.map((project, index) => (
            <React.Fragment key={project.id}>
              <ProjectEntry
                project={project}
                onUpdate={handleUpdate}
                onDelete={() => {
                  setProjectToDelete(project);
                  setIsDeleteModalOpen(true);
                }}
                resumeId={resumeId}
              />
              {index < projects.length - 1 && <Divider my={1} />}
            </React.Fragment>
          ))
        )}
        </VStack>
      </Box>
      {projects.length > 0}
        {isAdding ? (
          <Box px={4} pb={1}>
          <ProjectForm 
            onSubmit={handleAdd} 
            onCancel={() => setIsAdding(false)} 
            resumeId={resumeId}
          />
          </Box>
        ) : (
          <Box 
            borderBottomRadius="12px"
            px="4"
            py="2"
          >
            <Button 
              leftIcon={<LuPlusCircle />} 
              onClick={() => setIsAdding(true)} 
              variant="ghost" 
              size="sm"
              borderRadius="12px"
              textColor="#364954"
            >
              {t('prj.add')}
            </Button>
          </Box>
        )}

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay
          backdropFilter="blur(10px)"
          />
        <ModalContent
          borderRadius={12}
          boxShadow="lg"
          background="rgba(255, 255, 255, 0.9)"
          backdropFilter="blur(10px)"
          transition="all 0.1s ease"
          _hover={{
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
          }}
          >
          <ModalHeader>{t('prj.delete_modaltitle')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          {t('prj.delete_modalbody')}
          </ModalBody>
          <ModalFooter>
            <Button 
              variant="ghost"
              mr={3} 
              borderRadius="12px"
              onClick={() => setIsDeleteModalOpen(false)}
              isDisabled={isDeleting}
            >
              {t('prj.cancel')}
            </Button>
            <Button 
              colorScheme="red" 
              borderRadius="12px"
              onClick={() => handleDelete(projectToDelete.id)}
              isLoading={isDeleting}
              loadingText={t('prj.deleting')}
            >
              {isDeleting ? <Spinner size="sm" /> : t('prj.delete')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Projects;