import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Heading, VStack, Image, 
  Skeleton, useBreakpointValue, SkeletonText,
  Badge 
} from '@chakra-ui/react';
import { WandSparkles } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const OurSolutionsResumePolish = () => {
  const { t, i18n, ready } = useTranslation("homepage");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  // Using Chakra's responsive values
  const flexDirection = useBreakpointValue({ base: "column-reverse", md: "row" });
  const textAlign = useBreakpointValue({ base: "center", md: "left" });
  const imageFirst = useBreakpointValue({ base: true, md: false });

  const imageUrls = {
    jobAnalysis: {
      en: "/homepage/solution-job-analysis.png",
      zh: "/homepage/solution-job-analysis-zh.png",
    },
    resumeAnalysis: {
      en: "/homepage/solution-resume-analysis.png",
      zh: "/homepage/solution-resume-analysis-zh.png",
    },
    resumeRetouch: {
      en: "/homepage/solution-resume-retouch.png",
      zh: "/homepage/solution-resume-retouch-zh.png",
    },
    // Add more image types as needed
  };

  return (
    <Box 
      // bg="#f5f7fa" 
      bg="linear-gradient(to top, #f5f7fa 0%, #e7f0fd 100%)"
      pb={10}
    >
      <Box 
        pt={10} 
        pb={5} 
        px={6} 
        align="center"
      >
        <Text mb={2}><WandSparkles size="50px" /></Text>
          <Heading as="h2" size="xl" textAlign="center" mb={10} mx="auto">
            {showContent ? t('v2.solution.resumepolish.title') : <Skeleton height="40px" width="200px" mx="auto" />}
          </Heading>
        <Box px={6}>
          <Skeleton isLoaded={showContent} borderRadius={12} >
            <Text as="h3" fontSize="20px" fontWeight="bold" mb={5}>
            {t('v2.solution.resumepolish.subtitle')}
            </Text>
            <Text fontSize="16px" maxW={{ base: "400px", md: "800px" }}>
            {t('v2.solution.resumepolish.headingdesc')}
            </Text>
          </Skeleton>
        </Box>
      </Box>

      {/* Job Analysis Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box width={{ base: "100%", md: "40%" }} textAlign={textAlign}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12} >
                <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.resumepolish.jobanalysis.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12} >
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.resumepolish.jobanalysis.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.resumepolish.jobanalysis.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>

          <Box width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }} ml={{ md: "10" }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.jobAnalysis[i18n.language] || imageUrls.jobAnalysis.en}
                alt="Job Analysis Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>
        </Flex>
      </Box>

      {/* Resume Analysis Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box order={imageFirst ? 2 : 1} width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.resumeAnalysis[i18n.language] || imageUrls.resumeAnalysis.en}
                alt="Resume Analysis Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>

          <Box order={imageFirst ? 1 : 2} width={{ base: "100%", md: "40%" }} textAlign={textAlign} ml={{ md: "10" }}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12} >
                <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.resumepolish.resumeanalysis.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.resumepolish.resumeanalysis.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.resumepolish.resumeanalysis.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>
        </Flex>
      </Box>

      {/* Resume Retouch Section */}
      <Box py={10} px={6} align="center">
        <Flex
          maxW="1200px"
          direction={flexDirection}
          align="center"
        >
          <Box width={{ base: "100%", md: "40%" }} textAlign={textAlign}>
            <VStack align="left">
              <Skeleton isLoaded={showContent} borderRadius={12} >
                <Badge as="h2" 
                  fontSize="16px" 
                  fontWeight="bold" 
                  variant="solid" 
                  borderRadius="full" 
                  px={3}
                  bg="linear-gradient(to right, #82B2CA 0%, #364954 100%, #82B2CA 100%, #364954 100%);"
                >
                {t('v2.solution.resumepolish.resumeretouch.title')}
                </Badge>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="3xl" fontWeight="bold" mt={2}>
                {t('v2.solution.resumepolish.resumeretouch.tagline')}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={showContent} borderRadius={12}>
                <Text fontSize="md" color="gray.600" mt={4}>
                {t('v2.solution.resumepolish.resumeretouch.desc')}
                </Text>
              </Skeleton>
            </VStack>
          </Box>

          <Box width={{ base: "100%", md: "60%" }} mt={{ base: 10, md: 0 }} ml={{ md: "10" }}>
            <Skeleton isLoaded={showContent} borderRadius={12}>
              <Image
                src={imageUrls.resumeRetouch[i18n.language] || imageUrls.resumeRetouch.en}
                alt="Resume Retouch Graphic"
                objectFit="contain"
                width="100%"
              />
            </Skeleton>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default OurSolutionsResumePolish;
