import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, VStack, Image } from '@chakra-ui/react';
import { MonitorCheck } from 'lucide-react';
import { isWeChatBrowser } from '../utils/WechatDetect';

const WeChatNotice = () => {
    const [isWeChat, setIsWeChat] = useState(false);

    useEffect(() => {
        if (isWeChatBrowser()) {
            setIsWeChat(true);
        }
    }, []);

    if (!isWeChat) {
        return null;
    }

    return (
        <Box 
            position="fixed" 
            top="0" 
            left="0" 
            width="100%" 
            height="100%"
            bg="#CFD9DF" 
            color="white" 
            textAlign="center" 
            p="4" 
            zIndex="9999"
            backdropFilter="blur(20px)"
        >
            <Box
                mt={5}
                bg="#FFFFFF"
                borderRadius="16px"
                p={5}
                textColor="#364954"
            >
            <Flex
                direction="row"
                align="center"
            >
            <MonitorCheck
                size="100px"
            />
            <VStack textAlign="left" ml="6">
                <Text
                    mt={3}
                    fontWeight="bold"
                >
                    It looks like you're using the WeChat browser. For the best experience, please open this website in your system browser.
                </Text>
                <Text
                    mb={3}
                    fontWeight="bold"
                >
                    为了给您提供更好的浏览和使用体验，请您使用系统自带的浏览器打开本网站。
                </Text>
            </VStack>
            </Flex>
            </Box>
            <Box align="center" mt={8} > 
                <Image src="/myCareerHelp.png" alt="site_logo" mx="auto" width="150px" />
                <Text fontSize="14px" color="#737D82">Empowering Careers, One Step at a Time.</Text>
            </Box>
        </Box>
    );
};

export default WeChatNotice;