// src/components/Orders/ResumeAnalysisSection.js
import React, { useState, useEffect } from 'react';
import { Box, Text, Spinner, Flex, VStack, HStack, 
  Table, Thead, Tbody, Tr, Th, Td, TableContainer,
  Heading, Alert, AlertIcon, AlertTitle, AlertDescription, Tag, Divider } from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import RerunButton from './RerunButton';

const ResumeAnalysisSection = ({ taskId }) => {
  const { t } = useTranslation();
  const [resumeAnalysis, setResumeAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getColorScheme = (score) => {
    switch (score) {
      case "0.0":
        return "blackAlpha";
      case "1.0":
        return "yellow";
      case "2.0":
        return "blue";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    const fetchResumeAnalysis = async () => {
      if (!taskId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get(`/api/v1/orders/resume-analysis/${taskId}/`);
        setResumeAnalysis(response.data);
      } catch (err) {
        console.error('Error fetching resume analysis:', err);
        setError('Failed to load resume analysis');
      } finally {
        setIsLoading(false);
      }
    };

    fetchResumeAnalysis();
  }, [taskId]);

  if (isLoading)
    return <Spinner />;

  if (error)
    return (
      <Alert status="error" variant="left-accent" borderRadius="md" mb={4}>
        <AlertIcon />
        <AlertTitle mr={2}>{t('orderdetail.error_title')}</AlertTitle>
        <HStack spacing={2}>
          <AlertDescription>{t('orderdetail.error_content')}</AlertDescription>
          <RerunButton taskId={taskId} onRerunComplete={() => window.location.reload()} />
        </HStack>
      </Alert>
    );

  if (!resumeAnalysis)
    return null;

  return (
    <Flex align="stretch">
      <VStack align="left" width="100%">
        <Heading size="16px" mt={2} mb={2} color="#2D3748">{t('orderdetail.resumeanalysis_requirements_title')}</Heading>
        <Flex width="100%" direction="column" mb="6">
          <TableContainer width="100%" mb="3">
            <Table variant='simple' size="md">
              <Thead>
                <Tr>
                  <Th>{t('orderdetail.resumeanalysis_th_requirements')}</Th>
                  <Th>{t('orderdetail.resumeanalysis_th_score')}</Th>
                  <Th>{t('orderdetail.resumeanalysis_th_analysis')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {resumeAnalysis.requirement_matches.map(match => (
                  <Tr key={match.id}>
                    <Td whiteSpace={"pre-wrap"} fontSize="sm">{match.requirement}</Td>
                    <Td><Tag size="sm" colorScheme={getColorScheme(match.score)}>{match.score}</Tag></Td>
                    <Td whiteSpace={"pre-wrap"} fontSize="sm">{match.analysis}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Text
            fontSize="14px"
            color="#737D82"
          >{t('orderdetail.resumeanalysis_requirements_tablecaption')}</Text>
        </Flex>
        <Box>
          <Heading size="16px" mb={4} color="#2D3748">{t('orderdetail.resumeanalysis_recentjobs_title')}</Heading>
          {resumeAnalysis.recent_jobs.map(job => (
            <Box key={job.id} mb={4} p={0}
              background="#ffffff"
              borderRadius={12}
            >
              <Flex 
                key={job.id} 
                align="left"
                bg="#f5f7fa"
                borderRadius="14px"
                mb={4}
              >
                <HStack align="top" p={3}>
                  <Box 
                    width="30%" 
                    p={1}
                    bg="#f5f7fa"
                    borderRadius="14px"
                  >
                    <Text fontWeight="bold" fontSize="16px" color="#2D3748" mb={3}>{job.job_role}</Text>
                    <Text as="cite">{job.summary}</Text>
                  </Box>
                  <VStack 
                    align="left" 
                    fontSize="14px" 
                    width="70%"
                    spacing="3"
                    ml="3"
                  >
                    <Box 
                      bg="#ffffff"
                      borderRadius="14px"
                      px={3}
                      py={3}
                    >
                      <Text as="b" color="#737D82">{t('orderdetail.resumeanalysis_capabilities')}:</Text>
                      <Text fontSize="16px">{job.capabilities}</Text>
                    </Box>
                    <Box
                      bg="#ffffff"
                      borderRadius="14px"
                      px={3}
                      py={3}
                    >
                      <Text as="b" color="#737D82">{t('orderdetail.resumeanalysis_alignments')}? </Text>
                      <Text fontSize="16px">{job.alignment}</Text>
                    </Box>
                    <Box
                      bg="#ffffff"
                      borderRadius="14px"
                      px={3}
                      py={3}
                      mb={3}
                    >
                      <Text as="b" color="#737D82">{t('orderdetail.resumeanalysis_risks')}: </Text>
                      <Text fontSize="16px">{job.risks}</Text>
                    </Box>
                  </VStack>
                </HStack>
              </Flex>
            </Box>
          ))}
        </Box>
      </VStack>
    </Flex>
  );
};

export default ResumeAnalysisSection;
